// @flow
import React, { useState, useContext } from 'react'
import { change } from 'redux-form'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { useParams } from 'react-router-dom'
import {
  addNetworkAction,
  removeNetworkAction,
  addConnectionAction,
  removeConnectionAction,
  confirmConnectionAction
} from 'src/state/actions/profile'
import { makeStyles } from '@material-ui/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import Button from 'src/components/atoms/buttons/Button'
import IconButton from 'src/components/atoms/buttons/IconButton'
import CreateIcon from '@material-ui/icons/Create'
import FullPageLoading from 'src/components/atoms/FullPageLoading'
import CardActionArea from '@material-ui/core/CardActionArea'
import EditNameForm from 'src/components/organisms/forms/EditName'
import EditBioForm from 'src/components/organisms/forms/EditBio'
import EditProfileImageForm from 'src/components/organisms/forms/EditProfileImage'
import { FORM_ID } from 'src/components/organisms/forms/CreateThread'
import getImage from 'src/utils/images/getImage'
import { ConfigContext } from 'src/components/hoc/RouteWithConfig'
import type { ProfileState } from 'src/state/reducers/profile'
import type { State } from 'src/utils/types'
import type { SocialNetwork } from 'src/utils/types/users'
import { useAnalytics } from 'src/hooks/useAnalytics'

// $FlowFixMe
import { ReactComponent as OptionsIcon } from 'src/images/optionsIcon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import YoutubeIcon from '@material-ui/icons/YouTube'
import { useAuth } from '../../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  container: {
    width: '90%',
    margin: 'auto',
    marginTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      flexDirection: 'column'
    }
  },
  flexColumnLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nameAndOptions: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  optionsButton: {
    marginLeft: theme.spacing(2),
    padding: 0
  },
  flexColumnRight: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: theme.spacing(3)
    }
  },
  name: {
    fontWeight: 'bolder',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem'
    }
  },
  followerStats: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(3),
      textAlign: 'center'
    }
  },
  publisherFollowerCount: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.label
  },
  bioWrapper: {
    display: 'flex'
  },
  bio: {
    display: 'block',
    color: theme.palette.text.label,
    overflow: 'hidden',
    lineHeight: 'calc(.875rem * 1.75)',
    maxWidth: 700,
    maxHeight: 'calc(0.855rem * 4 * 2.4)',
    textOverflow: 'ellipsis',
    '&::after': {
      content: '"..."'
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      textAlign: 'center'
    }
  },
  bioParagraph: {
    paddingBottom: theme.spacing(1.5),
    lineHeight: '150%'
  },
  followButtons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    width: 300,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      justifyContent: 'center',
      margin: 'auto'
    }
  },
  followButton: {
    marginRight: theme.spacing(2)
  },
  uploadImageButton: {
    position: 'relative',
    display: 'flex'
  },
  uploadImageHeader: {
    position: 'absolute',
    top: '35%',
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    textShadow: '0px 0px 10px rgba(0,0,0,0.60)'
  },
  profileImage: {
    border: props =>
      props.isReferred
        ? `4px solid ${theme.palette.tertiary.main}`
        : `4px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.main,
    backgroundImage: props => `url(${props.profileImage})`,
    filter: "brightness(75%)",
    backgroundSize: 'cover',
    borderRadius: 185,
    width: 185,
    height: 185,
    marginBottom: theme.spacing(1)
  },
  editIcon: {
    color: '#FFF'
  },
  editButton: {
    minWidth: 45,
    height: 45,
    width: 45,
    marginLeft: theme.spacing(2)
  },
  readMore: {
    cursor: 'pointer',
    textDecoration: 'underline',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  paidMemberIcon: {
    width: 95,
    height: 35,
    backgroundColor: theme.palette.tertiary.main,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5
  },
  referredCaption: {
    marginLeft: theme.spacing(1),
    color: '#FFF'
  },
  socialMedia: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '& > a': {
      marginRight: theme.spacing(2)
    },
    '& > a:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      '& > a': {
        marginRight: theme.spacing(3)
      }
    }
  },
  socialMediaIcon: {
    transition: 'transform 0.2s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.4)'
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(1.2)'
    }
  },
  fb: {
    color: '#3b5998'
  },
  insta: {
    color: '#E1306C'
  },
  twitter: {
    color: '#00acee'
  },
  youtube: {
    color: '#c4302b'
  }
}))

const isLoggedInUser = (id: string) => {
  const userJSON = localStorage.getItem('user')
  if (userJSON) {
    const loggedInUser = JSON.parse(userJSON)
    return loggedInUser._id === id
  }
  return false
}

const selectProfile: State => ProfileState = state => state.profile

type ProfileHeaderProps = {
  editMode?: boolean
}

export default ({ editMode }: ProfileHeaderProps) => {
  const profile: ProfileState = useSelector(selectProfile, shallowEqual)
  const {
    user = null,
    followButtonProcessing,
    connectionButtonProcessing,
    isFollowing,
    isConnected,
    isConnectionPending,
    isAwaitingConfirmation,
    isLoading,
    profileImage: uploadedProfileImage
  } = profile
  const config = useContext(ConfigContext)
  const { user: currentUser } = useAuth()
  const profileImage = user && getImage('profileImage', user._id, config)
  const didChangeProfileImage =
    !!uploadedProfileImage && user?._id === currentUser?._id
  const styles = useStyles({
    //have to invalidate image otherwise it will used cache version
    profileImage: didChangeProfileImage
      ? `${uploadedProfileImage}?invalidator=${Math.random()}`
      : profileImage,
    isReferred: user && user.isReferred
  })
  const businessVerified =
    currentUser?.accountType !== 'Business' || currentUser?.isVerified
  let currentUserIsPublisher = false
  if (currentUser && currentUser.stats.occupation.includes('Publisher')) {
    currentUserIsPublisher = true
  }
  const [isBioOpen, setIsBioOpen] = useState(false)
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [optionsAnchor, setOptionsAnchor] = useState(null)
  const [isEditNameOpen, setIsEditNameOpen] = useState(false)
  const [isEditBioOpen, setIsEditBioOpen] = useState(false)
  const [isEditProfileImageOpen, setIsEditProfileImageOpen] = useState(false)

  const { analytics } = useAnalytics()

  const dispatch = useDispatch()
  const { id } = useParams()

  // redirect to read only view if attempting to edit a profile that is not the current user's
  if (editMode && id && !isLoggedInUser(id)) {
    dispatch(push(`/profile/${id}`))
  }

  if (isLoading) return <FullPageLoading />

  const onFollowClicked = () => {
    if (!id) return

    if (isFollowing) {
      analytics.logEvent('unfollowed')
      dispatch(
        removeNetworkAction({
          recipient: id,
          followedByPublisher: currentUserIsPublisher
        })
      )
    } else {
      analytics.logEvent('followed')
      if (currentUserIsPublisher) {
        analytics.logEvent('publisher_followed')
      }
      dispatch(
        addNetworkAction({
          recipient: id,
          followedByPublisher: currentUserIsPublisher
        })
      )
    }
  }

  const onConnectClicked = () => {
    if (isConnected) {
      analytics.logEvent('disconnected')
      dispatch(
        removeConnectionAction({
          recipient: id
        })
      )
    } else if (isAwaitingConfirmation && user) {
      analytics.logEvent('connection_confirmed')
      dispatch(confirmConnectionAction({ sender: user._id }))
    } else {
      analytics.logEvent('connection_requested')
      dispatch(
        addConnectionAction({
          recipient: id
        })
      )
    }
  }

  let bioText
  if (user) {
    bioText =
      user.profile.bio.split('\n').map((line, index) => (
        <Typography
          variant="body2"
          key={index}
          color="inherit"
          className={styles.bioParagraph}
        >
          {line}
        </Typography>
      )) || 'This user has not created a bio yet.'
  }

  return (
    user && (
      <div className={styles.container}>
        <div className={styles.flexColumnLeft}>
          {editMode ? (
            <CardActionArea
              className={styles.uploadImageButton}
              onClick={() => setIsEditProfileImageOpen(true)}
            >
              <div className={styles.profileImage} />
              <Typography variant="h6" className={styles.uploadImageHeader}>
                Edit Image (square, max 3mb png, jpeg)
              </Typography>
            </CardActionArea>
          ) : (
            <div className={styles.profileImage} />
          )}
          <div className={styles.socialMedia}>
            {user.profile.socialMediaLinks.map((media, index) => (
              <SocialMediaLink key={`media.url-${media.name}`} media={media} />
            ))}
          </div>
          {user && user.isReferred && (
            <div className={styles.paidMemberIcon}>
              <FontAwesomeIcon icon={faGem} size="sm" />
              <Typography
                variant="caption"
                color="inherit"
                className={styles.referredCaption}
              >
                <b>REFERRED</b>
              </Typography>
            </div>
          )}
        </div>
        <div className={styles.flexColumnRight}>
          <div className={styles.nameAndOptions}>
            <Typography
              variant="h2"
              className={styles.name}
            >{`${user.firstname} ${user.lastname}`}</Typography>
            {editMode ? (
              <Fab
                color="secondary"
                aria-label="Edit Name"
                className={styles.editButton}
                onClick={() => setIsEditNameOpen(true)}
              >
                <CreateIcon className={styles.editIcon} />
              </Fab>
            ) : (
              <IconButton
                ariaLabel="more-options"
                className={styles.optionsButton}
                onClick={(e: Event) => {
                  setOptionsAnchor(e.currentTarget)
                  setIsOptionsOpen(true)
                }}
              >
                <OptionsIcon />
              </IconButton>
            )}
          </div>

          <Typography variant="subtitle2" className={styles.followerStats}>
            {`${user.stats.followingCount} following - ${user.stats.followerCount} followers`}
            <Typography
              variant="caption"
              className={styles.publisherFollowerCount}
            >
              {user.stats.publisherFollowerCount
                ? ` (${user.stats.publisherFollowerCount} are publishers)`
                : ''}
            </Typography>
          </Typography>
          <div className={styles.bioWrapper}>
            <CardActionArea onClick={() => setIsBioOpen(true)}>
              <div className={styles.bio}>{bioText}</div>
            </CardActionArea>
            {editMode && (
              <Fab
                color="secondary"
                aria-label="Edit Name"
                className={styles.editButton}
                onClick={() => setIsEditBioOpen(true)}
              >
                <CreateIcon className={styles.editIcon} />
              </Fab>
            )}
          </div>
          {!isLoggedInUser(user._id) && businessVerified && (
            <div className={styles.followButtons}>
              {!isConnected && (
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={followButtonProcessing}
                  loading={followButtonProcessing}
                  onClick={onFollowClicked}
                  className={styles.followButton}
                >
                  {isFollowing ? 'Unfollow' : 'Follow'}
                </Button>
              )}
              {user.accountType !== 'Admin' && (
                <Button
                  color={isConnected ? 'secondary' : 'primary'}
                  variant="contained"
                  disabled={connectionButtonProcessing || isConnectionPending}
                  loading={connectionButtonProcessing}
                  onClick={onConnectClicked}
                  className={styles.followButton}
                >
                  {isConnected
                    ? 'Disconnect'
                    : isConnectionPending
                    ? 'Pending'
                    : isAwaitingConfirmation
                    ? 'Accept Request'
                    : 'Connect'}
                </Button>
              )}
            </div>
          )}
        </div>
        <EditNameForm
          isOpen={isEditNameOpen}
          onClose={() => setIsEditNameOpen(false)}
          initialValues={{ firstname: user.firstname, lastname: user.lastname }}
        />
        <EditBioForm
          isOpen={isEditBioOpen}
          onClose={() => setIsEditBioOpen(false)}
          initialValues={{ bio: user.profile.bio }}
        />
        <EditProfileImageForm
          isOpen={isEditProfileImageOpen}
          onClose={() => setIsEditProfileImageOpen(false)}
          initialValues={{ profileImage: { img: profileImage } }}
        />
        <Dialog
          open={isBioOpen}
          onClose={() => setIsBioOpen(false)}
          aria-labelledby="bio-dialog-title"
          aria-describedby="bio-dialog-description"
        >
          <DialogTitle id="bio-dialog-title">{`${user.firstname} ${user.lastname}`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="bio-dialog-description">
              {bioText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={() => setIsBioOpen(false)}>
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
        {(isConnected || isLoggedInUser(user._id)) &&
          user.accountType !== 'Admin' && (
            <Menu
              keepMounted
              open={isOptionsOpen}
              anchorEl={optionsAnchor}
              onClose={() => {
                setIsOptionsOpen(false)
              }}
            >
              {isLoggedInUser(user._id) && (
                <MenuItem
                  onClick={() => {
                    dispatch(push(`/profile/edit/${user._id}`))
                  }}
                >
                  Edit
                </MenuItem>
              )}
              {!isLoggedInUser(user._id) && (
                <MenuItem
                  onClick={() => {
                    dispatch(
                      change(FORM_ID, 'member', {
                        value: user._id,
                        label: `${user.firstname} ${user.lastname}`
                      })
                    )
                    dispatch(push('/inbox?open=true'))
                  }}
                >
                  Send Message
                </MenuItem>
              )}
            </Menu>
          )}
      </div>
    )
  )
}

type SocialMediaLinkProps = {
  media: SocialNetwork
}
const SocialMediaLink = ({ media }: SocialMediaLinkProps) => {
  const styles = useStyles()
  return (
    <>
      {media.name === 'Facebook' && (
        <a href={media.url}>
          <FacebookIcon className={`${styles.fb} ${styles.socialMediaIcon}`} />
        </a>
      )}
      {media.name === 'Instagram' && (
        <a href={media.url}>
          <InstagramIcon
            className={`${styles.insta} ${styles.socialMediaIcon}`}
          />
        </a>
      )}
      {media.name === 'Twitter' && (
        <a href={media.url}>
          <TwitterIcon
            className={`${styles.twitter} ${styles.socialMediaIcon}`}
          />
        </a>
      )}
      {media.name === 'Youtube' && (
        <a href={media.url}>
          <YoutubeIcon
            className={`${styles.youtube} ${styles.socialMediaIcon}`}
          />
        </a>
      )}
    </>
    // </IconButton>
  )
}
