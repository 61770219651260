// @flow
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Link from '@material-ui/core/Link'
import { fade } from '@material-ui/core/styles/colorManipulator'

const getColor = (placement: string, variant: string, theme: Object) => {
  if (variant === 'menuitem') return theme.palette.text.main
  if (placement === 'default') return theme.palette.primary.main
  if (variant === 'contained') return theme.palette.menu.contrastText
  if (placement === 'menu') return theme.palette.menu.text
}

const useStyles = makeStyles(theme => ({
  root: {
    color: props => getColor(props.placement, props.variant, theme),
    display: 'flex',
    alignItems: 'center',
    width: props => (props.placement === 'menu' ? '100%' : 'auto'),
    background: props =>
      props.variant === 'contained' && theme.palette.secondary.main,
    paddingTop: props => props.placement === 'menu' && theme.spacing(1),
    paddingBottom: props => props.placement === 'menu' && theme.spacing(1),
    '&:hover': {
      color: props =>
        props.placement === 'menu'
          ? theme.palette.menu.contrastText
          : theme.palette.primary.main,
      background: props =>
        props.variant === 'contained' && fade(theme.palette.secondary.main, 0.8)
    },
    '&:focus': {
      color: props =>
        props.placement === 'menu'
          ? theme.palette.menu.contrastText
          : theme.palette.primary.main,
      background: props =>
        props.variant === 'contained' && fade(theme.palette.secondary.main, 0.8)
    }
  }
}))

type LinkProps = {
  to: string,
  sameTab?: boolean,
  className?: string,
  isExternal?: boolean,
  placement?: 'menu' | 'default',
  variant?: 'contained' | 'menuitem' | 'default',
  children: any
}

export default ({
  to,
  sameTab,
  className,
  isExternal,
  children,
  placement = 'default',
  variant = 'default',
  ...props
}: LinkProps) => {
  const styles = useStyles({ placement, variant })
  const withHttpPrefix = to.includes('http')
  const targetProps = sameTab
    ? { underline: 'none' }
    : { underline: 'none', target: '_blank', rel: 'noopener noreferrer' }
  return isExternal ? (
    <Link
      className={className}
      href={withHttpPrefix ? to : `//${to}`}
      classes={styles}
      {...targetProps}
      {...props}
    >
      {children}
    </Link>
  ) : (
    <Link
      className={className}
      component={RouterLink}
      to={to}
      underline="none"
      classes={styles}
      {...props}
    >
      {children}
    </Link>
  )
}
