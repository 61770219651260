// @flow
import React, { useMemo } from 'react'
import type { Node } from 'react'
import { makeStyles } from '@material-ui/styles'
import MuiButton from '@material-ui/core/Button'
import CircularProgress from 'src/components/atoms/CircularProgress'
import classnames from 'classnames'
//$FlowFixMe
import { ReactComponent as SpotifyIcon } from 'src/images/logos/spotify.svg'
//$FlowFixMe
import { ReactComponent as GoogleIcon } from 'src/images/logos/google.svg'
import FacebookIcon from '@material-ui/icons/Facebook'

const useOverrideStyles = makeStyles(theme => ({
  root: {
    minWidth: 100,
    minHeight: 40,
    maxHeight: 50,
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),

    textDecoration: 'none'
    // boxShadow: 'none',
    // '&:active': {
    //   boxShadow: 'none'
    // },
    // '&:focus': {
    //   boxShadow: 'none'
    // }
  },
  containedPrimary: {
    color: '#FFFFFF'
  },
  containedSecondary: {
    color: '#FFFFFF'
  },
  outlined: {
    color: theme.palette.text.label
  },
  outlinedPrimary: {
    color: theme.palette.primary.main
  },
  outlinedSecondary: {
    color: theme.palette.secondary.main
  },
  fullWidth: {
    minHeight: 50
  },
  label: {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center'
  }
}))

const useStyles = makeStyles(theme => ({
  // PROVIDER STYLING

  providerButton: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    color: '#FFF',
    fontWeight: 600,
    textTransform: 'none',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  facebookLogin: {
    backgroundColor: '#3B5998',
    '&:hover': {
      backgroundColor: '#3B5998'
    },
    '&:not(:hover)': {
      backgroundColor: '#3B5998 !important'
    },
    '&:focus': {
      backgroundColor: '#3B5998'
    }
  },
  spotifyLogin: {
    backgroundColor: '#1ED760',
    '&:hover': {
      backgroundColor: '#1ED760'
    },
    '&:not(:hover)': {
      backgroundColor: '#1ED760 !important'
    },
    '&:focus': {
      backgroundColor: '#1ED760'
    }
  },
  googleLogin: {
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#FFF'
    },
    '&:not(:hover)': {
      backgroundColor: '#FFF !important'
    },
    '&:focus': {
      backgroundColor: '#FFF'
    },
    color: 'rgba(0,0,0,0.87)'
  },
  providerIcon: {
    marginRight: theme.spacing(2)
  }
}))

type ButtonProps = {
  social?: 'none' | 'facebook' | 'spotify' | 'google',
  children: Node,
  loading?: boolean,
  isLink?: boolean,
  to?: string,
  onClick?: (e: Event) => any
}

const Button = ({
  children,
  social,
  onClick,
  loading,
  isLink,
  to,
  ...props
}: ButtonProps) => {
  const styles = useStyles()
  const overrides = useOverrideStyles()

  const SocialIcon = useMemo(() => {
    switch (social) {
      case 'facebook':
        return <FacebookIcon className={styles.providerIcon} />
      case 'google':
        return <GoogleIcon className={styles.providerIcon} />
      case 'spotify':
        return <SpotifyIcon className={styles.providerIcon} />
      default:
        return <SpotifyIcon className={styles.providerIcon} />
    }
  }, [social, styles.providerIcon])
  return (
    <MuiButton
      classes={overrides}
      className={
        social && classnames(styles[`${social}Login`], styles.providerButton)
      }
      onClick={
        isLink && to
          ? () => {
              window.location.href = to
            }
          : onClick
      }
      {...props}
    >
      {social && SocialIcon}
      {loading ? <CircularProgress /> : children}
    </MuiButton>
  )
}

export default Button
