// @flow

import React, { useEffect } from 'react'

import { shallowEqual, useDispatch, useSelector, batch } from 'react-redux'
import { reduxForm, SubmissionError, change } from 'redux-form'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextFieldSelect from 'src/components/atoms/TextFieldSelect'
import TextField from 'src/components/atoms/TextField'
import Button from 'src/components/atoms/buttons/Button'
import {
  EDIT_SKILLS_OPTIONS,
  EDIT_COWRITE_FREQUENCY_OPTIONS,
  EDIT_COWRITE_EXPERIENCE_OPTIONS,
  EDIT_GENRES_OPTIONS,
  EDIT_PERFORMING_ORGS_OPTIONS,
  EDIT_SONGWRITING_ORGS_OPTIONS,
  EDIT_LIVE_PERFORMANCE_OPTIONS,
  EDIT_SHOWS_PER_YEAR,
  EDUCATION_TYPE_OPTIONS
} from './index.consts'
import { transformOptions } from 'src/utils/fieldTransformers'
import { validYearRange } from 'src/utils/fieldValidators'
import { updateUserAction } from 'src/state/actions/profile'
import { requiredField } from 'src/utils/fieldValidators'
import type { ReduxFormProps } from 'src/utils/types'
import type { ProfileState } from 'src/state/reducers/profile'
import type { State } from 'src/utils/types'
import type { ConfigState } from 'src/state/reducers/Config'

const FORM_ID = 'editSkills'

const useStyles = makeStyles(theme => ({
  paper: {
    zIndex: 1,
    width: '100%',
    maxWidth: 600,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)}px auto`
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  actionButton: {
    margin: `0px ${theme.spacing(1)}px`
  },
  subForm: {
    width: '95%',
    margin: 'auto'
  },
  fieldGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': {
      marginRight: theme.spacing(2)
    }
  },
  formSectionTitle: {
    color: theme.palette.text.label,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  }
}))

type EditAccountState = ProfileState &
  ConfigState & {
    isUpdatingUser: boolean
  }

const selectState: State => EditAccountState = state => ({
  ...state.profile,
  ...state.config
})

export const EditSkillsForm = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error
}: ReduxFormProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const state: EditAccountState = useSelector(selectState, shallowEqual)
  const user = state.user

  const fillFormData = () => {
    batch(() => {
      dispatch(
        change(
          FORM_ID,
          'stats.skills',
          user.stats.skills.map(skill => ({
            value: skill,
            label: skill
          }))
        )
      )
      dispatch(
        change(
          FORM_ID,
          'stats.genres',
          user.stats.genres.map(genre => ({
            label: genre,
            value: genre
          }))
        )
      )
      dispatch(
        change(FORM_ID, 'stats.cowriting.experience', {
          label: user.stats.cowriting.experience,
          value: user.stats.cowriting.experience
        })
      )
      dispatch(
        change(FORM_ID, 'stats.cowriting.frequency', {
          label: user.stats.cowriting.frequency,
          value: user.stats.cowriting.frequency
        })
      )
      dispatch(
        change(FORM_ID, 'stats.performingOrgs', {
          label: user.stats.performingOrgs,
          value: user.stats.performingOrgs
        })
      )
      dispatch(
        change(FORM_ID, 'stats.showsPerYear', {
          label: user.stats.showsPerYear,
          value: user.stats.showsPerYear
        })
      )
      dispatch(
        change(
          FORM_ID,
          'stats.songwritingOrgs',
          user.stats.songwritingOrgs.map(org => ({
            label: org,
            value: org
          }))
        )
      )
      dispatch(
        change(
          FORM_ID,
          'stats.livePerformance',
          user.stats.livePerformance.map(perf => ({
            label: perf,
            value: perf
          }))
        )
      )
      dispatch(
        change(FORM_ID, 'stats.education.educationType', {
          label: user.stats.education.educationType,
          value: user.stats.education.educationType
        })
      )

      dispatch(
        change(FORM_ID, 'stats.education.school', user.stats.education.school)
      )
      dispatch(
        change(FORM_ID, 'stats.education.major', user.stats.education.major)
      )
      dispatch(
        change(FORM_ID, 'stats.education.year', user.stats.education.year)
      )

      dispatch(
        change(
          FORM_ID,
          'stats.highestStreaming.songTitle',
          user.stats.highestStreaming.songTitle
        )
      )
      dispatch(
        change(
          FORM_ID,
          'stats.highestStreaming.url',
          user.stats.highestStreaming.url
        )
      )
      dispatch(
        change(
          FORM_ID,
          'stats.highestStreaming.count',
          user.stats.highestStreaming.count
        )
      )
    })
  }
  useEffect(() => {
    if (user) {
      fillFormData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch])
  return (
    <form
      onSubmit={handleSubmit(async ({ stats }) => {
        const transformedStats = {
          ...stats
        }
        transformedStats.skills = transformOptions(stats.skills) || []
        transformedStats.education.educationType = transformOptions(
          stats.education.educationType
        )
        transformedStats.songwritingOrgs =
          transformOptions(stats.songwritingOrgs) || []
        transformedStats.performingOrgs = transformOptions(stats.performingOrgs)
        transformedStats.cowriting.frequency = transformOptions(
          stats.cowriting.frequency
        )
        transformedStats.cowriting.experience = transformOptions(
          stats.cowriting.experience
        )
        transformedStats.livePerformance =
          transformOptions(stats.livePerformance) || []
        transformedStats.showsPerYear = transformOptions(stats.showsPerYear)
        transformedStats.genres = transformOptions(stats.genres)
        try {
          await dispatch(
            updateUserAction({ fields: { stats: transformedStats } })
          )
        } catch (errors) {
          throw new SubmissionError(errors)
        }
      })}
    >
      <Paper className={styles.paper}>
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          General Stats
        </Typography>
        <div className={styles.subForm}>
          <TextFieldSelect
            required
            id="edit-genre-field"
            label="Genres"
            name="stats.genres"
            placeholder="Rock..."
            validators={[requiredField]}
            options={EDIT_GENRES_OPTIONS}
            isMulti
            fullWidth
          />
          <TextFieldSelect
            required
            id="edit-cowrite-experience-field"
            label="Cowriting Experience"
            name="stats.cowriting.experience"
            placeholder="1-2 Years..."
            validators={[requiredField]}
            options={EDIT_COWRITE_EXPERIENCE_OPTIONS}
            fullWidth
          />
          <TextFieldSelect
            id="edit-cowrite-frequency-field"
            label="Cowrite Frequency Per Month"
            name="stats.cowriting.frequency"
            placeholder="2-5..."
            options={EDIT_COWRITE_FREQUENCY_OPTIONS}
            fullWidth
          />
          <TextFieldSelect
            id="edit-skills-field"
            label="Skills"
            name="stats.skills"
            placeholder="Add Skill..."
            options={EDIT_SKILLS_OPTIONS}
            isMulti
            fullWidth
          />
          <TextFieldSelect
            id="edit-live-performance-field"
            label="Live Performances"
            name="stats.livePerformance"
            placeholder="Add Live Performance..."
            options={EDIT_LIVE_PERFORMANCE_OPTIONS}
            isMulti
            fullWidth
          />
          <TextFieldSelect
            required
            id="edit-shows-per-year-field"
            label="Shows Per Year"
            name="stats.showsPerYear"
            placeholder="Under 50...."
            validators={[requiredField]}
            options={EDIT_SHOWS_PER_YEAR}
            fullWidth
          />
        </div>
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Organizations
        </Typography>
        <div className={styles.subForm}>
          <TextFieldSelect
            id="edit-performing-org-field"
            label="Performing Rights Organization"
            name="stats.performingOrgs"
            placeholder="ASCAP..."
            defaultOptions={EDIT_PERFORMING_ORGS_OPTIONS}
            variant="async-creatable"
            fullWidth
          />
          <TextFieldSelect
            id="edit-songwriting-org-field"
            label="Songwriting Organization(s)"
            name="stats.songwritingOrgs"
            placeholder="Global Songwriters Connection (GSC)..."
            defaultOptions={EDIT_SONGWRITING_ORGS_OPTIONS}
            variant="async-creatable"
            isMulti
            fullWidth
          />
        </div>
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Highest Streaming
        </Typography>
        <div className={styles.subForm}>
          <TextField
            id="highest-streaming-title"
            label="Song Title"
            name="stats.highestStreaming.songTitle"
            placeholder="Song Name..."
          />
          <TextField
            id="highest-streaming-url"
            label="Song Link"
            name="stats.highestStreaming.url"
            placeholder="https://"
          />
          <TextField
            numerical
            id="highest-streaming-count"
            label="Stream Count"
            name="stats.highestStreaming.count"
            placeholder="1432..."
          />
        </div>
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Music Education
        </Typography>
        <div className={styles.subForm}>
          <div className={styles.fieldGroup}>
            <TextFieldSelect
              id="edit-education-type-field"
              label="Music Education"
              name="stats.education.educationType"
              placeholder="Music Undergraduate..."
              options={EDUCATION_TYPE_OPTIONS}
            />
            <TextField
              id="edit-school-field"
              label="School Name"
              name="stats.education.school"
              placeholder="Berklee College of Music..."
            />
          </div>
          <div className={styles.fieldGroup}>
            <TextField
              id="edit-major-field"
              label="Major"
              name="stats.education.major"
              placeholder="Music Theory..."
            />
            <TextField
              id="edit-grad-year-field"
              label="Graduation Year (Or Projected)"
              name="stats.education.year"
              placeholder="2014..."
              validators={[validYearRange]}
            />
          </div>
        </div>
        <div className={styles.actionButtons}>
          <Button
            color="secondary"
            className={styles.actionButton}
            onClick={fillFormData}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={pristine || submitting || invalid || submitting}
            loading={submitting}
          >
            UPDATE
          </Button>
        </div>
      </Paper>
    </form>
  )
}

export default reduxForm({
  form: FORM_ID,
  initialValues: {
    stats: {
      highestStreaming: {
        songTitle: ''
      },
      education: {
        educationType: 'No Formal Education',
        major: '',
        year: '',
        school: ''
      }
    }
  }
})(EditSkillsForm)
