// @flow

import React, { useState, useEffect, useContext } from 'react'
import numeral from 'numeral'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { reset } from 'redux-form'
import { push } from 'react-router-redux'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { SelectField } from 'src/components/atoms/Select'
import Button from 'src/components/atoms/buttons/Button'
import Table from 'src/components/molecules/Table'
import FilterIcon from '@material-ui/icons/FilterList'
import XIcon from 'src/components/atoms/icons/X'
import CheckIcon from 'src/components/atoms/icons/Check'
import UserIcon from 'src/components/atoms/icons/User'
import FilterForm from 'src/components/organisms/forms/Filter'
import getImage from 'src/utils/images/getImage'
import { ConfigContext } from 'src/components/hoc/RouteWithConfig'
import type { Config } from 'src/utils/types/Config'
import type { State } from 'src/utils/types'
import type { NetworkRelationship, Connection } from 'src/utils/types/network'
import type { NetworkState } from 'src/state/reducers/network'
import { filterNetworkAction } from 'src/state/actions/network'

import {
  NETWORK_RELATIONSHIP_OPTIONS,
  COLUMNS
} from 'src/components/organisms/Network/index.consts'

const NameComponent = ({ name = '', title = '', accountType = '' }) => (
  <div>
    <Typography color="primary" variant="body1" style={{ fontWeight: 'bold' }}>
      {name}
    </Typography>
    <Typography variant="caption"> {title} </Typography>
  </div>
)

const useStyles = makeStyles(theme => ({
  networkContent: {
    width: '85%',
    margin: 'auto',
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      width: '95%'
    }
  },
  networkHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'sticky',
    background: 'white',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  networkHeaderText: {
    fontWeight: 'bold'
  },
  emptyNetworkMessage: {
    margin: theme.spacing(5),
    textAlign: 'center'
  },
  noUsersFound: {
    color: theme.palette.text.label
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  loadMore: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  loadMoreButton: {
    width: '100%',
    marginTop: theme.spacing(),
    height: 65,
    color: theme.palette.text.label
  }
}))

type NetworkProps = {
  initialRelationship: NetworkRelationship
}

const networkSelector = state => state.network
const filterSelector = state =>
  state.form.filters ? state.form.filters.values : {}

export const Network = ({ initialRelationship }: NetworkProps) => {
  const {
    networkList: network,
    disableLoadMore,
    isLoadingMore,
    isLoading,
    resultIndex
  } = useSelector<State, NetworkState>(networkSelector, shallowEqual)
  const filters = useSelector(filterSelector, shallowEqual)
  const dispatch = useDispatch()
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [relationship, setRelationship] = useState(initialRelationship)
  const styles = useStyles({ isFilterOpen })

  const config = useContext(ConfigContext)

  useEffect(() => {
    dispatch(reset('filters'))
    dispatch(
      filterNetworkAction({
        relationship
      })
    )
  }, [dispatch, relationship])

  const onLoadMore = () => {
    dispatch(
      filterNetworkAction({
        loadMore: true,
        relationship,
        filters,
        resultIndex
      })
    )
  }

  const loadingOrContentExists = isLoading || network.length

  return (
    <div className={styles.networkContent}>
      <FilterForm
        open={isFilterOpen}
        setOpen={setIsFilterOpen}
        relationship={relationship}
        resultIndex={resultIndex}
        onSubmitFilters={data => dispatch(filterNetworkAction(data))}
      />
      <div className={styles.networkHeader}>
        <SelectField
          variant="header"
          options={NETWORK_RELATIONSHIP_OPTIONS}
          onChange={e => {
            const selectedRelationship = (e.target: window.HTMLInputElement)
              .value
            setRelationship(selectedRelationship)
            dispatch(reset('filters'))
          }}
          value={relationship}
        />

        <Button
          color="primary"
          variant="outlined"
          onClick={() => setIsFilterOpen(true)}
        >
          <FilterIcon className={styles.filterIcon} />
          Filter
        </Button>
      </div>
      {loadingOrContentExists ? (
        <Table
          isLoading={isLoading}
          columns={COLUMNS}
          rows={network.length && networkToRows(network, config)}
          rowIds={network.map(user => user.userId)}
          onClick={rowId => {
            // alert(rowId)
            dispatch(push(`/profile/${rowId}`))
          }}
        />
      ) : (
        <div className={styles.emptyNetworkMessage}>
          <Typography variant="h4" className={styles.noUsersFound}>
            No users found.
          </Typography>
        </div>
      )}
      {!isLoading && (
        <div className={styles.loadMore}>
          <Button
            className={styles.loadMoreButton}
            onClick={onLoadMore}
            disabled={disableLoadMore}
            loading={isLoadingMore}
          >
            {disableLoadMore ? 'No More Results' : 'LOAD MORE'}
          </Button>
        </div>
      )}
    </div>
  )
}

const networkToRows = (connections: Connection[], config: Config) => {
  return (
    connections.length &&
    connections.map(connection => {
      return [
        {
          value: (
            <UserIcon
              img={getImage('profileImage', connection.userId, config) || ''}
            />
          ),
          align: 'right'
        },
        {
          value: (
            <NameComponent
              name={`${connection.firstname} ${connection.lastname}`}
              title={connection.stats && connection.stats.occupation[0]}
              accountType={connection.accountType}
            />
          ),
          align: 'left'
        },
        {
          value: `${connection.location.city} ${
            connection.location.state ? ',' : ''
          } ${connection.location.state}`,
          align: 'right'
        },
        {
          value:
            connection.accountType === 'Songwriter'
              ? numeral(connection.stats.highestStreaming.count).format('0,0')
              : 'n/a',
          align: 'right'
        },
        {
          value:
            connection.accountType === 'Songwriter'
              ? connection.stats.genres[0]
              : 'n/a',
          align: 'right'
        },
        {
          value: () =>
            connection.accountType === 'Business' ? (
              'n/a'
            ) : connection.stats.publishingFlags.available ? (
              <CheckIcon />
            ) : (
              <XIcon />
            ),
          align: 'right'
        },
        {
          value: () =>
            connection.accountType === 'Business' ? (
              'n/a'
            ) : connection.stats.availableTopChartSongs?.length ? (
              <CheckIcon />
            ) : (
              <XIcon />
            ),
          align: 'right'
        }
      ]
    })
  )
}

export default Network
