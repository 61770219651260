// @flow
import numeral from 'numeral'
import type { Option } from 'src/components/atoms/TextFieldSelect'

export const numberField = (value: string) => numeral(value).format('0,0')

export const transformOptions = (options: Option[]) => {
  if (Array.isArray(options)) {
    if (options.length) return options.map<string>(option => option.value)
    return []
  } else {
    return options ? options.value : ''
  }
}

export const transformBandMembers = (members: Option[]) => {
  if (members?.length) {
    //$FlowFixMe
    return members.map(option => ({ id: option.value, name: option.label }))
  }
}
