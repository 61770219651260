// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
// $FlowFixMe
import { ReactComponent as PlayIcon } from 'src/images/playIcon.svg'

const useStyles = makeStyles(theme => ({
  icon: {
    width: props => (props.size ? props.size : 35),
    height: props => (props.size ? props.size : 35),
    color: theme.palette.primary.main
  }
}))

type PlayIconProps = {
  size?: number
}

export default ({ size }: PlayIconProps) => {
  const styles = useStyles({ size })
  return <PlayIcon className={styles.icon} />
}
