// @flow

import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ThreadComponent from 'src/components/molecules/Thread'
import FullPageLoading from 'src/components/atoms/FullPageLoading'
import {
  markThreadReadAction,
  deleteThreadAction
} from 'src/state/actions/inbox'
import type { Thread, ThreadMember } from 'src/utils/types/inbox'
import type { User } from 'src/utils/types/users'
import { useAuth } from '../../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    backgroundColor: theme.palette.background.contrast,
    overflow: 'visible',
    overflowY: 'auto',
    width: '40%',
    height: '100%',
    top: 0,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  newThreadButton: {
    color: 'white',
    marginRight: theme.spacing(3)
  },
  messageList: {
    width: '100%',
    overflow: 'visible',
    paddingBottom: theme.spacing(0),
    WebkitOverflowScrolling: 'touch'
  }
}))

const hasUnreadMessages = (currentUser: User, members: ThreadMember[]) => {
  const currentUserInThread =
    currentUser && members.find(member => member.userId === currentUser._id)

  return !!currentUserInThread && !currentUserInThread.readAll
}

const generateThreads = (
  currentUser: User,
  threads: Thread[],
  activeThreadId: string,
  onSelect: string => void,
  onDelete: string => void,
  isDeletingThread?: boolean
) => {
  const readMessages = threads
    .filter(thread => hasUnreadMessages(currentUser, thread.members))
    .sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
  const unreadMessages = threads
    .filter(thread => !hasUnreadMessages(currentUser, thread.members))
    .sort((a, b) => Number(b.timestamp) - Number(a.timestamp))

  const sortedMessages = readMessages
    .concat(unreadMessages)
    .sort((a, b) => (a._id === activeThreadId ? -1 : 1))

  return sortedMessages.map(thread => (
    <ThreadComponent
      key={`${thread._id}`}
      onClick={onSelect}
      onDelete={onDelete}
      thread={thread}
      hasUnreadMessages={hasUnreadMessages(currentUser, thread.members)}
      active={activeThreadId === thread._id}
      isDeleting={isDeletingThread}
    />
  ))
}

type ThreadListProps = {
  activeThreadId: string,
  setActiveThreadId: string => void,
  onCreateNewThread: () => void,
  threads: Thread[],
  isLoading?: boolean
}

export default ({
  activeThreadId,
  setActiveThreadId,
  onCreateNewThread,
  threads,
  isLoading
}: ThreadListProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { user: currentUser } = useAuth()

  const canSendMessages =
    currentUser?.accountType !== 'Business' || currentUser?.isVerified
  const { isDeletingThread } = useSelector(state => state.inbox, shallowEqual)

  const onThreadClick = (threadId: string) => {
    const selectedThread = threads.find(thread => thread._id === threadId)
    if (threadId !== activeThreadId) {
      if (
        currentUser &&
        selectedThread &&
        hasUnreadMessages(currentUser, selectedThread.members)
      )
        dispatch(markThreadReadAction({ userId: currentUser._id, threadId }))
      setActiveThreadId(threadId)
    }
  }

  const onThreadDelete = (threadId: string) => {
    dispatch(deleteThreadAction({ threadId }))
    setActiveThreadId('')
  }

  return (
    <div className={styles.container}>
      {isLoading ? (
        <FullPageLoading />
      ) : (
        <React.Fragment>
          <div className={styles.header}>
            <Typography variant="h5">
              <b>Messages</b>
            </Typography>
            <Fab
              color="secondary"
              aria-label="new message"
              disabled={!canSendMessages}
              className={styles.newThreadButton}
              onClick={onCreateNewThread}
            >
              <AddIcon fontSize="large" />
            </Fab>
          </div>
          <div className={styles.messageList}>
            {generateThreads(
              currentUser,
              threads,
              activeThreadId,
              onThreadClick,
              onThreadDelete,
              isDeletingThread
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
