// @flow

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

const palette = {
  primary: {
    main: '#2D4179'
  },
  secondary: {
    main: '#ff6161'
  },
  tertiary: {
    main: '#0B1C4D'
  },
  text: {
    primary: '#0B1C4D',
    label: '#9E9E9E',
    main: '#383838',
    button: '#FFFFFF'
  },
  message: {
    main: '#0B1C4D',
    light: '#FFFFFF'
  },
  menu: {
    main: '#0B1C4D',
    text: '#9E9E9E',
    contrastText: '#FFFFFF'
  },
  background: {
    main: '#F6F6F6',
    contrast: '#FFFFFF'
  },
  border: {
    main: '#EAEAEA'
  },
  error: { main: '#d91e18' },
  success: { main: '#4BB543' }
}

let theme = createMuiTheme({
  palette,
  layers: {
    onTop: 2000
  },
  FULL_HEIGHT: window.innerHeight,
  overrides: {
    MuiTab: {
      root: {
        '&&': {
          color: palette.text.label
        }
      }
    },
    MuiInput: {
      underline: {
        '&&:hover:not($disabled):before': {
          borderBottom: `1px solid ${palette.primary.main}`
        },
        '&:after': {
          borderBottom: `2px solid ${palette.primary.main}`
        },
        '&&$focused:after': {
          borderBottom: `2px solid ${palette.primary.main}`
        }
      }
    },
    MuiInputLabel: {
      root: {
        // color: palette.text.label
      },
      outlined: {
        transform: `translate(14px, 15px) scale(1)`
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          '&&': {
            color: palette.primary.main
          }
        }
      }
    },
    MuiTypography: {
      root: {
        color: palette.text.main
      },
      gutterBottom: {
        paddingBottom: 4
      }
    }
  }
})
export default responsiveFontSizes(theme)
