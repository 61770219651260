// @flow
import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import numeral from 'numeral'
import { reduxForm, SubmissionError, reset } from 'redux-form'
import { connect, batch } from 'react-redux'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import Modal, {
  ModalHeader,
  ModalActions
} from 'src/components/molecules/Modal'
import Typography from '@material-ui/core/Typography'
import Button from 'src/components/atoms/buttons/Button'
import RadioField from 'src/components/molecules/RadioField'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from 'src/components/atoms/TextField'
import TextFieldSelect from 'src/components/atoms/TextFieldSelect'
import { transformOptions } from 'src/utils/fieldTransformers'
import {
  REFERAL_TYPES,
  SONGWRITER_TYPES,
  RECENT_SIGNUPS,
  GENRES,
  PUBLISHING_AVAILABLE,
  LIVE_PERFORMANCE_OPTIONS,
  SHOWS_PER_YEAR,
  PUBLISHING_FLAGS,
  SKILLS,
  EDUCATION
} from 'src/components/organisms/forms/Filter/index.consts'
import { STATES_OPTIONS } from 'src/components/organisms/forms/EditAccount/index.consts'
import type { FilterNetworkParams } from 'src/state/actions/network'
import type {
  MapDispatchToProps,
  Dispatch,
  ReduxFormProps
} from 'src/utils/types'
import type { NetworkRelationship } from 'src/utils/types/network'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { camelToSnake } from 'src/utils/camelize'
import { COUNTRY_OPTIONS } from 'src/consts'
import { useStyles } from './index.styles'
import { TOP_CHART_SONG_AVAILABLE } from './index.consts'

const _useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
    color: 'white'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))

type FilterFormProps = {
  setOpen: boolean => void,
  onSubmitFilters: FilterNetworkParams => Dispatch,
  resultIndex: number,
  open: boolean,
  relationship: NetworkRelationship
}

type DispatchProps = {
  resetFilters: (
    NetworkRelationship,
    (FilterNetworkParams) => Dispatch
  ) => Dispatch
}

export const LoginForm = ({
  pristine,
  submitting,
  invalid,
  error,
  handleSubmit,
  onSubmitFilters,
  setOpen,
  open,
  resetFilters,
  relationship,
  resultIndex,
  ...props
}: FilterFormProps & DispatchProps & ReduxFormProps) => {
  const styles = useStyles({ open })
  const classes = _useStyles()
  const { analytics } = useAnalytics()
  const [expanded, setExpanded] = React.useState(false)
  const locationEl = useRef(null)

  const handleFabSeeMoreClick = () => {
    setExpanded(expanded => !expanded)
    locationEl.current.scrollIntoView()
    if (!expanded) {
      setTimeout(function() {
        document.getElementById('location_section').scrollIntoView()
      }, 500)
    }
  }

  return (
    <Modal open={open} setOpen={setOpen} paperClassName={styles.paper}>
      <form
        className={styles.form}
        onSubmit={handleSubmit(async filters => {
          try {
            filters.minStreamCount = numeral(filters.minStreamCount).value()
            filters.maxStreamCount = numeral(filters.maxStreamCount).value()
            if (filters.state) filters.state = transformOptions(filters.state)
            if (filters.country)
              filters.country = transformOptions(filters.country)
            await onSubmitFilters({ relationship, filters })
            sendFilterAnalytics(filters, analytics)
            setOpen(false)
          } catch (errors) {
            throw new SubmissionError(errors)
          }
        })}
      >
        <ModalHeader onClose={() => setOpen(false)} title={'Search'}>
          <Button
            className={styles.clearAll}
            onClick={() => resetFilters(relationship, onSubmitFilters)}
          >
            Clear All
          </Button>
        </ModalHeader>
        <div className={styles.filters}>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Name </Typography>
            <div className={styles.fieldGroup}>
              <TextField
                id="firstname-field"
                label="First Name"
                name="firstname"
                placeholder="John..."
              />
              <div className={styles.rangeDivider} />
              <TextField
                id="lastname-field"
                label="Last Name"
                name="lastname"
                placeholder="Doe..."
              />
            </div>
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Genre </Typography>
            <RadioField name="genres" options={GENRES} multiSelect expand />
          </div>

          <div
            className={styles.filterSection}
            ref={locationEl}
            id="location_section"
          >
            <Typography variant="h4"> Location </Typography>
            <div className={styles.fieldGroup}>
              <TextField
                id="city"
                label="City"
                name="city"
                placeholder="Nashville..."
              />
              <div className={styles.rangeDivider} />
              <TextFieldSelect
                name="state"
                placeholder="TN..."
                label="State"
                options={STATES_OPTIONS}
              />
            </div>
            <div className={styles.fieldGroup}>
              <TextFieldSelect
                fullWidth
                name="country"
                placeholder="US..."
                label="Country"
                options={COUNTRY_OPTIONS}
              />
            </div>
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Songwriter Type </Typography>
            <RadioField
              name="occupation"
              options={SONGWRITER_TYPES}
              multiSelect
              expand
            />
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Referral Status </Typography>
            <RadioField name="referalStatus" options={REFERAL_TYPES} />
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Number of Streams </Typography>
            <FormLabel className={styles.streamFilterDesc}>
              Filter based on highest streaming song. Number is totalled across
              all platforms.
            </FormLabel>
            <div className={styles.fieldGroup}>
              <TextField
                numerical
                id="min-stream-count"
                label="Minimum"
                name="minStreamCount"
              />
              <Typography variant="subtitle2" className={styles.rangeDivider}>
                to
              </Typography>
              <TextField
                numerical
                id="max-stream-count"
                label="Maximum"
                name="maxStreamCount"
              />
            </div>
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Skill Set</Typography>
            <RadioField name="skills" multiSelect options={SKILLS} expand />
          </div>

          <div className={styles.filterSection}>
            <Typography variant="h4"> Live Performances </Typography>
            <RadioField
              name="livePerformance"
              multiSelect
              options={LIVE_PERFORMANCE_OPTIONS}
              expand
            />
          </div>

          <div className={styles.filterSection}>
            <Typography variant="h4"> Shows Per Year </Typography>
            <RadioField name="showsPerYear" options={SHOWS_PER_YEAR} expand />
          </div>

          <div className={styles.filterSection}>
            <Typography variant="h4"> Publishing Availability </Typography>
            <RadioField name="available" options={PUBLISHING_AVAILABLE} />
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Publishing Flags </Typography>
            <RadioField
              name="publishingFlags"
              multiSelect
              options={PUBLISHING_FLAGS}
            />
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Top Chart Song </Typography>
            <FormLabel className={styles.streamFilterDesc}>
              Filter for users who have a top chart song available for
              publishing
            </FormLabel>
            <RadioField
              name="requireTopChartSongs"
              multiSelect
              options={TOP_CHART_SONG_AVAILABLE}
            />
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Music Education </Typography>
            <RadioField name="education" multiSelect options={EDUCATION} />
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Band </Typography>
            <div className={styles.fieldGroup}>
              <TextField
                id="bandName-field"
                label="Band Name"
                name="bandName"
                placeholder="Super Cool Band..."
              />
            </div>
          </div>
          <div className={styles.filterSection}>
            <Typography variant="h4"> Latest Sign Ups </Typography>
            <RadioField name="recentSignups" options={RECENT_SIGNUPS} />
          </div>
        </div>
        <ModalActions onCancel={() => setOpen(false)}>
          <div className={styles.submitButton}>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
              disabled={pristine || submitting || invalid}
              loading={submitting}
            >
              Update Results
            </Button>
            {error && (
              <span className={styles.formErrorMessage}>
                <strong>{error}</strong>
              </span>
            )}
          </div>
          <div
            style={{
              position: 'absolute',
              zIndex: 99,
              right: '40px',
              bottom: '10px',
              transform: 'translateY(-100px)'
            }}
          >
            {/* <Tooltip title={expanded ? 'Show less' : 'Show more'}>
              <Fab color="primary" aria-label="show more" size="small">
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded
                  })}
                  onClick={handleFabSeeMoreClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Fab>
            </Tooltip> */}
          </div>
        </ModalActions>
      </form>
    </Modal>
  )
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps> = (
  dispatch: Dispatch
) => ({
  resetFilters: (
    relationship: NetworkRelationship,
    onSubmitFilters: FilterNetworkParams => Dispatch
  ) =>
    batch(() => {
      dispatch(reset('filters'))
      onSubmitFilters({ relationship })
    })
})

const connectedForm = connect(null, mapDispatchToProps)(LoginForm)

export default reduxForm({
  form: 'filters',
  initialValues: {
    referalStatus: 'All',
    recentSignups: RECENT_SIGNUPS[0].value,
    minStreamCount: 0,
    maxStreamCount: 100000000
  }
})(connectedForm)

const sendFilterAnalytics = (filters, analytics: any) => {
  const keys = Object.keys(filters)

  keys.forEach(key => {
    //make sure we don't log events for default filters
    if (
      filters[key] !== '' &&
      filters[key] !== 0 &&
      filters[key] !== 100000000 &&
      filters[key] !== 'All' &&
      filters[key] !== RECENT_SIGNUPS[0].value
    )
      analytics.logEvent(camelToSnake(key))
  })
}
