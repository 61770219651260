// @flow
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'src/components/atoms/buttons/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { CardElement, injectStripe } from 'react-stripe-elements'
import type { ReduxFormProps } from 'src/utils/types'
import { TextField } from 'src/components/atoms/TextField'
import { RadioField } from 'src/components/molecules/RadioField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Flex } from 'src/components/molecules/Flex'
import Link from '@material-ui/core/Link'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { submitPaymentAction } from 'src/state/actions/payments'
import { useAuth } from '../../../../hooks/useAuth'
import { history } from 'react-router-util'

export const FORM_ID = 'payments'

const useStyles = makeStyles(theme => ({
  dialog: {
    margin: `${theme.spacing(2)}px 0`,
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '90vw'
    }
  },
  modalTitle: {
    fontWeight: 'bold'
  },
  price: {
    color: theme.palette.text.label,
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  dialogContent: {
    width: '100%',
    height: 'auto',
    minHeight: 100

    // overflow: 'hidden'
  },
  paymentInfoTitle: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.text.label,
    fontSize: '1rem',
    transform: 'scale(0.75)',
    transformOrigin: 'top left'
  },
  terms: {
    color: theme.palette.text.label,
    paddingTop: theme.spacing(2),
    textAlign: 'center'
  },
  couponWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  planInfo: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.label
  },
  paymentError: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    color: theme.palette.error.main
  },
  successWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  checkIcon: {
    width: 65,
    height: 65,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main
  },
  successMessage: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  dialogActions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  memberSelect: {
    paddingBottom: theme.spacing(4),
    width: '100%'
  },
  sendIcon: {
    marginRight: theme.spacing(1)
  }
}))

type PaymentFormProps = {
  monthlyPrice: number,
  yearlyPrice: string,
  plan: 'Career' | 'Premium',
  isOpen?: boolean,
  error?: string,
  success?: boolean,
  isSubmitting?: boolean,
  email: string,
  onClose: () => void
}

type StripeProps = {
  stripe: any,
  elements: any
}

export const PaymentForm = ({
  monthlyPrice,
  yearlyPrice,
  plan,
  email,
  isOpen,
  onClose,
  stripe,
  elements,
  error,
  success,
  isSubmitting,
  ...props
}: PaymentFormProps & ReduxFormProps & StripeProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { analytics } = useAnalytics()
  const [coupon, setCoupon] = useState('')
  const [planPeriod, setPlanPeriod] = useState('Monthly')
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [cardError, setCardError] = useState('')
  const { user, updateUser } = useAuth()

  const submit = async values => {
    const cardElement = elements.getElement('card')
    if (!cardElement._complete) {
      cardElement.focus();
      setCardError('Please enter a valid card number, expiration date and CVC')
    } else if (cardElement._invalid) {
      cardElement.focus();
      setCardError(
        'One of fields "Card Number", "Expiration Date (MM/YY)", or "CVC" are invalid'
      )
    } else {
      dispatch(
        submitPaymentAction({
          user,
          updateUser,
          email,
          plan,
          coupon,
          planPeriod,
          stripeObject: stripe,
          cardElement
        })
      )
    }
  }

  useEffect(() => {
    if (success) {
      analytics.logEvent('career_upgrade')
      setTimeout(() => {
        history.push('/discover')
      }, 5000);
    }
  }, [analytics, success])

  useEffect(() => {
    setInterval(() => {
      const cardElement = elements.getElement('card')
      if (cardElement) {
        if (cardElement._complete && !cardElement._invalid) {
          setCardError(cardError => {
            return ''
        });
        }
      }
    }, 500)
  }, [])

  return (
    <Dialog
      aria-labelledby="create-message-modal-title"
      open={isOpen}
      scroll="body"
      onClose={onClose}
      PaperProps={{
        className: styles.dialog
      }}
    >
      <DialogTitle>
        {!success && (
          <>
            <Typography
              variant="h5"
              id="create-message-modal-title"
              className={styles.modalTitle}
            >
              {plan === "Career" ? "Membership" : `${plan} Plan`}
            </Typography>
            <Typography variant="subtitle1" className={styles.price}>
              {plan === 'Career'
                ? `$${monthlyPrice} / month or $${yearlyPrice} / month billed annually plus sales tax where applicable`
                : `$${monthlyPrice} / month or $${yearlyPrice} / month billed annually plus sales tax where applicable`}
            </Typography>
          </>
        )}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {!success && (
          <>
            <Typography variant="subtitle2" className={styles.paymentInfoTitle}>
              Card Information
            </Typography>
            <CardElement onReady={e => e.focus()} style={cardError ? {
                base: {
                  padding: '12px',
                  '::placeholder': {
                    color: '#dd5555',
                    backgroundColor: '#fff5ee'
                  }
                },
            } : {}}/>
            <Typography variant="caption" className={styles.paymentError}>
              {cardError}
            </Typography>
            <div className={styles.couponWrapper}>
              <TextField
                touched
                id="coupon-field"
                label="Promo Code"
                placeholder="PROMO-CODE-123"
                value={coupon}
                error={
                  error === 'INVALID_PROMO_CODE'
                    ? 'This coupon is not valid'
                    : ''
                }
                onChange={e => setCoupon(e.target.value)}
              />
            </div>
            <RadioField
              label="Billing Period"
              activeOptions={planPeriod}
              onChange={val => setPlanPeriod(val)}
              options={[
                { name: `Monthly (at $${monthlyPrice} / month)`, value: 'Monthly' },
                { name: `Yearly (at $${yearlyPrice} / month)`, value: 'Yearly' }
              ]}
            />
            <Typography variant="subtitle2" className={styles.terms}>
              By {plan === "Career" ? `starting your Membership` : `joining the ${plan} Plan`}
              , you agree to be billed according
              to the rate option you have selected. Before proceeding, we ask
              that you{' '}
              <Link href="https://discoversooner.com/terms-of-service">
                please read the terms and conditions.
              </Link>
            </Typography>
            <Flex container justifyContent="center" margin="16px 0 0 0">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedToTerms}
                    onChange={() => setAgreedToTerms(!agreedToTerms)}
                    color="primary"
                  />
                }
                label="I Agree to the Terms &amp; Conditions"
              />
            </Flex>
          </>
        )}
        {success && (
          <div className={styles.successWrapper}>
            <CheckCircleIcon className={styles.checkIcon} />
            <Typography className={styles.successMessage} variant="h5">
              You {plan === "Career" ? `started your Membership` : `are now on the ${plan} Plan`}!
              
            </Typography>
          </div>
        )}
        {error === 'REQUIRE_NEW_PAYMENT_METHOD' && (
          <Typography className={styles.paymentError} variant="subtitle2">
            There was an error with this payment method. Please try another.
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button onClick={onClose}>{success ? 'CLOSE' : 'CANCEL'}</Button>
        {!success && (
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={isSubmitting || !agreedToTerms}
            loading={isSubmitting}
            onClick={submit}
          >
            SUBMIT
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default injectStripe(PaymentForm)
