/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import React, { useState, useContext, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Helmet from 'react-helmet'
import Sidebar from 'src/components/molecules/Sidebar'
import Header from 'src/components/molecules/Header'
import Snackbar from 'src/components/atoms/Snackbar'
import ProfileChecklistModal from 'src/components/molecules/ProfileChecklistModal'
import { makeStyles } from '@material-ui/styles'
import getImage from 'src/utils/images/getImage'
import { getSongsAction } from 'src/state/music'
import { getSongs } from 'src/services/music'
import { ConfigContext } from 'src/components/hoc/RouteWithConfig'
import { useAuth } from '../../../hooks/useAuth'

import {
  SNACKBAR_MESSAGES_CAREER,
  SNACKBAR_MESSAGES_PUBLISHERS
} from '../../../consts'

const MUSIC_ACCOUNT_NAMES = [
  'Apple Music',
  'Spotify',
  'SoundCloud',
  'Pandora',
  'Youtube'
]

const filterMusicAccounts = (accounts?: SocialNetwork[]) =>
  accounts?.filter(account => MUSIC_ACCOUNT_NAMES.includes(account.name))

const selectMusic: State => MusicState & {
  musicAccounts: SocialNetwork[]
} = state => ({
  ...state.music,
  musicAccounts: filterMusicAccounts(
    state.profile.user?.profile.socialMediaLinks
  )
})

const useStyles = makeStyles(theme => ({
  headerAndContent: {
    backgroundColor: 'white',
    transition: 'transform 0.2s',
    overflowY: 'auto',
    position: 'fixed',
    width: 'calc(100% - 75px)',
    height: theme.FULL_HEIGHT,
    transform: props => (props.isOpen ? `translate(250px)` : 'translate(75px)'),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      transform: props => (props.isOpen ? `translate(250px)` : 'translate(0px)')
    }
  },
  content: {
    height: 'calc(100% - 65px)',
    position: 'relative'
  }
}))

type NavigationBarsProps = {
  pageTitle: string,
  hideSidebar?: boolean,
  children: any
}

const Navigation = ({
  pageTitle,
  hideSidebar,
  children
}: NavigationBarsProps) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [picked, setPickedMess] = useState(null)
  const [checkeduseSelector, setNewValue] = useState(false)
  const [isProfileChecklistOpen, setIsProfileChecklistOpen] = useState(false)
  const [songsList, setSongList] = useState(null)
  const styles = useStyles({ isOpen })
  const { user } = useAuth()

  const { songs } = useSelector(selectMusic, shallowEqual)
  const config = useContext(ConfigContext)
  const profileImage = user && getImage('profileImage', user._id, config)

  let SNACKBAR_MESSAGES = []
  let afterSignUp = 0
  let isReferred = user?.isReferred
  let referredOption = 0

  if (user.accountLevel === 'Career') {
    SNACKBAR_MESSAGES = SNACKBAR_MESSAGES_CAREER
    afterSignUp = 1
    referredOption = 0
  }
  if (user.accountType === 'Business') {
    afterSignUp = -1
    isReferred = false
    SNACKBAR_MESSAGES = SNACKBAR_MESSAGES_PUBLISHERS
  }

  useEffect(() => {
    if (checkeduseSelector) {
      if (isReferred) {
        setIsProfileChecklistOpen(picked === afterSignUp)
      } else {
        setIsProfileChecklistOpen(picked === afterSignUp)
      }
    }
  }, [songs])

  useEffect(() => {
    async function gettingSongs() {
      await getSongs({ authorId: user._id }).then(({ data }) =>
        setSongList(data)
      )
    }

    gettingSongs()

    setNewValue(true)
    const lastOpened = localStorage.getItem('openedSnackbar')
    let currentDate = new Date()
    let baseDate = new Date(lastOpened || currentDate)

    const diffTime = Math.abs(currentDate - baseDate)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    // para restar minutos...
    // const dt1 = new Date(lastOpened)

    // let diffTime = (currentDate.getTime() - dt1.getTime()) / 1000
    // diffTime /= 60
    // const diffMins = Math.abs(Math.round(diffTime))

    // days/..
    if (!(user.accountLevel === 'Free' && user.accountType === 'Songwriter')) {
      if (lastOpened) {
        if (diffDays > 6) {
          const picked = Math.floor(diffDays / 7) % SNACKBAR_MESSAGES.length
          setPickedMess(picked)
          setIsProfileChecklistOpen(picked === afterSignUp)
        }
      } else {
        if (isReferred) {
          setPickedMess(1)
          localStorage.setItem(`openedTurn_${user.accountLevel}`, 2)
          setIsProfileChecklistOpen(1 === afterSignUp)
        } else {
          setPickedMess(0)
          localStorage.setItem(`openedTurn_${user.accountLevel}`, 1)
          setIsProfileChecklistOpen(0 === afterSignUp)
        }
      }
    }

    // minutes..
    // if (lastOpened) {
    //   if (diffMins > 1) {
    //     // const picked = Math.floor(diffDays / 7) % SNACKBAR_MESSAGES.length
    //     const turn =
    //       localStorage.getItem(`openedTurn_${user.accountLevel}`) ?? 0

    //     // const picked = Math.floor(diffDays / 7) % SNACKBAR_MESSAGES.length
    //     const picked = turn % SNACKBAR_MESSAGES.length
    //     setPickedMess(picked)
    //     setIsProfileChecklistOpen(picked === afterSignUp)
    //     localStorage.setItem(
    //       `openedTurn_${user.accountLevel}`,
    //       parseInt(turn) + 1
    //     )
    //   }
    // } else {
    //   if (isReferred) {
    //     setPickedMess(1)
    //     localStorage.setItem(`openedTurn_${user.accountLevel}`, 2)
    //     // setIsProfileChecklistOpen(1 === afterSignUp)
    //   } else {
    //     setPickedMess(0)
    //     localStorage.setItem(`openedTurn_${user.accountLevel}`, 1)
    //     // setIsProfileChecklistOpen(0 === afterSignUp)
    //   }
    // }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {!hideSidebar && (
        <Sidebar
          currentPath="/"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          accountType={user && user.accountType}
        />
      )}

      <div className={styles.headerAndContent}>
        <Header
          pageTitle={pageTitle}
          profileImage={profileImage || ''}
          id={user ? user._id : ''}
          isSidebarOpen={isOpen}
          setSidebarOpen={setIsOpen}
        />
        <div
          role="main"
          className={styles.content}
          onClick={() => setIsOpen(false)}
        >
          {children}
        </div>
      </div>
      {picked !== null && !!SNACKBAR_MESSAGES[picked]?.message && (
        <Snackbar
          open={true}
          type={SNACKBAR_MESSAGES[picked].type ?? 'general'}
          snackbarMessage={SNACKBAR_MESSAGES[picked].message}
          buttonText={SNACKBAR_MESSAGES[picked].buttonText}
          clickAction={
            typeof SNACKBAR_MESSAGES[picked].link === 'string'
              ? SNACKBAR_MESSAGES[picked].link
              : SNACKBAR_MESSAGES[picked].buttonText === 'Music List'
              ? SNACKBAR_MESSAGES[picked].link(
                  `/profile/edit/${user._id}?editMusic=true`
                )
              : SNACKBAR_MESSAGES[picked].link(`/profile/edit/${user._id}`)
          }
          backgroundColor={SNACKBAR_MESSAGES[picked].color}
        />
      )}
      {songsList &&
        !(
          songsList.length > 0 &&
          user.profile.socialMediaLinks.length !== 0 &&
          user.stats.genres.length > 0 &&
          user.stats.skills.length > 0 &&
          user.stats.cowriting.experience &&
          user.stats.cowriting.frequency &&
          user.stats.skills.length > 0
        ) && (
          <ProfileChecklistModal
            open={
              !(
                songs.length > 0 &&
                user.profile.socialMediaLinks.length !== 0 &&
                user.stats.genres.length > 0 &&
                user.stats.skills.length > 0 &&
                user.stats.cowriting.experience &&
                user.stats.cowriting.frequency &&
                user.stats.skills.length > 0
              ) && isProfileChecklistOpen
            }
            setOpen={() => setIsProfileChecklistOpen(false)}
            possibleActionsList={[
              {
                description: 'Add a song to your music list',
                isDone: songsList.length > 0,
                linkTo: `/profile/edit/${user._id}?editMusic=true`
              },
              {
                description: 'Add a social link',
                isDone: user.profile.socialMediaLinks.length !== 0,
                linkTo: `/profile/edit/${user._id}`
              },
              {
                description: 'Edit your stats and skills',
                isDone:
                  user.stats.genres.length > 0 &&
                  user.stats.skills.length > 0 &&
                  user.stats.cowriting.experience &&
                  user.stats.cowriting.frequency &&
                  user.stats.skills.length > 0,
                linkTo: `/profile/edit/${user._id}`
              }
            ]}
          />
        )}
    </React.Fragment>
  )
}

export default Navigation
