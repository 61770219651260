/* eslint-disable flowtype/space-after-type-colon */
// @flow

import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Flex } from 'src/components/molecules/Flex'
import { useStyles } from './index.styles'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from 'src/components/atoms/buttons/IconButton'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { Field } from 'redux-form'
import type { FieldProps } from 'redux-form'
import { requiredField } from 'src/utils/fieldValidators'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

type EditableMusicLinkProps = {
  showDelete?: boolean,
  onDelete?: () => void,
  onSwap: number => void,
  elevate?: boolean,
  onChangeOrder?: (index: number) => void,
  totalSongs: number,
  order?: number,
  fieldName?: string
}

export const EditableMusicLink = ({
  elevate,
  onSwap,
  onDelete,
  showDelete = true,
  onChangeOrder,
  totalSongs,
  order,
  fieldName
}: EditableMusicLinkProps) => {
  const styles = useStyles({ elevate })
  return (
    <div className={styles.songLink}>
      <Flex
        container
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex container alignItems="center" flex="1">
          {order && (
            <Typography
              variant="h6"
              className={styles.orderContainer}
              style={{ marginRight: 24 }}
            >
              {order}
            </Typography>
          )}

          <Flex container flexDirection="column" flex="1">
            <Field
              name={fieldName ? `${fieldName}.title` : 'title'}
              component={TitleField}
              validate={[requiredField]}
            />
            <Field
              name={fieldName ? `${fieldName}.desc` : 'desc'}
              component={DescField}
            />
          </Flex>
        </Flex>

        {showDelete && (
          <IconButton
            ariaLabel="Delete Song"
            color="secondary"
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        )}

        <Field
          name={fieldName ? `${fieldName}.isPrivate` : 'isPrivate'}
          component={VisibilityField}
        />

        {order && (
          <Flex container flexDirection="column">
            {order > 1 && (
              <IconButton ariaLabel="move up" onClick={() => onSwap(-1)}>
                <ArrowDropUpIcon />
              </IconButton>
            )}
            {order < totalSongs && (
              <IconButton ariaLabel="move down" onClick={() => onSwap(1)}>
                <ArrowDropDownIcon />
              </IconButton>
            )}
          </Flex>
        )}
      </Flex>
      {/* {showSongLink && ( */}
      <Flex margin="8px 0 0 0">
        <Field
          required
          id={`${fieldName || 'new-link'}-url-field`}
          name={fieldName ? `${fieldName}.url` : 'url'}
          component={SongLinkField}
          validate={[requiredField]}
        />
      </Flex>
      {/* )} */}
    </div>
  )
}

const SongLinkField = ({ input, meta, ...props }: FieldProps) => {
  const styles = useStyles()
  return (
    <input
      placeholder="https://"
      className={styles.songLinkInput}
      {...input}
      {...props}
    />
  )
}

const TitleField = ({ input, meta }: FieldProps) => {
  const styles = useStyles()
  return (
    <input
      placeholder="Enter Song Title"
      className={styles.songTitleInput}
      validate={[requiredField]}
      {...input}
    />
  )
}

const DescField = ({ input, meta }: FieldProps) => {
  const styles = useStyles()
  return (
    <textarea
      placeholder="Enter description (optional)"
      className={styles.songDescInput}
      validate={[requiredField]}
      {...input}
    />
  )
}

const VisibilityField = ({ input, meta }: FieldProps) => {
  const [hovering, setHovering] = useState(false)
  const styles = useStyles({ hovering })

  return (
    <div className={styles.visibilityInput}>
      <Typography variant="subtitle2" className={styles.tooltip}>
        {input.value
          ? 'Set song to be viewable by all users'
          : 'Set song to be for publisher viewing only'}
      </Typography>

      {input.value ? (
        <IconButton
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          ariaLabel="private"
          onClick={() => input.onChange(false)}
        >
          <VisibilityOffIcon />
        </IconButton>
      ) : (
        <IconButton
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          ariaLabel="not private"
          onClick={() => input.onChange(true)}
        >
          <VisibilityIcon />
        </IconButton>
      )}
    </div>
  )
}
