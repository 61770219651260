// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  icon: {
    width: 55,
    height: 55,
    borderRadius: 55,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 45,
      width: 45,
      height: 45
    }
  }
}))

type UserIconProps = {
  img: string
}

export default ({ img }: UserIconProps) => {
  const styles = useStyles({ img })
  return (
    <div
      className={styles.icon}
      alt="profile"
      style={{ backgroundImage: `url(${img})` }}
    />
  )
}
