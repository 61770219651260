// @flow
import React from 'react'
import { Field } from 'redux-form'
import { makeStyles } from '@material-ui/styles'

import type { FieldProps } from 'redux-form'
import type { FieldValidator } from 'src/utils/fieldValidators'

const useStyles = makeStyles(theme => ({
  chatInput: {
    margin: 0,
    border: 0,
    display: 'block',
    flex: 1,
    height: '100%',
    '&:focus': {
      outline: 'none'
    },
    padding: ` 0 ${theme.spacing(2)}px`,
    fontSize: '1rem'
  }
}))

type ChatInputProps = {
  id: string,
  touched?: boolean,
  active?: boolean
}

export const ChatInput = ({
  id,
  touched,
  active,
  ...props
}: ChatInputProps) => {
  const styles = useStyles()
  const errorId = `${id}-error`

  return (
    <input
      type="text"
      placeholder="Type your message here..."
      id={id}
      className={styles.chatInput}
      aria-errormessage={errorId}
      {...props}
    />
  )
}

const renderTextField = ({
  id,
  input,
  meta,
  ...props
}: ChatInputProps & FieldProps) => (
  <ChatInput
    id={id}
    touched={meta.touched}
    active={meta.active}
    {...input}
    {...props}
  ></ChatInput>
)

type ConnectedTextFieldProps = {
  name: string,
  validators?: FieldValidator[]
} & ChatInputProps

export default ({
  name,
  id,
  validators = [],
  ...props
}: ConnectedTextFieldProps) => (
  <Field
    name={name}
    id={id}
    component={renderTextField}
    validate={[...validators]} // not ideal since validators is already an array.. but flow complains otherwise
    {...props}
  />
)
