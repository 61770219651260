// @flow

import { push } from 'react-router-redux'
import type { ThunkAction } from 'src/utils/types'
import {
  FILTER_USERS_REQUEST,
  FILTER_USERS_SUCCESS,
  SEARCH_USERS_REQUEST,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_ERROR,
  DISABLE_LOAD_MORE_USERS_BUTTON,
  LOAD_MORE_USER_RESULTS_REQUEST,
  LOAD_MORE_USER_RESULTS_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  UPGRADE_USER_REQUEST,
  UPGRADE_USER_SUCCESS,
  UPGRADE_USER_ERROR,
  DOWNGRADE_USER_REQUEST,
  DOWNGRADE_USER_SUCCESS,
  DOWNGRADE_USER_ERROR
} from 'src/state/reducers/users'
import {
  createAccount,
  deleteAccount,
  upgradeAccount,
  downgradeAccount,
  filterUsers,
  searchUsers
} from 'src/services/users'
import { getServiceErrors } from 'src/utils/api/apiErrors'
import type { Filters } from 'src/utils/types/network'
import type {
  CreateAccountRequest,
  CreateAccountService,
  SearchUserService
} from 'src/services/users'
import type {
  FilterUsersService,
  DeleteAccountService,
  UpgradeAccountService,
  DowngradeAccountService
} from 'src/services/users'

const reduceFilters = filters => {
  const keys = Object.keys(filters)
  keys.forEach(key => {
    if (filters[key] === 'All') {
      delete filters[key]
    }
  })
}

export type FilterUsersParams = {
  loadMore?: boolean,
  resultIndex?: number,
  filters?: Filters,
  service?: FilterUsersService
}

export type FilterUsersAction = FilterUsersParams => ThunkAction

export const filterUsersAction: FilterUsersAction = ({
  loadMore,
  resultIndex = 0,
  filters,
  service = filterUsers
}) => async dispatch => {
  try {
    filters && reduceFilters(filters)
    if (loadMore) {
      dispatch({ type: LOAD_MORE_USER_RESULTS_REQUEST })
      const { data } = await service({
        resultIndex,
        ...filters
      })
      if (data.length === 0) {
        dispatch({ type: DISABLE_LOAD_MORE_USERS_BUTTON })
      }

      dispatch({ type: LOAD_MORE_USER_RESULTS_SUCCESS, payload: data })
    } else {
      dispatch({ type: FILTER_USERS_REQUEST })
      const { data } = await service({
        resultIndex,
        ...filters
      })

      dispatch({ type: FILTER_USERS_SUCCESS, payload: data })
    }
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
    console.error(err)
  }
}

export type CreateAccountAction = (
  CreateAccountRequest & { service?: CreateAccountService }
) => ThunkAction

export const createAccountAction: CreateAccountAction = ({
  email,
  password,
  accountType,
  firstname,
  lastname,
  businessVerification,
  service = createAccount
}) => async dispatch => {
  try {
    const { data } = await service({
      email,
      password,
      accountType,
      firstname,
      lastname,
      businessVerification
    })
    return data
  } catch (err) {
    return Promise.reject(getServiceErrors(err))
  }
}

export type DeleteAccountAction = ({
  service?: DeleteAccountService
}) => ThunkAction

export const deleteAccountAction: DeleteAccountAction = ({
  service = deleteAccount
}) => async dispatch => {
  try {
    dispatch({ type: DELETE_USER_REQUEST })
    await service()
    dispatch({ type: DELETE_USER_SUCCESS })
  } catch (err) {
    dispatch({
      type: DELETE_USER_ERROR,
      payload:
        'We had a problem closing your account. Try again later or contact support.'
    })
    return Promise.reject(getServiceErrors(err))
  }
}

export type UpgradeAccountAction = ({
  service?: UpgradeAccountService
}) => ThunkAction

export const upgradeAccountAction: UpgradeAccountAction = ({
  service = upgradeAccount
}) => async dispatch => {
  try {
    dispatch({ type: UPGRADE_USER_REQUEST })
    await service()
    dispatch({ type: UPGRADE_USER_SUCCESS })
  } catch (err) {
    dispatch({
      type: UPGRADE_USER_ERROR,
      payload:
        'We had a problem upgrading your account. Try again later or contact support.'
    })
    return Promise.reject(getServiceErrors(err))
  }
}


export type DowngradeAccountAction = ({
  service?: DowngradeAccountService
}) => ThunkAction

export const downgradeAccountAction: DowngradeAccountAction = ({
  service = downgradeAccount
}) => async dispatch => {
  try {
    dispatch({ type: DOWNGRADE_USER_REQUEST })
    await service()
    dispatch({ type: DOWNGRADE_USER_SUCCESS })
  } catch (err) {
    dispatch({
      type: DOWNGRADE_USER_ERROR,
      payload:
        'We had a problem downgrading your account. Try again later or contact support.'
    })
    return Promise.reject(getServiceErrors(err))
  }
}

export type SearchUsersParams = {
  searchString: string,
  service?: SearchUserService
}

export type SearchUsersAction = SearchUsersParams => ThunkAction

export const searchUsersAction: SearchUsersAction = ({
  searchString,
  service = searchUsers
}) => async dispatch => {
  try {
    dispatch({ type: SEARCH_USERS_REQUEST })
    const { data } = await service({ searchString })
    dispatch({
      type: SEARCH_USERS_SUCCESS,
      payload: data
    })
    return data
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({
        type: SEARCH_USERS_ERROR,
        message: 'There was an error searching for users'
      })
    }
  }
}
