// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import CheckCircle from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles(theme => ({
  icon: {
    width: 35,
    height: 35,
    color: theme.palette.primary.main
  }
}))

export default () => {
  const styles = useStyles()
  return <CheckCircle className={styles.icon} />
}
