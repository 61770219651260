// @flow

import React from 'react'
import CreatePostTemplate from 'src/components/templates/CreatePost'

export const CreatePostPage = () => {
  return <CreatePostTemplate />
}

export default CreatePostPage
