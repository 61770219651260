// @flow

import React from 'react'
import { Flex } from 'src/components/molecules/Flex'
import { makeStyles } from '@material-ui/styles'
import TextField from 'src/components/atoms/TextField'
import TextFieldSelect from 'src/components/atoms/TextFieldSelect'
import { Card, CardHeader } from 'src/components/molecules/Card'
import { STATES_OPTIONS } from 'src/components/organisms/forms/EditAccount/index.consts'
import { CTA } from 'src/components/molecules/CTA'
import ImageUploadField from 'src/components/atoms/ImageUploadField'
import { requiredField, validFile } from 'src/utils/fieldValidators'
import { BUSINESS_TYPE_OPTIONS } from 'src/components/organisms/forms/EditAccount/index.consts'
import Typography from '@material-ui/core/Typography'
import { COUNTRY_OPTIONS } from 'src/consts'

const useStyles = makeStyles(theme => ({
  uploadContainer: {
    width: 145,
    height: 145,
    margin: 'auto'
  }
}))

type PublisherProfileProps = {
  formState: Object,
  creatingProfile?: boolean,
  error?: boolean
}

export const PublisherProfile = ({
  formState,
  error,
  creatingProfile
}: PublisherProfileProps) => {
  const styles = useStyles()

  return (
    <Flex container flexDirection="column" alignItems="center" padding="0 16px">
      <CTA
        header="Profile &amp; Verification"
        subHeader="Let's get the basics out of the way."
      />
      <Flex
        container
        margin="32px 0 0 0"
        width="100%"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Card
          mode="light"
          actionLabel="Create Profile"
          variant="form"
          loading={creatingProfile}
        >
          <CardHeader>Name</CardHeader>
          <TextField
            required
            id="first-name"
            name="firstname"
            label="First Name"
            validators={[requiredField]}
          />
          <TextField
            required
            id="last-name"
            name="lastname"
            label="Last Name"
            validators={[requiredField]}
          />
          <CardHeader margin="48px 0 32px 0">Location</CardHeader>
          <TextFieldSelect
            required
            fullWidth
            id="country"
            label="Country"
            name="country"
            options={COUNTRY_OPTIONS}
            validators={[requiredField]}
          />
          <TextField
            required
            id="city"
            name="city"
            label="City"
            placeholder="enter city"
            validators={[requiredField]}
          />
          {formState.country ? (
            formState.country?.value === 'US' ? (
              <TextFieldSelect
                required
                fullWidth
                id="state"
                label="State"
                name="state"
                options={STATES_OPTIONS}
                validators={[requiredField]}
              />
            ) : (
              <TextField
                required
                id="state"
                label="State or Province"
                name="state"
                placeholder="State or Province"
                validators={[requiredField]}
              />
            )
          ) : (
            <div />
          )}

          <CardHeader margin="48px 0 24px 0">Verification</CardHeader>

          <TextField
            required
            id="business-name"
            name="businessName"
            label="Company Name"
            validators={[requiredField]}
          />
          <TextFieldSelect
            required
            fullWidth
            id="occupation"
            name="occupation"
            label="Occupation"
            validators={[requiredField]}
            options={BUSINESS_TYPE_OPTIONS}
          />
          <Typography
            variant="subtitle2"
            style={{ marginTop: 24, marginBottom: 16 }}
          >
            Please highlight a songwriter, artist or producer who is a member or
            client of your organization.
          </Typography>
          <TextField
            required
            id="writer-name"
            name="songwriterName"
            label="Writer Name"
            validators={[requiredField]}
          />
          <TextField
            required
            id="song-title"
            name="songTitle"
            label="Song Title (by your writer or client)"
            validators={[requiredField]}
          />
          <TextField
            required
            id="song-year"
            name="songYear"
            label="Year Song Was Released"
            validators={[requiredField]}
          />
          <TextField id="song-url" name="songUrl" label="Song URL (optional)" />
          <TextField
            id="film-title"
            name="filmTitle"
            label="Film or TV Title (optional)"
          />
          <CardHeader margin="48px 0 32px 0">Profile Picture</CardHeader>
          <ImageUploadField
            name="profileImage"
            label="Upload Image (square, max 3mb png, jpeg)"
            classes={{ container: styles.uploadContainer }}
            validators={[requiredField, validFile]}
          />
        </Card>
      </Flex>
    </Flex>
  )
}
