// @flow

import type { Action, Reducer } from 'src/utils/types'
import type { Connection } from 'src/utils/types/network'

export const FILTER_NETWORK_REQUEST: 'FILTER_NETWORK_REQUEST' =
  'FILTER_NETWORK_REQUEST'
export const FILTER_NETWORK_SUCCESS: 'FILTER_NETWORK_SUCCESS' =
  'FILTER_NETWORK_SUCCESS'
export const LOAD_MORE_NETWORK_RESULTS_REQUEST: 'LOAD_MORE_NETWORK_RESULTS_REQUEST' =
  'LOAD_MORE_NETWORK_RESULTS_REQUEST'

export const LOAD_MORE_NETWORK_RESULTS_SUCCESS: 'LOAD_MORE_NETWORK_RESULTS_SUCCESS' =
  'LOAD_MORE_NETWORK_RESULTS_SUCCESS'

export type NetworkState = {
  networkList: Connection[],
  disableLoadMore: boolean,
  isLoadingMore: boolean,
  isLoading: boolean,
  resultIndex: number
}

const networkState = {
  networkList: [],
  disableLoadMore: false,
  isLoadingMore: false,
  isLoading: false,
  resultIndex: 0
}

// type GetNetworkSuccessPayload = Connection[]

const reducer: Reducer<NetworkState> = (
  state: NetworkState = networkState,
  action: Action<any>
) => {
  switch (action.type) {
    case FILTER_NETWORK_REQUEST:
      return {
        ...state,
        isLoading: true,
        disableLoadMore: false,
        networkList: []
      }
    case FILTER_NETWORK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        disableLoadMore: action.payload ? action.payload.length < 10 : false,
        networkList: action.payload,
        resultIndex: action.payload && action.payload.length
      }
    case LOAD_MORE_NETWORK_RESULTS_REQUEST:
      return {
        ...state,
        isLoadingMore: true
      }
    case LOAD_MORE_NETWORK_RESULTS_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        disableLoadMore: action.payload ? action.payload.length < 10 : false,
        networkList: action.payload
          ? state.networkList.concat(action.payload)
          : state.networkList,
        resultIndex:
          action.payload && action.payload.length + state.networkList.length
      }

    default:
      return state
  }
}

export default reducer
