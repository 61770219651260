// @flow

type Option = {
  label: string,
  value: string
}

export const EDIT_SKILLS_OPTIONS = [
  { label: 'Demo Vocalist', value: 'Demo Vocalist' },
  { label: 'Demo Producer', value: 'Demo Producer' },
  { label: 'Demo BGVs', value: 'Demo BGVs' },
  {
    label: 'Demo Instrumentalist',
    value: 'Demo Instrumentalist'
  },
  { label: 'Has a Songplugger', value: 'Has a Songplugger' },
  { label: 'Has a Sync Agent', value: 'Has a Sync Agent' },
  { label: 'Lyrics', value: 'Lyrics' },
  { label: 'Melody', value: 'Melody' }
]

export const EDIT_COWRITE_FREQUENCY_OPTIONS = [
  {
    value: '0',
    label: '0'
  },
  {
    value: '1',
    label: '1'
  },
  {
    value: '2-5',
    label: '2-5'
  },
  {
    value: '6-10',
    label: '6-10'
  },
  {
    value: '11-15+',
    label: '11-15+'
  }
]

export const EDIT_LIVE_PERFORMANCE_OPTIONS = [
  {
    value: 'Open Mics & Rounds',
    label: 'Open Mics & Rounds'
  },
  {
    value: 'Solo shows',
    label: 'Solo shows'
  },
  {
    value: 'Full band shows',
    label: 'Full band shows'
  }
]

export const EDIT_SHOWS_PER_YEAR = [
  {
    value: 'Under 50',
    label: 'Under 50'
  },
  {
    value: '51-100',
    label: '51-100'
  },
  {
    value: '101-150',
    label: '101-150'
  },
  {
    value: '151-200',
    label: '151-200'
  },
  {
    value: '201+',
    label: '201+'
  }
]

export const EDIT_COWRITE_EXPERIENCE_OPTIONS = [
  {
    value: 'I do not co-write',
    label: 'I do not co-write'
  },
  {
    value: 'Less than 1 Year',
    label: 'Less than 1 Year'
  },
  {
    value: '1-2 Years',
    label: '1-2 Years'
  },
  {
    value: '3-6 Years',
    label: '3-6 Years'
  },
  {
    value: '7+ Years',
    label: '7+ Years'
  }
]

export const EDIT_GENRES_OPTIONS: Option[] = [
  { label: 'Americana', value: 'Americana' },
  { label: 'Blue Grass', value: 'Blue Grass' },
  { label: 'Country (Contemporary)', value: 'Country (Contemporary)' },
  { label: 'Country (Traditional)', value: 'Country (Traditional)' },
  { label: 'EDM', value: 'EDM' },
  { label: 'Jazz', value: 'Jazz' },
  { label: 'Reggae', value: 'Reggae' },
  { label: 'Sync', value: 'Sync' },
  { label: 'TV/Film Composer', value: 'TV/Film Composer' },
  { label: 'Pop', value: 'Pop' },
  { label: 'CCM', value: 'CCM' },
  { label: 'Gospel', value: 'Gospel' },
  { label: 'Rock', value: 'Rock' },
  { label: 'Hip Hop', value: 'Hip Hop' },
  { label: 'Latin', value: 'Latin' },
  { label: 'R&B Urban', value: 'R&B Urban' }
].sort((a, b) => a.label.localeCompare(b.label))

export const EDIT_SONGWRITING_ORGS_OPTIONS = [
  {
    value: 'Global Songwriters Connection (GSC)',
    label: 'Global Songwriters Connection (GSC)'
  },
  {
    value: 'Nashville Songwriters Assoc Intl (NSAI)',
    label: 'Nashville Songwriters Assoc Intl (NSAI)'
  },
  {
    value: 'Tenn Songwriters Assoc Intl (TSAI)',
    label: 'Tenn Songwriters Assoc Intl (TSAI)'
  },
  {
    value: 'SongTown',
    label: 'SongTown'
  }
]

export const EDUCATION_TYPE_OPTIONS = [
  {
    label: 'No Formal Education',
    value: 'No Formal Education'
  },
  {
    label: 'Undergraduate',
    value: 'Undergraduate'
  },
  {
    label: 'Masters',
    value: 'Masters'
  },
  {
    label: 'Songwriting Program',
    value: 'Songwriting Program'
  },
  {
    label: 'Audio Program',
    value: 'Audio Program'
  }
]

export const EDIT_PERFORMING_ORGS_OPTIONS: Option[] = [
  {
    value: 'ASCAP',
    label: 'ASCAP'
  },
  {
    value: 'BMI',
    label: 'BMI'
  },
  {
    value: 'GMR',
    label: 'GMR'
  },
  {
    value: 'SESAC',
    label: 'SESAC'
  },
  {
    value: 'SOCAN',
    label: 'SOCAN'
  },
  {
    value: 'ACEMLA',
    label: 'ACEMLA'
  },
  {
    value: 'SPACEM',
    label: 'SPACEM'
  },
  {
    value: 'APRA',
    label: 'APRA'
  },
  {
    value: 'CMRRA',
    label: 'CMRRA'
  },
  {
    value: 'PRS',
    label: 'PRS'
  },
  {
    value: 'PPL',
    label: 'PPL'
  },
  {
    value: 'SODRAC',
    label: 'SODRAC'
  },
  {
    value: 'SPACQ',
    label: 'SPACQ'
  },
  {
    value: 'SACM',
    label: 'SACM'
  }
].sort((a, b) => a.label.localeCompare(b.label))
