// @flow

import update from 'immutability-helper'
import type { Action, Reducer } from 'src/utils/types'
import type { User } from 'src/utils/types/users'
import type { NetworkEntry } from 'src/utils/types/network'
import type { Content } from 'src/utils/types/content'

export const UPLOAD_PROFILE_IMAGE_REQUEST: 'UPLOAD_PROFILE_IMAGE_REQUEST' =
  'UPLOAD_PROFILE_IMAGE_REQUEST'
export const UPLOAD_PROFILE_IMAGE_SUCCESS: 'UPLOAD_PROFILE_IMAGE_SUCCESS' =
  'UPLOAD_PROFILE_IMAGE_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_ERROR: 'UPLOAD_PROFILE_IMAGE_ERROR' =
  'UPLOAD_PROFILE_IMAGE_ERROR'

export const UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST' = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS' = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR: 'UPDATE_USER_ERROR' = 'UPDATE_USER_ERROR'

export const GET_USER_REQUEST: 'GET_USER_REQUEST' = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS: 'GET_USER_SUCCESS' = 'GET_USER_SUCCESS'

export const GET_VIDEOS_BY_USER_REQUEST: 'GET_VIDEOS_BY_USER_REQUEST' =
  'GET_VIDEOS_BY_USER_REQUEST'
export const GET_VIDEOS_BY_USER_SUCCESS: 'GET_VIDEOS_BY_USER_SUCCESS' =
  'GET_VIDEOS_BY_USER_SUCCESS'

export const REMOVE_NETWORK_REQUEST: 'REMOVE_NETWORK_REQUEST' =
  'REMOVE_NETWORK_REQUEST'
export const REMOVE_NETWORK_SUCCESS: 'REMOVE_NETWORK_SUCCESS' =
  'REMOVE_NETWORK_SUCCESS'
export const REMOVE_NETWORK_ERROR: 'REMOVE_NETWORK_ERROR' =
  'REMOVE_NETWORK_ERROR'

export const ADD_NETWORK_REQUEST: 'ADD_NETWORK_REQUEST' = 'ADD_NETWORK_REQUEST'
export const ADD_NETWORK_SUCCESS: 'ADD_NETWORK_SUCCESS' = 'ADD_NETWORK_SUCCESS'
export const ADD_NETWORK_ERROR: 'ADD_NETWORK_ERROR' = 'ADD_NETWORK_ERROR'

export const ADD_CONNECTION_REQUEST: 'CONNECTION_REQUEST' = 'CONNECTION_REQUEST'
export const ADD_CONNECTION_SUCCESS: 'ADD_CONNECTION_SUCCESS' =
  'ADD_CONNECTION_SUCCESS'
export const ADD_CONNECTION_ERROR: 'ADD_CONNECTION_ERROR' =
  'ADD_CONNECTION_ERROR'

export const CONFIRM_CONNECTION_REQUEST: 'CONFIRM_CONNECTION_REQUEST' =
  'CONFIRM_CONNECTION_REQUEST'
export const CONFIRM_CONNECTION_SUCCESS: 'CONFIRM_CONNECTION_SUCCESS' =
  'CONFIRM_CONNECTION_SUCCESS'

export const REMOVE_CONNECTION_REQUEST: 'REMOVE_CONNECTION_REQUEST' =
  'REMOVE_CONNECTION_REQUEST'
export const REMOVE_CONNECTION_SUCCESS: 'REMOVE_CONNECTION_SUCCESS' =
  'REMOVE_CONNECTION_SUCCESS'
export const REMOVE_CONNECTION_ERROR: 'REMOVE_CONNECTION_ERROR' =
  'REMOVE_CONNECTION_ERROR'

export const GET_NETWORK_REQUEST: 'GET_NETWORK_REQUEST' = 'GET_NETWORK_REQUEST'
export const GET_NETWORK_SUCCESS: 'GET_NETWORK_SUCCESS' = 'GET_NETWORK_SUCCESS'
export const GET_NETWORK_ERROR: 'GET_NETWORK_ERROR' = 'GET_NETWORK_ERROR'

export type ProfileState = {
  user: ?User,
  videos: ?(Content[]),
  isLoading: boolean,
  isLoadingVideos: boolean,
  isFollowing?: boolean,
  isConnected?: boolean,
  isConnectionPending?: boolean,
  isAwaitingConfirmation?: boolean,
  followButtonProcessing: boolean,
  connectionButtonProcessing: boolean,
  addNetworkError?: boolean,
  getNetworkError?: boolean,
  isUpdatingUser: boolean,
  profileImage: string
}

const profileState = {
  user: null,
  videos: null,
  isLoading: false,
  isLoadingVideos: false,
  followButtonProcessing: false,
  isAwaitingConfirmation: false,
  isConnectionPending: false,
  connectionButtonProcessing: false,
  isUpdatingUser: false,
  profileImage: ''
}

const setIsFollowing = (connections?: NetworkEntry[]) => {
  return (
    connections &&
    !!connections.length &&
    !!connections.find(val => val.relationship === 'isFollowing')
  )
}

const setIsConnected = (connections?: NetworkEntry[]) => {
  return (
    connections &&
    !!connections.length &&
    !!connections.find(val => val.relationship === 'hasConnection')
  )
}

const updateFollowerCount = (
  increment: number,
  state: ProfileState,
  followedByPublisher?: boolean
) => {
  if (state && state.user) {
    const { user } = state
    const updatedUser = update(user, {
      stats: {
        followerCount: {
          $set: user.stats.followerCount + increment
        },
        publisherFollowerCount: {
          $set: followedByPublisher
            ? user.stats.publisherFollowerCount + increment
            : user.stats.publisherFollowerCount
        }
      }
    })

    return updatedUser
  }
  return state.user
}

const reducer: Reducer<ProfileState> = (
  state: ProfileState = profileState,
  action: Action<any>
) => {
  switch (action.type) {
    case UPLOAD_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        isUpdatingUser: true
      }
    case UPLOAD_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        profileImage: action.payload
      }
    case UPLOAD_PROFILE_IMAGE_ERROR:
      return {
        ...state,
        isUpdatingUser: false
      }
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdatingUser: true
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isUpdatingUser: false
      }
    case UPDATE_USER_ERROR:
      return {
        ...state,
        isUpdatingUser: false
      }
    case GET_USER_REQUEST:
      return { ...state, isLoading: true }
    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload && action.payload.user,
        isConnectionPending:
          action.payload && action.payload.isConnectionPending,
        isAwaitingConfirmation:
          action.payload && action.payload.isAwaitingConfirmation
      }
    case GET_VIDEOS_BY_USER_REQUEST:
      return { ...state, isLoadingVideos: true }
    case GET_VIDEOS_BY_USER_SUCCESS:
      return { ...state, isLoadingVideos: false, videos: action.payload }
    case ADD_CONNECTION_REQUEST:
      return { ...state, connectionButtonProcessing: true }
    case ADD_CONNECTION_SUCCESS:
      return {
        ...state,
        connectionButtonProcessing: false,
        isConnectionPending: true
      }
    case CONFIRM_CONNECTION_REQUEST:
      return {
        ...state,
        connectionButtonProcessing: true
      }
    case CONFIRM_CONNECTION_SUCCESS:
      return {
        ...state,
        connectionButtonProcessing: false,
        isConnectionPending: false,
        isAwaitingConfirmation: false,
        isConnected: true
      }
    case REMOVE_CONNECTION_REQUEST:
      return { ...state, connectionButtonProcessing: true }
    case REMOVE_CONNECTION_SUCCESS:
      return {
        ...state,
        connectionButtonProcessing: false,
        isConnected: false
      }
    case ADD_NETWORK_REQUEST:
      return {
        ...state,
        followButtonProcessing: true
      }
    case ADD_NETWORK_SUCCESS:
      return {
        ...state,
        isFollowing: true,
        followButtonProcessing: false,
        user:
          action.payload &&
          updateFollowerCount(1, state, action.payload.followedByPublisher)
      }
    case REMOVE_NETWORK_REQUEST:
      return {
        ...state,
        followButtonProcessing: true,
        user:
          action.payload &&
          updateFollowerCount(-1, state, action.payload.followedByPublisher)
      }
    case REMOVE_NETWORK_SUCCESS:
      return {
        ...state,
        isFollowing: false,
        followButtonProcessing: false
      }
    case ADD_NETWORK_ERROR:
      return {
        ...state,
        followButtonProcessing: false,
        connectionButtonProcessing: false,
        addNetworkError: true
      }
    case GET_NETWORK_REQUEST:
      return {
        ...state,
        followButtonProcessing: true,
        connectionButtonProcessing: true
      }
    case GET_NETWORK_SUCCESS:
      return {
        ...state,
        followButtonProcessing: false,
        connectionButtonProcessing: false,
        isFollowing: setIsFollowing(action.payload),
        isConnected: setIsConnected(action.payload)
      }
    case GET_NETWORK_ERROR:
      return {
        ...state,
        followButtonProcessing: false,
        connectionButtonProcessing: false,
        getNetworkError: true
      }
    default:
      return state
  }
}

export default reducer
