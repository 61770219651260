// @flow

import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import network from 'src/state/reducers/network'
import users from 'src/state/reducers/users'
import profile from 'src/state/reducers/profile'
import inbox from 'src/state/reducers/inbox'
import splashes from 'src/state/reducers/splashes'
import config from 'src/state/reducers/config'
import payments from 'src/state/reducers/payments'
import dashboard from 'src/state/reducers/dashboard'
import notifications from 'src/state/reducers/notifications'
import admin from 'src/state/reducers/admin'
import music from 'src/state/music'
import type { Reducer } from 'redux'
import { routerReducer as routing } from 'react-router-redux'
import type { State, ReduxAction } from 'src/utils/types'

const reducers: Reducer<State, ReduxAction> = combineReducers({
  admin,
  dashboard,
  network,
  form,
  users,
  music,
  profile,
  inbox,
  splashes,
  config,
  payments,
  notifications,
  routing
})

export default reducers
