// @flow

export type FieldValidator = any => void | string

// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const requiredField: FieldValidator = value => {
  if (value) {
    if (Array.isArray(value)) {
      return value.length ? undefined : 'This field is required'
    }
    return undefined
  } else {
    return 'This field is required'
  }
}

export const nonEmptyArray = (value: Array<string>) => {
  if (value.length > 0) return undefined
  return 'This field is required'
}

export const validateConfirmPassword = (password: string) => (value: string) =>
  value === password ? undefined : `Oops, this isn't the password you entered.`

export const invalidEmail: FieldValidator = value =>
  emailRegex.test(value) ? undefined : 'Please enter a valid email'

export const validYearRange: FieldValidator = value => {
  if (!value) return undefined
  return isNaN(value) ? 'Please enter a valid year' : undefined
}

export const validPhone: FieldValidator = (value: string) => {
  const onlyNumbers = value.replace(/[+\- ]/g, '')
  if (
    value.replace(/[0-9+\- ()]/g, '').length > 0 ||
    onlyNumbers.length < 7 ||
    onlyNumbers.length > 17
  ) {
    return 'Please enter a valid Phone Number'
  }
}

export const validFile: FieldValidator = (value: File) => {
  return value && value.size / 1024 / 1024 > 3
    ? 'Files must be less than 3mb'
    : undefined
}

export const validYoutubeUrl: FieldValidator = (value: string) => {
  if (value.includes('/watch?v=') || value.includes('/embed/')) {
    return
  } else {
    return 'Please use a share url with the format "https://www.youtube.com/watch?v=abc123"'
  }
}

export const validMusicUrl: FieldValidator = (value: string) => {
  if (!value.includes('spotify') && !value.includes('soundcloud')) {
    return 'This is neither a spotify nor a soundcloud link'
  }
}

export const validNumber: FieldValidator = (value?: string) => {
  if (isNaN(value?.trim())) {
    return 'Please enter a numeric value'
  }
}
