// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import AppleMusicIcon from 'src/images/logos/apple-icon.svg'
import PandoraIcon from 'src/images/logos/pandora-icon.svg'
import SpotifyIcon from 'src/images/logos/spotify-icon.svg'
import SoundCloudIcon from 'src/images/logos/soundcloud-icon.png'
import YoutubeIcon from 'src/images/logos/youtube-icon.png'
import type { SocialNetworkNames } from 'src/utils/types/users'

const getColor = (theme: Object, color: IconColors) => {
  switch (color) {
    case 'Primary':
      return theme.palette.primary.main
    case 'Secondary':
      return theme.palette.secondary.main
    case 'Error':
      return fade(theme.palette.error.main, 0.7)
    default:
      return theme.palette.primary.main
  }
}

const getPlatformIcon = (plat: SocialNetworkNames) => {
  switch (plat) {
    case 'Apple Music':
      return AppleMusicIcon
    case 'Pandora':
      return PandoraIcon
    case 'SoundCloud':
      return SoundCloudIcon
    case 'Spotify':
      return SpotifyIcon
    case 'Youtube':
      return YoutubeIcon
    default:
      return SpotifyIcon
  }
}

const useStyles = makeStyles(theme => ({
  icon: {
    width: props => (props.size === 'sm' ? 45 : 65),
    height: props => (props.size === 'sm' ? 45 : 65),
    backgroundImage: props =>
      `url(${props.img || getPlatformIcon(props.platform)})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: props => getColor(theme, props.color),
    [theme.breakpoints.down('xs')]: {
      width: props => (props.size === 'sm' ? 28 : 50),
      height: props => (props.size === 'sm' ? 28 : 50)
    }
  }
}))

type IconColors = 'Primary' | 'Secondary' | 'Error'

type IconProps = {
  img?: string,
  platform?: SocialNetworkNames,
  size?: 'sm' | 'md',
  color?: IconColors,
  className?: string,
  ariaLabel: string
}

export const GenericIcon = ({
  img,
  platform,
  size = 'sm',
  ariaLabel,
  color = 'Primary',
  className = '',
  ...props
}: IconProps) => {
  const styles = useStyles({ color, img, size, platform })
  return (
    <div
      aria-label={ariaLabel}
      className={`${styles.icon} ${className}`}
      {...props}
    />
  )
}
