// @flow

import type { Action } from 'src/utils/types'
import type { Notification } from 'src/utils/types/notifications'

export const GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST' =
  'GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS' =
  'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR: 'GET_NOTIFICATIONS_ERROR' =
  'GET_NOTIFICATIONS_ERROR'

export const MARK_READ_REQUEST: 'MARK_READ_REQUEST' = 'MARK_READ_REQUEST'
export const MARK_READ_SUCCESS: 'MARK_READ_SUCCESS' = 'MARK_READ_SUCCESS'
export const MARK_READ_ERROR: 'MARK_READ_ERROR' = 'MARK_READ_ERROR'

export type NotificationState = {
  index: number,
  isFetchingNotifs: boolean,
  isMarkingRead: boolean,
  notifications: Notification[],
  noNewNotifs: boolean
}

const notificationState = {
  index: 0,
  isFetchingNotifs: false,
  isMarkingRead: false,
  noNewNotifs: false,
  notifications: [],
  mostRecentBroadcastNotification: null
}

const reducer = (
  state: NotificationState = notificationState,
  action: Action<any>
) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return { ...state, isFetchingNotifs: true }
    case GET_NOTIFICATIONS_SUCCESS:
      const mergedNotifications = addNotifications(
        state.notifications,
        action.payload
      )
      const broadNotifs = mergedNotifications.filter(
        notification => notification.notifType === 'BROADCAST'
      )

      let mostRecentNotification = null
      if (broadNotifs.length > 0) {
        mostRecentNotification = broadNotifs[0]
        for (let index = 1; index < broadNotifs.length; index++) {
          if (
            new Date(mostRecentNotification.timestamp) <
            new Date(broadNotifs[index].timestamp)
          ) {
            mostRecentNotification = broadNotifs[index]
          }
        }
      }

      return {
        ...state,
        isFetchingNotifs: false,
        notifications: mergedNotifications,
        noNewNotifs: action.payload && action.payload?.length < 5,
        index: mergedNotifications.length,
        mostRecentBroadcastNotification: mostRecentNotification
      }
    case GET_NOTIFICATIONS_ERROR:
      return { ...state, isFetchingNotifs: true }
    case MARK_READ_REQUEST:
      return { ...state, isMarkingRead: true }
    case MARK_READ_SUCCESS:
      return {
        ...state,
        isMarkingRead: false,
        notifications:
          action.payload &&
          removeNotification(state.notifications, action.payload)
      }
    case MARK_READ_ERROR:
      return { ...state, isMarkingRead: false }
    default:
      return state
  }
}

const addNotifications = (
  oldNotifs: Notification[],
  newNotifs?: Notification[]
) => {
  if (!newNotifs) {
    return oldNotifs
  }
  if (!oldNotifs.length) {
    return newNotifs
  } else {
    return oldNotifs.concat(
      newNotifs.filter(
        newNotif => !!oldNotifs.every(oldNotif => oldNotif._id !== newNotif._id)
      )
    )
  }
}

const removeNotification = (notifications: Notification[], id: string) =>
  //$FlowFixMe
  notifications.filter(notif => notif._id !== id)

export default reducer
