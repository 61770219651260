// @flow
import axios from 'src/settings/axios'
import type { Thread } from 'src/utils/types/inbox'

export type GetThreadsResponse = {
  data: Thread[]
}

export type GetThreadsService = () => Promise<GetThreadsResponse>

export const getThreads: GetThreadsService = data => axios.get('/threads')

export type MarkThreadReadRequest = {
  threadId: string
}
export type MarkThreadReadResponse = {}

export type MarkThreadReadService = MarkThreadReadRequest => Promise<MarkThreadReadResponse>

export const markThreadRead: MarkThreadReadService = data =>
  axios.patch('/threads/mark-read', null, {
    params: data
  })

export type CreateThreadRequest = {
  members: string[],
  messagePreview: string
}
export type CreateThreadResponse = {
  data: Thread
}

export type CreateThreadService = CreateThreadRequest => Promise<CreateThreadResponse>

export const createThread: CreateThreadService = data =>
  axios.post('/threads', data)

export type DeleteThreadRequest = {
  threadId: string
}
export type DeleteThreadResponse = {}

export type DeleteThreadService = DeleteThreadRequest => Promise<DeleteThreadResponse>

export const deleteThread: DeleteThreadService = data =>
  axios.delete('/threads', {
    params: data
  })
