// @flow
import customAxios from 'src/settings/axios'
import type { User, AccountType, Genre } from 'src/utils/types/users'
import formatFormData from 'src/utils/api/formatFormData'

type LoginUserResponse = {
  data: User
}

export type CreateAccountRequest = {
  email: string,
  genre: Genre,
  city: string,
  state: string,
  occupation: string,
  firstname: string,
  lastname: string,
  accountType: AccountType,
  // business verification fields
  companyName?: string,
  writerName?: string,
  songTitle?: string,
  releaseYear?: string,
  songUrl?: string,
  filmTitle?: string
}

export type CreateUserService = (
  data?: CreateAccountRequest
) => Promise<LoginUserResponse>

// NOTE: credentials are passed from generated firebase token via Authorization header in axios interceptor
export const createUser: CreateUserService = data =>
  customAxios.post('/auth/signup', formatFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

// export type SpotifySignupRequest = {
//   code?: string,
//   error?: string,
//   state: string
// }

// export type SpotifySignupResponse = {
//   token: string,
//   refresh_token: string
// }

// export type SpotifySignupService = SpotifySignupRequest => Promise<SpotifySignupResponse>

// export const getAccessToken: SpotifySignupService = data => {
//   return customAxios.post('/auth/spotify/token', {
//     ...data
//   })
// }

export type SpotifyRefreshRequest = {
  refresh_token: string
}

export type SpotifyRefreshResponse = {
  access_token: string,
  refresh_token: string,
  expires_in: number,
  scope: string
}

export type SpotifyRefreshService = SpotifyRefreshRequest => Promise<SpotifyRefreshResponse>

export const getSpotifyRefreshToken: SpotifyRefreshService = data =>
  customAxios.post('/auth/spotify/refresh', { ...data })
