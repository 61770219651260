// @flow

import React, { useState } from 'react'
import { Flex } from 'src/components/molecules/Flex'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { Card } from 'src/components/molecules/Card'
import type { ReduxFormProps } from 'src/utils/types'
import { reduxForm, SubmissionError } from 'redux-form'
import TextField from 'src/components/atoms/TextField'
import { invalidEmail, requiredField } from 'src/utils/fieldValidators'
import Link from 'src/components/atoms/Link'
import Button from 'src/components/atoms/buttons/Button'
import { CTA } from '../../../molecules/CTA'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { getMe } from 'src/services/users'

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 700
  },
  subHeader: {
    color: theme.palette.text.label,
    textAlign: 'center'
  },
  loginButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  resetPasswordLink: {
    justifyContent: 'flex-end'
  },
  methodContainer: {
    display: 'flex',
    margin: `${theme.spacing(4)}px 0`,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing(2)
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(4)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px 0`
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  providerButton: {
    color: '#FFF',
    fontWeight: 600,
    textTransform: 'none',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  providerIcon: {
    marginRight: theme.spacing(2)
  },
  link: {
    display: 'inline',
    textDecoration: 'underline',
    paddingBottom: theme.spacing(3)
  },
  newUserLink: {
    color: theme.palette.text.label,
    textAlign: 'center'
  },
  loginError: {
    display: 'block',
    marginTop: 8,
    maxWidth: 300,
    textAlign: 'center',
    color: theme.palette.error.main
  }
}))

type LoginProps = {
  onEmailLoginSuccess: () => void,
  onEmailLogin: (email: String, password: string) => Promise<void>,
  onGoogleLogin: () => Promise<void>,
  onFacebookLogin: () => Promise<void>
}

export const Login = ({
  onEmailLoginSuccess,
  onEmailLogin,
  onGoogleLogin,
  onFacebookLogin
}: LoginProps) => {
  const styles = useStyles()
  const history = useHistory()
  const [providerLoginError, setProviderLoginError] = useState('')

  const onProviderLogin = (login?: () => Promise<void>) => async () => {
    try {
      login && (await login())
      history.replace('/discover')
    } catch (err) {
      setProviderLoginError(err.message)
    }
  }

  return (
    <Flex container flexDirection="column" alignItems="center" padding="0 16px">
      <CTA
        header="Welcome Back!"
        subHeader="Let's get you logged in to see what you've missed."
      />

      <div className={styles.methodContainer}>
        <ReduxLoginForm
          onEmailLogin={onEmailLogin}
          onEmailLoginSuccess={onEmailLoginSuccess}
        />
        <div className={styles.column}>
          <Typography variant="h6">or</Typography>
        </div>
        <div className={styles.column}>
          <Button
            social="google"
            variant="contained"
            onClick={onProviderLogin(onGoogleLogin)}
            size="large"
          >
            Login with Google
          </Button>
          {providerLoginError && (
            <Typography
              className={styles.loginError}
              variant="subtitle1"
              color="error"
            >
              {providerLoginError}
            </Typography>
          )}
        </div>
      </div>
    </Flex>
  )
}

const LoginForm = ({
  onEmailLogin,
  onEmailLoginSuccess,
  pristine,
  submitting,
  invalid,
  error,
  handleSubmit
}: ReduxFormProps & LoginProps) => {
  const styles = useStyles()
  const [token, setToken] = useState();

  return (
    <form
      onSubmit={handleSubmit(async ({ email, password }) => {
        try {
          await onEmailLogin(email.trim(), password)
          await new Promise((resolve) => setTimeout(resolve, 350))
          const data = await getMe();
          if (!data) {
            throw new Error("User Not Found in DB")
          }
          onEmailLoginSuccess()
        } catch (error) {
          console.log(error)
          throw new SubmissionError({
            _error:
              `Invalid username or password, or this user is tied to a social account. Please contact Customer Care at staff@discoversooner.com`
          })
        }
      })}
    >
      <Card
        width={350}
        minHeight={200}
        mode="light"
        variant="form"
        actionLabel="Login with Email"
        loading={submitting}
        disabled={pristine || submitting || invalid || !token}
      >
        <TextField
          id="email-input"
          name="email"
          label="Email"
          validators={[requiredField, invalidEmail]}
          fullWidth
        />
        <TextField
          type="password"
          id="password-input"
          name="password"
          label="Password"
          validators={[requiredField]}
          fullWidth
        />
        <GoogleReCaptchaProvider reCaptchaKey="6LdRs3EeAAAAAGB6Hs9cH5mlFCY3Ol4aohF-DXYF">
          <GoogleReCaptcha onVerify={value => {
            if (!token) {
              setToken(value);
            }
          }} />
        </GoogleReCaptchaProvider>
        <Link className={styles.resetPasswordLink} to="/reset-password">
          <Typography
            color="primary"
            variant="subtitle2"
            style={{ marginTop: -8 }}
          >
            Forgot Password?
          </Typography>
        </Link>
        {error && (
          <span className={styles.loginError}>
            <strong>{error}</strong>
          </span>
        )}
      </Card>
      <Typography variant="subtitle1" className={styles.newUserLink}>
        New to Sooner?{' '}
        <Link to="/signup" className={styles.link} replace>
          Sign up here.
        </Link>
      </Typography>
    </form>
  )
}

const ReduxLoginForm = reduxForm({ form: 'login' })(LoginForm)
