// @flow
import axios from 'src/settings/axios'

export type VerifyAccountRequest = { email: string, verified: boolean }

export type VerifyAccountService = VerifyAccountRequest => Promise<{}>

export const verifyAccount: VerifyAccountService = data =>
  axios.post('/admin/verify-business', data)

export type ProcessReferralRequest = {
  email: string,
  approved: boolean,
  feedback: string
}

export type ProcessReferralService = ProcessReferralRequest => Promise<{}>

export const processReferral: ProcessReferralService = data =>
  axios.post('/admin/refer-user', data)

export type CreateConnectionRequest = {
  userA: string,
  userB: string
}

export type CreateConnectionService = CreateConnectionRequest => Promise<{}>

export const createConnection: CreateConnectionService = data =>
  axios.post('/admin/connect', data)

export type DeleteUserRequest = {
  email: string
}

export type DeleteUserService = DeleteUserRequest => Promise<{}>

export const deleteUser: DeleteUserService = data =>
  axios.post('/admin/delete-user', data)

export type UpgradeUserService = DeleteUserRequest => Promise<{}>

export const upgradeUser: UpgradeUserService = data =>
  axios.post('/admin/upgrade-user', data)


export type DowngradeUserService = DeleteUserRequest => Promise<{}>

export const downgradeUser: DowngradeUserService = data =>
  axios.post('/admin/downgrade-user', data)
  