// @flow

import React from 'react'
import { Flex } from 'src/components/molecules/Flex'
import Typography from '@material-ui/core/Typography'
import { Card } from 'src/components/molecules/Card'
import { CTA } from 'src/components/molecules/CTA'

type WelcomeProps = {
  onLoginClick: () => void,
  onSignupClick: () => void
}

export const Welcome = ({ onLoginClick, onSignupClick }: WelcomeProps) => {
  return (
    <Flex container flexDirection="column" alignItems="center" padding="0 16px">
      <CTA
        header="Welcome to Sooner!"
        subHeader="Let's figure out where you should go from here."
      />

      <Flex
        container
        margin="32px 0 0 0"
        width="100%"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Card
          mode="light"
          actionLabel="Login"
          headerText="Have an Account?"
          onActionClick={onLoginClick}
        >
          <Typography variant="body1">
            No need for introductions, get logged in and see what you’ve missed!
          </Typography>
        </Card>
        <Card
          mode="dark"
          actionLabel="Signup"
          headerText="New to Sooner?"
          onActionClick={onSignupClick}
        >
          <Typography variant="body1" color="inherit">
            Make quality music industry connections in ways not possible on
            other platforms.
          </Typography>
        </Card>
      </Flex>
    </Flex>
  )
}
