// @flow

import React from 'react'
import ProfileTemplate from 'src/components/templates/Profile'

const ProfileImage = () => {
  return <ProfileTemplate />
}

export default ProfileImage
