// @flow
export const COLUMNS = [
  {
    value: '',
    align: 'left'
  },
  {
    value: 'NAME',
    align: 'left'
  },
  {
    value: 'LOCATION',
    align: 'right'
  },
  {
    value: 'STREAM COUNT',
    align: 'right'
  },
  {
    value: 'GENRE',
    align: 'right'
  },
  {
    value: 'SEEKING DEAL',
    align: 'right'
  },
  {
    value: 'TOP CHART',
    align: 'right'
  }
]

export const NETWORK_RELATIONSHIP_OPTIONS = [
  {
    name: 'Following',
    value: 'isFollowing'
  },
  {
    name: 'Followers',
    value: 'hasFollower'
  },
  {
    name: 'Connections',
    value: 'hasConnection'
  }
]
