/* eslint-disable jsx-a11y/no-onchange */
// @flow

import React from 'react'
import type { FieldProps } from 'redux-form'
import { Field } from 'redux-form'

import NativeSelect from '@material-ui/core/NativeSelect'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: 'auto'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  caret: {
    fontSize: '1rem',
    marginLeft: theme.spacing(2)
  },
  select: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
    top: 0,
    left: 0
  }
}))

type Option = {
  name: string,
  value: string
}

type SelectProps = {
  variant?: 'default' | 'header',
  options: Option[],
  onChange: (SyntheticEvent<HTMLSelectElement>) => void,
  value: string
}

export const SelectField = ({
  options,
  value,
  onChange,
  variant = 'default',
  ...props
}: SelectProps) => {
  const styles = useStyles()

  const htmlOptions = options.map(opt => (
    <option key={opt.value} value={opt.value}>
      {opt.name}
    </option>
  ))

  const activeOption = options.find(option => option.value === value)

  const HeaderSelect = (
    <div className={styles.container}>
      <Typography variant="h5" className={styles.header}>
        {activeOption && activeOption.name}
        <span className={styles.caret}> &#x25BC; </span>
      </Typography>
      <select
        className={styles.select}
        value={value}
        onChange={onChange}
        {...props}
      >
        {htmlOptions}
      </select>
    </div>
  )

  switch (variant) {
    case 'header':
      return HeaderSelect
    default:
      return (
        <NativeSelect defaultValue={value} onChange={onChange} {...props}>
          {htmlOptions}
        </NativeSelect>
      )
  }
}

const renderSelectField = ({
  variant,
  value,
  options,
  input,
  onChange,
  ...props
}: SelectProps & FieldProps) => {
  return (
    <SelectField
      variant={variant}
      options={options}
      value={input.value}
      onChange={onChange}
      {...input}
      {...props}
    />
  )
}

type ConnectedSelectFieldProps = {
  name: string,
  variant: string,
  options: Option[],
  onChange?: string => void
}

export default ({
  name,
  variant,
  options,
  onChange,
  ...props
}: ConnectedSelectFieldProps) => (
  <Field
    name={name}
    component={renderSelectField}
    variant={variant}
    options={options}
    {...props}
  />
)
