// @flow

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Expansion from 'src/components/molecules/Expansion'
import EditSkillsForm from 'src/components/organisms/forms/EditSkills'
import EditAccountForm from 'src/components/organisms/forms/EditAccount'
import EditSocialMediaForm from 'src/components/organisms/forms/EditSocialMedia'
import EditPublishingForm from 'src/components/organisms/forms/EditPublishing'
import { Elements, StripeProvider } from 'react-stripe-elements'
import env from 'src/settings/env'
import { useAuth } from '../../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  emptyContentMessage: {
    color: theme.palette.text.label
  },
  emptyContentWrapper: {
    width: '100%',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  editProfileContainer: {
    width: '90%',
    margin: 'auto',
    marginTop: theme.spacing(4)
  }
}))

export default () => {
  const styles = useStyles()
  const { user } = useAuth()

  return user ? (
    <div className={styles.editProfileContainer}>
      <Expansion title="Account">
        <StripeProvider apiKey={env.STRIPE_API_KEY}>
          <Elements>
            <EditAccountForm accountType={user.accountType} />
          </Elements>
        </StripeProvider>
      </Expansion>
      {user.accountType === 'Songwriter' && (
        <>
          <Expansion title="Stats &amp; Skills">
            <EditSkillsForm />
          </Expansion>
          {user.accountLevel !== 'Free' && (
            <Expansion title="Publishing">
              <EditPublishingForm />
            </Expansion>
          )}
        </>
      )}
      <Expansion title="Social Media">
        <EditSocialMediaForm accountType={user.accountType} />
      </Expansion>
    </div>
  ) : (
    ''
  )
}
