/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React from 'react'

import Link from 'src/components/atoms/Link'
import IconButton from 'src/components/atoms/buttons/IconButton'
import HomeIcon from '@material-ui/icons/Home'
import NetworkIcon from '@material-ui/icons/People'
import InboxIcon from '@material-ui/icons/Mail'
import ApplyIcon from '@material-ui/icons/Assignment'
import VideoLibraryRoundedIcon from '@material-ui/icons/VideoLibraryRounded'
import HelpIcon from '@material-ui/icons/Help'
import SearchIcon from '@material-ui/icons/Search'
import EditIcon from '@material-ui/icons/Edit'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import EventIcon from '@material-ui/icons/Event'
import SettingsIcon from '@material-ui/icons/Settings'
import SmsIcon from '@material-ui/icons/Sms'

import classnames from 'classnames'
import type { AccountType } from 'src/utils/types/users'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
// $FlowFixMe
import { ReactComponent as MemberIcon } from 'src/images//members.svg'
// $FlowFixMe
import { ReactComponent as PitchIcon } from 'src/images/megaphone.svg'
// $FlowFixMe
import { ReactComponent as Logo } from 'src/images/logos/Logo.svg'
import { useAuth } from 'src/hooks/useAuth'
import { useStyles } from './index.styles'

type SidebarProps = {
  currentPath: string,
  isOpen: boolean,
  accountType?: AccountType,
  setIsOpen: boolean => void,
  match?: {
    params: Object,
    isExact: boolean,
    path: string,
    url: string
  }
}

export default ({
  isOpen,
  setIsOpen,
  currentPath,
  accountType = 'Songwriter',
  ...props
}: SidebarProps) => {
  const styles = useStyles({ isOpen })
  const { user } = useAuth()

  return (
    <React.Fragment>
      <div
        role="navigation"
        aria-label="sidebar navigation"
        className={styles.container}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div className={styles.bodyContainer}>
          <div className={styles.logoContainer}>
            <IconButton
              ariaLabel="search"
              className={styles.logoButton}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Logo className={styles.logo} />
            </IconButton>
            <span className={styles.fadeText}> Sooner </span>
          </div>
          <div className={styles.flexLinks}>
            <div className={styles.navLinks}>
              <Link placement="menu" to="/dashboard">
                <HomeIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Dashboard </span>
              </Link>
              <Link placement="menu" to="/discover?open=true">
                <SearchIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Discover </span>
              </Link>
              <Link placement="menu" to="/network">
                <NetworkIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Network </span>
              </Link>
              <Link placement="menu" to="/inbox">
                <InboxIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Inbox </span>
              </Link>
              {user?.accountType === 'Songwriter' && (
                <>
                  {user?.accountLevel === 'Career' && !user?.isReferred && (
                    <Link placement="menu" to="/referral">
                      <ApplyIcon className={styles.buttonIcon} />
                      <span className={styles.fadeText}>Request Referral</span>
                    </Link>
                  )}
                </>
              )}
              <Link
                placement="menu"
                to={`/profile/edit/${user._id}?editMusic=true`}
              >
                <PlaylistAddIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Add Music </span>
              </Link>
              <Link placement="menu" to="/post" sameTab>
                <EditIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Create Splash! </span>
              </Link>
              {user?.accountType !== 'Business' && !shouldUserUpgrade(user) && (
                <Link
                  placement="menu"
                  sameTab
                  isExternal
                  to={
                    user?.accountLevel === 'Free'
                      ? 'https://www.discoversooner.com/pay-to-pitch'
                      : 'https://www.discoversooner.com/pitch-opportunities'
                  }
                >
                  <PitchIcon
                    className={styles.buttonIcon}
                    style={{ color: 'white' }}
                  />
                  <span className={styles.fadeText}> Pitch </span>
                </Link>
              )}

              {!shouldUserUpgrade(user) && (<Link
                placement="menu"
                to={
                  user?.accountLevel === 'Career'
                    ? 'https://www.discoversooner.com/event-center'
                    : 'https://www.discoversooner.com/events'
                }
                sameTab
                isExternal
              >
                <EventIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Events </span>
              </Link>)}
              {!shouldUserUpgrade(user) && (
              <Link isExternal sameTab placement="menu" to={'https://www.discoversooner.com/member-videos'}>
                <VideoLibraryRoundedIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Videos </span>
              </Link>
              )}
              {user?.accountType !== 'Business' && !shouldUserUpgrade(user) && (
                <Link
                  placement="menu"
                  to="https://www.discoversooner.com/meet-mentors"
                  sameTab
                  isExternal
                >
                  <AccountCircleIcon className={styles.buttonIcon} />
                  <span className={styles.fadeText}> Mentors </span>
                </Link>
              )}
              {!shouldUserUpgrade(user) && (<Link
                placement="menu"
                to="https://www.discoversooner.com/advertise"
                sameTab
                isExternal
              >
                <SmsIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Super Splash! </span>
              </Link>)}
              {!shouldUserUpgrade(user) && (<Link
                sameTab
                isExternal
                placement="menu"
                to={getMembersLink(user)}
              >
                <MemberIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Members </span>
              </Link>)}
            </div>
            <div className={styles.actionLinks}>
              {/* <Link
                isExternal
                placement="menu"
                to="https://www.discoversooner.com/how-to-videos"
              >
                <MentorIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Get a Mentor </span>
              </Link>
              <Link
                isExternal
                placement="menu"
                to="https://www.discoversooner.com/how-to-videos"
              >
                <MentorIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Video Library </span>
              </Link> */}
              {/* <Link
                sameTab
                isExternal
                placement="menu"
                to="https://www.discoversooner.com/help"
              >
                <HelpIcon className={styles.buttonIcon} />
                <span className={styles.fadeText}> Help </span>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const getMembersLink = user => {
  if (user?.accountType === 'Songwriter') {
    return user?.accountLevel === 'Free'
      ? 'https://www.discoversooner.com/members-free'
      : 'https://www.discoversooner.com/members-career'
  }
  return 'https://www.discoversooner.com/members-publishers'
}

const shouldUserUpgrade = user => {
  return user?.accountLevel === "Free" && user?.accountType === "Songwriter"
}