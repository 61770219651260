// @flow

import 'firebase/auth'
import * as Firebase from 'firebase/app'

const env = {
  //'https://stage-api.discoversooner.com',
  API_URL:
    process.env.REACT_APP_ENV === 'local'
      ? 'https://local-api.discoversooner.com:8010'
      : process.env.REACT_APP_ENV === 'production'
      ? 'https://api.discoversooner.com'
      : 'https://stage-api.discoversooner.com',
  // 'https://stage-api.discoversooner.com', //'https://local-api.discoversooner.com:8010'
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyC2d7bdNPj571BXDjvWPfOsNKtIH3yxvpE',
    authDomain: 'sooner.firebaseapp.com',
    databaseURL: 'https://sooner.firebaseio.com',
    projectId: 'sooner',
    storageBucket: 'sooner.appspot.com',
    messagingSenderId: '883324765604',
    appId: '1:883324765604:web:5b57a27d00708710fead31',
    measurementId: 'G-4WL7TW00JT'
  },
  STRIPE_API_KEY:
    process.env.REACT_APP_ENV === 'production'
      ? 'pk_live_RyHZI2I55nd5tIBVmvB0H82c00YI5BewyF'
      : 'pk_test_Ni8deV4SDWN6USmZQ0BrL8iJ002vgZY4Ca',
  //'https://stage-app.discoversooner.com/referral/success',
  STRIPE_SUCCESS_URL:
    process.env.NODE_ENV === 'development'
      ? 'https://local-app.discoversooner.com/referral/success'
      : process.env.REACT_APP_ENV === 'production'
      ? 'https://app.discoversooner.com/referral/success'
      : 'https://stage-app.discoversooner.com/referral/success',

  // 'https://stage-app.discoversooner.com/referral',
  STRIPE_CANCEL_URL:
    process.env.NODE_ENV === 'development'
      ? 'https://local-app.discoversooner.com/referral/success'
      : process.env.REACT_APP_ENV === 'production'
      ? 'https://app.discoversooner.com/referral'
      : 'https://stage-app.discoversooner.com/referral',
  REFERRAL_PRODUCT_PRICE_ID:
    process.env.REACT_APP_ENV === 'production'
      ? 'price_1HUJ8SLQVGIKrLhWgQHKTWDb'
      : 'price_1HUIHRLQVGIKrLhWS5bnaGgt'
}

export const firebaseApp = Firebase.initializeApp(env.FIREBASE_CONFIG)

export default env
