// @flow
import axios from 'src/settings/axios'
import type { Content } from 'src/utils/types/content'

type GetVideosByUserResponse = {
  data: Content[]
}

export type GetVideosByUserService = ({
  id: string
}) => Promise<GetVideosByUserResponse>

export const getVideosByUser: GetVideosByUserService = body =>
  axios.get('/content/videos', {
    params: body
  })
