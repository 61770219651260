// @flow
import axios from 'src/settings/axios'
import type {
  Splash,
  SplashPostType,
  SplashContentType,
  Comment
} from 'src/utils/types/splashes'
import formatFormData from 'src/utils/api/formatFormData'

type GetSplashRequest = {
  id: string
}

type GetSplashResponse = {
  data: Splash
}

export type GetSplashService = GetSplashRequest => Promise<GetSplashResponse>

export const getSplash: GetSplashService = data =>
  axios.get('/splashes/edit', {
    params: { ...data }
  })

type GetSplashFeedResponse = {
  data: Splash[]
}

export type GetSplashFeedService = ({
  feedIndex: number
}) => Promise<GetSplashFeedResponse>

export const getSplashFeed: GetSplashFeedService = data =>
  axios.get('/splashes/all', { params: data })

type GetSplashesByUserResponse = {
  data: Splash[]
}

export type GetSplashesByUserService = ({
  id: string
}) => Promise<GetSplashesByUserResponse>

export const getSplashesByUser: GetSplashesByUserService = body =>
  axios.get('/splashes', {
    params: body
  })

type CreateSplashRequest = {
  id?: string,
  title: string,
  body: string,
  postType: SplashPostType,
  contentType: SplashContentType,
  coverImage: {
    name: string,
    type: string,
    size: number,
    lastMod: number,
    lastModDate: string,
    img: string
  },
  contentUrl: string
}

type CreateSplashResponse = {
  data: Splash
}

export type CreateSplashService = CreateSplashRequest => Promise<CreateSplashResponse>

export const createSplash: CreateSplashService = body =>
  axios.post('/splashes', formatFormData(body), {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

type DeleteSplashRequest = {
  id: string
}

type DeleteSplashResponse = {}

export type DeleteSplashService = DeleteSplashRequest => Promise<DeleteSplashResponse>

export const deleteSplash: DeleteSplashService = data =>
  axios.delete('/splashes', {
    params: { ...data }
  })

type ClapRequest = {
  id: string
}

export type ClapService = ClapRequest => Promise<{ data: Splash }>

export const clap: ClapService = data => axios.post('/splashes/clap', data)

type GetCommentsRequest = {
  splashId?: string,
  commentId?: string
}

export type GetCommentsService = GetCommentsRequest => Promise<{
  data: Comment[]
}>

export const getComments: GetCommentsService = data =>
  axios.get('/splashes/comments', { params: { ...data } })

type PostCommentRequest = {
  splashId: string,
  comment: string,
  parentCommentId?: string
}

export type PostCommentService = PostCommentRequest => Promise<{
  data: Comment
}>

export const postComment: PostCommentService = data =>
  axios.post('/splashes/comments', data)

type DeleteCommentRequest = {
  commentId: string
}

export type DeleteCommentService = DeleteCommentRequest => Promise<{
  data: string
}>

export const deleteComment: DeleteCommentService = data =>
  axios.delete('/splashes/comments', { params: { ...data } })

type GetSplashImageRequest = {
  url: string,
  name: string
}

export type GetSplashImageService = GetSplashImageRequest => Promise<*>

export const getSplashImage: GetSplashImageService = ({ url, name }) =>
  axios.get(url, { withCredentials: false, responseType: 'blob' }).then(res => {
    return new Promise((resolve, reject) => {
      const blob = res.data
      blob.lastModifiedDate = new Date()
      blob.name = name
      const reader = new FileReader()
      reader.onload = () => {
        blob.img = reader.result
        resolve(blob)
      }
      reader.readAsDataURL(blob)
    })
  })
