// @flow
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  /***************************** NEW VIEW STYLES ********************* */
  addMusicButton: {
    color: '#FFF',
    height: 45,
    width: 45,
    marginLeft: theme.spacing(2)
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    fontWeight: 500
  },
  musicViewContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    marginTop: theme.spacing(4)
  },
  songLinksContainer: {
    width: '65%',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(2),
      order: 2
    }
  },
  songLinksBackground: {
    width: '100%',
    backgroundColor: '#FFF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  hearMeOnContainer: {
    // maxWidth: 320,
    width: '35%',
    minWidth: 300,
    minHeight: 50,
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(2)
    }
  }
}))
