// @flow
import React from 'react'
import type { FieldValidator } from 'src/utils/fieldValidators'
import { makeStyles } from '@material-ui/styles'
import CardMedia from '@material-ui/core/CardMedia'
import CardActionArea from '@material-ui/core/CardActionArea'
import classnames from 'classnames'
import { Field } from 'redux-form'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  uploadImageButton: {
    width: '100%',
    height: '100%'
  },
  upload: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer'
  },
  uploadImageHeader: {
    position: 'absolute',
    top: '30%',
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
    color: theme.palette.text.main,
    pointerEvents: 'none',
    opacity: 0.7
  },
  thumbnail: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: '100%',
    height: '100%'
  },
  errorMessage: {
    width: '100%',
    marginTop: '2px',
    paddingTop: '0px',
    textAlign: 'center',
    pointerEvents: 'none',
    color: theme.palette.error.main
  }
}))

type File = {
  name: string,
  type: string,
  size: number,
  lastMod: string,
  lastModDate: string,
  img: string
}

type StyleOverrides = {
  container: string,
  thumbnail: string
}

type ImageUploadProps = {
  classes?: StyleOverrides,
  onChange: () => void,
  value?: File,
  styles: any,
  label?: string,
  meta: any
}

export const ImageUpload = ({
  classes = {},
  onChange,
  value,
  styles,
  label,
  meta,
  ...props
}: ImageUploadProps) => {
  return (
    <div className={classnames(styles.imageUploadContainer, classes.container)}>
      <CardActionArea className={styles.uploadImageButton} tabIndex="-1">
        <input
          accept="image/*"
          className={styles.upload}
          id="cover-image-upload"
          type="file"
          onChange={onChange}
          {...props}
        />
        <CardMedia
          className={classnames(styles.thumbnail, classes.thumbnail)}
          image={value && !meta.error ? value.img : ''}
          title={value ? value.name : 'upload cover image'}
        />
        <Typography
          className={ styles.uploadImageHeader }
        >
          {value ? '' : `${label || 'UPLOAD IMAGE'}`}
        </Typography>
      </CardActionArea>
      {meta.touched && meta.error && (
        <div className={styles.errorMessage}>
          <span>
            <strong>{meta.error}</strong>
          </span>
        </div>
      )}
    </div>
  )
}

const renderImageUpload = ({ input, classes, styles, ...props }) => {
  const { onChange, value } = input

  const onFileUpload = e => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        onChange &&
          onChange({
            name: file.name,
            type: file.type,
            size: file.size,
            lastMod: file.lastModified,
            lastModDate: file.lastModifiedDate,
            img: reader.result
          })
      },
      false
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  return (
    <ImageUpload
      classes={classes}
      onChange={onFileUpload}
      value={value}
      styles={styles}
      {...props}
    />
  )
}

type ImageUploadFieldProps = {
  name: string,
  validators?: FieldValidator[],
  classes?: {
    container: string
  }
}

export default ({
  name,
  classes = {},
  validators = [],
  ...props
}: ImageUploadFieldProps) => {
  const styles = useStyles()
  return (
    <Field
      name={name}
      styles={styles}
      classes={classes}
      component={renderImageUpload}
      validate={[...validators]} // not ideal since validators is already an array.. but flow complains otherwise
      {...props}
    />
  )
}
