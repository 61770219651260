// @flow
import axios from 'src/settings/axios'
import type { Message } from 'src/utils/types/inbox'

export type GetMessagesRequest = {
  threadId: string,
  resultIndex: number
}

export type GetMessagesResponse = {
  data: Message[]
}

export type GetMessagesService = GetMessagesRequest => Promise<GetMessagesResponse>

export const getMessages: GetMessagesService = data =>
  axios.get('/messages', {
    params: data
  })

export type SendMessageRequest = {
  threadId: string,
  author: string,
  authorImage: string,
  message: string,
  recipients: string[]
}

export type SendMessageResponse = {
  data: Message
}

export type SendMessageService = SendMessageRequest => Promise<SendMessageResponse>

export const sendMessage: SendMessageService = data =>
  axios.post('/messages', data)

// export const getMessages: GetMessagesService = data =>
//   Promise.resolve({ data: messages })
