// @flow

import React, { useState } from 'react'
import type {
  Notification,
  NotificationType
} from 'src/utils/types/notifications'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Flex } from 'src/components/molecules/Flex'
import { Typography } from '@material-ui/core'
import Button from 'src/components/atoms/buttons/Button'
import moment from 'moment'
import { markNotificationReadAction } from 'src/state/actions/notifications'
import {
  confirmConnectionAction,
  denyConnectionAction
} from 'src/state/actions/network'
import { push } from 'react-router-redux'

const useStyles = makeStyles(theme => ({
  notificationWrapper: {
    width: '100%',
    padding: `${theme.spacing(2)}px 0`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  image: {
    display: 'block',
    backgroundImage: props => `url(${props.imageUrl})`,
    backgroundSize: 'cover',
    width: 55,
    height: 55,
    border: '1px solid #fff',
    borderRadius: 55
  },
  timestamp: {
    color: theme.palette.text.label,
    fontWeight: 'normal'
  },
  message: {
    fontWeight: 'normal',
    marginTop: theme.spacing(0.5)
  },
  secondaryButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.label
  }
}))

type NotificationProps = {
  notification: Notification
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ notification }: NotificationProps) => {
  const {
    _id,
    notifType,
    header,
    message,
    actionUrl,
    imageUrl,
    requesterId,
    timestamp
  } = notification

  const styles = useStyles({ notifType, imageUrl })
  const [isMarkingRead, setIsMarkingRead] = useState(false)
  const [isConfirmingRequest, setIsConfirmingRequest] = useState(false)
  const dispatch = useDispatch()

  const secondaryAction = () => {
    setIsMarkingRead(true)
    if (notifType === 'CONNECTION_REQUEST') {
      dispatch(denyConnectionAction({ requesterId }))
    }
    dispatch(markNotificationReadAction({ notificationId: _id }))
  }

  const onPrimaryClick = () => {
    if (notifType === 'CONNECTION_REQUEST') {
      setIsConfirmingRequest(true)
      dispatch(confirmConnectionAction({ sender: requesterId })).then(() => {
        dispatch(markNotificationReadAction({ notificationId: _id }))
      })
    }
    if (notifType === 'NEW_COMMENT') {
      goToProfile()
    }
    if (
      notifType === 'CONNECTION_CONFIRMED' ||
      notifType === 'SIGNUP' ||
      notifType === 'NEW_MESSAGE'
    ) {
      actionUrl && dispatch(push(actionUrl))
    }
    if (notifType === 'BROADCAST') {
      actionUrl && window.open(actionUrl)
    }
  }

  const goToProfile = () => {
    if (requesterId) {
      dispatch(push(`/profile/${requesterId}?tab=Splashes`))
    }
    if (notifType === 'CONNECTION_CONFIRMED' || notifType === 'SIGNUP') {
      //actionUrl && dispatch(push(actionUrl))
      dispatch(push(actionUrl))
    }
  }

  return (
    <div className={styles.notificationWrapper}>
      <Flex container alignItems="center">
        <Flex width="65px" margin="0 8px 0 0">
          <div className={styles.image} alt="Profile" />
        </Flex>
        <Flex flex="1" flexDirection="column">
          <Flex container alignItems="center" justifyContent="space-between">
            <Typography
              color="primary"
              variant="h6"
            >
              {header}
            </Typography>
            <Typography variant="subtitle2" className={styles.timestamp}>
              {moment(timestamp).fromNow()}
            </Typography>
          </Flex>
          <Typography variant="subtitle2" className={styles.message}>
            {message}
          </Typography>
        </Flex>
      </Flex>

      <Flex container justifyContent="flex-end" margin="8px 0 0 0">
        <Button
          loading={isMarkingRead}
          disabled={isMarkingRead}
          className={styles.secondaryButton}
          onClick={secondaryAction}
        >
          {getSecondaryActionText(notifType)}
        </Button>
        {'GENERAL' !== notifType && 'BROADCAST' !== notifType && (
          <Button
            disabled={isConfirmingRequest}
            loading={isConfirmingRequest}
            // isLink={notifType !== 'CONNECTION_REQUEST'}
            // to={actionUrl}
            color="primary"
            variant="contained"
            onClick={onPrimaryClick}
          >
            {getPrimaryActionText(notifType)}
          </Button>
        )}
        {actionUrl && '' !== actionUrl && 'BROADCAST' === notifType && (
          <Button
            disabled={isConfirmingRequest}
            loading={isConfirmingRequest}
            color="primary"
            variant="contained"
            onClick={onPrimaryClick}
          >
            {getPrimaryActionText(notifType)}
          </Button>
        )}
      </Flex>
    </div>
  )
}

const getSecondaryActionText = (type: NotificationType) => {
  switch (type) {
    case 'CONNECTION_REQUEST':
      return 'DENY'
    case 'GENERAL':
      return 'MARK READ'
    default:
      return 'DISMISS'
  }
}

const getPrimaryActionText = (type: NotificationType) => {
  switch (type) {
    case 'CONNECTION_REQUEST':
      return 'ACCEPT'
    case 'REFERRAL_REMINDER':
      return 'APPLY'
    case 'NEW_MESSAGE':
      return 'REPLY'
    case 'FEEDBACK':
      return 'GIVE FEEDBACK'
    case 'SIGNUP':
      return 'PROFILE'
    case 'CONNECTION_CONFIRMED':
      return 'PROFILE'
    case 'NEW_COMMENT':
      return 'REPLY'
    case 'BROADCAST':
      return 'GO TO...'
    default:
      return 'IGNORE'
  }
}
