// @flow

import type { SetSongsService, GetSongsService } from 'src/services/music'
import type { ThunkAction } from 'src/utils/types'
import { setSongs, getSongs } from 'src/services/music'
import type { Song } from 'src/utils/types/music'
import type { Action } from 'src/utils/types'

/******************************* STATE *******************************/

export type MusicState = {
  songs: Song[]
}

const musicState = {
  songs: []
}

/******************************* ACTIONS  *******************************/

const SET = 'songs/set'
const GET = 'songs/get'
const DELETE = 'songs/delete'

/******************************* REDUCERS  *******************************/

export default function reducer(
  state: MusicState = musicState,
  action: Action<any>
) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        songs: action.payload,
        set: true
      }

    case GET:
      return {
        ...state,
        songs: action.payload
      }
    case DELETE:
      return {
        ...state,
        songs: action.payload && deleteSong(action.payload, state.songs)
      }
    default:
      return state
  }
}

/******************************* TRANSFORMERS  *******************************/

const deleteSong = (song: Song, songs: Song[]) => {
  const copy = [...songs]
  const existing = copy.findIndex(_song => _song._id === song._id)
  if (existing) copy.splice(existing, 1)
  return copy
}

const getDeletedSongIds = (newSet: Song[], oldSet: Song[]) => {
  return oldSet
    .filter(
      oldSong => newSet.findIndex(newSong => newSong._id === oldSong._id) === -1
    )
    .map(s => s._id)
}

/******************************* ACTION CREATORS *******************************/

export type SetSongsParams = {
  songs: Song[],
  originalSongs: Song[],
  service?: SetSongsService
}

export type SetSongsAction = SetSongsParams => ThunkAction

export const setSongsAction: SetSongsAction = ({
  service = setSongs,
  originalSongs,
  songs
}) => async dispatch => {
  try {
    const deletedSongIds = getDeletedSongIds(songs, originalSongs)
    const { data } = await service({ songs, deletedSongIds })
    if (data) {
      dispatch({ type: SET, payload: data })
    }
  } catch (err) {
    throw new Error(err)
  }
}

export type GetSongsParams = {
  authorId: string,
  service?: GetSongsService
}

export type GetSongsAction = GetSongsParams => ThunkAction

export const getSongsAction: GetSongsAction = ({
  service = getSongs,
  authorId
}) => async dispatch => {
  const { data } = await service({ authorId })
  dispatch({ type: GET, payload: data })
}
