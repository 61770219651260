// @flow
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import moment from 'moment'

import Modal from '@material-ui/core/Modal'
import MusicPlayer from 'src/components/molecules/MusicPlayer'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import ExternalLinkDialog from 'src/components/molecules/dialogs/ExternalLinkDialog'
import IconButton from 'src/components/atoms/buttons/IconButton'
import PlayIcon from 'src/components/atoms/icons/Play'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import type { Splash } from 'src/utils/types/splashes'
//$FlowFixMe
import { ReactComponent as ClapIcon } from 'src/images/clap-hands.svg'
import CommentIcon from '@material-ui/icons/Comment'
// $FlowFixMe
import { ReactComponent as OptionsIcon } from 'src/images/optionsIcon.svg'
import Link from 'src/components/atoms/Link'
import Comments from 'src/components/organisms/CommentsDialog'
import { useAuth } from 'src/hooks/useAuth'
import { useStyles } from './index.styles'

type SplashCardProps = {
  splash: Splash,
  onClap: (claps: number, id: string) => void,
  onDelete?: (id: string) => void,
  lastInList?: boolean,
  isProfile?: boolean,
  noPadding?: boolean,
  size?: 'sm' | 'md' | 'lg'
}

export default ({
  splash,
  lastInList,
  onClap,
  onDelete,
  isProfile,
  noPadding,
  size = 'lg'
}: SplashCardProps) => {
  const {
    _id,
    author,
    authorName,
    coverImage,
    content,
    title,
    clapCount,
    commentCount,
    body,
    creationDate,
    clappers
  } = splash

  const [areCommentsOpen, setAreCommentsOpen] = useState(false)
  const { user: currentUser } = useAuth()
  const userDidClap = currentUser && alreadyClapped(currentUser._id, clappers)
  const userIsAuthor = currentUser && currentUser._id === author

  const [isContentModalOpen, setIsContentModalOpen] = useState(false)
  const [optionsAnchor, setOptionsAnchor] = useState(null)
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [currentClapCount, setCurrentClapCount] = useState(clapCount)
  const [clapped, setClapped] = useState(userDidClap)
  const dispatch = useDispatch()
  const styles = useStyles({
    lastInList,
    size,
    isProfile,
    clapped: clapped || userDidClap
  })

  const _onClap = () => {
    if (!userIsAuthor && !clapped) {
      onClap(1, splash._id)
      setCurrentClapCount(currentClapCount + 1)
      setClapped(true)
    }
  }
  return (
    <div className={styles.container} style={noPadding && { padding: 0 }}>
      <Card className={styles.card} elevation={3}>
        <CardMedia
          className={styles.thumbnail}
          image={coverImage}
          title={title}
        />
        <CardContent className={styles.content}>
          <div className={styles.header}>
            <Typography className={styles.title} variant="h6">
              {title}
            </Typography>
            <div className={styles.nameAndDate}>
              <Link to={`/profile/${author}`}>
                <Typography className={styles.authorName} variant="caption">
                  {authorName}
                </Typography>
                <Typography className={styles.creationDate} variant="caption">
                  {' - '}
                  {moment(creationDate).format('MMM Do YYYY')}
                </Typography>
              </Link>
            </div>
          </div>
          <Typography className={styles.body} variant="body2" component="p">
            {body}
          </Typography>
        </CardContent>
        <CardActions className={styles.cardActions}>
          <div className={styles.mediaType}>
            <Link isExternal to={content.url}>
              <IconButton ariaLabel="link to content">
                <PlayIcon />
              </IconButton>
            </Link>
          </div>
          <div className={styles.actionIcons}>
            {currentUser && !userIsAuthor && (
              <CardActionArea
                className={`${styles.actionLabel} ${styles.clapButton}`}
                onClick={_onClap}
                disabled={clapped}
              >
                <Typography className={styles.iconText}>
                  {currentClapCount}
                </Typography>
                <ClapIcon />
              </CardActionArea>
            )}
            <CardActionArea
              className={`${styles.actionLabel}`}
              onClick={() => setAreCommentsOpen(true)}
            >
              <Typography className={styles.iconText}>
                {commentCount}
              </Typography>
              <CommentIcon style={{ width: 30, height: 30 }} />
            </CardActionArea>

            {userIsAuthor && isProfile && (
              <>
                <IconButton
                  className={styles.actionLabel}
                  ariaLabel="Splash Settings"
                  onClick={(e: Event) => {
                    setOptionsAnchor(e.currentTarget)
                    setIsOptionsOpen(true)
                  }}
                >
                  <OptionsIcon style={{ width: 30, height: 30 }} />
                </IconButton>
                <Menu
                  keepMounted
                  open={isOptionsOpen}
                  anchorEl={optionsAnchor}
                  onClose={() => {
                    setIsOptionsOpen(false)
                  }}
                >
                  <MenuItem onClick={() => dispatch(push(`/post/${_id}`))}>
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      onDelete && onDelete(_id)
                    }}
                  >
                    <Typography color="secondary">Delete</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </CardActions>
      </Card>
      <Comments
        splashId={_id}
        isOpen={areCommentsOpen}
        onClose={() => setAreCommentsOpen(false)}
      />
      {renderContentModal({
        styles,
        url: splash.content.url,
        type: splash.content.contentType,
        isOpen: isContentModalOpen,
        onClose: () => setIsContentModalOpen(false)
      })}
    </div>
  )
}

const renderContentModal = ({ styles, url, type, isOpen, onClose }) => {
  if (type === 'YOUTUBE') {
    return (
      <Modal
        aria-labelledby="splash-youtube-video"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={onClose}
        className={styles.modal}
      >
        <iframe
          className={styles.video}
          title="Youtube Video"
          src={url}
        ></iframe>
      </Modal>
    )
  }
  if (type === 'MUSIC') {
    return <MusicPlayer url={url} isOpen={isOpen} onClose={onClose} />
  }
  if (type === 'EXTERNAL') {
    return <ExternalLinkDialog url={url} isOpen={isOpen} onClose={onClose} />
  }
}

const alreadyClapped = (userId: string, clappers: string[]) =>
  !!clappers.find(clapperId => clapperId === userId)
