// @flow
import { change } from 'redux-form'
import {
  createSplash,
  clap,
  deleteSplash,
  getSplash,
  getSplashImage,
  deleteComment
} from 'src/services/splashes'
import type {
  CreateSplashService,
  ClapService,
  DeleteSplashService,
  GetSplashService,
  GetSplashImageService,
  GetCommentsService,
  PostCommentService,
  DeleteCommentService
} from 'src/services/splashes'
import { push } from 'react-router-redux'
import { getServiceErrors } from 'src/utils/api/apiErrors'
import {
  GET_SPLASH_REQUEST,
  GET_SPLASH_SUCCESS,
  DELETE_SPLASH_REQUEST,
  DELETE_SPLASH_SUCCESS,
  CREATE_SPLASH_REQUEST,
  CREATE_SPLASH_SUCCESS,
  CLAP_REQUEST,
  CLAP_SUCCESS
} from 'src/state/reducers/splashes'
import type {
  SplashPostType,
  SplashContentType
} from 'src/utils/types/splashes'
import type { ThunkAction } from 'src/utils/types'
import { resizeImage } from '../../../utils/api/formatFormData'
import {
  GET_COMMENT_REQUEST,
  GET_COMMENT_SUCCESS,
  GET_COMMENT_ERROR,
  POST_COMMENT_REQUEST,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_ERROR,
  GET_REPLIES_REQUEST,
  GET_REPLIES_SUCCESS,
  GET_REPLIES_ERROR,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR
} from '../../reducers/splashes'
import { getComments, postComment } from '../../../services/splashes'

export type CreateSplashParams = {
  id?: string,
  title: string,
  body: string,
  postType: SplashPostType,
  contentType: SplashContentType,
  contentUrl: string,
  coverImage: {
    name: string,
    type: string,
    size: number,
    lastMod: number,
    lastModDate: string,
    img: string
  },
  service?: CreateSplashService
}

export type CreateSplashAction = CreateSplashParams => ThunkAction

export const createSplashAction: CreateSplashAction = ({
  id,
  title,
  body,
  postType,
  contentType,
  contentUrl,
  coverImage,
  service = createSplash
}) => async dispatch => {
  try {
    dispatch({ type: CREATE_SPLASH_REQUEST })
    const resizedFile = await resizeImage(coverImage)
    await service({
      id,
      title,
      body,
      postType,
      contentType,
      contentUrl,
      coverImage: resizedFile
    })
    dispatch({ type: CREATE_SPLASH_SUCCESS })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      Promise.reject(errors)
    }
  }
}

export type DeleteSplashParams = {
  splashId: string,
  service?: DeleteSplashService
}

export type DeleteSplashAction = DeleteSplashParams => ThunkAction

export const deleteSplashAction: DeleteSplashAction = ({
  splashId,
  service = deleteSplash
}) => async dispatch => {
  try {
    dispatch({ type: DELETE_SPLASH_REQUEST })
    await service({
      id: splashId
    })
    dispatch({ type: DELETE_SPLASH_SUCCESS, payload: splashId })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      Promise.reject(errors)
    }
  }
}

export type GetSplashParams = {
  splashId: string,
  service?: GetSplashService
}

export type GetSplashAction = GetSplashParams => ThunkAction

export const getSplashAction: GetSplashAction = ({
  splashId,
  service = getSplash
}) => async dispatch => {
  try {
    dispatch({ type: GET_SPLASH_REQUEST })
    const { data } = await service({
      id: splashId
    })
    dispatch({ type: GET_SPLASH_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      Promise.reject(errors)
    }
  }
}

export type ClapParams = {
  count: number,
  splashId: string,
  service?: ClapService
}

export type ClapAction = ClapParams => ThunkAction

export const clapAction: ClapAction = ({
  splashId,
  service = clap
}) => async dispatch => {
  try {
    dispatch({ type: CLAP_REQUEST })
    const { data } = await service({
      id: splashId
    })
    dispatch({ type: CLAP_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      Promise.reject(errors)
    }
  }
}

export type GetCommentsParams = {
  splashId: string,
  service?: GetCommentsService
}

export type GetCommentsAction = GetCommentsParams => ThunkAction

export const getCommentsAction: GetCommentsAction = ({
  splashId,
  service = getComments
}) => async dispatch => {
  try {
    dispatch({ type: GET_COMMENT_REQUEST })
    const { data } = await service({ splashId })
    dispatch({ type: GET_COMMENT_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({ type: GET_COMMENT_ERROR })
      Promise.reject(errors)
    }
  }
}

export type GetRepliesParams = {
  commentId: string,
  service?: GetCommentsService
}

export type GetRepliesAction = GetRepliesParams => ThunkAction

export const getRepliesAction: GetRepliesAction = ({
  commentId,
  service = getComments
}) => async dispatch => {
  try {
    dispatch({ type: GET_REPLIES_REQUEST })
    const { data } = await service({ commentId })
    dispatch({
      type: GET_REPLIES_SUCCESS,
      payload: {
        commentId,
        replies: data
      }
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({ type: GET_REPLIES_ERROR })
      Promise.reject(errors)
    }
  }
}

export type PostCommentsParams = {
  splashId: string,
  comment: string,
  commentParentId?: string,
  service?: PostCommentService
}

export type PostCommentsAction = PostCommentsParams => ThunkAction

export const postCommentsAction: PostCommentsAction = ({
  splashId,
  comment,
  commentParentId,
  service = postComment
}) => async dispatch => {
  try {
    dispatch({ type: POST_COMMENT_REQUEST })
    const { data } = await service({ splashId, commentParentId, comment })
    dispatch({ type: POST_COMMENT_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({ type: POST_COMMENT_ERROR })
      Promise.reject(errors)
    }
  }
}

export type DeleteCommentsParams = {
  commentId: string,
  service?: DeleteCommentService
}

export type DeleteCommentsAction = DeleteCommentsParams => ThunkAction

export const deleteCommentsAction: DeleteCommentsAction = ({
  commentId,
  service = deleteComment
}) => async dispatch => {
  try {
    dispatch({ type: DELETE_COMMENT_REQUEST })
    service({ commentId })
    dispatch({ type: DELETE_COMMENT_SUCCESS, payload: commentId })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({ type: DELETE_COMMENT_ERROR })
      Promise.reject(errors)
    }
  }
}

export type GetSplashImageParams = {
  url: string,
  formId: string,
  imageName: string,
  service?: GetSplashImageService
}

export type GetSplashImageAction = GetSplashImageParams => ThunkAction

export const getSplashImageAction: GetSplashImageAction = ({
  url,
  formId,
  imageName,
  service = getSplashImage
}) => async dispatch => {
  try {
    const file = await getSplashImage({ url, name: imageName })
    dispatch(change(formId, 'coverImage', file))
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      Promise.reject(errors)
    }
  }
}
