// @flow
import type { ThunkAction } from 'src/utils/types'
import { getSplashFeed } from 'src/services/splashes'
import type { GetSplashFeedService } from 'src/services/splashes'
import {
  GET_SPLASH_FEED_REQUEST,
  GET_SPLASH_FEED_SUCCESS,
  GET_SPLASH_FEED_ERROR
} from 'src/state/reducers/dashboard'
export type GetSplashFeedParams = {
  feedIndex: number,
  service?: GetSplashFeedService
}

export type GetSplashFeedAction = GetSplashFeedParams => ThunkAction

export const getSplashFeedAction: GetSplashFeedAction = ({
  feedIndex,
  service = getSplashFeed
}) => async dispatch => {
  try {
    dispatch({ type: GET_SPLASH_FEED_REQUEST })
    const { data } = await service({ feedIndex })

    dispatch({ type: GET_SPLASH_FEED_SUCCESS, payload: data })
    // dispatch(getUserAction({ id: user._id }))
  } catch (err) {
    console.error(err)
    dispatch({
      type: GET_SPLASH_FEED_ERROR,
      payload: 'There was an error updating your dashboard. Please try again.'
    })
  }
}
