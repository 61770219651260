// @flow

import React from 'react'
import { Flex } from 'src/components/molecules/Flex'
import Typography from '@material-ui/core/Typography'
import { Card } from 'src/components/molecules/Card'
import { CTA } from 'src/components/molecules/CTA'

type AccountSelectProps = {
  onSongwriterSelect: () => void,
  onPublisherSelect: () => void
}

export const AccountSelect = ({
  onSongwriterSelect,
  onPublisherSelect
}: AccountSelectProps) => {
  return (
    <Flex container flexDirection="column" alignItems="center" padding="0 16px">
      <CTA
        header="Songwriter or Publisher?"
        subHeader="If you are a songwriter and a publisher, choose songwriter."
      />

      <Flex
        container
        margin="32px 0 0 0"
        width="100%"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Card
          mode="light"
          actionLabel="Songwriter Profile"
          headerText="I'm a Songwriter"
          onActionClick={onSongwriterSelect}
        >
          <Typography variant="body1">
          You’re minutes away from meeting a publisher. Check your “Welcome email”
          for a link to schedule a meeting.
          </Typography>
        </Card>
        <Card
          mode="dark"
          actionLabel="Publisher Profile"
          headerText="I'm a Publisher"
          onActionClick={onPublisherSelect}
        >
          <Typography variant="body1" color="inherit">
            After a brief vetting process, you will have the best networking
            tools on the market for connecting with songwriters and publishers.
          </Typography>
        </Card>
      </Flex>
    </Flex>
  )
}
