// @flow
import { makeStyles } from '@material-ui/styles'

// client -  asidebar
// 569    -> 783 = 214
// 640    -> 870 = 230

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    left: 0,
    zIndex: 0,
    background: theme.palette.menu.main,
    width: 250,
    height: theme.FULL_HEIGHT,
    color: theme.palette.menu.text,
    overflow: 'hidden',
    '@media (max-height:714px)': {
      // eslint-disable-line no-useless-computed-key
      overflowY: 'scroll !important'
      // height: document.documentElement.clientHeight + (1000 - document.documentElement.clientHeight)
    },
    fontSize: 'large'
  },
  bodyContainer: {
    width: 250,
    height: '100%'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 65,
    color: theme.palette.menu.contrastText,
    fontSize: '1.5rem',
    fontWeight: 'lighter'
  },
  logoButton: {
    marginLeft: theme.spacing(1.1),
    marginRight: theme.spacing(1)
  },
  logo: {
    width: 40,
    height: 40
  },
  fadeText: {
    transition: 'opacity 0.2s',
    opacity: props => (props.isOpen ? 1 : 0)
  },
  flexLinks: {
    width: '100%',
    height: 'calc(100% - 65px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-height:714px)': {
      // eslint-disable-line no-useless-computed-key
      overflowY: 'scroll',
      height: '910px'
    }
  },
  navLinks: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
    fontWeight: '100'
  },
  createSplashIcon: {
    color: '#FFF !important'
  },
  buttonIcon: {
    color: theme.palette.menu.text,
    marginRight: theme.spacing(3),
    width: 30,
    marginLeft: 23,
    height: 30,
    fill: 'rgb(158, 158, 158)'
  }
}))
