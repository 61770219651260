// @flow
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import InputAdornment from '@material-ui/core/InputAdornment'
import { sendMessage } from 'src/services/messages'
import axios from 'src/settings/axios'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import { useParams } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

// export type SendMessageParams = {
//   thread: Thread,
//   currentUser: User,
//   message: string,
//   service?: SendMessageService
// }

// export type SendMessageAction = SendMessageParams => ThunkAction

const selectProfile: State => ProfileState = state => state.profile

export default () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { id } = useParams()
  const [value, setValue] = useState('')
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)
  const [broadcasts, setBroadcasts] = useState([])
  const [title, setTitle] = useState('')
  const [link, setLink] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [plansSelected, setPlansSelected] = useState([])
  const handleChange = event => {
    setValue(event.target.value)
  }

  useEffect(() => {
    // broadcast
    setLoading(true)
    try {
      axios.get('/notifications/broadcast').then(({ data }) => {
        setBroadcasts(data)
        setLoading(false)
      })
    } catch (e) {
      console.log('e=>', e)
      setLoading(false)
    }
  }, [])

  const profile: ProfileState = useSelector(selectProfile, shallowEqual)
  const { user } = profile

  const deleteBroadcastMessage = async id => {
    await axios
      .delete(`/notifications/${id}`)
      .then(res => {
        const newList = broadcasts.filter(value => value !== id)
        setBroadcasts(newList)
      })
      .catch(e =>
        alert('there was a problem deleting this message. try again later')
      )
  }

  const handleChangeMultiple = event => {
    const { value: options } = event.target
    const value = []
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i]) {
        value.push(options[i])
      }
    }
    setPlansSelected(value)
  }

  const handleDeleteAll = async () => {
    await axios.delete('/notifications')
  }

  const handleSubmitAction = async () => {
    if (content !== '' && title !== '') {
      setDisabled(true)
      const finalLink = link ? (link.startsWith('http') ? link : 'https://' + link ) : link
      await axios
        .post('/notifications', {
          recipients: plansSelected.join('-'),
          email: user.email,
          content,
          title,
          id: user._id,
          link: finalLink,
        })
        .then(res => alert('broadcast sent correctly!'))
        .catch(err =>
          alert('There was a problem. Please, try again in few minutes.')
        )
        .finally(setDisabled(false))
      setContent('')
      setTitle('')
      setLink('')
      setPlansSelected([])
    } else {
      alert('Please fill out the required fields')
    }
  }

  const handleChangeTitle = event => {
    setTitle(event.target.value)
  }

  const handleChangeContent = event => {
    setContent(event.target.value)
  }

  const handleChangeLink = event => {
    setLink(event.target.value)
  }

  return (
    <React.Fragment>
      <h4
        style={{
          marginLeft: 30,
          display: 'flex',
          alignItems: 'center',
          fontWeight: 500,
          marginBottom: '32px',
          fontSize: '2.0243rem'
        }}
      >
        Broadcast Message
      </h4>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <h5
            style={{
              marginLeft: 30,
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
              marginBottom: '32px',
              fontSize: '1.5243rem'
            }}
          >
            Create Broadcast
          </h5>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '80%',
              marginLeft: 30
            }}
          >
            <TextField
              required
              id="standard-required"
              onChange={handleChangeTitle}
              value={title}
              label="Title"
            />
            <br />
            <TextField
              required
              id="outlined-multiline-flexible"
              label="Broadcast Message"
              multiline
              rowsMax={6}
              onChange={handleChangeContent}
              value={content}
            />
            <br />
            <TextField
              id="standard-required-2"
              label="Link"
              onChange={handleChangeLink}
              value={link}
              placeholder="https://"
            />
            <br />
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Choose Plan</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                value={plansSelected}
                onChange={handleChangeMultiple}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => {
                  return (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value={'Business-Career'}>All</MenuItem>
                <MenuItem value={'Career'}>Career Plan</MenuItem>
                <MenuItem value={'Business'}>Publishers</MenuItem>
              </Select>
            </FormControl>
            <br />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitAction}
                disabled={disabled}
              >
                Submit
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <h5
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
              marginBottom: '32px',
              fontSize: '1.5243rem'
            }}
          >
            Delete Broadcasts
          </h5>
          {loading && <p>loading broadcast messages</p>}
          {broadcasts.length === 0 && !loading && (
            <p>There are no Broadcast messages</p>
          )}
          {broadcasts.map(broad => (
            <div
              role="button"
              key={broad}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <p
                style={{
                  margin: 0,
                  marginRight: '10px',
                  fontSize: '25px'
                }}
              >
                {broad}
              </p>
              <DeleteIcon
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => deleteBroadcastMessage(broad)}
              />
            </div>
          ))}
          <br />
          <Button variant="contained" color="primary" onClick={handleDeleteAll}>
            Delete All Broadcast
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
