// @flow

import type { Action } from 'src/utils/types'

export const VERIFY_ACCOUNT_REQUEST: 'VERIFY_ACCOUNT_REQUEST' =
  'VERIFY_ACCOUNT_REQUEST'
export const VERIFY_ACCOUNT_SUCCESS: 'VERIFY_ACCOUNT_SUCCESS' =
  'VERIFY_ACCOUNT_SUCCESS'
export const VERIFY_ACCOUNT_ERROR: 'VERIFY_ACCOUNT_ERROR' =
  'VERIFY_ACCOUNT_ERROR'

export const PROCESS_USER_REFERRAL_REQUEST: 'PROCESS_USER_REFERRAL_REQUEST' =
  'PROCESS_USER_REFERRAL_REQUEST'
export const PROCESS_USER_REFERRAL_SUCCESS: 'PROCESS_USER_REFERRAL_SUCCESS' =
  'PROCESS_USER_REFERRAL_SUCCESS'
export const PROCESS_USER_REFERRAL_ERROR: 'PROCESS_USER_REFERRAL_ERROR' =
  'PROCESS_USER_REFERRAL_ERROR'

export const CREATE_CONNECTION_REQUEST: 'CREATE_CONNECTION_REQUEST' =
  'CREATE_CONNECTION_REQUEST'
export const CREATE_CONNECTION_SUCCESS: 'CREATE_CONNECTION_SUCCESS' =
  'CREATE_CONNECTION_SUCCESS'
export const CREATE_CONNECTION_ERROR: 'CREATE_CONNECTION_ERROR' =
  'CREATE_CONNECTION_ERROR'

export const DELETE_USER_REQUEST: 'DELETE_USER_REQUEST' = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS' = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR: 'DELETE_USER_ERROR' = 'DELETE_USER_ERROR'

export const UPGRADE_USER_REQUEST: 'UPGRADE_USER_REQUEST' = 'UPGRADE_USER_REQUEST'
export const UPGRADE_USER_SUCCESS: 'UPGRADE_USER_SUCCESS' = 'UPGRADE_USER_SUCCESS'
export const UPGRADE_USER_ERROR: 'UPGRADE_USER_ERROR' = 'UPGRADE_USER_ERROR'

export const DOWNGRADE_USER_REQUEST: 'DOWNGRADE_USER_REQUEST' = 'DOWNGRADE_USER_REQUEST'
export const DOWNGRADE_USER_SUCCESS: 'DOWNGRADE_USER_SUCCESS' = 'DOWNGRADE_USER_SUCCESS'
export const DOWNGRADE_USER_ERROR: 'DOWNGRADE_USER_ERROR' = 'DOWNGRADE_USER_ERROR'

export type AdminState = {
  isConnecting: boolean,
  connectionError: string,
  connectionCreated: boolean,
  isVerifyingAccount: boolean,
  isProcessingReferral: boolean,
  accountVerificationError: string,
  referralProcessingError: string,
  verificationSuccess: boolean,
  referralProcessed: boolean,
  isDeletingUser: boolean,
  userDeleted: boolean,
  deleteUserError: boolean,
  userUpgraded: boolean,
  isUpgradingUser: boolean,
  upgradeUserError: boolean,
  userDowngraded: boolean,
  isDowngradingUser: boolean,
  downgradeUserError: boolean
}

const adminState = {
  isConnecting: false,
  connectionCreated: false,
  isVerifyingAccount: false,
  isProcessingReferral: false,
  verificationSuccess: false,
  referralProcessed: false,
  isDeletingUser: false,
  deleteUserError: false,
  userDeleted: false,
  isUpgradingUser: false,
  upgradeUserError: false,
  userUpgraded: false,
  isDowngradingUser: false,
  downgradeUserError: false,
  userDowngraded: false,
  connectionError: '',
  accountVerificationError: '',
  referralProcessingError: ''
}

const reducer = (state: AdminState = adminState, action: Action<any>) => {
  switch (action.type) {
    case VERIFY_ACCOUNT_REQUEST:
      return { ...state, isVerifyingAccount: true, verificationSuccess: false }
    case VERIFY_ACCOUNT_SUCCESS:
      return { ...state, isVerifyingAccount: false, verificationSuccess: true }
    case VERIFY_ACCOUNT_ERROR:
      return {
        ...state,
        isVerifyingAccount: false,
        accountVerificationError: action.payload
      }
    case PROCESS_USER_REFERRAL_REQUEST:
      return {
        ...state,
        isProcessingReferral: true,
        referralProcessed: false
      }
    case PROCESS_USER_REFERRAL_SUCCESS:
      return {
        ...state,
        isProcessingReferral: false,
        referralProcessed: true
      }
    case PROCESS_USER_REFERRAL_ERROR:
      return {
        ...state,
        isProcessingReferral: false,
        referralProcessingError: action.payload
      }
    case CREATE_CONNECTION_REQUEST:
      return {
        ...state,
        isConnecting: true,
        connectionCreated: false,
        connectionError: ''
      }
    case CREATE_CONNECTION_SUCCESS:
      return {
        ...state,
        isConnecting: false,
        connectionCreated: true,
        connectionError: ''
      }
    case CREATE_CONNECTION_ERROR:
      return {
        ...state,
        isConnecting: false,
        connectionCreated: false,
        connectionError:
          'There was an error connecting these users. Either one of the users dont exist, or there is an API issue'
      }
    case DELETE_USER_REQUEST:
      return {
        ...state,
        isDeletingUser: true,
        deleteUserError: '',
        userDeleted: false
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeletingUser: false,
        userDeleted: true,
        deleteUserError: ''
      }
    case DELETE_USER_ERROR:
      return {
        ...state,
        userDeleted: false,
        isDeletingUser: false,
        deleteUserError: action.payload
      }
    case UPGRADE_USER_REQUEST:
      return {
        ...state,
        isUpgradingUser: true,
        upgradeUserError: '',
        userUpgraded: false
      }
    case UPGRADE_USER_SUCCESS:
      return {
        ...state,
        isUpgradingUser: false,
        userUpgraded: true,
        upgradeUserError: ''
      }
    case UPGRADE_USER_ERROR:
      return {
        ...state,
        userUpgraded: false,
        isUpgradingUser: false,
        upgradeUserError: action.payload
      }
    case DOWNGRADE_USER_REQUEST:
      return {
        ...state,
        isDowngradingUser: true,
        downgradeUserError: '',
        userDowngraded: false
      }
    case DOWNGRADE_USER_SUCCESS:
      return {
        ...state,
        isDowngradingUser: false,
        userDowngraded: true,
        downgradeUserError: ''
      }
    case DOWNGRADE_USER_ERROR:
      return {
        ...state,
        userDowngraded: false,
        isDowngradingUser: false,
        downgradeUserError: action.payload
      }
    default:
      return state
  }
}

export default reducer
