// @flow
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useLocation, Redirect } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import TextField from 'src/components/atoms/TextField'
import Typography from '@material-ui/core/Typography'
import Button from 'src/components/atoms/buttons/Button'
// $FlowFixMe
import { ReactComponent as Logo } from 'src/images/logos/sooner-logo-text.svg'
import { reduxForm, SubmissionError } from 'redux-form'
import { invalidEmail, requiredField } from 'src/utils/fieldValidators'
import type { ReduxFormProps } from 'src/utils/types'
import queryString from 'query-string'
import { useAuth } from 'src/hooks/useAuth'

export const FORM_ID = 'resetPassword'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.main,
    display: 'flex',
    flexDirection: 'column'
  },
  logo: {
    transform: 'scale(0.8)',
    maxHeight: 50,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  cardWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  resetPasswordCard: {
    width: 400,
    padding: theme.spacing(3)
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    flex: 1
  },
  formErrorMessage: {
    display: 'block',
    marginTop: theme.spacing(1),
    height: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
    color: theme.palette.error.main
  }
}))

const validate = values => {
  const errors = {}
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Make sure this matches your password.'
  }
  return errors
}

const ResetPasswordTemplate = ({
  pristine,
  submitting,
  invalid,
  error,
  isOpen,
  onClose,
  handleSubmit
}: ReduxFormProps) => {
  const styles = useStyles()
  const loc = useLocation()
  const queryVars = queryString.parse(loc.search)
  const token = queryVars.oobCode
  const { sendPasswordResetEmail, confirmPasswordReset } = useAuth()
  const [isSendingEmail, setIsSendingEmail] = useState()
  const [isResettingPassword, setIsResettingPassword] = useState(false)
  const [linkSent, setLinkSent] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)

  return (
    <div className={styles.wrapper}>
      <Logo className={styles.logo} />
      <div className={styles.cardWrapper}>
        <Card className={styles.resetPasswordCard}>
          <Typography className={styles.header} variant="h5">
            {token ? 'Reset Password' : 'Confirm Email'}
          </Typography>
          <form
            onSubmit={handleSubmit(async ({ email, newPassword }) => {
              try {
                if (token) {
                  setIsResettingPassword(true)

                  await confirmPasswordReset(token, newPassword)
                  setIsResettingPassword(false)
                  setPasswordReset(true)
                } else {
                  setIsSendingEmail(true)
                  await sendPasswordResetEmail(email)
                  setIsSendingEmail(false)
                  setLinkSent(true)
                }
              } catch (error) {
                setIsResettingPassword(false)
                setIsSendingEmail(false)
                throw new SubmissionError({ _error: error.message })
              }
            })}
          >
            {token ? (
              <>
                <TextField
                  type="password"
                  id="new-password-input"
                  name="newPassword"
                  label="New Password"
                  placeholder="****"
                  validate={[requiredField]}
                />
              </>
            ) : (
              <TextField
                id="email-input"
                name="email"
                label="Confirm Email"
                placeholder="name@email.com"
                validate={[invalidEmail]}
              />
            )}

            <div className={styles.actions}>
              <Button
                className={styles.submit}
                disabled={
                  pristine ||
                  error ||
                  isSendingEmail ||
                  isResettingPassword ||
                  linkSent ||
                  passwordReset
                }
                loading={isSendingEmail || isResettingPassword}
                type="submit"
                variant="contained"
                color="primary"
              >
                {linkSent ? (
                  'Link Sent.'
                ) : passwordReset ? (
                  <Redirect to="/login" />
                ) : token ? (
                  'SET PASSWORD'
                ) : (
                  'SEND LINK'
                )}
              </Button>
            </div>
            {error && (
              <span className={styles.formErrorMessage}>
                <Typography color="secondary" variant="subtitle2">
                  {error}
                </Typography>
              </span>
            )}
          </form>
        </Card>
      </div>
    </div>
  )
}

export default reduxForm({
  form: FORM_ID,
  validate
})(ResetPasswordTemplate)
