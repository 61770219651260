// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  reminderAlert: {
    background: '#0B1C4D'
  },
  generalAlert: {
    background: '#0B1C4D'
  },
  wrapperAlertMessage: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      width: '60%'
    }
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  actionButton: {
    minHeight: 20,
    border: '1px solid white',
    fontSize: '12px',
    color: '#fff',
    width: '100%'
  },
  textSnackbarContainer: {
    color: 'white',
    width: '65%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '10px'
    }
  },
  textSnackbarContainerGeneral: {
    color: 'white',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '10px'
    }
  },
  btnSnackbarContainer: {
    color: 'white',
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '10px'
    }
  }
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default ({
  open,
  snackbarMessage,
  type,
  clickAction,
  buttonText,
  backgroundColor
}) => {
  const styles = useStyles()
  const [isOpen, setIsOpen] = React.useState(open)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    let currentDate = new Date()
    localStorage.setItem('openedSnackbar', currentDate)
    setIsOpen(false)
  }

  const handleClick = () => {
    window.location.replace(clickAction)
    let currentDate = new Date()
    localStorage.setItem('openedSnackbar', currentDate)
  }

  let alertElement = null

  switch (type) {
    case 'warning':
      alertElement = (
        <Alert onClose={handleClose} severity="error">
          {snackbarMessage}
          <Button
            variant="outlined"
            color="primary"
            className={styles.actionButton}
          >
            {buttonText}
          </Button>
        </Alert>
      )
      break

    case 'general':
      alertElement = (
        <Alert
          onClose={handleClose}
          severity="info"
          style={{
            backgroundColor: backgroundColor
          }}
        >
          <div className={styles.wrapper}>
            <div className={styles.textSnackbarContainer}>
              {snackbarMessage}
            </div>
            <div className={styles.btnSnackbarContainer}>
              <Button
                variant="outlined"
                color="primary"
                className={styles.actionButton}
                onClick={handleClick}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </Alert>
      )
      break

    case 'general-no-actions':
      alertElement = (
        <Alert
          onClose={handleClose}
          severity="info"
          style={{
            backgroundColor: backgroundColor
          }}
        >
          <div className={styles.wrapper}>
            <div className={styles.textSnackbarContainerGeneral}>
              {snackbarMessage}
            </div>
          </div>
        </Alert>
      )
      break

    case 'reminder':
      alertElement = (
        <Alert
          icon={<p>1/5</p>}
          onClose={handleClose}
          severity="info"
          className={styles.reminderAlert}
        >
          <div className={styles.wrapperAlertMessage}>
            <p>{snackbarMessage}</p>
            <Button
              variant="outlined"
              color="primary"
              className={styles.actionButton}
              onClick={clickAction}
            >
              {'VIEW CHECKLIST'}
            </Button>
          </div>
        </Alert>
      )
      break

    default:
      alertElement = null
      break
  }

  return (
    <Snackbar
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      {alertElement}
    </Snackbar>
  )
}
