// @flow

import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { AuthHeader } from 'src/components/molecules/AuthHeader'
import { AccountSelect } from 'src/components/templates/onboarding/AccountSelect'
import { useAuth } from 'src/hooks/useAuth'
import { reduxForm, change, SubmissionError } from 'redux-form'
import type { ReduxFormProps } from 'src/utils/types'
import { SongwriterProfile } from 'src/components/templates/onboarding/SongwriterProfile'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { PublisherProfile } from 'src/components/templates/onboarding/PublisherProfile'
import { transformOptions } from 'src/utils/fieldTransformers'
import { createUser } from 'src/services/auth'
import { firebaseApp } from 'src/settings/env'
import { resizeImage } from 'src/utils/api/formatFormData'

const FORM_ID = 'onboarding'

const formSelector = state => state.form.onboarding.values

export const OnboardingPage = ({
  pristine,
  submitting,
  invalid,
  error,
  handleSubmit
}: ReduxFormProps) => {
  const loc = useLocation()
  const history = useHistory()
  const { updateUser, loggedIn, logout } = useAuth()
  const dispatch = useDispatch()
  const formState = useSelector(formSelector, shallowEqual)

  const getOnboardingStep = () => {
    switch (loc.pathname) {
      case '/account-select':
        return (
          <AccountSelect
            onSongwriterSelect={() => {
              dispatch(change(FORM_ID, 'accountType', 'Songwriter'))
              history.replace('/songwriter-setup')
            }}
            onPublisherSelect={() => {
              dispatch(change(FORM_ID, 'accountType', 'Business'))
              history.replace('/publisher-setup')
            }}
          />
        )
      case '/songwriter-setup':
        return (
          <SongwriterProfile
            formState={formState}
            creatingProfile={submitting}
            error={invalid || error}
          />
        )

      case '/publisher-setup':
        return (
          <PublisherProfile
            formState={formState}
            creatingProfile={submitting}
            error={invalid || error}
          />
        )

      default:
        return <div />
    }
  }

  const authStep = getOnboardingStep()

  return (
    <>
      <AuthHeader logout={logout} loggedIn={loggedIn} />
      <form
        onSubmit={handleSubmit(async formData => {
          try {
            const resizedImage = await resizeImage(formData.profileImage)
            const transformedData = {
              ...formData,
              occupation: transformOptions(formData.occupation),
              genres: transformOptions(formData.genres),
              state:
                typeof formData.state === 'string'
                  ? formData.state
                  : transformOptions(formData.state),
              country: transformOptions(formData.country),
              email: firebaseApp.auth().currentUser?.email,
              profileImage: resizedImage
            }
            // console.log(transformedData)
            const { data } = await createUser(transformedData)
            updateUser(data)
            if (data.accountType === "Songwriter") {
              history.replace(`/upgrade`)
            } else {
              history.replace(`/discover`)

            }
          } catch (error) {
            new SubmissionError({ _error: error.message })
          }
        })}
      >
        {authStep}
      </form>
    </>
  )
}

export default reduxForm({
  form: FORM_ID,
  initialValues: {
    accountType: ''
  }
})(OnboardingPage)
