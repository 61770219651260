// @flow

import React, { useEffect } from 'react'
import Navigation from 'src/components/organisms/Navigation'
import CreatePost from 'src/components/organisms/forms/CreatePost'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSplashAction } from 'src/state/actions/splashes'
import type { State } from 'src/utils/types'
import type { Splash } from 'src/utils/types/splashes'

type SelectorFunction = State => ?Splash

const selectState: SelectorFunction = state => state.splashes.splash

export default () => {
  const dispatch = useDispatch()
  const splash: ?Splash = useSelector(selectState, shallowEqual)
  const { id } = useParams()

  useEffect(() => {
    if (id) dispatch(getSplashAction({ splashId: id }))
  }, [dispatch, id])

  return (
    <Navigation pageTitle="Create Post">
      <CreatePost splash={splash} />
    </Navigation>
  )
}
