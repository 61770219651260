// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Tab from '@material-ui/core/Tab'

/*
  STORYBOOK FOR THIS COMPONENT IS COMBINED WITH `molecules/Tabs` 
  COMPONENT AS IT CAN'T LIVE BY ITSELF
*/

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '125px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100px',
      fontSize: '0.8rem'
    }
  }
}))

type TabProps = {
  label: string
}

export default ({ ...props }: TabProps) => {
  const styles = useStyles()
  return <Tab classes={styles} {...props} />
}
