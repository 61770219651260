// @flow
import type { Config } from 'src/utils/types/config'

//images are stored with a pattern so profile images and content are easy to fetch & update
export default (
  type: 'profileImage' | 'coverImage',
  userId: string,
  config?: Config,
  identifier?: string
) => {
  if (config) {
    const contentIdentifier =
      type === 'coverImage' && identifier ? `_${identifier}` : ''
    return `${config.contentStorageUrl}/${userId}_${type}${contentIdentifier}`
  }
}
