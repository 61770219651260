// @flow

import React from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  player: {
    width: 300,
    height: 400,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      height: '80%'
    }
  },
  video: {
    width: 600,
    height: 400,
    [theme.breakpoints.down('xs')]: {
      width: 300,
      height: 200
    }
  }
}))

type FeaturedPlaylistProps = {
  url: string,
  isOpen?: boolean,
  platform?: 'Spotify' | 'SoundCloud' | 'Youtube',
  onClose: () => void
}

export default ({ url, platform, isOpen, onClose }: FeaturedPlaylistProps) => {
  const styles = useStyles()

  if (!platform) {
    platform =
      url.indexOf('spotify') > -1
        ? 'Spotify'
        : url.indexOf('youtube') > -1
        ? 'Youtube'
        : 'SoundCloud'
  }

  const getUrl = () => {
    switch (platform) {
      case 'SoundCloud':
        return `https://w.soundcloud.com/player/?url=${url}`
      default:
        const spotifyUrl = getSpotifyUrl(url)
        return `https://open.spotify.com/embed/${spotifyUrl}`
    }
  }

  const renderSoundCloud = () => (
    <iframe
      className={styles.player}
      title="sound cloud playlist"
      id="sound-cloud-frame"
      scrolling="no"
      src={getUrl()}
      frameBorder="0"
    />
  )

  const renderSpotify = () => (
    <iframe
      className={styles.player}
      title="Spotify Song"
      src={getUrl()}
      frameborder="0"
      allowtransparency="true"
      allow="encrypted-media"
    />
  )

  const renderYoutube = () => (
    <iframe className={styles.video} title="Youtube Video" src={url} />
  )

  return isOpen ? (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={onClose}
      className={styles.modal}
    >
      {platform === 'Spotify'
        ? renderSpotify()
        : platform === 'SoundCloud'
        ? renderSoundCloud()
        : renderYoutube()}
    </Modal>
  ) : (
    ''
  )
}

const getSpotifyUrl = (url: string) => {
  if (url.includes('open.spotify.com/')) {
    return url.split('open.spotify.com/')[1]
  }
  if (url.includes('spotify:')) {
    const isAlbum = url.indexOf('album') > -1 ? 'album' : ''
    const isTrack = url.indexOf('track') > -1 ? 'track' : ''
    const splitUri = url.split(':')
    let id = ''
    if (splitUri.length === 3) {
      id = splitUri[2]
    }
    return `${isAlbum || isTrack}/${id}`
  }
  return url
}

/*
mongodump --host 'soonerdb-shard-0/soonerdb-shard-00-00.ouuwm.gcp.mongodb.net:27017,soonerdb-shard-00-01.ouuwm.gcp.mongodb.net:27017,soonerdb-shard-00-02.ouuwm.gcp.mongodb.net:27017' --ssl --username 'sooner-api' --password '8Ncj0ooDErNo1uBM' --authenticationDatabase 'admin' --db 'sooner-db'

mongorestore --host soonerdb-shard-0/soonerdb-shard-00-00.ouuwm.gcp.mongodb.net:27017,soonerdb-shard-00-01.ouuwm.gcp.mongodb.net:27017,soonerdb-shard-00-02.ouuwm.gcp.mongodb.net:27017 --ssl --username 'sooner-api' --password '8Ncj0ooDErNo1uBM' --authenticationDatabase admin --db 'sooner-test-db'
*/
