// @flow
import * as React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
// import Button from 'src/components/atoms/buttons/Button'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '40%',
    maxWidth: '60%',
    padding: '0px 30px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '95%',
      maxWidth: '95%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '95%',
      maxWidth: '95%',
    }
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  modalHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '20vh',
      overflowY: 'scroll',
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '20vh',
      overflowY: 'scroll',
      alignItems: 'baseline',
    }
  },
  modalActions: {
    bottom: 0,
    width: '100%',
    height: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => (props.justify ? props.justify : 'end')
  },
  modalActionsChildren: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  closeIcon: {
    marginLeft: 15
  },
  cancelButton: {
    height: 30,
  },
  linkButton: {
    height: 30,
    backgroundColor: "#40C7C0",
  },
  paper: {
    width: 700,
    height: 600,
    position: 'relative',
    outline: 0,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      height: '95%'
    }
  }
}))

type ModalHeaderProps = {
  className?: string,
  children?: any,
  onClose: () => void
}

export const ModalHeader = ({
  className,
  children,
  onClose,
  ...props
}: ModalHeaderProps) => {
  const styles = useStyles()

  return (
    <div className={styles.modalHeader} {...props}>
      <h3>
        <strong>{children}</strong>
      </h3>
    </div>
  )
}

type ModalContentProps = {
  className?: string,
  children?: any
}

export const ModalContent = ({
  className,
  children,
  ...props
}: ModalContentProps) => {
  const styles = useStyles()

  return (
    <div className={styles.modalContent} {...props}>
      {children}
    </div>
  )
}

type ModalActionsProps = {
  className?: string,
  children?: any,
  justify?: string,
  isThereALink?: Boolean,
  onDismiss?: () => void,
  onCancel?: () => void
}

export const ModalActions = ({
  className,
  children,
  onDismiss,
  onCancel,
  justify = 'flex-end',
  isThereALink,
  ...props
}: ModalActionsProps) => {
  const styles = useStyles({ justify })

  return (
    <div className={styles.modalActions} {...props}>
      <Button
        aria-label="cancel modal action"
        className={styles.cancelButton}
        size="small"
        onClick={onDismiss}
      >
        Dismiss
      </Button>
      {isThereALink && (
        <Button
          aria-label="cancel modal action"
          className={styles.linkButton}
          onClick={onCancel}
          variant="contained"
          color="primary"
          size="small"
        >
          Go to...
        </Button>
      )}
    </div>
  )
}

export default ({
  open,
  setOpen,
  className,
  paperClassName,
  children,
  ...props
}: ModalProps) => {
  const styles = useStyles()
  return (
    <Modal
      className={classNames(styles.modal, className, styles.modalPaper)}
      open={open}
      onClose={() => setOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300
      }}
      closeAfterTransition
      {...props}
    >
      <Fade in={open}>
        <Card className={styles.root}>{children}</Card>
      </Fade>
    </Modal>
  )
}
