// @flow

import React from 'react'
import NetworkTemplate from 'src/components/templates/Network'

const AuthPage = () => {
  return <NetworkTemplate />
}

export default AuthPage
