// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: props =>
      'margin' in props ? theme.spacing(props.margin || 0) : theme.spacing(4)
  }
}))

type TabView = {
  children: any,
  defaultTab?: number,
  value?: number,
  margin?: number,
  onChange?: (e: any, val: number) => void
}

export default ({
  children,
  onChange,
  defaultTab = 0,
  margin,
  value,
  ...props
}: TabView) => {
  const [activeTab, setActiveTab] = React.useState(defaultTab)

  const onTabsChange = (e, val) => {
    if (typeof value !== 'undefined') {
      //if value is set, it's a form sequence so we can only move backward via tabs
      if (val < value) {
        onChange && onChange(e, val)
        setActiveTab(val)
      }
    } else {
      onChange && onChange(e, val)
      setActiveTab(val)
    }
  }

  const styles = useStyles({ margin })

  return (
    <Tabs
      classes={styles}
      value={value ? value : activeTab}
      onChange={onTabsChange}
      indicatorColor="primary"
      textColor="primary"
      {...props}
    >
      {children}
    </Tabs>
  )
}
