// @flow
import type { ThreadMember } from 'src/utils/types/inbox'
import type { User } from 'src/utils/types/users'

export default (currentUser: ?User, members: ThreadMember[]) => {
  return members.length < 2
    ? 'All Members Left Chat'
    : currentUser &&
        members
          .filter(member => currentUser && member.userId !== currentUser._id)
          .map(member => `${member.firstname} ${member.lastname}`)
          .reduce((prev, next): string => `${prev}, ${next}`)
}
