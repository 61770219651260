// @flow
import React, { useState, useEffect } from 'react'
import type { Comment } from 'src/utils/types/splashes'
import type { SplashState } from 'src/state/reducers/splashes'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Expansion from 'src/components/molecules/Expansion'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'src/components/atoms/buttons/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { TextField } from 'src/components/atoms/TextField'
import { reduxForm } from 'redux-form'
import LoadingIndicator from 'src/components/atoms/LoadingIndicator'
import { getRepliesAction, getCommentsAction } from 'src/state/actions/splashes'
import Link from 'src/components/atoms/Link'
import {
  postCommentsAction,
  deleteCommentsAction
} from '../../../state/actions/splashes'
import moment from 'moment'
import { useAuth } from '../../../hooks/useAuth'

const FORM_ID = 'comments-form'

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  closeButton: {
    marginRight: theme.spacing(2)
  },
  dialogContent: {
    paddingBottom: theme.spacing(3),
    minWidth: 400,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%'
    }
  },
  commentField: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(3)
  },
  submitComment: {
    margin: 0,
    marginLeft: theme.spacing(1)
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      margin: 0,
      alignItems: 'center'
    }
  }
}))

type CommentsDialogProps = {
  splashId: string,
  isOpen?: boolean,
  onClose: () => void
}

const CommentsDialog = ({ splashId, isOpen, onClose }: CommentsDialogProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const state: SplashState = useSelector(state => state.splashes, shallowEqual)

  const fetchReplies = (commentId: string) =>
    dispatch(getRepliesAction({ commentId }))

  const postComment = (body: string, commentParentId?: string) => {
    dispatch(postCommentsAction({ splashId, commentParentId, comment: body }))
  }

  const deleteComment = (commentId: string) => {
    dispatch(deleteCommentsAction({ commentId }))
  }

  useEffect(() => {
    isOpen && dispatch(getCommentsAction({ splashId }))
  }, [dispatch, isOpen, splashId])

  return (
    <Dialog
      fullScreen={window.innerWidth < 420}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={styles.dialogHeader}>
        <DialogTitle>Comments</DialogTitle>
        <IconButton className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent className={styles.dialogContent}>
        {state.isFetchingComments ? (
          <div className={styles.loaderWrapper}>
            <LoadingIndicator size="small" />
          </div>
        ) : (
          <>
            <CommentField
              loading={state.isPostingComment}
              label="Add a comment"
              buttonLabel="POST"
              onSubmit={postComment}
            />
            {state.comments.map(comment => (
              <CommentBlock
                comment={comment}
                deleteComment={deleteComment}
                isPostingComment={state.isPostingComment}
                fetchingReplies={state.isFetchingReplies}
                fetchReplies={fetchReplies}
                postComment={postComment}
              />
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default reduxForm({
  form: FORM_ID
})(CommentsDialog)

const useCommentStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    marginBottom: theme.spacing(3),
    '&:hover  h6:first-of-type': {
      display: 'block'
    }
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  commentBody: {
    textAlign: 'left',
    width: '100%',
    margin: 0
  },
  nameAndTime: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    fontWeight: 'bold'
  },
  timestamp: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.label,
    fontWeight: 'normal'
  },
  replies: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  deleteButton: {
    display: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  expansion: {
    padding: 0
  }
}))

type CommentProps = {
  comment: Comment,
  isPostingComment?: boolean,
  deleteComment: (commentId: string) => void,
  postComment: (body: string, commentParentId?: string) => void,
  fetchingReplies?: boolean,
  fetchReplies: (id: string) => void,
  loading?: boolean
}

const CommentBlock = ({
  comment,
  loading,
  deleteComment,
  isPostingComment,
  postComment,
  fetchingReplies,
  fetchReplies
}: CommentProps) => {
  const styles = useCommentStyles()
  const { user: currentUser } = useAuth()
  const onOpenReplies = () => {
    comment.replyCount > 0 && !comment.replies && fetchReplies(comment._id)
  }

  return (
    <div className={styles.container}>
      <div className={styles.commentHeader}>
        <div className={styles.nameAndTime}>
          <Link to={`/profile/${comment.authorId}`}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={styles.name}
            >
              {comment.authorName}
            </Typography>
          </Link>
          <Typography variant="subtitle2" className={styles.timestamp}>
            {moment(Number(comment.timestamp)).fromNow()}
          </Typography>
        </div>
        {comment.authorId === currentUser?._id && (
          <Typography
            variant="subtitle2"
            color="secondary"
            className={styles.deleteButton}
            onClick={() => {
              deleteComment(comment._id)
            }}
          >
            delete
          </Typography>
        )}
      </div>
      <Typography variant="body" className={styles.commentBody}>
        {comment.body}
      </Typography>
      {!comment.commentParentId && (
        <Expansion
          align="right"
          onOpen={onOpenReplies}
          title={
            comment.replyCount === 0
              ? 'Reply'
              : `${comment.replyCount} Repl${
                  comment.replyCount === 1 ? 'y' : 'ies'
                }`
          }
          variant="secondary"
          className={styles.expansion}
        >
          <div className={styles.replies}>
            {fetchingReplies ? (
              <LoadingIndicator size="small" />
            ) : (
              <>
                <CommentField
                  commentParentId={comment._id}
                  loading={isPostingComment}
                  label="Reply to comment"
                  buttonLabel="REPLY"
                  onSubmit={postComment}
                />
                {comment.replies?.map(reply => (
                  <CommentBlock
                    comment={reply}
                    fetchingReplies={fetchingReplies}
                    fetchReplies={fetchReplies}
                    postComment={postComment}
                    deleteComment={deleteComment}
                  />
                ))}
              </>
            )}
          </div>
        </Expansion>
      )}
    </div>
  )
}

const useFieldStyles = makeStyles(theme => ({
  commentField: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(3)
  },
  submitComment: {
    margin: 0,
    marginLeft: theme.spacing(1)
  }
}))

type CommentFieldProps = {
  label: string,
  loading?: boolean,
  buttonLabel: string,
  commentParentId?: string,
  onSubmit: (body: string, commentParentId?: string) => void
}

const CommentField = ({
  label,
  loading,
  onSubmit,
  buttonLabel,
  commentParentId
}: CommentFieldProps) => {
  const styles = useFieldStyles()
  const [comment, setComment] = useState('')
  return (
    <div className={styles.commentField}>
      <TextField
        multiline
        noMargin
        name="newComment"
        id="new-comment"
        placeholder={label}
        onChange={e => {
          setComment(e.target.value)
        }}
      />
      <Button
        disabled={comment === '' || loading}
        variant="contained"
        color="secondary"
        onClick={() => {
          onSubmit(comment, commentParentId)
          setComment('')
        }}
        className={styles.submitComment}
      >
        {buttonLabel}
      </Button>
    </div>
  )
}
