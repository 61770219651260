// @flow
import 'firebase/auth'
import { useState, useEffect } from 'react'
import * as Firebase from 'firebase/app'
import { firebaseApp } from 'src/settings/env'
import { useLocalStorage } from '@rehooks/local-storage'
import constate from 'constate'
import type { User } from 'src/utils/types/users'
import type { CreateAccountRequest } from 'src/services/auth'
import { getMe } from 'src/services/users'
import { createUser } from 'src/services/auth'
import { history } from 'react-router-util'
import { matchPaths } from '../settings/axios'

function useAuthHook() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)
  const [user, setUser] = useLocalStorage('user')
  const [initialized, setInitialized] = useState(false)
  const [isCreatingAccount, setIsCreatingAccount] = useState(false)

  const loginWithEmail = async (email: string, password: string) => {
    await firebaseApp.auth().setPersistence(Firebase.auth.Auth.Persistence.LOCAL)
    await firebaseApp.auth().signInWithEmailAndPassword(email, password)
  }

  const createSoonerAccount = async (data: CreateAccountRequest) => {
    setIsCreatingAccount(true)
    await createUser(data)
    setIsCreatingAccount(false)
  }

  const loginWithGoogle = async () => {
    await firebaseApp.auth().setPersistence(Firebase.auth.Auth.Persistence.LOCAL)
    return firebaseApp.auth().signInWithPopup(new Firebase.auth.GoogleAuthProvider())
  }

  const loginWithFacebook = () =>
    firebaseApp.auth().signInWithPopup(new Firebase.auth.FacebookAuthProvider())

  const loginWithSoundCloud = () => {}

  const signupWithEmail = async (email, password) => {
    await firebaseApp.auth().setPersistence(Firebase.auth.Auth.Persistence.LOCAL)
    return firebaseApp.auth().createUserWithEmailAndPassword(email.trim(), password)
  }

  const updateUser = async (updatedUser: User) => {
    setUser(updatedUser)
  }

  const logout = async () => firebaseApp.auth().signOut()

  const sendPasswordResetEmail = async (email: string) =>
    firebaseApp.auth().sendPasswordResetEmail(email)

  const confirmPasswordReset = async (code: string, password: string) =>
    firebaseApp.auth().confirmPasswordReset(code, password)

  const clearAuth = () => {
    setUser('')
    setLoggedIn(false)
    setLoggingIn(false)
    localStorage.removeItem('user')
    localStorage.removeItem('access_token')
  }

  useEffect(() => {
    const unsubscribe = firebaseApp
      .auth()
      .onAuthStateChanged(async firebaseUser => {
        if (initialized) {
          if (firebaseUser) {
            try {
              setLoggingIn(true)
              const token = await firebaseUser.getIdToken(true)
              localStorage.setItem('access_token', token)
              try {
                const { data } = await getMe()
                setUser(data)
                setLoggedIn(true)
                setLoggingIn(false)
                if (data.accountType === "Songwriter" && data.accountLevel === "Free" && !matchPaths(['upgrade'])) {
                  history.replace('/upgrade');
                }
                const redirectToDiscover = (data && (data.accountType === "Songwriter" ||data.accountType === 'Business') && matchPaths(['songwriter-setup', 'publisher-setup', 'account-select']))
                  || matchPaths(['login', 'signup']);
                if (redirectToDiscover) {
                  history.push('/discover')
                }
              } catch (err) {
                console.log(err)
                if (err === 'ONBOARDING_REQUIRED') {
                  setLoggedIn(true)
                }
                setLoggingIn(false)
                if (!matchPaths(['welcome', 'login', 'signup', 'reset-password', 'account-select', 'songwriter-setup', 'publisher-setup']))
                  history.replace('/login')
              }
            } catch (err) {
              clearAuth()
            }
          } else {
            clearAuth()
            if (!matchPaths(['welcome', 'login', 'signup', 'reset-password']))
              history.replace('/login')
          }
        }
        if (!initialized) {
          setInitialized(true)
        }
      })

    // Cleanup subscription on unmount
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized])

  return {
    initialized,
    isCreatingAccount,
    user,
    loggedIn,
    loggingIn,
    updateUser,
    loginWithEmail,
    loginWithGoogle,
    loginWithFacebook,
    loginWithSoundCloud,
    signupWithEmail,
    createSoonerAccount,
    logout,
    sendPasswordResetEmail,
    confirmPasswordReset
  }
}

export const [FirebaseAuthProvider, useAuth] = constate(useAuthHook)
