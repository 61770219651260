// @flow
import React, { useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Button from 'src/components/atoms/buttons/Button'
import StatField from 'src/components/atoms/StatField'
import Modal, {
  ModalHeader,
  ModalActions
} from 'src/components/molecules/Modal'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(2)
  },
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '100%'
    }
  },
  companyAndRecommender: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    maxWidth: '75%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  text: {
    width: '80%',
    margin: 'auto'
  },
  recommender: {
    marginTop: theme.spacing(4),
    fontWeight: 'bold'
  },
  date: {
    color: theme.palette.primary.main
    // fontWeight: 'bold'
  },
  recommendation: {
    marginTop: theme.spacing(3)
  },
  footer: {
    marginTop: theme.spacing(3)
  }
}))

type RecCardProps = {
  organization: string,
  recommender: string,
  body: string,
  date: string
}

export default ({
  organization,
  recommender,
  date,
  body,
  ...props
}: RecCardProps) => {
  const styles = useStyles()

  const [open, setOpen] = useState(false)

  const formattedDate = moment(Number(date)).format('MM-DD-YYYY')

  return (
    <React.Fragment>
      <Paper className={styles.paper} {...props}>
        <StatField
          className={styles.companyAndRecommender}
          fieldTitle={organization}
          fieldValue={`${recommender} - ${date}`}
          invert
        />
        <Button
          color="primary"
          className={styles.readButton}
          onClick={() => setOpen(true)}
        >
          Read
        </Button>
      </Paper>
      <Modal open={open} setOpen={setOpen}>
        <ModalHeader onClose={() => setOpen(false)} />
        <div className={styles.text}>
          <Typography variant="h4" className={styles.recommender}>
            {organization}
          </Typography>
          <Typography variant="subtitle1" className={styles.date}>
            {formattedDate}
          </Typography>
          <Typography variant="body1" className={styles.recommendation}>
            {body}
          </Typography>
          <Typography variant="body1" className={styles.footer}>
            <i>- {recommender}</i>
          </Typography>
        </div>
        <ModalActions onCancel={() => setOpen(false)} />
      </Modal>
    </React.Fragment>
  )
}
