// @flow
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  musicAccountLink: {
    textDecoration: 'none',
    outline: 'none',
    padding: `0 ${theme.spacing(3)}px`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    backgroundColor: '#FFF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
    width: '100%',
    height: 100,
    [theme.breakpoints.down('sm')]: {
      width: '45%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 85
    }
  },
  header: {
    fontWeight: 700
  },
  subHeader: {
    color: theme.palette.text.label
  }
}))
