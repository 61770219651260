// @flow

import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch, batch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { push } from 'react-router-redux'
import Navigation from 'src/components/organisms/Navigation'
import ProfileHeader from 'src/components/organisms/ProfileHeader'
import ProfileContent from 'src/components/molecules/ProfileContent'
import ContentView from 'src/components/organisms/ContentView'
import MusicView from 'src/components/organisms/MusicView'
import StatsView from 'src/components/organisms/StatsView'
import BroadcastView from 'src/components/organisms/BroadcastView'
import FullPageLoading from 'src/components/atoms/FullPageLoading'
import { getUserAction, getNetworkAction } from 'src/state/actions/profile'
import type { State } from 'src/utils/types'
import type { ProfileState } from 'src/state/reducers/profile'
import { useAuth } from '../../../hooks/useAuth'
import queryString from 'query-string'

const renderSongwriterContent = ({ activeTab }) => {
  switch (activeTab) {
    case 'Splashes':
      return <ContentView />
    case 'Music':
      return <MusicView />
    case 'Stats':
      return <StatsView />
    case 'Broadcast':
      return <BroadcastView />
    default:
      return ''
  }
}

const selectProfile: State => ProfileState = state => state.profile

export default () => {
  const profile: ProfileState = useSelector(selectProfile, shallowEqual)
  const { user, isConnected, isAwaitingConfirmation } = profile
  const { user: currentUser } = useAuth()
  const { id } = useParams()
  const loc = useLocation()
  const { tab } = queryString.parse(loc.search)
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      batch(() => {
        dispatch(getUserAction({ id }))
        dispatch(getNetworkAction({ userId: id }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id])

  // no permission needed for songwriter profiles
  // business profiles require a connection with that business to view
  const hasViewPermission =
    user?._id === currentUser?._id ||
    user?.accountType === 'Songwriter' ||
    (user?.accountType === 'Business' && isConnected) ||
    (user?.accountType === 'Business' &&
      currentUser?.accountType === 'Business') ||
    isAwaitingConfirmation ||
    user?.accountType === 'Admin' ||
    currentUser?.accountType === 'Admin'

  // make sure user and currentuser exist so we dont prematurely boot them to discover page
  if (!hasViewPermission && user && currentUser) {
    dispatch(push('/discover'))
  }

  const labelsArr = ['Music', 'Stats', 'Splashes']

  if (currentUser?.accountType === 'Admin') {
    labelsArr.push('Broadcast')
  }

  return user && currentUser ? (
    <Navigation pageTitle="Profile">
      <ProfileHeader isVisible />
      <ProfileContent
        defaultTab={tab || 'Music'}
        labels={labelsArr}
        component={renderSongwriterContent}
      />
    </Navigation>
  ) : (
    <FullPageLoading variant="absolute" />
  )
}
