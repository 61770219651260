// @flow
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Field } from 'redux-form'
import TextField from 'src/components/atoms/TextField'
import PlayIcon from '@material-ui/icons/PlayCircleFilled'
import MusicIcon from '@material-ui/icons/MusicNote'
import LinkIcon from '@material-ui/icons/Link'
import CardActionArea from '@material-ui/core/CardActionArea'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import TabView from 'src/components/atoms/Tabs'
import Tab from 'src/components/atoms/Tab'
import Button from 'src/components/atoms/buttons/Button'
import {
  requiredField,
  validYoutubeUrl,
  validMusicUrl
} from 'src/utils/fieldValidators'

const useStyles = makeStyles(theme => ({
  dialog: {
    margin: 0,
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '90vw'
    }
  },
  flexMediaOptions: {
    display: 'flex',
    flexDirection: 'column'
  },
  mediaOption: {
    width: '100%',
    minHeight: 125,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  mediaOptionTitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  mediaOptionBody: {
    width: '90%',
    margin: 'auto',
    textAlign: 'center',
    color: theme.palette.text.label,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  },
  mediaOptionIcon: {
    width: 30,
    height: 30,
    fontSize: 30,
    marginRight: theme.spacing(2)
  },
  urlFormRoot: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  urlField: {
    marginBottom: theme.spacing(2)
  }
}))

type SpotifyUrlFormProps = {
  type: string,
  placeholder: string,
  styles: any
}

const UrlField = ({ type, placeholder, styles }: SpotifyUrlFormProps) => {
  const validators = [requiredField]
  switch (type) {
    case 'YOUTUBE':
      validators.push(validYoutubeUrl)
      break
    default:
      break
  }
  return (
    <div className={styles.urlFormRoot}>
      <div className={styles.urlField}>
        <TextField
          id="content-url-field"
          name="contentUrl"
          placeholder={placeholder}
          fullWidth
          center
          validate={validators}
        />
      </div>
      {type === 'MUSIC' && (
        <Typography variant="body2" className={styles.mediaOptionBody}>
          <i>
            Please verify that your url is correct before creating your post
          </i>
        </Typography>
      )}
    </div>
  )
}

type SplashContentOptionProps = {
  icon: 'MUSIC' | 'YOUTUBE' | 'EXTERNAL',
  title: string,
  description: string,
  onClick: () => void,
  styles: any
}

const SplashContentOption = ({
  icon,
  title,
  description,
  onClick,
  styles
}: SplashContentOptionProps) => (
  <CardActionArea className={styles.mediaOption} onClick={onClick}>
    <Typography variant="h6" className={styles.mediaOptionTitle}>
      {icon === 'MUSIC' ? (
        <MusicIcon className={styles.mediaOptionIcon} />
      ) : icon === 'YOUTUBE' ? (
        <PlayIcon className={styles.mediaOptionIcon} />
      ) : icon === 'EXTERNAL' ? (
        <LinkIcon className={styles.mediaOptionIcon} />
      ) : (
        ''
      )}
      {title}
    </Typography>
    <Typography
      variant="body1"
      className={styles.mediaOptionBody}
      component="p"
    >
      {description}
    </Typography>
  </CardActionArea>
)

const renderContentOptionsField = ({ input, styles, onClick, ...props }) => {
  const { onChange } = input
  return (
    <div className={styles.flexMediaOptions}>
      <SplashContentOption
        icon="MUSIC"
        styles={styles}
        title="Music"
        description="Share a music link with your audience."
        onClick={() => {
          onChange('MUSIC')
          onClick('MUSIC')
        }}
      />
      <SplashContentOption
        icon="YOUTUBE"
        styles={styles}
        title="Videos"
        description="Share a video link with your audience."
        onClick={() => {
          onChange('YOUTUBE')
          onClick('YOUTUBE')
        }}
      />
      <SplashContentOption
        icon="EXTERNAL"
        styles={styles}
        title="External Link"
        description="Use this when your link points to neither music nor videos."
        onClick={() => {
          onChange('EXTERNAL')
          onClick('EXTERNAL')
        }}
      />
    </div>
  )
}

type AddSplashContentProps = {
  formId: string,
  isOpen: boolean,
  disabled?: boolean,
  onClose: () => void
}

export default ({
  formId,
  isOpen,
  onClose,
  disabled
}: AddSplashContentProps) => {
  const styles = useStyles()
  const [formData, setFormData] = useState({
    currentTab: 0,
    contentType: ''
  })

  const urlFormPlaceholder =
    formData.contentType === 'MUSIC'
      ? 'Share URL'
      : formData.contentType === 'YOUTUBE'
      ? 'Youtube URL'
      : formData.contentType === 'EXTERNAL'
      ? 'Content URL'
      : ''

  return (
    <Dialog
      keepMounted
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: styles.dialog
      }}
    >
      <DialogTitle>
        <TabView
          variant="fullWidth"
          onChange={(e, val) => setFormData({ ...formData, currentTab: val })}
          value={formData.currentTab}
          margin={0}
        >
          <Tab label="1. CHOOSE TYPE" />
          <Tab label="2. ADD CONTENT" />
        </TabView>
      </DialogTitle>
      <DialogContent>
        {formData.currentTab === 0 ? (
          <Field
            name="contentType"
            component={renderContentOptionsField}
            onClick={contentType => {
              setFormData({
                currentTab: 1,
                contentType
              })
            }}
            styles={styles}
          />
        ) : (
          <UrlField
            type={formData.contentType}
            placeholder={urlFormPlaceholder}
            styles={styles}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          CANCEL
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (formData.currentTab === 0) {
              setFormData({ ...formData, currentTab: 1 })
            } else {
              onClose()
            }
          }}
          disabled={formData.currentTab === 0 || disabled}
        >
          ADD CONTENT
        </Button>
      </DialogActions>
    </Dialog>
  )
}
