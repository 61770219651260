// @flow

import React from 'react'
import 'firebase/auth'
import { Router, Route, Switch } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { history } from 'react-router-util'
import store from 'src/state/store'
import RouteWithConfig from 'src/components/hoc/RouteWithConfig'
import AuthPage from 'src/pages/Auth'
import NetworkPage from 'src/pages/Network'
import DiscoverPage from 'src/pages/Discover'
import ProfilePage from 'src/pages/Profile'
import EditProfilePage from 'src/pages/EditProfile'
import InboxPage from 'src/pages/Inbox'
import CreatePostPage from 'src/pages/CreatePost'
import UpgradePage from 'src/pages/Upgrade'
import UpgradePageOld from 'src/pages/UpgradeOld'
import DashboardPage from 'src/pages/Dashboard'
import ReferralPage from 'src/pages/Referral'
import AdminPanel from 'src/pages/AdminPanel'
import ResetPassword from 'src/pages/ResetPassword'
import { FirebaseAuthProvider, useAuth } from 'src/hooks/useAuth'
import { makeStyles } from '@material-ui/styles'
import LoadingIndicator from 'src/components/atoms/LoadingIndicator'
import OnboardingPage from 'src/pages/Onboarding'

const enhancedHistory = syncHistoryWithStore(history, store)

export const Routes = () => {
  return (
    <React.Fragment>
      <FirebaseAuthProvider>
        <Router history={enhancedHistory}>
          <AuthedRoutes />
        </Router>
      </FirebaseAuthProvider>
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export type AuthFlowRoutes =
  | '/login'
  | '/welcome'
  | '/signup'
  | '/account-select'
  | '/profile-setup'
  | '/choose-plan'
  | '/invite-friends'

const AuthedRoutes = () => {
  const { initialized, loggingIn } = useAuth()
  const styles = useStyles()
  const LoadingPage = (
    <div className={styles.loadingContainer}>
      <LoadingIndicator />
    </div>
  )

  if (!initialized || loggingIn) {
    return LoadingPage
  }
  return (
    <>
      <Switch>
        <Route exact path="/(login|signup|welcome)" component={AuthPage} />
        <Route
          exact
          path="/(account-select|songwriter-setup|publisher-setup|choose-plan|invite-friends)"
          component={OnboardingPage}
        />
        <Route exact path="/reset-password" component={ResetPassword} />
        <RouteWithConfig
          exact
          path="/"
          pageTitle="Dashboard"
          component={DashboardPage}
        />
        <RouteWithConfig
          exact
          path="/dashboard"
          pageTitle="Dashboard"
          component={DashboardPage}
        />
        <RouteWithConfig
          path="/network"
          pageTitle="Network"
          component={NetworkPage}
        />
        <RouteWithConfig
          path="/discover"
          pageTitle="Discover"
          component={DiscoverPage}
        />
        <RouteWithConfig
          exact
          path="/profile/:id"
          pageTitle="Profile"
          component={ProfilePage}
        />
        <RouteWithConfig
          exact
          path="/profile/edit/:id"
          component={EditProfilePage}
          pageTitle="Edit Profile"
        />
        <RouteWithConfig
          path="/inbox/:threadId?"
          pageTitle="Inbox"
          component={InboxPage}
        />
        <RouteWithConfig
          exact
          path="/post"
          pageTitle="Create Splash"
          component={CreatePostPage}
        />
        <RouteWithConfig
          path="/post/:id"
          pageTitle="Create Splash"
          component={CreatePostPage}
        />
        <RouteWithConfig
          path="/upgrade"
          pageTitle="Upgrade"
          component={UpgradePage}
        />
        <RouteWithConfig
          path="/upgrade-old"
          pageTitle="Upgrade"
          component={UpgradePageOld}
        />
        <RouteWithConfig
          path="/admin-panel"
          pageTitle="Admin"
          component={AdminPanel}
        />
        <RouteWithConfig
          path="/referral/:result?"
          pageTitle="Referrals"
          component={ReferralPage}
        />
      </Switch>
    </>
  )
}

export default Routes
