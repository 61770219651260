// @flow
import React from 'react'
import type { ReduxFormProps } from 'src/utils/types'

import { useSelector, shallowEqual } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Field } from 'redux-form'
import Button from 'src/components/atoms/buttons/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import { requiredField, validFile } from 'src/utils/fieldValidators'

//$FlowFixMe
import { ReactComponent as ClapIcon } from 'src/images/clap-hands.svg'
//$FlowFixMe
import { ReactComponent as CommentIcon } from 'src/images/comment.svg'

const useStyles = makeStyles(theme => ({
  container: {
    transform: 'scale(1.15)',
    paddingLeft: theme.spacing(3),
    paddingRight: props =>
      props.lastInList ? theme.spacing(8) : theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: props =>
        props.lastInList ? theme.spacing(3) : theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      transform: 'scale(1)'
    }
  },
  card: {
    width: 375,

    [theme.breakpoints.down('xs')]: {
      width: 315
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  uploadImageButton: {
    width: '100%',
    height: 175
  },
  upload: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer'
  },
  uploadImageHeader: {
    position: 'absolute',
    top: '45%',
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
    color: theme.palette.text.button
  },
  thumbnail: {
    backgroundColor: theme.palette.text.label,
    height: 175,

    [theme.breakpoints.down('xs')]: {
      height: 150
    }
  },
  titleField: {
    fontSize: 'inherit',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    border: 'none',
    outline: 'none',
    background: 'none',
    color: theme.palette.text.main
  },
  bodyField: {
    marginTop: theme.spacing(1.5),
    resize: 'none',
    color: theme.palette.text.label,
    minHeight: 100,
    overflow: 'visible',
    lineHeight: 'calc(.875rem * 1.75)',
    fontSize: '0.8rem',
    background: 'none',
    border: 'none',
    outline: 'none'
  },
  body: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.text.label,
    lineHeight: 'calc(.875rem * 1.75)',
    maxHeight: 115,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      lineHeight: 'calc(.8rem * 1.75)'
    }
  },
  cardActions: {
    display: 'flex',
    paddingTop: 0,
    width: '100%'
  },
  iconText: {
    fontWeight: 'lighter',
    paddingRight: theme.spacing(1)
  },
  addContentIcon: {
    marginRight: theme.spacing(1)
  },
  mediaType: {
    flex: 1,
    display: 'flex'
  },
  actionIcons: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  actionLabel: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: theme.spacing(1.5),
    alignItems: 'center'
  },
  formActions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  errorMessage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: theme.palette.error.main
  }
}))

const renderCoverImageUpload = ({ input, styles, meta, ...props }) => {
  const { onChange, value } = input

  const onFileUpload = e => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        onChange &&
          onChange({
            name: file.name,
            type: file.type,
            size: file.size,
            lastMod: file.lastModified,
            lastModDate: file.lastModifiedDate,
            img: reader.result
          })
      },
      false
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  return (
    <CardActionArea className={styles.uploadImageButton} tabIndex="-1">
      <Typography variant="h6" className={styles.uploadImageHeader}>
        {value ? '' : 'Upload a Rectangular Image'}
      </Typography>
      {value && meta.error && (
        <Typography variant="h6" className={styles.errorMessage}>
          {meta.error}
        </Typography>
      )}
      <input
        accept="image/*"
        className={styles.upload}
        id="cover-image-upload"
        type="file"
        onChange={onFileUpload}
        {...props}
      />
      <CardMedia
        className={styles.thumbnail}
        image={value ? value.img : ''}
        title={value ? value.name : 'upload cover image'}
      />
    </CardActionArea>
  )
}

const renderTitleField = ({ input, styles, ...props }) => {
  return (
    <Typography variant="h6">
      <input type="text" className={styles.titleField} {...input} {...props} />
    </Typography>
  )
}

const renderBodyField = ({ input, styles, ...props }) => {
  return (
    <textarea
      className={styles.bodyField}
      maxLength={185}
      {...input}
      {...props}
    />
  )
}

type SplashCardProps = {
  formId: string,
  onOpenContentDialog: () => void,
  onNext: () => void,
  disabled?: boolean
}

export default ({
  formId,
  error,
  onOpenContentDialog,
  onNext,
  disabled
}: SplashCardProps & ReduxFormProps) => {
  const styles = useStyles()

  const contentUrl = useSelector(
    state => state.form[formId].values.contentUrl,
    shallowEqual
  )

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <Field
          name="coverImage"
          component={renderCoverImageUpload}
          styles={styles}
          validate={[requiredField, validFile]}
        />
        <CardContent className={styles.content}>
          <Field
            name="title"
            placeholder="Enter a Title Here"
            component={renderTitleField}
            styles={styles}
            validate={[requiredField]}
          />
          <Field
            name="body"
            component={renderBodyField}
            placeholder="Type up to 170 characters describing this event. The number of posts per month are limited, so make each one count!"
            styles={styles}
            validate={[requiredField]}
          />
        </CardContent>
        <CardActions className={styles.cardActions}>
          <div className={styles.mediaType}>
            <Button
              variant={contentUrl ? 'contained' : 'outlined'}
              color="primary"
              onClick={onOpenContentDialog}
            >
              {contentUrl ? (
                <CheckIcon className={styles.addContentIcon} />
              ) : (
                <AddIcon className={styles.addContentIcon} />
              )}
              CONTENT
            </Button>
          </div>
          <div className={styles.actionIcons}>
            <div className={styles.actionLabel}>
              <Typography className={styles.iconText}> 0 </Typography>
              <CommentIcon />
            </div>
            <div className={styles.actionLabel}>
              <Typography className={styles.iconText}> 0 </Typography>
              <ClapIcon />
            </div>
          </div>
        </CardActions>
      </Card>
      <div className={styles.formActions}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onNext}
          disabled={disabled || !contentUrl || error}
        >
          NEXT
        </Button>
      </div>
    </div>
  )
}
