/* eslint-disable flowtype/space-after-type-colon */
// @flow

import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Flex } from 'src/components/molecules/Flex'
import CardActionArea from '@material-ui/core/CardActionArea'
import { useStyles } from './index.styles'

type MusicLinkProps = {
  isPrivate?: boolean,
  order: number,
  title: string,
  desc?: string,
  url: string
}

export const MusicLink = ({
  order,
  isPrivate,
  title,
  desc,
  url
}: MusicLinkProps) => {
  const styles = useStyles()
  const withHttpPrefix = url.includes('http')
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={withHttpPrefix ? url : `//${url}`}
      className={styles.anchor}
    >
      <CardActionArea className={styles.songLink}>
        <Typography variant="h6" className={styles.orderContainer}>
          {order}
        </Typography>
        <Flex flexDirection="column" margin="0 0 0 24px">
          <Flex alignItems="center">
            <Typography variant="h6" className={styles.songTitle}>
              {title}
              {isPrivate && (
                <span className={styles.publisherOnlyLabel}>
                  (Publishers Only)
                </span>
              )}
            </Typography>
          </Flex>

          <Typography variant="subtitle1" className={styles.songDesc}>
            {desc}
          </Typography>
        </Flex>
      </CardActionArea>
    </a>
  )
}
