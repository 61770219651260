// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Link from 'src/components/atoms/Link'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  externalLinkWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1rem'
  }
}))

type DeleteThreadDialogProps = {
  isOpen?: boolean,
  onClose: () => void,
  url: string
}

export default ({ isOpen, onClose, url }: DeleteThreadDialogProps) => {
  const styles = useStyles()
  return (
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={onClose}
      className={styles.modal}
    >
      <DialogTitle id="alert-dialog-title">External Link</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You will be redirected to a website unaffiliated with sooner.com. Are
          you sure you wish to visit this link?
        </DialogContentText>
        <div className={styles.externalLinkWrapper}>
          <Link isExternal to={url}>
            {url}
          </Link>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}
