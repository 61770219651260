// @flow

import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    margin: 'auto',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: props =>
      props.variant === 'primary'
        ? `1px solid ${theme.palette.border.main}`
        : 'none'
  },
  expansionHeaderPrimary: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  expansionHeaderSecondary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: props =>
      props.align === 'center'
        ? 'center'
        : props.align === 'right'
        ? 'flex-end'
        : 'flex-start',
    cursor: 'pointer',
    textTransform: 'capitalize'
  },
  details: {
    minHeight: 400,
    width: '100%',
    paddingTop: theme.spacing(2),
    display: props => (props.isOpen ? 'block' : 'none')
  }
}))

type ExpansionProps = {
  title: string,
  isOpenDefault?: boolean,
  variant?: 'primary' | 'secondary',
  align?: 'left' | 'center' | 'right',
  children?: any,
  className?: string,
  onOpen?: () => void
}

export default ({
  children,
  title,
  align = 'left',
  isOpenDefault,
  variant = 'primary',
  onOpen,
  className,
  ...props
}: ExpansionProps) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault)
  const styles = useStyles({ isOpen, variant, align })
  return (
    <div className={classnames(styles.container, className)}>
      <Typography
        variant={variant === 'primary' ? 'h4' : 'subtitle1'}
        component="p"
        onClick={() => {
          setIsOpen(!isOpen)
          onOpen && !isOpen === true && onOpen()
        }}
        className={
          variant === 'primary'
            ? styles.expansionHeaderPrimary
            : styles.expansionHeaderSecondary
        }
      >
        {title}
        {isOpen ? (
          <ExpandLessIcon
            fontSize={variant === 'primary' ? 'large' : 'default'}
          />
        ) : (
          <ExpandMoreIcon
            fontSize={variant === 'primary' ? 'large' : 'default'}
          />
        )}
      </Typography>
      <div className={styles.details}>{isOpen ? children : ''}</div>
    </div>
  )
}
