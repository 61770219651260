// @flow

import React from 'react'
import Navigation from 'src/components/organisms/Navigation'
import Network from 'src/components/organisms/Network'

export default () => {
  return (
    <Navigation pageTitle="My Network">
      <Network initialRelationship="hasFollower" />
    </Navigation>
  )
}
