// @flow

import React, { useState } from 'react'
import { Flex } from 'src/components/molecules/Flex'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { Card } from 'src/components/molecules/Card'
import type { ReduxFormProps } from 'src/utils/types'
import { reduxForm, SubmissionError } from 'redux-form'
import TextField from 'src/components/atoms/TextField'
import { invalidEmail, requiredField } from 'src/utils/fieldValidators'
import Link from 'src/components/atoms/Link'
import Button from 'src/components/atoms/buttons/Button'
import { CTA } from '../../../molecules/CTA'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 700
  },
  subHeader: {
    color: theme.palette.text.label,
    textAlign: 'center'
  },
  loginButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  resetPasswordLink: {
    justifyContent: 'flex-end'
  },
  methodContainer: {
    display: 'flex',
    margin: `${theme.spacing(4)}px 0`,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing(2)
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(4)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px 0`
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  link: {
    display: 'inline',
    textDecoration: 'underline',
    paddingBottom: theme.spacing(3)
  },
  newUserLink: {
    color: theme.palette.text.label,
    textAlign: 'center'
  },
  formErrorMessage: {
    color: theme.palette.error.main
  },
  signupError: {
    display: 'block',
    marginTop: 8,
    maxWidth: 300,
    textAlign: 'center',
    color: theme.palette.error.main
  }
}))

type LoginProps = {
  onEmailSignupSuccess: () => void,
  onEmailSignup: (email: String, password: string) => void,
  onGoogleLogin: () => Promise<void>,
  onFacebookLogin: () => Promise<void>
}

export const Signup = ({
  onEmailSignupSuccess,
  onEmailSignup,
  onGoogleLogin,
  onFacebookLogin
}: LoginProps) => {
  const styles = useStyles()
  const history = useHistory()
  const [providerLoginError, setProviderLoginError] = useState('')

  const onProviderLogin = (login?: () => Promise<void>) => async () => {
    try {
      login && (await login())
      history.replace('/account-select')
    } catch (err) {
      setProviderLoginError(err.message)
    }
  }
  return (
    <Flex container flexDirection="column" alignItems="center" padding="0 16px">
      <CTA
        header="Choose a Sign Up Method"
        subHeader="Connect with Google to sign up quicker."
      />
      <div className={styles.methodContainer}>
        <ReduxSignupForm
          onEmailSignup={onEmailSignup}
          onEmailSignupSuccess={onEmailSignupSuccess}
        />
        <div className={styles.column}>
          <Typography variant="h6">or</Typography>
        </div>
        <div className={styles.column}>
          <Button
            social="google"
            variant="contained"
            onClick={onProviderLogin(onGoogleLogin)}
            size="large"
          >
            Signup with Google
          </Button>

          {providerLoginError && (
            <Typography
              className={styles.signupError}
              variant="subtitle1"
              color="error"
            >
              {providerLoginError}
            </Typography>
          )}
        </div>
      </div>
    </Flex>
  )
}

const SignupForm = ({
  onEmailSignup,
  onEmailSignupSuccess,
  pristine,
  submitting,
  invalid,
  error,
  handleSubmit
}: ReduxFormProps & LoginProps) => {
  const styles = useStyles()

  return (
    <form
      onSubmit={handleSubmit(async ({ email, password }) => {
        try {
          await onEmailSignup(email, password)
          onEmailSignupSuccess()
        } catch (error) {
          console.log(error)
          throw new SubmissionError({
            _error: error?.message
          })
        }
      })}
    >
      <Card
        width={350}
        minHeight={175}
        mode="light"
        variant="form"
        actionLabel="Signup with Email"
        loading={submitting}
        disabled={pristine || submitting || invalid}
      >
        <TextField
          id="email-input"
          name="email"
          label="Email"
          validators={[requiredField, invalidEmail]}
          fullWidth
        />
        <TextField
          type="password"
          id="password-input"
          name="password"
          label="Password"
          validators={[requiredField]}
          fullWidth
        />
        {error && (
          <span className={styles.signupError}>
            <strong>{error}</strong>
          </span>
        )}
      </Card>
      <Typography variant="subtitle1" className={styles.newUserLink}>
        Already have an account?
        <Link to="/login" className={styles.link}>
          Log in here.
        </Link>
      </Typography>
    </form>
  )
}

const ReduxSignupForm = reduxForm({ form: 'signup' })(SignupForm)
