// @flow

import React from 'react'
import InboxTemplate from 'src/components/templates/Inbox'

const InboxPage = () => {
  return <InboxTemplate />
}

export default InboxPage
