// @flow

import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import CardActionArea from '@material-ui/core/CardActionArea'
import Link from 'src/components/atoms/Link'
import { GenericIcon } from 'src/components/atoms/icons/GenericIcon'
import CheckIcon from 'src/components/atoms/icons/Check'
import PaperList, { TransparentList } from 'src/components/molecules/PaperList'
import StatField from 'src/components/atoms/StatField'
import RecCard from 'src/components/molecules/RecCard'
import Typography from '@material-ui/core/Typography'
import type { User, SocialNetworkNames } from 'src/utils/types/users'
import numeral from 'numeral'
import classnames from 'classnames'
/******* ICONS *******/
import Facebook from 'src/images/logos/facebook-icon.png'
import Instagram from 'src/images/logos/instagram-icon.png'
import Twitter from 'src/images/logos/twitter-icon.png'
import Youtube from 'src/images/logos/youtube-icon.png'
import Spotify from 'src/images/logos/spotify-icon.png'
import SoundCloud from 'src/images/logos/soundcloud-icon.png'
import Apple from 'src/images/logos/apple-icon.png'
import Pandora from 'src/images/logos/pandora-icon.png'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {}
  },
  contentBackground: {
    width: '100%',
    minHeight: '70%',
    backgroundColor: theme.palette.background.main,
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1)
  },
  statViews: {
    paddingBottom: theme.spacing(1),
    width: 500,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  flexList: {
    display: 'flex',
    maxWidth: 500
  },
  socialNetworkLink: {
    flex: 1,
    minWidth: 200,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  skillWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(2)
  },
  skillButtonText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  stat: {
    flex: 1,
    minWidth: 200,
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  topChartSongTitle: {
    paddingBottom: 0,
    minWidth: '100%',
    fontWeight: 500
  },
  networkIcon: {
    marginRight: theme.spacing(2)
  },
  publishing: {
    flex: 2
  }
}))

const getNetworkIcon = (network: SocialNetworkNames) => {
  switch (network) {
    case 'Facebook':
      return <GenericIcon ariaLabel={network} img={Facebook} />
    case 'Instagram':
      return <GenericIcon ariaLabel={network} img={Instagram} />
    case 'Twitter':
      return <GenericIcon ariaLabel={network} img={Twitter} />
    case 'Spotify':
      return <GenericIcon ariaLabel={network} img={Spotify} />
    case 'SoundCloud':
      return <GenericIcon ariaLabel={network} img={SoundCloud} />
    case 'Youtube':
      return <GenericIcon ariaLabel={network} img={Youtube} />
    case 'Pandora':
      return <GenericIcon ariaLabel={network} img={Pandora} />
    case 'Apple Music':
      return <GenericIcon ariaLabel={network} img={Apple} />

    default:
      return ''
  }
}

const renderSocialMediaLinks = (user: User, styles: Object) => {
  return (
    user.profile.socialMediaLinks.length && (
      <PaperList title="Social Media">
        {user.profile.socialMediaLinks.map(network => (
          <CardActionArea
            className={styles.socialNetworkLink}
            tabIndex="-1"
            key={network.name}
          >
            <Link
              isExternal
              to={network.url}
              className={styles.socialMediaLink}
            >
              <div className={styles.networkIcon}>
                {getNetworkIcon(network.name)}
              </div>
              <Typography>
                {network.name} <br />
                <Typography variant="caption">{`(${network.followerCount ||
                  '0'} followers)`}</Typography>
              </Typography>
            </Link>
          </CardActionArea>
        ))}
      </PaperList>
    )
  )
}

const renderSkills = (user: User, styles: Object) => {
  return user.stats.skills.map(skill => (
    <CardActionArea className={styles.skillWrapper} key={skill}>
      <CheckIcon />
      <Typography className={styles.skillButtonText}> {skill}</Typography>
    </CardActionArea>
  ))
}

const renderRecommendations = (user: User) => {
  return user.stats.recommendations.map(rec => (
    <RecCard
      key={rec.recommender}
      organization={rec.organization}
      recommender={rec.recommender}
      date={rec.date}
      body={rec.body}
    />
  ))
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const profile = useSelector(state => state.profile, shallowEqual)
  const user: User = profile.user //prefer destructuring but this gives us safe typing of the User
  console.log(user?.stats)
  const styles = useStyles()
  return user?.accountType === 'Songwriter' ? (
    <div className={styles.container}>
      <div className={styles.statViews}>
        {user?.stats.band?.name && (
          <PaperList title="Band">
            <StatField
              fieldTitle="Name"
              fieldValue={user.stats.band.name}
              className={styles.stat}
            />
            <StatField
              fieldTitle="Members"
              fieldValue={user.stats.band.members?.map(member => (
                <Link key={member.name} to={`/profile/${member.id}`}>
                  {member.name}
                </Link>
              ))}
              className={styles.stat}
            />
          </PaperList>
        )}
        {user?.profile.website && (
          <PaperList title="Website">
            <StatField
              fieldTitle="URL"
              fieldValue={
                <Link isExternal to={user.profile.website}>
                  {user.profile.website}
                </Link>
              }
              className={styles.stat}
            />
          </PaperList>
        )}

        {user?.stats.genres && (
          <PaperList title="Genres">
            <StatField
              fieldTitle="Active Genres"
              fieldValue={
                user.stats.genres.length
                  ? user.stats.genres.reduce(
                      (prev, next) => `${prev} | ${next}`
                    )
                  : 'None'
              }
              className={styles.stat}
            />
          </PaperList>
        )}

        {user?.stats.highestStreaming.songTitle &&
          user.stats.highestStreaming.url && (
            <PaperList title="Highest Streaming Song">
              <StatField
                fieldTitle="Song Title"
                fieldValue={
                  <Link isExternal to={user.stats.highestStreaming.url}>
                    {user.stats.highestStreaming.songTitle}
                  </Link>
                }
                className={styles.stat}
              />
              <StatField
                fieldTitle="Stream Count"
                fieldValue={numeral(user.stats.highestStreaming.count).format(
                  '0,0'
                )}
                className={styles.stat}
              />
            </PaperList>
          )}

        {renderSocialMediaLinks(user, styles) || ''}
        {user.stats.skills.length ? (
          <PaperList title="Skills (After Co-Write)">
            {renderSkills(user, styles)}
          </PaperList>
        ) : (
          ''
        )}
        {user.accountLevel !== 'Free' && (
          <>
            <PaperList
              title={`Publishing ${
                user.stats.publishingFlags.available
                  ? '(Available)'
                  : '(Unavailable)'
              }`}
            >
              <StatField
                fieldTitle="current publisher(s)"
                fieldValue={
                  user.stats.currentPublishers.length
                    ? user.stats.currentPublishers.reduce(
                        (prev, current) => `${prev} | ${current}`
                      )
                    : 'None'
                }
                className={styles.stat}
              />
              <StatField
                fieldTitle="previous publisher(s)"
                fieldValue={
                  user.stats.previousPublishers.length
                    ? user.stats.previousPublishers.reduce(
                        (result, pub) => `${result} | ${pub}`
                      )
                    : 'None'
                }
                className={styles.stat}
              />
              <StatField
                fieldTitle="Seeking Publishing Deal?"
                fieldValue={
                  user.stats.publishingFlags.proSeekingDeal ? 'Yes' : 'No'
                }
                className={styles.stat}
              />

              <StatField
                fieldTitle="catalog for sale"
                fieldValue={
                  user.stats.publishingFlags.catalogForSale &&
                  user.stats.hitSongsInCatalog.length
                    ? user.stats.hitSongsInCatalog
                        .map(song => song.songTitle)
                        .reduce((prev, next) => `${prev} | ${next}`)
                    : 'No'
                }
                className={styles.stat}
              />
            </PaperList>
            <PaperList title="Top Chart Songs">
              {user.stats.availableTopChartSongs?.map(song => (
                <>
                  <Typography
                    variant="h5"
                    className={classnames(
                      styles.stat,
                      styles.topChartSongTitle
                    )}
                  >
                    {song.songTitle}
                  </Typography>
                  <StatField
                    fieldTitle="Artist Name"
                    fieldValue={song.artistName}
                    className={styles.stat}
                  />
                  <StatField
                    fieldTitle="Chart Name"
                    fieldValue={song.chartName}
                    className={styles.stat}
                  />
                  <StatField
                    fieldTitle="Chart Rank"
                    fieldValue={song.chartRank || 'n/a'}
                    className={styles.stat}
                  />
                </>
              ))}
            </PaperList>
          </>
        )}

        {user.stats.recommendations.length ? (
          <TransparentList title="Recommendations">
            {renderRecommendations(user)}
          </TransparentList>
        ) : (
          ''
        )}

        <PaperList title="Organizations">
          <StatField
            fieldTitle="Performing Rights"
            fieldValue={user.stats.performingOrgs || 'None'}
            className={styles.stat}
          />
          <StatField
            fieldTitle="Songwriting"
            fieldValue={
              user.stats.songwritingOrgs.length
                ? user.stats.songwritingOrgs.reduce(
                    (result, org) => `${result} | ${org}`
                  )
                : 'None'
            }
            className={styles.stat}
          />
        </PaperList>

        <PaperList title="Performances">
          <StatField
            fieldTitle="Live Performances"
            fieldValue={
              user.stats.livePerformance.length
                ? user.stats.livePerformance.reduce(
                    (result, org) => `${result} | ${org}`
                  )
                : 'None'
            }
            className={styles.stat}
          />
          <StatField
            fieldTitle="Shows Per Year"
            fieldValue={user.stats.showsPerYear || '-'}
            className={styles.stat}
          />
        </PaperList>

        <PaperList title="Co-writing">
          <StatField
            fieldTitle="Frequency (per month)"
            fieldValue={user.stats.cowriting.frequency}
            className={styles.stat}
          />
          <StatField
            fieldTitle="Experience"
            fieldValue={user.stats.cowriting.experience}
            className={styles.stat}
          />
        </PaperList>
        <PaperList title="Education">
          <StatField
            fieldTitle="education type"
            fieldValue={user.stats.education.educationType}
            className={styles.stat}
          />
          <StatField
            fieldTitle="school"
            fieldValue={
              user.stats.education.school
                ? `${user.stats.education.school} (${user.stats.education.year})`
                : 'None'
            }
            className={styles.stat}
          />
        </PaperList>
      </div>
    </div>
  ) : (
    <div className={styles.contentBackground}>
      <div className={styles.statViews}>
        <PaperList title="General Info">
          <StatField
            fieldTitle="Company Name"
            fieldValue={user.businessVerification?.businessName}
            className={styles.stat}
          />
          <StatField
            fieldTitle="Website"
            fieldValue={
              user.profile.website ? (
                <Link isExternal to={user.profile.website}>
                  {user.profile.website}
                </Link>
              ) : (
                'None'
              )
            }
            className={styles.stat}
          />
          <StatField
            fieldTitle="Professional Title"
            fieldValue={
              user.stats.occupation.length
                ? user.stats.occupation.reduce(
                    (prev, cur) => `${prev} | ${cur}`
                  )
                : 'n/a'
            }
            className={styles.stat}
          />
        </PaperList>
        <PaperList title="Highlights">
          <StatField
            fieldTitle="Songwriter"
            fieldValue={user.businessVerification?.songwriterName}
            className={styles.stat}
          />
          <StatField
            fieldTitle="Song Title"
            fieldValue={
              user?.businessVerification?.songUrl ? (
                <Link isExternal to={user?.businessVerification?.songUrl || ''}>
                  {user.businessVerification?.songTitle}
                </Link>
              ) : (
                user.businessVerification?.songTitle
              )
            }
            className={styles.stat}
          />
          <StatField
            fieldTitle="Year Released"
            fieldValue={user.businessVerification?.songYear}
            className={styles.stat}
          />

          {user.businessVerification?.film && (
            <StatField
              fieldTitle="Film Title"
              fieldValue={user.businessVerification?.film}
              className={styles.stat}
            />
          )}
        </PaperList>
      </div>
    </div>
  )
}
