// @flow

import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { history } from 'react-router-util'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from 'src/state/reducers'
import type { State, ReduxAction } from 'src/utils/types'

const composeEnhancers = composeWithDevTools({ trace: true })

const middlewares = [thunk, routerMiddleware(history)]

export default createStore<State, ReduxAction, *>(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
)
