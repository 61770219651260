// @flow
import axios from 'src/settings/axios'
import type { Song } from 'src/utils/types/music'

/******************* NEW SONGS API ***************************/

export type GetSongsRequest = { authorId: string }
export type GetSongsResponse = { data: Song }

export type GetSongsService = GetSongsRequest => Promise<GetSongsResponse>

export const getSongs: GetSongsService = data =>
  axios.get('/music/songs', { params: data })

export type DeleteSongsRequest = { id: string }
export type DeleteSongsResponse = { data: Song }

export type DeleteSongsService = DeleteSongsRequest => Promise<DeleteSongsResponse>

export const deleteSongs: DeleteSongsService = data =>
  axios.delete('/music/songs', { params: data })

export type SetSongRequest = { songs: Song[], deletedSongIds: string[] }

export type SetSongsService = SetSongRequest => Promise<{ data: Song[] }>

export const setSongs: SetSongsService = data =>
  axios.post('/music/songs', data)
