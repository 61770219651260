// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { fade } from '@material-ui/core/styles/colorManipulator'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const getMaxColumns = (isTablet: boolean, isMobile: boolean) => {
  if (isMobile) return 2
  if (isTablet) return 5
  return 7
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    top: 0,
    width: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  row: {
    cursor: 'pointer',
    transition: 'background-color 0.1s',
    '&:nth-child(even)': {
      backgroundColor: theme.palette.background.main
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.1)
    },
    '&:active': {
      backgroundColor: fade(theme.palette.primary.main, 0.4)
    },
    '&:focus': {
      backgroundColor: fade(theme.palette.primary.main, 0.4)
    },
    borderRadius: 5
  },
  column: {
    color: theme.palette.menu.text,
    whiteSpace: 'nowrap'
  },
  placeHolderAnim: {
    animationName: '$fadeAnimation',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate'
  },
  '@keyframes fadeAnimation': {
    from: {
      backgroundColor: fade(theme.palette.menu.text, 0.8)
    },
    to: {
      backgroundColor: fade(theme.palette.menu.text, 0.3)
    }
  },
  colPlaceholder: {
    height: '10px',
    width: '100px',
    borderRadius: 2
  },
  rowPlaceholder: {
    height: '10px',
    paddingRight: '100px',
    borderRadius: 2
  },
  profilePlaceholder: {
    width: 55,
    height: 55,
    borderRadius: 55
  },
  cell: {
    border: 'none',
    outline: 'none',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  imgCell: {
    paddingRight: theme.spacing(1)
  }
}))

const columnPlaceholders = (styles: Object) => {
  const cols = []
  for (let i = 0; i < 7; i++) {
    cols.push(
      <TableCell
        key={`${i}-col-placeholder`}
        align={i === 1 ? 'right' : 'left'}
        classes={{
          root: i === 0 ? `${styles.imgCell} ${styles.cell}` : styles.cell
        }}
      >
        {i > 0 && (
          <div
            className={`${styles.colPlaceholder} ${styles.placeHolderAnim}`}
          />
        )}
      </TableCell>
    )
  }
  return cols
}

const dataPlaceholders = (styles: Object) => {
  const data = []
  for (let i = 0; i < 7; i++) {
    if (i === 0) {
      data.push(
        <TableCell
          key={`${i}-row-data-placeholder`}
          align={i === 1 ? 'right' : 'left'}
          classes={{
            root: i === 0 ? `${styles.imgCell} ${styles.cell}` : styles.cell
          }}
        >
          <div
            className={`${styles.profilePlaceholder} ${styles.placeHolderAnim}`}
          />
        </TableCell>
      )
    } else {
      data.push(
        <TableCell
          key={`${i}-row-data-placeholder`}
          align={i === 1 ? 'right' : 'left'}
          classes={{
            root: i === 0 ? `${styles.imgCell} ${styles.cell}` : styles.cell
          }}
        >
          <div
            className={`${styles.rowPlaceholder} ${styles.placeHolderAnim}`}
          />
        </TableCell>
      )
    }
  }
  return data
}

const rowPlaceholders = (styles: Object) => {
  const rows = []
  for (let i = 0; i < 5; i++) {
    rows.push(
      <TableRow key={`${i}-row-placeholder`} align={i === 1 ? 'right' : 'left'}>
        {i > 0 && dataPlaceholders(styles)}
      </TableRow>
    )
  }
  return rows
}

const createData = (row, styles, maxColumns) =>
  row
    .map((data, index) => (
      <TableCell
        key={`${data.value}-${index}`}
        align={data.align}
        classes={{
          root: index === 0 ? `${styles.imgCell} ${styles.cell}` : styles.cell
        }}
      >
        {typeof data.value === 'function' ? data.value() : data.value}
      </TableCell>
    ))
    .slice(0, maxColumns)

type Column = {
  value: string,
  align: string
}

type TableProps = {
  columns: Column[],
  rows: any[][],
  rowIds?: string[],
  isLoading?: boolean,
  onClick: string => void
}

export default ({
  columns,
  rows,
  rowIds = [],
  isLoading,
  onClick
}: TableProps) => {
  const styles = useStyles()

  const isTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const maxColumns = getMaxColumns(isTablet, isMobile)

  const Columns =
    columns.length &&
    columns
      .map((col, index) => (
        <TableCell
          key={col.value}
          align={col.align}
          classes={{ root: styles.cell }}
          className={styles.column}
        >
          {col.value}
        </TableCell>
      ))
      .slice(0, maxColumns)

  const Rows =
    rows.length &&
    rows.map((row, index) => (
      <TableRow
        key={index + '-row'}
        classes={{ root: styles.row }}
        onClick={() => onClick && rowIds.length && onClick(rowIds[index])}
        onKeyPress={e => e.key === 'Enter' && onClick && onClick(rowIds[index])}
        tabIndex={0}
        role="button"
      >
        {createData(row, styles, maxColumns)}
      </TableRow>
    ))

  return (
    <div className={styles.container}>
      <Table>
        <TableHead>
          <TableRow>
            {isLoading ? columnPlaceholders(styles) : Columns}
          </TableRow>
        </TableHead>
        <TableBody>{isLoading ? rowPlaceholders(styles) : Rows}</TableBody>
      </Table>
    </div>
  )
}
