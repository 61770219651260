// @flow
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '90%',
    margin: 'auto'
  },
  header: {
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  price: {
    // fontWeight: 'bold',
    color: theme.palette.text.label,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1rem',
      marginTop: theme.spacing(1)
    }
  },
  monthlyPrice: {
    fontWeight: 'light',
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.text.label,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  emphasizedText: {
    color: '#000'
  },
  tabsWrapper: {
    width: '60%',
    maxWidth: 600,
    minWidth: 300,
    margin: 'auto',
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4)
    }
  },
  featureList: {
    display: 'flex',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(3)
    }
  },
  upgradeButtonText: {
    marginRight: theme.spacing(2)
  },
  upgradeButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2)
    }
  }
}))
