// @flow
import axios from 'src/settings/axios'
import type {
  AccountType,
  BusinessVerification,
  User
} from 'src/utils/types/users'
import formatFormData from 'src/utils/api/formatFormData'
import type { Filters } from 'src/utils/types/network'

export type CreateAccountRequest = {
  email: string,
  firstname: string,
  lastname: string,
  accountType: AccountType,
  businessVerification: BusinessVerification,
  password: string
}

type CreateAccountResponse = {
  data: User
}

export type CreateAccountService = CreateAccountRequest => Promise<CreateAccountResponse>

export const createAccount: CreateAccountService = data =>
  axios.post('/users', data)

type FilterUsersRequest = Filters & {
  resultIndex: number
}

type FilterUsersResponse = { data: User[] }

export type FilterUsersService = FilterUsersRequest => Promise<FilterUsersResponse>

export const filterUsers: FilterUsersService = data =>
  axios.post('/users/filter', data)

type GetMeResponse = {
  data: {
    user: User,
    isConnectionPending: boolean,
    isAwaitingConfirmation: boolean
  }
}

export type GetMeService = () => Promise<GetMeResponse>

export const getMe: GetMeService = data => axios.get('/users/me')

type GetUserResponse = {
  data: {
    user: User,
    isConnectionPending: boolean,
    isAwaitingConfirmation: boolean
  }
}

export type GetUserService = ({ id: string }) => Promise<GetUserResponse>

export const getUser: GetUserService = data =>
  axios.get('/users', {
    params: data
  })

type UpdateUserResponse = { data: User }

export type UpdateUserService = ({ id: string }) => Promise<UpdateUserResponse>

export const updateUser: UpdateUserService = data => axios.patch('/users', data)

export type ImageProps = {
  name: string,
  type: string,
  size: number,
  lastMod: number,
  lastModDate: string,
  img: string
}

type UploadProfileImageResponse = { data: User }

export type UploadProfileImageService = ({
  profileImage: ImageProps
}) => Promise<UploadProfileImageResponse>

export const uploadProfileImage: UploadProfileImageService = data =>
  axios.post('/users/profileImage', formatFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

type SearchUserRequest = { searchString: string }

type SearchUserResponse = { data: User }

export type SearchUserService = SearchUserRequest => Promise<SearchUserResponse>

export const searchUsers: SearchUserService = data =>
  axios.get('/users/search', {
    params: { ...data }
  })

export type DeleteAccountService = () => Promise<{}>

export const deleteAccount: DeleteAccountService = data =>
  axios.delete('/users')

export type UpgradeAccountService = () => Promise<{}>

export const upgradeAccount: UpgradeAccountService = data =>
  axios.post('/users/upgrade')

export type DowngradeAccountService = () => Promise<{}>

export const downgradeAccount: DowngradeAccountService = data =>
  axios.post('/users/downgrade')