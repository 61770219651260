// @flow
import React, { useState, useEffect } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const useStyles = makeStyles(theme => ({
  root: {
    width: '40%',
    padding: '0px 30px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '0px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      padding: '0px 10px',
    }
  },
  indicatorEl: {
    border: '2px solid #333',
    borderRadius: '23px',
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0
  },
  modalHeader: {
    width: '100%',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalActions: {
    bottom: 0,
    width: '100%',
    height: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => (props.justify ? props.justify : 'end')
  },
  modalActionsChildren: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  closeIcon: {
    marginLeft: 15
  },
  cancelButton: {
    height: 30
  },
  paper: {
    width: 700,
    height: 600,
    position: 'relative',
    outline: 0,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      height: '95%'
    }
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  typo: {
    color: '#fff',
    fontWeight: 'bold'
  },
  icons: {
    color: '#fff'
  }
}))

type ModalHeaderProps = {
  className?: string,
  children?: any,
  onClose: () => void
}

export const ModalHeader = ({
  className,
  children,
  onClose,
  ...props
}: ModalHeaderProps) => {
  const styles = useStyles()

  return (
    <div className={styles.modalHeader} {...props}>
      <h3>
        <strong>{children}</strong>
      </h3>
    </div>
  )
}

type ModalContentProps = {
  className?: string,
  children?: any
}

export const ModalContent = ({
  className,
  children,
  ...props
}: ModalContentProps) => {
  const styles = useStyles()

  return (
    <div className={styles.modalContent} {...props}>
      {children}
    </div>
  )
}

type ModalActionsProps = {
  className?: string,
  children?: any,
  justify?: string,
  onCancel?: () => void
}

export const ModalActions = ({
  className,
  children,
  onCancel,
  justify = 'flex-end',
  ...props
}: ModalActionsProps) => {
  const styles = useStyles({ justify })

  return (
    <div className={styles.modalActions} {...props}>
      <Button
        aria-label="cancel modal action"
        className={styles.cancelButton}
        size="small"
        onClick={onCancel}
      >
        Dismiss
      </Button>
      <Button
        aria-label="cancel modal action"
        className={styles.cancelButton}
        onClick={onCancel}
        variant="contained"
        color="primary"
        size="small"
      >
        ACTION LABEL
      </Button>
    </div>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ possibleActionsList, open, setOpen }) => {
  const styles = useStyles()
  const [isOpen, setIsOpen] = useState(open)
  useEffect(() => {
    setIsOpen(open)
  }, [open, setIsOpen])

  const handleCloseProfile = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    let currentDate = new Date()
    localStorage.setItem('openedSnackbar', currentDate)
    setIsOpen(false)
  }

  const handleLinkTo = url => {
    let currentDate = new Date()
    localStorage.setItem('openedSnackbar', currentDate)
    setIsOpen(false)
    window.location.href = url
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => setOpen(false)}
      BackdropComponent={Backdrop}
      className={styles.modal}
      BackdropProps={{
        timeout: 300
      }}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Card className={styles.root}>
          <CardContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <div className={styles.indicatorEl}>
                {console.log(possibleActionsList)}
                {`${Math.floor(
                  (possibleActionsList.filter(option => option.isDone).length *
                    100) /
                    3
                )}%`}
              </div>
              <h3>Profile Checklist</h3>
            </div>
            {possibleActionsList.map(option => {
              return (
                <button
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                    background: option.isDone ? 'green' : '#0B1C4D',
                    textDecoration: option.isDone && 'line-through',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    width: '100%',
                    border: 'unset'
                  }}
                  onClick={() => handleLinkTo(option.linkTo)}
                >
                  <Typography className={styles.typo}>
                    {option.description}
                  </Typography>
                  {option.isDone ? (
                    <DoneIcon className={styles.icons} />
                  ) : (
                    <ArrowForwardIcon className={styles.icons} />
                  )}
                </button>
              )
            })}
          </CardContent>
          <CardActions className={styles.actionsWrapper}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Button size="small" onClick={handleCloseProfile}>
                Close
              </Button>
            </div>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  )
}
