// @flow
import React from 'react'
import { Flex } from 'src/components/molecules/Flex'
import { makeStyles } from '@material-ui/styles'
import Link from 'src/components/atoms/Link'
// $FlowFixMe
import { ReactComponent as Logo } from 'src/images/logos/logo-with-text.svg'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Button from 'src/components/atoms/buttons/Button'

const useStyles = makeStyles(theme => ({
  authHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    width: '100%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    }
  },
  link: {
    color: theme.palette.menu.main,
    padding: `0px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: `0px ${theme.spacing(2)}px`
    }
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      width: 120
    }
  }
}))

type AuthHeaderProps = {
  logout: () => void,
  loggedIn?: boolean
}

export const AuthHeader = ({ logout, loggedIn }: AuthHeaderProps) => {
  const styles = useStyles()
  const theme = useTheme()
  const isXS = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div className={styles.authHeader}>
      <Link to="/welcome">
        <Logo className={styles.logo} />
      </Link>
      <Flex container>
        {!isXS && (
          <>
            <Link isExternal to="https://www.discoversooner.com/songwriters">
              <Typography className={styles.link} variant="h6">
                Features &amp; Pricing
              </Typography>
            </Link>
            <Link isExternal to="https://www.discoversooner.com/help">
              <Typography className={styles.link} variant="h6">
                Help
              </Typography>
            </Link>
          </>
        )}

        {loggedIn && (
          <Button variant="outlined" color="primary" onClick={logout}>
            <Typography className={styles.link} variant="h6">
              Logout
            </Typography>
          </Button>
        )}
      </Flex>
    </div>
  )
}
