// @flow
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import DeleteThreadDialog from 'src/components/molecules/dialogs/DeleteThreadDialog'
import moment from 'moment'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import type { Thread } from 'src/utils/types/inbox'
import printThreadMembers from 'src/utils/printThreadMembers'
import getImage from 'src/utils/images/getImage'
import { ConfigContext } from 'src/components/hoc/RouteWithConfig'
import type { Config } from 'src/utils/types/config'
import type { User } from 'src/utils/types/users'

//$FlowFixMe
import { ReactComponent as DeleteIcon } from 'src/images/deleteIcon.svg'
import { useAuth } from '../../../hooks/useAuth'

const getDragTarget = ({ isDragging, deltaPos, open }) => {
  let offset = -deltaPos

  if (isDragging) {
    if (offset > 0) {
      return offset < 65 ? offset : 65
    }
    if (offset < 0) {
      const offsetRight = 65 + offset
      if (open) {
        return offsetRight > 0 ? offsetRight : 0
      }
    }
    return 0
  }
  if (open) {
    return 65
  }
  return 0
}

const useStyles = makeStyles(theme => ({
  messageContainer: {
    display: 'flex',
    width: '100%',
    height: 100,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    cursor: 'pointer'
  },

  messageContent: {
    position: 'relative',
    zIndex: 2,
    transition: props =>
      props.dragProps.isDragging ? 'none' : 'transform 0.2s',
    transform: props => `translateX(-${getDragTarget(props.dragProps)}px)`,
    backgroundColor: theme.palette.background.contrast,
    minWidth: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.down('sm')]: {
      cursor: 'grab'
    }
  },
  messageDetails: {
    flex: 4,
    minWidth: '80%',
    height: 65,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      height: 50
    }
  },
  userImage: {
    flex: 1,
    width: 60,
    height: 60,
    marginRight: theme.spacing(2),
    backgroundColor: 'grey',
    backgroundImage: props => `url(${props.threadImage})`,
    backgroundSize: 'cover',
    borderRadius: 75
  },
  recipientAndTime: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1
  },
  recipient: {
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.main,
    flex: 4
  },
  timestamp: {
    color: props =>
      props.hasUnreadMessages
        ? theme.palette.text.main
        : theme.palette.text.label,
    fontWeight: props => (props.hasUnreadMessages ? 700 : 300)
  },
  messagePreview: {
    fontWeight: props => (props.hasUnreadMessages ? 700 : 300),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.main
  },
  actionButtons: {
    position: 'absolute',
    display: 'flex',
    zIndex: 1,
    right: 0,
    flex: 1,
    minWidth: 65,
    height: 99
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    width: 65,
    height: '100%'
  },
  profileLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    width: 65,
    height: '100%'
  },
  link: {
    width: '100%',
    height: '100%'
  }
}))

type ThreadProps = {
  thread: Thread,
  hasUnreadMessages?: boolean,
  active?: boolean,
  onClick?: string => void,
  onDelete: string => void,
  isLoading?: boolean,
  isDeleting?: boolean
}

const getThreadImage = (currentUser: ?User, thread: Thread, config: Config) => {
  if (currentUser) {
    const nonUserMember = thread.members.find(
      member => member.userId !== currentUser._id
    )
    if (nonUserMember) {
      return getImage('profileImage', nonUserMember.userId, config)
    }
  }
  return ''
}

const ThreadComponent = ({
  thread,
  active,
  onClick,
  onDelete,
  hasUnreadMessages,
  isLoading,
  isDeleting
}: ThreadProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { user } = useAuth()

  const { _id, timestamp, messagePreview } = thread

  const [dragProps, setDragProps] = useState({
    isDragging: false,
    startPos: 0,
    deltaPos: 0,
    open: false
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const config = useContext(ConfigContext)

  const styles = useStyles({
    threadImage: getThreadImage(user, thread, config),
    hasUnreadMessages,
    active,
    dragProps,
    isLoading
  })

  useEffect(() => {
    if (!isMobile) {
      setDragProps({
        ...dragProps,
        isDragging: false,
        open: active
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  const touchStart = e => {
    setDragProps({
      ...dragProps,
      isDragging: true,
      startPos: e.touches[0].clientX,
      deltaPos: dragProps.open ? -65 : 0
    })
  }

  const touchEnd = e => {
    let open
    if (dragProps.deltaPos < -32) {
      open = true
    } else {
      open = false
    }
    setDragProps({
      ...dragProps,
      open,
      isDragging: false
    })
  }

  const touchMove = e => {
    const deltaPos = e.touches[0].clientX - dragProps.startPos
    setDragProps({
      ...dragProps,
      deltaPos
    })
  }

  const mouseDown = e => {
    if (isMobile) {
      setDragProps({
        ...dragProps,
        isDragging: true,
        startPos: e.screenX,
        deltaPos: dragProps.open ? -65 : 0
      })
    }
  }

  const mouseMove = e => {
    if (isMobile) {
      if (dragProps.isDragging) {
        const deltaPos = e.screenX - dragProps.startPos
        setDragProps({
          ...dragProps,
          deltaPos
        })
      }
    }
  }

  const mouseUp = e => {
    if (isMobile) {
      let open
      if (dragProps.deltaPos < -65) {
        open = true
      } else {
        open = false
      }
      setDragProps({
        ...dragProps,
        isDragging: false,
        open
      })
    }
  }

  const _onClick = e => {
    // make sure we don't mistake a drag for a click
    dragProps.deltaPos === 0 && onClick && onClick(_id)
  }

  return (
    <div className={styles.messageContainer}>
      <CardActionArea
        className={styles.messageContent}
        onClick={_onClick}
        onTouchStart={touchStart}
        onTouchEnd={touchEnd}
        onTouchMove={touchMove}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        onMouseMove={mouseMove}
      >
        <div className={styles.userImageContainer}>
          <div className={styles.userImage} />
        </div>
        <div className={styles.messageDetails}>
          <div className={styles.recipientAndTime}>
            <Typography variant="h6" className={styles.recipient}>
              {thread &&
                thread.members.length &&
                printThreadMembers(user, thread.members)}
            </Typography>
            <Typography variant="subtitle2" className={styles.timestamp}>
              {moment(Number(timestamp)).fromNow()}
            </Typography>
          </div>
          <Typography variant="subtitle2" className={styles.messagePreview}>
            {messagePreview}
          </Typography>
        </div>
      </CardActionArea>

      <div className={styles.actionButtons}>
        <CardActionArea
          className={styles.deleteButton}
          tabIndex={dragProps.open ? '0' : '-1'}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <DeleteIcon className={styles.actionIcon} />
        </CardActionArea>
      </div>
      <DeleteThreadDialog
        threadId={thread._id}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
        isDeleting={isDeleting}
      />
    </div>
  )
}

export default ThreadComponent
