// @flow

import React from 'react'
import EditProfileTemplate from 'src/components/templates/EditProfile'

const ProfileImage = () => {
  return <EditProfileTemplate />
}

export default ProfileImage
