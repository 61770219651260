// @flow
import axios from 'src/settings/axios'
import type { Notification } from 'src/utils/types/notifications'

export type GetNotificationResponse = {
  data: Notification[]
}

export type GetNotificationService = ({
  index: number
}) => Promise<GetNotificationResponse>

export const getNotifications: GetNotificationService = params =>
  axios.get('/notifications', { params })

export type MarkReadResponse = {
  data: Notification[]
}

export type MarkReadService = ({
  notificationId: string
}) => Promise<MarkReadResponse>

export const markRead: MarkReadService = data =>
  axios.post('/notifications/mark-read', data)
