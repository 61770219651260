// @flow
import React, { useState, useRef, useEffect } from 'react'
import { batch, useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { push } from 'react-router-redux'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { reduxForm, SubmissionError } from 'redux-form'
import SplashForm from 'src/components/organisms/forms/SplashForm'
import PostType from 'src/components/organisms/forms/PostType'
import AddSplashContent from 'src/components/organisms/forms/AddSplashContent'
import { createSplashAction } from 'src/state/actions/splashes'
import { getSplashImageAction } from 'src/state/actions/splashes'
import type { ReduxFormProps } from 'src/utils/types'

import type { Splash } from 'src/utils/types/splashes'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { useAuth } from '../../../../hooks/useAuth'

const FORM_ID = 'createPost'

const useStyles = makeStyles(theme => ({
  splashFormContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 65px)'
  }
}))

type CreatePostFormProps = {
  isOpen?: boolean,
  splash?: Splash
}

export const CreatePostForm = ({
  pristine,
  submitting,
  invalid,
  error,
  isOpen,
  splash,
  handleSubmit,
  ...props
}: CreatePostFormProps & ReduxFormProps) => {
  const styles = useStyles()
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { analytics } = useAnalytics()
  const [isContentDialogOpen, setIsContentDialogOpen] = useState(false)
  const [isPostTypeDialogOpen, setIsPostTypeDialogOpen] = useState(false)

  const { user } = useAuth()

  useEffect(() => {
    //once we load a splash, fill form with existing data
    if (splash) {
      batch(() => {
        dispatch(
          getSplashImageAction({
            url: splash.coverImage,
            formId: FORM_ID,
            imageName: `${splash._id}_image`
          })
        )
        dispatch(change(FORM_ID, 'title', splash.title))
        dispatch(change(FORM_ID, 'body', splash.body))
        dispatch(change(FORM_ID, 'contentType', splash.content.contentType))
        dispatch(change(FORM_ID, 'contentUrl', splash.content.url))
        dispatch(change(FORM_ID, 'postType', splash.postType))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splash])

  return (
    user && (
      <div className={styles.splashFormContainer}>
        <form
          id="create-post-form"
          ref={formRef}
          onSubmit={handleSubmit(
            async ({
              title,
              body,
              postType,
              contentType,
              contentUrl,
              coverImage
            }) => {
              try {
                await dispatch(
                  createSplashAction({
                    id: splash ? splash._id : '',
                    title,
                    body,
                    postType,
                    contentType,
                    contentUrl:
                      contentType === 'YOUTUBE'
                        ? sanitizeYoutubeLink(contentUrl)
                        : contentType === 'MUSIC'
                        ? sanitizeSoundCloudLink(contentUrl)
                        : contentUrl,
                    coverImage
                  })
                )
                analytics.logEvent('splash_created')

                setIsPostTypeDialogOpen(false)
                dispatch(push(`/profile/${user._id}`))
              } catch (errors) {
                setIsPostTypeDialogOpen(false)
                throw new SubmissionError(errors)
              }
            }
          )}
        >
          <SplashForm
            formId={FORM_ID}
            disabled={pristine || error || invalid}
            onOpenContentDialog={() => setIsContentDialogOpen(true)}
            onNext={() => setIsPostTypeDialogOpen(true)}
          />
          <AddSplashContent
            formId={FORM_ID}
            isOpen={isContentDialogOpen}
            onClose={() => setIsContentDialogOpen(false)}
            submitting={submitting}
            error={error}
            disabled={pristine || error}
          />
          <PostType
            formId={FORM_ID}
            isOpen={isPostTypeDialogOpen}
            submitting={submitting}
            postUsage={user && user.postUsage}
            onClose={() => setIsPostTypeDialogOpen(false)}
          />
          <Typography variant="subtitle1">{error}</Typography>
        </form>
      </div>
    )
  )
}

const sanitizeYoutubeLink = (link?: string) => {
  let editedLink = link
  if (
    editedLink &&
    editedLink.includes('youtu.be') &&
    !editedLink.includes('/watch?v=')
  ) {
    editedLink = editedLink.replace('youtu.be', 'youtube.com')
    editedLink = editedLink.replace('/', '/watch?v=')
  }
  if (editedLink && editedLink.includes('/watch?v=')) {
    // if we have query vars in the link, remove them
    return editedLink.replace('/watch?v=', '/embed/').split('&')[0]
  }
  return editedLink?.split('&')[0]
}

const sanitizeSoundCloudLink = (link?: string) => {
  if (link && link.includes('soundcloud') && link.includes('iframe')) {
    let cutLink = link.split('&color')[0]
    cutLink = cutLink.split('url=')[1]
    return cutLink
  }
  return link
}

export default reduxForm({
  form: FORM_ID,
  initialValues: {
    postType: 'SPLASH'
  }
})(CreatePostForm)
