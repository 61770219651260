// @flow

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 700,
    textAlign: 'center'
  },
  subHeader: {
    color: theme.palette.text.label,
    textAlign: 'center'
  }
}))

type CTAProps = {
  header: string,
  subHeader: string
}

export const CTA = ({ header, subHeader }: CTAProps) => {
  const styles = useStyles()

  return (
    <>
      <Typography variant="h3" color="textPrimary" className={styles.header}>
        {header}
      </Typography>
      <Typography variant="h5" className={styles.subHeader}>
        {subHeader}
      </Typography>
    </>
  )
}
