// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  topText: {
    marginBottom: theme.spacing(0.75)
  },
  bottomText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  title: {
    color: theme.palette.text.label,
    whiteSpace: 'nowrap',
    fontSize: '0.875rem'
  },

  value: {
    fontWeight: 'bold'
  }
}))

type StatFieldProps = {
  fieldTitle: string,
  fieldValue?: any,
  invert?: boolean
}

export default ({
  fieldTitle,
  fieldValue,
  invert,
  ...props
}: StatFieldProps) => {
  const styles = useStyles()
  return (
    <div {...props}>
      <Typography
        variant="button"
        component="p"
        className={classNames(
          invert ? styles.value : styles.title,
          styles.topText
        )}
      >
        {fieldTitle}
      </Typography>
      <Typography
        variant="subtitle2"
        className={classNames(
          invert ? styles.title : styles.value,
          styles.bottomText
        )}
      >
        {fieldValue}
      </Typography>
    </div>
  )
}
