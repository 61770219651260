// @flow

import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch, batch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { push } from 'react-router-redux'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Button from 'src/components/atoms/buttons/Button'
import { getUserProfileContentAction } from 'src/state/actions/profile'
import { clapAction, deleteSplashAction } from 'src/state/actions/splashes'
import HorizontalList from 'src/components/molecules/HorizontalList'
import SplashCard from 'src/components/molecules/SplashCard'
import DeleteSplashDialog from 'src/components/molecules/dialogs/DeleteSplashDialog'
import type { Splash } from 'src/utils/types/splashes'
import { useAuth } from '../../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  emptyContentMessage: {
    color: theme.palette.text.label
  },
  createSplashButton: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(4)
  }
}))

// const renderVideos = (videos: Content[]) =>
//   videos
//     .reverse()
//     .map(video => (
//       <VideoCard
//         key={video.title}
//         title={video.title}
//         thumbnail="https://tinyurl.com/y4vlv7sf"
//       />
//     ))

const renderSplashes = (splashes: Splash[], onClap, onSplashDelete) =>
  splashes
    .sort((a, b) => b.creationDate - a.creationDate)
    .map(splash => (
      <SplashCard
        isProfile
        key={splash._id}
        splash={splash}
        onClap={onClap}
        onDelete={onSplashDelete}
      />
    ))

export default () => {
  const [splashId, setSplashId] = useState('')
  const { splashList: splashes, isDeletingSplash } = useSelector(
    state => state.splashes,
    shallowEqual
  )
  const dispatch = useDispatch()
  const { id } = useParams()
  const styles = useStyles()
  const { user: currentUser } = useAuth()

  useEffect(() => {
    batch(() => {
      if (id) {
        dispatch(getUserProfileContentAction({ id }))
      }
    })
  }, [dispatch, id])

  const onClap = (count: number, id: string) => {
    dispatch(clapAction({ count, splashId: id }))
  }

  const onSplashDelete = (id: string) => {
    setSplashId(id)
  }

  return (
    <React.Fragment>
      <div style={{}}>
        {id === currentUser?._id && (
          <Button
            className={styles.createSplashButton}
            variant="contained"
            color="secondary"
            onClick={() => dispatch(push('/post'))}
          >
            ADD SPLASH +
          </Button>
        )}
      </div>
      <HorizontalList empty={!splashes || !splashes.length}>
        {splashes && splashes.length ? (
          renderSplashes(splashes, onClap, onSplashDelete)
        ) : (
          <Typography variant="h6" className={styles.emptyContentMessage}>
            No Splashes Available
          </Typography>
        )}
      </HorizontalList>
      <DeleteSplashDialog
        isOpen={!!splashId}
        isDeleting={isDeletingSplash}
        splashId={splashId}
        onClose={() => setSplashId('')}
        onDelete={async () => {
          await dispatch(deleteSplashAction({ splashId }))
        }}
      />
      {/* <HorizontalList title="Videos" empty={!videos || !videos.length}>
        {videos && videos.length ? (
          renderVideos(videos)
        ) : (
          <Typography variant="h6" className={styles.emptyContentMessage}>
            No Videos Available
          </Typography>
        )}
      </HorizontalList> */}
    </React.Fragment>
  )
}
