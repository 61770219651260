// @flow
import axios from 'src/settings/axios'
import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Navigation from 'src/components/organisms/Navigation'
import Typography from '@material-ui/core/Typography'
import FeatureGroup from 'src/components/molecules/FeatureGroup'
import RateReviewIcon from '@material-ui/icons/RateReview'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PaymentIcon from '@material-ui/icons/Payment'
import Button from 'src/components/atoms/buttons/Button'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import PaymentForm from 'src/components/organisms/forms/ReferralForm'
import { Elements, StripeProvider } from 'react-stripe-elements'
import env from 'src/settings/env'
import { makeStyles } from '@material-ui/styles'
import type { State } from 'src/utils/types'
import type { PaymentState } from 'src/state/reducers/payments'
import { useParams } from 'react-router-dom'
import { getPaymentRecordAction } from 'src/state/actions/payments'
import { Flex } from 'src/components/molecules/Flex'
import Link from 'src/components/atoms/Link'
import { useAuth } from '../../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '90%',
    margin: 'auto'
  },
  header: {
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  price: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: theme.palette.text.label,
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      textAlign: 'center',
      fontSize: '1rem',
      marginTop: theme.spacing(1)
    }
  },
  emphasizedText: {
    color: theme.palette.primary.main
  },
  detailsLink: {
    color: theme.palette.text.label
  },
  tabsWrapper: {
    width: '60%',
    maxWidth: 600,
    minWidth: 300,
    margin: 'auto',
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4)
    }
  },
  featureList: {
    display: 'flex',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(3)
    }
  },
  upgradeButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    fontSize: 16,
    maxHeight: 'auto'
  },
  upgradeButtonText: {
    marginRight: theme.spacing(2)
  },
  upgradeButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2)
    }
  }
}))

const selectState: State => PaymentState = state => state.payments

export default () => {
  const styles = useStyles()
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const state: PaymentState = useSelector(selectState, shallowEqual)
  const [monthlyPrice, setMonthlyPrice] = useState("13")
  const [yearlyPrice, setYearlyPrice] = useState("10")
  const dispatch = useDispatch()
  const { result } = useParams()
  const { user } = useAuth()

  useEffect(() => {
    dispatch(getPaymentRecordAction({ user }))
    if (result === 'success') {
      setIsPaymentModalOpen(true)
    }

    try {
      axios.get('/payments/prices').then(({ data }) => {
        setMonthlyPrice((data.monthly.unit_amount / 100).toFixed(2).replace('.00', ''))
        setYearlyPrice((data.yearly.unit_amount / 1200).toFixed(2).replace('.00', ''))
      })
    } catch (e) {
      console.log('e=>', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = () => {
    setIsPaymentModalOpen(true)
  }

  return (
    <Navigation pageTitle="Upgrade">
      <div className={styles.wrapper}>
        <Typography variant="h2" component="h1" className={styles.header}>
          Request a <span className={styles.emphasizedText}>Referral!</span>
        </Typography>
        <Link
          sameTab
          isExternal
          to="https://www.discoversooner.com/request-referral"
          variant="menuitem"
        >
          <Typography variant="h6" component="h2" className={styles.price}>
            Things to Know Before you Request a Referral
          </Typography>
        </Link>

        {/* <div className={styles.tabsWrapper}>
          <Tabs variant="fullWidth" className={styles.tabs}>
            <Tab label="CAREER" onClick={() => setActiveTab(0)} />
            <Tab label="PREMIUM" onClick={() => setActiveTab(1)} />
          </Tabs>
        </div> */}
        <div className={styles.featureList}>
          <FeatureGroup
            Icon={PaymentIcon}
            header="Referral Request Fee"
            desc="The fee covers processing costs and prevents spam requests so we can give your request the attention it deserves."
          />
          <FeatureGroup
            Icon={AccountCircleIcon}
            header="Profile Review"
            desc="We circulate your profile to up to 5 review team members who review your music for commercial value."
          />
          <FeatureGroup
            Icon={RateReviewIcon}
            header="Feedback"
            desc="You receive either a referral confirmation and referral badge, or advice from reviewers on how to take your songwriting to the next level."
          />
        </div>

        <div className={styles.upgradeButtonWrapper}>
          <Button
            disabled={!!state.record || state.isFetchingRecord}
            loading={state.isFetchingRecord}
            color="primary"
            onClick={onClick}
          >
            <Typography
              color="inherit"
              className={styles.upgradeButtonText}
              variant="h6"
            >
              {!!state.record ? (
                'Request Received'
              ) : (
                <Flex container alignItems="center">
                  <Button
                    className={styles.upgradeButton}
                    color="primary"
                    variant="contained"
                  >
                    Request Referral
                    <TrendingFlatIcon style={{ marginLeft: 8 }} />
                  </Button>
                </Flex>
              )}
            </Typography>
          </Button>
        </div>
      </div>
      <StripeProvider apiKey={env.STRIPE_API_KEY}>
        <Elements>
          <PaymentForm
            monthlyPrice={monthlyPrice}
            yearlyPrice={yearlyPrice}
            email={user && user.email}
            isOpen={isPaymentModalOpen}
            onClose={() => setIsPaymentModalOpen(false)}
            error={state.error}
            success={!!result}
          />
        </Elements>
      </StripeProvider>
    </Navigation>
  )
}
