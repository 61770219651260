// @flow
import { makeStyles } from '@material-ui/styles'
import { fade } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: props =>
      props.lastInList ? theme.spacing(8) : theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: props =>
        props.lastInList ? theme.spacing(3) : theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: 0
    }
  },
  card: {
    width: 340,
    [theme.breakpoints.down('md')]: {
      width: props => (props.isProfile ? 340 : 285)
    },
    [theme.breakpoints.down('xs')]: {
      width: 315
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 193,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  thumbnail: {
    height: 175,
    [theme.breakpoints.down('sm')]: {
      height: 175
    },
    [theme.breakpoints.down('xs')]: {
      height: 150
    }
  },
  header: {
    width: '100%'
  },
  authorName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  creationDate: {
    whiteSpace: 'nowrap',
    color: theme.palette.text.label,
    paddingLeft: theme.spacing(0.5)
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '115%',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      paddingRight: theme.spacing(1.5)
    }
  },
  body: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.text.label,
    lineHeight: 'calc(.875rem * 1.75)',
    height: 115,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      lineHeight: 'calc(.8rem * 1.75)'
    }
  },
  cardActions: {
    display: 'flex',
    paddingTop: 0,
    width: '100%'
  },
  iconText: {
    fontWeight: 'lighter',
    paddingRight: theme.spacing(2)
  },
  disabledIconText: {
    color: theme.palette.text.label
  },
  mediaType: {
    flex: 1,
    display: 'flex'
  },
  actionIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  actionLabel: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: theme.spacing(1.5),
    alignItems: 'center',
    borderRadius: 5
  },
  clapButton: {
    backgroundColor: props =>
      props.clapped && fade(theme.palette.text.label, 0.15)
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  externalLinkWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1rem'
  },
  commentPaper: {
    width: 600,
    height: `calc(100vh - ${theme.spacing(4)}px)`,
    [theme.breakpoints.down('xs')]: {
      width: `calc(100vw - ${theme.spacing(4)}px)`
    }
  },
  video: {
    width: 600,
    height: 400,
    [theme.breakpoints.down('xs')]: {
      width: 300,
      height: 200
    }
  }
}))
