// @flow

import type { Action } from 'src/utils/types'
import type { Config } from 'src/utils/types/config'

export const GET_CONFIG_REQUEST: 'GET_CONFIG_REQUEST' = 'GET_CONFIG_REQUEST'
export const GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS' = 'GET_CONFIG_SUCCESS'

export type ConfigState = {
  loadingConfig: boolean,
  config: ?Config
}

const configState = {
  loadingConfig: false,
  config: null
}

const reducer = (state: ConfigState = configState, action: Action<any>) => {
  switch (action.type) {
    case GET_CONFIG_REQUEST:
      return { ...state, loadingConfig: true }
    case GET_CONFIG_SUCCESS:
      return { ...state, loadingConfig: false, config: action.payload }
    default:
      return state
  }
}

export default reducer
