// @flow

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from 'src/components/atoms/buttons/Button'
import classnames from 'classnames'
import Typography from '@material-ui/core/Typography'

//$FlowFixMe
import { ReactComponent as ArrowRight } from 'src/images/arrow-right.svg'

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3),
    width: props => props.width,
    minHeight: props => props.minHeight,
    borderRadius: 5,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    color: '#2D4179',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: `${theme.spacing(2)}px 0`
    }
  },
  cardContent: {
    flex: 1,
    padding: theme.spacing(4)
  },
  cardButton: {
    padding: theme.spacing(2),
    borderRadius: '0 0 5px 5px',
    backgroundColor: '#2D4179',
    '&:hover': {
      backgroundColor: '#4B62A5'
    },
    '&:not(:hover)': {
      backgroundColor: '#2D4179'
    },
    '&:focus': {
      backgroundColor: '#4B62A5'
    },
    '&:disabled': {
      backgroundColor: theme.palette.border.main
    }
  },
  cardHeader: {
    fontWeight: 600,
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    borderBottom: `2px solid #3BBCB5`
  },
  cardButtonLabel: {
    justifyContent: props => (props.loading ? 'center' : 'space-between'),
    paddingLeft: theme.spacing(2),
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1rem',
    color: props =>
      props.disabled || props.loading ? theme.palette.text.label : '#FFF'
  },
  arrow: {
    width: 30,
    color: props =>
      props.disabled || props.loading ? theme.palette.text.label : '#FFF'
  },
  cardLight: {
    backgroundColor: '#FFF',
    color: '#FFF'
  },
  cardDark: {
    backgroundColor: theme.palette.text.primary,
    color: '#FFF'
  }
}))

export type CardProps = {
  variant?: 'blurb' | 'custom' | 'form',
  headerText?: string,
  minHeight?: number,
  width?: number,
  mode?: 'dark' | 'light',
  onActionClick?: () => void,
  actionLabel?: string,
  loading?: boolean,
  disabled?: boolean,
  children: any
}

export const Card = ({
  variant = 'blurb',
  width = 400,
  minHeight = 375,
  headerText,
  mode,
  loading,
  disabled,
  onActionClick,
  actionLabel,
  children
}: CardProps) => {
  const styles = useStyles({ minHeight, width })
  return (
    <div
      className={classnames(
        styles.card,
        mode === 'dark' ? styles.cardDark : styles.cardLight
      )}
    >
      <div className={styles.cardContent}>
        {variant === 'blurb' && headerText && (
          <Typography
            variant="h4"
            color={mode === 'dark' ? 'inherit' : 'textPrimary'}
            className={styles.cardHeader}
          >
            {headerText}
          </Typography>
        )}
        {children}
      </div>
      {actionLabel && (
        <CardButton
          loading={loading}
          disabled={disabled}
          type={variant === 'form' ? 'submit' : 'button'}
          label={actionLabel}
          onClick={onActionClick}
        />
      )}
    </div>
  )
}

type CardHeaderProps = {
  children: any,
  margin?: string
}
export const CardHeader = ({ margin, children }: CardHeaderProps) => {
  const styles = useStyles()
  return (
    <Typography
      variant="h5"
      color="textPrimary"
      className={styles.cardHeader}
      style={{ textAlign: 'center', margin }}
    >
      {children}
    </Typography>
  )
}

type CardButtonProps = {
  type: string,
  label: string,
  loading?: boolean,
  disabled?: boolean,
  onClick?: () => void
}
export const CardButton = ({
  label,
  type,
  onClick,
  loading,
  disabled
}: CardButtonProps) => {
  const styles = useStyles({ disabled, loading })
  return (
    <Button
      type={type}
      disableElevation
      variant="contained"
      color="inherit"
      onClick={onClick}
      className={styles.cardButton}
      classes={{ label: styles.cardButtonLabel }}
      disabled={loading || disabled}
      loading={loading}
    >
      {label}
      <ArrowRight className={styles.arrow} />
    </Button>
  )
}
