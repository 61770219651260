// @flow
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'src/components/atoms/buttons/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { injectStripe } from 'react-stripe-elements'
import type { ReduxFormProps } from 'src/utils/types'
import env from 'src/settings/env'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Flex } from 'src/components/molecules/Flex'
import Link from 'src/components/atoms/Link'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'

export const FORM_ID = 'payments'

const useStyles = makeStyles(theme => ({
  dialog: {
    margin: 0,
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '90vw'
    }
  },
  modalTitle: {
    fontWeight: 'bold'
  },
  infoLink: {
    fontWeight: 'bold',
    fontSize: '1.15rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      fontSize: '0.9rem',
      lineHeight: '125%',
      flexDirection: 'column'
    }
  },
  referalIcon: {
    width: 85,
    height: 85
  },
  price: {
    color: theme.palette.text.label,
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  dialogContent: {
    width: '100%',
    height: 'auto',
    minHeight: 100

    // overflow: 'hidden'
  },
  paymentInfoTitle: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.label,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem'
    }
  },
  termsLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem'
    }
  },
  planInfo: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.label
  },
  paymentError: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    color: theme.palette.error.main
  },
  successWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  checkIcon: {
    width: 65,
    height: 65,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main
  },
  successMessage: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  dialogActions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  memberSelect: {
    paddingBottom: theme.spacing(4),
    width: '100%'
  },
  sendIcon: {
    marginRight: theme.spacing(1)
  }
}))

type PaymentFormProps = {
  isOpen?: boolean,
  error?: string,
  success?: boolean,
  email: string,
  onClose: () => void
}

type StripeProps = {
  stripe: any,
  elements: any
}

export const ReferralRequestForm = ({
  email,
  isOpen,
  onClose,
  stripe,
  elements,
  error,
  success,
  ...props
}: PaymentFormProps & ReduxFormProps & StripeProps) => {
  const styles = useStyles()
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [redirectError, setRedirectError] = useState('')
  const [isRedirecting, setisRedirecting] = useState(false)

  const submit = async values => {
    setisRedirecting(true)
    const res = await stripe.redirectToCheckout({
      lineItems: [{ price: env.REFERRAL_PRODUCT_PRICE_ID, quantity: 1 }],
      successUrl: env.STRIPE_SUCCESS_URL,
      cancelUrl: env.STRIPE_CANCEL_URL,
      mode: 'payment',
      customerEmail: email
    })
    setisRedirecting(false)
    if (res) {
      setRedirectError(res.error.message)
    }
  }

  return (
    <Dialog
      aria-labelledby="create-message-modal-title"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: styles.dialog
      }}
    >
      <DialogTitle>
        {!success && (
          <>
            <Typography
              variant="h5"
              id="create-message-modal-title"
              className={styles.modalTitle}
            >
              Referral Request
            </Typography>
          </>
        )}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {!success && !error && (
          <>
            <Flex
              container
              flexDirection="column"
              alignItems="center"
              margin="0 0 16px 0"
            >
              <Flex container alignItems="center">
                <Button
                  isLink
                  to="https://www.discoversooner.com/request-referral"
                  variant="outlined"
                  color="primary"
                  className={styles.infoLink}
                >
                  Before You Request A Referral
                  <TrendingFlatIcon style={{ marginLeft: 8 }} />
                </Button>
              </Flex>
            </Flex>

            <Typography variant="body1" className={styles.paymentInfoTitle}>
              The button below will send you to a secure, one time payment
              checkout page. Please have your payment information ready. Before
              submitting payment{' '}
              <Link
                isExternal
                to="https://discoversooner.com/terms-of-service"
                style={{ display: 'inline' }}
              >
                please read the terms and conditions.
              </Link>
            </Typography>
            <Flex container justifyContent="center" margin="16px 0 0 0">
              <FormControlLabel
                classes={{ label: styles.termsLabel }}
                control={
                  <Checkbox
                    checked={agreedToTerms}
                    onChange={() => setAgreedToTerms(!agreedToTerms)}
                    color="primary"
                  />
                }
                label="I Agree to the Terms &amp; Conditions"
              />
            </Flex>
          </>
        )}
        {success && (
          <div className={styles.successWrapper}>
            <CheckCircleIcon className={styles.checkIcon} />
            <Typography className={styles.successMessage} variant="h5">
              Your referral request has been received!
            </Typography>
          </div>
        )}

        {redirectError && (
          <Typography className={styles.paymentError} variant="subtitle2">
            {redirectError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button onClick={onClose}>{success ? 'CLOSE' : 'CANCEL'}</Button>
        {!success && (
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={isRedirecting || !agreedToTerms}
            loading={isRedirecting}
            onClick={submit}
          >
            GO TO CHECKOUT
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default injectStripe(ReferralRequestForm)
