// @flow
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { push } from 'react-router-redux'
import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import NotificationIcon from '@material-ui/icons/NotificationsNone'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import HelpIcon from '@material-ui/icons/Help'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from 'src/components/atoms/buttons/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Link from 'src/components/atoms/Link'
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined'
import NotificationModal from 'src/components/organisms/NotificationModal'
import { markNotificationReadAction } from 'src/state/actions/notifications'
import { getNotificationsAction } from 'src/state/actions/notifications'
import SettingsIcon from '@material-ui/icons/Settings'
import GeneralMessageAlert, {
  ModalHeader,
  ModalContent,
  ModalActions
} from 'src/components/molecules/alerts/GeneralMessageAlert'
// $FlowFixMe
import { ReactComponent as Logo } from 'src/images/logos/Logo-dark.svg'
import { useAuth } from '../../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    zIndex: 25000000,
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    height: 65,
    // borderBottom: `1px solid ${theme.palette.border.main}`,
    background: 'white',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  headerActions: {
    transition: 'opacity 0.2s',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      opacity: props => (props.isSidebarOpen ? 0 : 1)
    }
  },
  icon: {
    width: 30,
    height: 30,
    color: theme.palette.menu.main
  },
  iconActive: {
    width: 30,
    height: 30,
    color: theme.palette.primary.main
  },
  profileImageContainer: {
    position: 'relative',
    width: 35,
    height: 35,
    color: theme.palette.menu.main
  },
  profileImage: {
    position: 'absolute',
    backgroundImage: props => `url(${props.profileImage})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: 35
  },
  profileImageFallback: {
    position: 'absolute',
    display: 'block',
    color: theme.palette.menu.main,
    width: 35,
    height: 35
  },
  pageTitle: {
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  menuButton: {
    display: 'none',
    transition: 'opacity 0.2s',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      opacity: props => (props.isSidebarOpen ? 0 : 1)
    }
  },
  menuButtonIcon: {
    width: 40,
    height: 40
  }
}))

type HeaderProps = {
  pageTitle: string,
  profileImage: string,
  id: string,
  setSidebarOpen: boolean => void,
  isSidebarOpen: boolean
}

const Header = ({
  pageTitle,
  profileImage,
  id,
  setSidebarOpen,
  isSidebarOpen
}: HeaderProps) => {
  const styles = useStyles({ profileImage, isSidebarOpen })
  const dispatch = useDispatch()
  const {
    isLoadingNotifs,
    noNewNotifs,
    index,
    notifications,
    mostRecentBroadcastNotification
  } = useSelector(stateSelector, shallowEqual)
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(true)
  const [message, setMessage] = useState(false)
  const { user, logout } = useAuth()

  const handleDismissButton = () => {
    setIsMessageModalOpen(false)
  }

  const handleOnCancelButton = () => {
    setIsMessageModalOpen(false)
    message.link && window.open(message.link)
  }

  useEffect(() => {
    if (message) {
      setIsMessageModalOpen(true)
    }
  }, [message])

  useEffect(() => {
    // Check if there is a notification and user is not an admin
    if (mostRecentBroadcastNotification && user.accountType !== 'Admin') {
      // Create localStorage key for later use
      const localStorageNotificationKey = 'notification_' + user._id

      // If there is no notification in localStorage, or the id is different from the new one, show the notification
      const lastNotificationFromLocalStorage = JSON.parse(localStorage.getItem(localStorageNotificationKey))
      if (!lastNotificationFromLocalStorage || mostRecentBroadcastNotification._id !== lastNotificationFromLocalStorage._id) {
        localStorage.setItem(localStorageNotificationKey, JSON.stringify(mostRecentBroadcastNotification))
        setMessage({
          title: mostRecentBroadcastNotification.header,
          content: mostRecentBroadcastNotification.message,
          link: mostRecentBroadcastNotification.actionUrl,
          id: mostRecentBroadcastNotification._id
        })
      }
    }
  }, [mostRecentBroadcastNotification, user])

  useEffect(() => {
    if (notifications.length === 0)
      dispatch(
        getNotificationsAction({ index, accountLevel: user.accountLevel })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <div className={styles.container}>
      {message && (
        <GeneralMessageAlert
          open={isMessageModalOpen}
          setOpen={() => setIsMessageModalOpen(false)}
        >
          <ModalHeader>{message.title}</ModalHeader>
          <ModalContent>{message.content}</ModalContent>
          <ModalActions
            onDismiss={handleDismissButton}
            onCancel={handleOnCancelButton}
            isThereALink={message.link ? true : false}
          ></ModalActions>
        </GeneralMessageAlert>
      )}
      <IconButton
        ariaLabel="open menu"
        className={styles.menuButton}
        onClick={() => setSidebarOpen(!isSidebarOpen)}
      >
        <Logo className={styles.menuButtonIcon} />
      </IconButton>
      <Typography variant="h5" className={styles.pageTitle}>
        {pageTitle}
      </Typography>
      <div className={styles.headerActions}>
        <IconButton ariaLabel="discover" tabIndex="-1">
          <Link to="/discover">
            <SearchIcon className={styles.icon} />
          </Link>
        </IconButton>
        <IconButton
          ariaLabel="notifications"
          onClick={() => setIsNotificationsOpen(true)}
        >
          {notifications.length ? (
            <NotificationsActiveIcon className={styles.iconActive} />
          ) : (
            <NotificationIcon className={styles.icon} />
          )}
        </IconButton>
        <IconButton ariaLabel="settings" tabIndex="-1" size="small">
          <Link to={`/profile/edit/${user._id}`}>
            <SettingsIcon className={styles.icon} />
          </Link>
        </IconButton>
        { !shouldUserUpgrade(user) && (<IconButton ariaLabel="help" tabIndex="-1" size="small">
          <Link 
            sameTab
            isExternal
            to="https://www.discoversooner.com/help"
          >
            <HelpIcon className={styles.icon} />
          </Link>
        </IconButton>)}

        

        <IconButton
          ariaLabel="profile"
          tabIndex="-1"
          onClick={e => {
            setProfileMenuAnchor(e.currentTarget)
            setIsProfileMenuOpen(true)
          }}
        >
          <div className={styles.profileImageContainer}>
            <AccountCircleIcon
              color="inherit"
              className={styles.profileImageFallback}
              alt="profile"
            />
            <div
              className={styles.profileImage}
              alt="profile"
              style={{ backgroundImage: `url(${profileImage})` }}
            />
          </div>
        </IconButton>
      </div>
      <Menu
        keepMounted
        open={isProfileMenuOpen}
        anchorEl={profileMenuAnchor}
        onClose={() => {
          setIsProfileMenuOpen(false)
        }}
      >
        <MenuItem>
          <Link variant="menuitem" to={`/profile/${id}`}>
            Profile
          </Link>
        </MenuItem>
        <MenuItem variant="menuitem">
          <Link variant="menuitem" to={`/profile/edit/${id}`}>
            Edit Profile
          </Link>
        </MenuItem>
        <MenuItem variant="menuitem" onClick={logout}>
          <Typography color="secondary">Logout</Typography>
        </MenuItem>
      </Menu>
      <NotificationModal
        loadMore={async () =>
          await dispatch(getNotificationsAction({ index, fetchMore: true }))
        }
        isOpen={isNotificationsOpen}
        setOpen={setIsNotificationsOpen}
        isLoading={isLoadingNotifs}
        notifications={notifications}
        noNewNotifications={noNewNotifs}
      />
    </div>
  )
}

const shouldUserUpgrade = user => {
  return user?.accountLevel === "Free" && user?.accountType === "Songwriter"
}

const stateSelector = state => state.notifications

export default Header
