// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'src/components/atoms/buttons/Button'

type DeleteThreadDialogProps = {
  splashId: string,
  isOpen?: boolean,
  onClose: () => void,
  isDeleting?: boolean,
  onDelete: string => Promise<*>
}

export default ({
  splashId,
  isOpen,
  isDeleting,
  onClose,
  onDelete
}: DeleteThreadDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Post</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this post?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={onClose}>
          CANCEL
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          loading={isDeleting}
          disabled={isDeleting}
          onClick={async () => {
            await onDelete(splashId)
            onClose()
          }}
        >
          DELETE POST
        </Button>
      </DialogActions>
    </Dialog>
  )
}
