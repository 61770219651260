// @flow

import React from 'react'
import Navigation from 'src/components/organisms/Navigation'
import InboxController from 'src/components/organisms/InboxController'

export default () => {
  return (
    <Navigation pageTitle="My Inbox">
      <InboxController />
    </Navigation>
  )
}
