// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    paddingTop: theme.spacing(5),

    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(5)
    }
  },
  horizontalList: {
    overflowX: 'auto',
    width: '100%',
    display: 'flex',
    paddingBottom: theme.spacing(1),
    '& > div:first-of-type': {
      marginLeft: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      '& > div:first-of-type': {
        marginLeft: 0
      }
    },
    justifyContent: props => (props.empty ? 'center' : 'start')
  },
  contentTitle: {
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(8),
    fontWeight: 'bolder',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3)
    }
  }
}))

type HorizontalListProps = {
  title?: any,
  empty?: boolean,
  children?: any
}

export default ({ title, empty, children }: HorizontalListProps) => {
  const styles = useStyles({ empty })
  return (
    <div className={styles.container}>
      {title && (
        <Typography variant="h5" className={styles.contentTitle}>
          {title}
        </Typography>
      )}
      <div className={styles.horizontalList}>{children}</div>
    </div>
  )
}
