// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  progress: {
    left: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.text.label
  }
}))

export default () => {
  const styles = useStyles()
  return <CircularProgress className={styles.progress} size={25} />
}
