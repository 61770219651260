// @flow

import React from 'react'
import { requiredField } from 'src/utils/fieldValidators'

import { Redirect } from 'react-router-dom'
import { Typography, makeStyles, Paper, Grid } from '@material-ui/core'
import Button from 'src/components/atoms/buttons/Button'
import TextField from 'src/components/atoms/TextField'
import { reduxForm, SubmissionError } from 'redux-form'
import RadioField from 'src/components/molecules/RadioField'
import { YES_NO_FIELD } from 'src/components/organisms/forms/EditPublishing/index.consts'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  verifyAccountAction,
  processReferralAction
} from 'src/state/actions/admin'
import { useAuth } from '../../hooks/useAuth'
import {
  createConnectionAction,
  deleteUserAction,
  upgradeUserAction,
  downgradeUserAction,
} from '../../state/actions/admin'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    minHeight: '100vh',
    backgroundColor: '#e8e8e8'
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: 350
  },
  formHeader: {
    marginBottom: theme.spacing(2)
  },
  fields: {
    maxWidth: 300
  }
}))

const AdminPanel = () => {
  const { user } = useAuth()
  const styles = useStyles()
  if (!user || user.accountType !== 'Admin') return <Redirect to="/login" />
  return (
    <div className={styles.container}>
      <Typography variant="h3"> Admin Dashboard </Typography>
      <VerifyBusinessForm />
      <UserReferralForm />
      <ConnectUsersForm />
      <DeleteUsersForm />
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <UpgradeUsersForm />
        </Grid>
        <Grid item sm={12} md={6}>
          <DowngradeUsersForm />
        </Grid>
      </Grid>
    </div>
  )
}

const DeleteUsers = ({ handleSubmit, pristine }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { isDeletingUser, deleteUserError, userDeleted } = useSelector(
    state => state.admin,
    shallowEqual
  )
  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(async ({ email }) => {
        try {
          dispatch(deleteUserAction({ email: email.trim() }))
        } catch (errors) {
          throw new SubmissionError(errors)
        }
      })}
    >
      <Paper className={styles.paper}>
        <Typography variant="h6" className={styles.formHeader}>
          Delete Users
        </Typography>

        <div className={styles.fields}>
          <TextField
            required
            type="text"
            id="user-email"
            label="Email"
            name="email"
            placeholder="johndoe@email.com"
            validators={[requiredField]}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isDeletingUser || pristine}
            loading={isDeletingUser}
          >
            SUBMIT
          </Button>
          <Typography color="secondary" variant="subtitle2">
            {deleteUserError}
          </Typography>

          <Typography color="primary" variant="subtitle2">
            {userDeleted && 'User has been wiped from the system'}
          </Typography>
        </div>
      </Paper>
    </form>
  )
}

const DeleteUsersForm = reduxForm({ form: 'deleteUsers' })(DeleteUsers)



const UpgradeUsers = ({ handleSubmit, pristine }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { isUpgradingUser, upgradeUserError, userUpgraded } = useSelector(
    state => state.admin,
    shallowEqual
  )
  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(async ({ email }) => {
        try {
          dispatch(upgradeUserAction({ email: email.trim() }))
        } catch (errors) {
          throw new SubmissionError(errors)
        }
      })}
    >
      <Paper className={styles.paper}>
        <Typography variant="h6" className={styles.formHeader}>
          Upgrade Users
        </Typography>

        <div className={styles.fields}>
          <TextField
            required
            type="text"
            id="user-email"
            label="Email"
            name="email"
            placeholder="johndoe@email.com"
            validators={[requiredField]}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isUpgradingUser || pristine}
            loading={isUpgradingUser}
          >
            SUBMIT
          </Button>
          <Typography color="secondary" variant="subtitle2">
            {upgradeUserError}
          </Typography>

          <Typography color="primary" variant="subtitle2">
            {userUpgraded && 'User has been Upgraded'}
          </Typography>
        </div>
      </Paper>
    </form>
  )
}

const UpgradeUsersForm = reduxForm({ form: 'upgradeUsers' })(UpgradeUsers)

const DowngradeUsers = ({ handleSubmit, pristine }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { isDowngradingUser, downgradeUserError, userDowngraded } = useSelector(
    state => state.admin,
    shallowEqual
  )
  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(async ({ email }) => {
        try {
          dispatch(downgradeUserAction({ email: email.trim() }))
        } catch (errors) {
          throw new SubmissionError(errors)
        }
      })}
    >
      <Paper className={styles.paper}>
        <Typography variant="h6" className={styles.formHeader}>
          Downgrade Users
        </Typography>

        <div className={styles.fields}>
          <TextField
            required
            type="text"
            id="user-email"
            label="Email"
            name="email"
            placeholder="johndoe@email.com"
            validators={[requiredField]}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isDowngradingUser || pristine}
            loading={isDowngradingUser}
          >
            SUBMIT
          </Button>
          <Typography color="secondary" variant="subtitle2">
            {downgradeUserError}
          </Typography>

          <Typography color="primary" variant="subtitle2">
            {userDowngraded && 'User has been Downgraded'}
          </Typography>
        </div>
      </Paper>
    </form>
  )
}

const DowngradeUsersForm = reduxForm({ form: 'downgradeUsers' })(DowngradeUsers)

const ConnectUsers = ({ handleSubmit, pristine }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { isConnecting, connectionError, connectionCreated } = useSelector(
    state => state.admin,
    shallowEqual
  )
  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(async ({ userA, userB }) => {
        try {
          dispatch(createConnectionAction({ userA, userB }))
        } catch (errors) {
          throw new SubmissionError(errors)
        }
      })}
    >
      <Paper className={styles.paper}>
        <Typography variant="h6" className={styles.formHeader}>
          Connect Users
        </Typography>

        <div className={styles.fields}>
          <TextField
            required
            type="text"
            id="userA-field"
            label="User A"
            name="userA"
            placeholder="johndoe@email.com"
            validators={[requiredField]}
          />
          <TextField
            required
            multiline
            type="text"
            id="userB-field"
            label="User B"
            name="userB"
            placeholder="johndoe@email.com"
            validators={[requiredField]}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isConnecting || pristine}
            loading={isConnecting}
          >
            SUBMIT
          </Button>
          <Typography color="secondary" variant="subtitle2">
            {connectionError}
          </Typography>

          <Typography color="primary" variant="subtitle2">
            {connectionCreated && 'Connection has been created'}
          </Typography>
        </div>
      </Paper>
    </form>
  )
}

const ConnectUsersForm = reduxForm({ form: 'connectUsers' })(ConnectUsers)

const UserReferral = ({ handleSubmit, pristine }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const {
    isProcessingReferral,
    referralProcessingError,
    referralProcessed
  } = useSelector(state => state.admin, shallowEqual)
  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(async ({ email, approved, feedback }) => {
        try {
          dispatch(processReferralAction({ email, approved, feedback }))
        } catch (errors) {
          throw new SubmissionError(errors)
        }
      })}
    >
      <Paper className={styles.paper}>
        <Typography variant="h6" className={styles.formHeader}>
          User Referral
        </Typography>

        <div className={styles.fields}>
          <TextField
            type="text"
            id="email-field"
            label="Email"
            name="email"
            placeholder="johndoe@email.com"
          />
          <RadioField
            name="approved"
            label="Was this user approved for a referral?"
            options={YES_NO_FIELD}
            darkLabel
          />
          <TextField
            multiline
            type="text"
            id="feedback"
            label="Feedback"
            name="feedback"
            placeholder="Feedback is important."
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isProcessingReferral || pristine}
            loading={isProcessingReferral}
          >
            SUBMIT
          </Button>
          <Typography color="secondary" variant="subtitle2">
            {referralProcessingError &&
              'There was an error in your input or the servers'}
          </Typography>
          <Typography color="primary" variant="subtitle2">
            {referralProcessed && 'Referral status has been updated'}
          </Typography>
        </div>
      </Paper>
    </form>
  )
}

const UserReferralForm = reduxForm({ form: 'userReferral' })(UserReferral)

const VerifyBusiness = ({ handleSubmit, pristine }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const {
    isVerifyingAccount,
    accountVerificationError,
    verificationSuccess
  } = useSelector(state => state.admin, shallowEqual)
  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(async ({ email, verified }) => {
        try {
          dispatch(verifyAccountAction({ email, verified }))
        } catch (errors) {
          throw new SubmissionError(errors)
        }
      })}
    >
      <Paper className={styles.paper}>
        <Typography variant="h6" className={styles.formHeader}>
          Verify Business Account
        </Typography>
        <div className={styles.fields}>
          <TextField
            type="text"
            id="email-field"
            label="Email"
            name="email"
            placeholder="johndoe@email.com"
          />
          <RadioField
            name="verified"
            label="Set this account as verified?"
            options={YES_NO_FIELD}
            darkLabel
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isVerifyingAccount || pristine}
            loading={isVerifyingAccount}
          >
            SUBMIT
          </Button>
          <Typography color="secondary" variant="subtitle2">
            {accountVerificationError &&
              'There was an error in your input or the servers'}
          </Typography>
          <Typography color="primary" variant="subtitle2">
            {verificationSuccess && 'Verification status has been updated'}
          </Typography>
        </div>
      </Paper>
    </form>
  )
}

const VerifyBusinessForm = reduxForm({ form: 'verifyBusiness' })(VerifyBusiness)

export default AdminPanel
