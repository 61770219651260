// @flow

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { EditSongForm } from 'src/components/organisms/forms/EditSong'
import Typography from '@material-ui/core/Typography'
import type { MusicState } from 'src/state/music'
import type { State } from 'src/utils/types'
import type { SocialNetwork } from 'src/utils/types/users'
import { useAuth } from 'src/hooks/useAuth'
import { Flex } from 'src/components/molecules/Flex'
import { MusicAccountLink } from 'src/components/molecules/MusicAccountLink'
import { MusicLink } from 'src/components/molecules/MusicLink'
import CreateIcon from '@material-ui/icons/Create'
import Fab from '@material-ui/core/Fab'
import { useParams } from 'react-router-dom'
import { getSongsAction } from 'src/state/music'
import { useStyles } from './index.styles'

const selectMusic: State => MusicState & {
  musicAccounts: SocialNetwork[]
} = state => ({
  ...state.music,
  musicAccounts: filterMusicAccounts(
    state.profile.user?.profile.socialMediaLinks
  )
})

export default () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const [editSongsModalOpen, setEditSongsModalOpen] = useState(false) //urlParams.get('editMusic') === 'true' ? true :
  const dispatch = useDispatch()
  const { id } = useParams()
  console.log(' useParams id=>', id)
  const styles = useStyles()

  const { songs, musicAccounts } = useSelector(selectMusic, shallowEqual)

  useEffect(() => {

    if (id) dispatch(getSongsAction({ authorId: id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id])

  const { user: currentUser } = useAuth()

  const filteredSongs = songs.filter(
    song =>
      !song.isPrivate ||
      currentUser._id === song.authorId ||
      currentUser.accountType === 'Business'
  )

  return (
    <React.Fragment>
      <div className={styles.musicViewContainer}>
        <div className={styles.songLinksContainer}>
          <Flex container>
            <Typography variant="h4" className={styles.sectionHeader}>
              Music List
            </Typography>
            {currentUser?._id === id && (
              <Fab
                className={styles.addMusicButton}
                color="secondary"
                aria-label="Edit Song List"
                onClick={() => {
                  setEditSongsModalOpen(true)
                }}
              >
                <CreateIcon />
              </Fab>
            )}
          </Flex>

          {filteredSongs.length ? (
            <div className={styles.songLinksBackground}>
              {filteredSongs
                .sort((a, b) => a.order - b.order)
                .map(song => (
                  <MusicLink
                    isPrivate={song.isPrivate}
                    key={song._id || song.url}
                    order={song.order}
                    title={song.title}
                    desc={song.desc}
                    url={song.url}
                  />
                ))}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={styles.hearMeOnContainer}>
          <Typography variant="h4" className={styles.sectionHeader}>
            Hear Me On
          </Typography>
          <Flex container flexWrap="wrap">
            {musicAccounts
              ? musicAccounts.map(account => (
                  <MusicAccountLink
                    key={account.name}
                    platform={account.name}
                    url={account.url}
                    followers={account.followerCount}
                  />
                ))
              : []}
          </Flex>
        </div>
      </div>
      <EditSongForm
        isOpen={editSongsModalOpen}
        onClose={() => {
          setEditSongsModalOpen(false)
        }}
      />
    </React.Fragment>
  )
}

const filterMusicAccounts = (accounts?: SocialNetwork[]) =>
  accounts?.filter(account => MUSIC_ACCOUNT_NAMES.includes(account.name))

const MUSIC_ACCOUNT_NAMES = [
  'Apple Music',
  'Spotify',
  'SoundCloud',
  'Pandora',
  'Youtube'
]
