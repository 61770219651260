// @flow
import React, { useState, useEffect, useContext } from 'react'
import { firestoreDB } from '../../../settings/env'
import numeral from 'numeral'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { push } from 'react-router-redux'
import { reset } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Button from 'src/components/atoms/buttons/Button'
import Table from 'src/components/molecules/Table'
import FilterIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import XIcon from 'src/components/atoms/icons/X'
import CheckIcon from 'src/components/atoms/icons/Check'
import UserIcon from 'src/components/atoms/icons/User'
import FilterForm from 'src/components/organisms/forms/Filter'
import GeneralMessageAlert, {
  ModalHeader,
  ModalContent,
  ModalActions
} from 'src/components/molecules/alerts/GeneralMessageAlert'
import ProfileChecklistModal from 'src/components/molecules/ProfileChecklistModal'
import Snackbar from 'src/components/atoms/Snackbar'
import { filterUsersAction } from 'src/state/actions/users'
import type { User } from 'src/utils/types/users'
import { ConfigContext } from 'src/components/hoc/RouteWithConfig'
import type { Config } from 'src/utils/types/config'
import { COLUMNS } from 'src/components/organisms/Network/index.consts'
import getImage from 'src/utils/images/getImage'
import { transformOptions } from 'src/utils/fieldTransformers'
import { useAuth } from '../../../hooks/useAuth'

const MUSIC_ACCOUNT_NAMES = [
  'Apple Music',
  'Spotify',
  'SoundCloud',
  'Pandora',
  'Youtube'
]

const filterMusicAccounts = (accounts?: SocialNetwork[]) =>
  accounts?.filter(account => MUSIC_ACCOUNT_NAMES.includes(account.name))

const selectMusic: State => MusicState & {
  musicAccounts: SocialNetwork[]
} = state => ({
  ...state.music,
  musicAccounts: filterMusicAccounts(
    state.profile.user?.profile.socialMediaLinks
  )
})

const NameComponent = ({ name = '', title = '' }) => (
  <div>
    <Typography color="primary" variant="body1" style={{ fontWeight: 'bold' }}>
      {name}
    </Typography>
    <Typography variant="caption"> {title} </Typography>
  </div>
)

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  price: {
    // fontWeight: 'bold',
    color: theme.palette.text.label,
    marginBottom: 26,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1rem',
      marginTop: theme.spacing(1)
    }
  },
  emphasizedText: {
    color: 'black'
  },
  paperRoot: {
    display: 'flex',
    alignItems: 'center'
  },
  networkContent: {
    width: '90%',
    margin: 'auto',
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      width: '95%'
    }
  },
  networkHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: 'white',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      textAlign: 'left !important'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'left !important'
    }
  },
  networkHeaderText: {
    fontWeight: 'bold',
    width: '50%'
  },
  emptyNetworkMessage: {
    margin: theme.spacing(5),
    textAlign: 'center'
  },
  noUsersFound: {
    color: theme.palette.text.label
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  discoverButton: {
    height: '50px',
    width: '15%',
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      color: 'black'
    },
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%'
    }
  },
  loadMore: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  loadMoreButton: {
    width: '100%',
    marginTop: theme.spacing(),
    height: 65,
    color: theme.palette.text.label
  },
  subtask: {
    fontSize: 16,
    fontStyle: 'italic'
  }
}))

const usersSelector = state => state.users
const filterSelector = state =>
  state.form.filters ? state.form.filters.values : {}

export const Discover = () => {
  const {
    userList: users,
    disableLoadMore,
    isLoadingMore,
    isLoadingFilteredUsers: isLoading,
    resultIndex
  } = useSelector(usersSelector, shallowEqual)

  const { user: currentUser } = useAuth()
  const filters = useSelector(filterSelector, shallowEqual)
  const { songs } = useSelector(selectMusic, shallowEqual)

  const config = useContext(ConfigContext)
  let testConfig = config
  testConfig.userTypes = ['Hit Songwriter']
  const dispatch = useDispatch()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const [isFilterOpen, setIsFilterOpen] = useState(
    urlParams.get('open') === 'true' ? true : false
  )
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
  const [isProfileChecklistOpen, setIsProfileChecklistOpen] = useState(true)
  const styles = useStyles({ isFilterOpen })

  useEffect(() => {
    const filterURL = urlParams.get('filters')

    dispatch(reset('filters'))
    if (filterURL) {
      if (filterURL === 'hitsongwriter') {
        const filters = {
          occupation: ['Hit Songwriter']
        }
        dispatch(filterUsersAction({ filters }))
      }

      if (filterURL === 'referred') {
        const filters = {
          referalStatus: 'Referred'
        }
        dispatch(filterUsersAction({ filters }))
      }
    } else {
      dispatch(filterUsersAction({}))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  // const handleClickSnackbar = () => {
  //   setIsProfileChecklistOpen(true)
  // }

  const onLoadMore = () => {
    if (filters.country) {
      // country is an object but API expects string
      filters.country = transformOptions(filters.country)
    }

    console.log({
      loadMore: true,
      filters,
      resultIndex
    })

    dispatch(
      filterUsersAction({
        loadMore: true,
        filters,
        resultIndex
      })
    )
  }

  const loadingOrContentExists = isLoading || users.length

  return (
    <div className={styles.networkContent}>
      {/* <Snackbar 
        open={true}
        type='warning'
        snackbarMessage='This is an urgent message'
      /> */}

      {/* <ProfileChecklistModal
        open={isProfileChecklistOpen}
        setOpen={() => setIsProfileChecklistOpen(false)}
        possibleActionsList={[
          {
            description: 'Fill "Edit Account" fields',
            isDone: currentUser.profile.bio !== '' && currentUser.profile.website !== '' && currentUser.profile.profileImage !== '',
            linkTo: `/profile/edit/${currentUser._id}`
          },
          {
            description: 'Add a social link',
            isDone: currentUser.profile.socialMediaLinks.length !== 0,
            linkTo: `/profile/edit/${currentUser._id}`
          },
          {
            description: 'Add a song to your music list',
            isDone: songs.length > 0,
            linkTo: `/profile/edit/${currentUser._id}`
          }
        ]}
      /> */}
      {/* 
      <Snackbar 
        open={true}
        type='general'
        snackbarMessage='This is a reminder message'
        className={styles.paperRoot}
      />  
      */}

      <FilterForm
        open={isFilterOpen}
        setOpen={setIsFilterOpen}
        resultIndex={resultIndex}
        onSubmitFilters={data => dispatch(filterUsersAction(data))}
      />
      <div className={styles.networkHeader}>
        <div>
          <Typography variant="h3" component="h1" className={styles.header}>
            <span className={styles.emphasizedText}>Discover Songwriters</span>
          </Typography>
          <br />
          {currentUser.accountLevel === 'Free' &&
            currentUser.accountType !== 'Business' && (
              <Typography variant="h6" component="h2" className={styles.price}>
                This is where you discover songwriters.
                <br />
                <span className={styles.subtask}>
                  Upgrade to the Career Plan so publishers can discover you!
                </span>
              </Typography>
            )}
          {currentUser.accountLevel === 'Career' && (
            <Typography variant="h6" component="h2" className={styles.price}>
              This is where you discover songwriters and
              publishers discover you!
            </Typography>
          )}
          {currentUser.accountType === 'Business' && (
            <Typography variant="h6" component="h2" className={styles.price}>
              You're an Industry Member. Search in private to find writers.
              <br />
              <span className={styles.subtask}>
                Songwriters can see your profile when you "connect".
              </span>
            </Typography>
          )}
        </div>

        <Button
          color="primary"
          variant="outlined"
          onClick={() => setIsFilterOpen(true)}
          className={styles.discoverButton}
        >
          <SearchIcon className={styles.filterIcon} />
          Search
        </Button>
      </div>
      {loadingOrContentExists ? (
        <Table
          isLoading={isLoading}
          columns={COLUMNS}
          rows={networkToRows(users, testConfig)}
          rowIds={users.map(user => user._id)}
          onClick={rowId => {
            dispatch(push(`profile/${rowId}`))
          }}
        />
      ) : (
        <div className={styles.emptyNetworkMessage}>
          <Typography variant="h4" className={styles.noUsersFound}>
            No users found.
          </Typography>
        </div>
      )}
      {!isLoading && (
        <div className={styles.loadMore}>
          <Button
            className={styles.loadMoreButton}
            onClick={onLoadMore}
            disabled={disableLoadMore}
            loading={isLoadingMore}
          >
            {disableLoadMore ? 'No More Results' : 'LOAD MORE'}
          </Button>
        </div>
      )}
    </div>
  )
}

const networkToRows = (users: User[], config: Config) => {
  const recentUsers = (a, b) => a.stats.joinDate - b.stats.joinDate
  return users.sort(recentUsers).map(user => {
    return [
      {
        value: (
          <UserIcon
            img={
              user.profile.profileImage ||
              getImage('profileImage', user._id, config) ||
              ''
            }
          />
        ),
        align: 'right'
      },
      {
        value: (
          <NameComponent
            name={`${user.firstname} ${user.lastname}`}
            title={user.stats.occupation[0]}
          />
        ),
        align: 'left'
      },
      {
        value:
          user.location.city && user.location.state
            ? `${user.location.city}, ${user.location.state}${
                user.location.country ? `, ${user.location.country}` : ''
              }`
            : 'N/A',
        align: 'right'
      },
      {
        value: numeral(user.stats.highestStreaming.count).format('0,0'),
        align: 'right'
      },
      {
        value: user.stats.genres[0] || 'N/A',
        align: 'right'
      },
      {
        value: () =>
          user.stats.publishingFlags.proSeekingDeal ? <CheckIcon /> : <XIcon />,
        align: 'right'
      },
      {
        value: () =>
          user.accountType === 'Business' ? (
            'n/a'
          ) : user.stats.availableTopChartSongs?.length ? (
            <CheckIcon />
          ) : (
            <XIcon />
          ),
        align: 'right'
      }
    ]
  })
}

export default Discover
