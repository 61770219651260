// @flow

import React from 'react'
import ReferralTemplate from 'src/components/templates/Referral'

const UpgradePage = () => {
  return <ReferralTemplate />
}

export default UpgradePage
