// @flow
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  songLink: {
    borderRadius: 5,
    boxShadow: props =>
      props.elevate ? '0px 4px 20px rgba(0, 0, 0, 0.25)' : '',
    boxSizing: 'border-box',
    margin: 'auto',
    marginTop: theme.spacing(2),
    width: '98%',

    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    }
  },
  songLinkInput: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 500,
    textAlign: 'center',
    border: 'none',
    borderBottom: props => `1px solid ${theme.palette.text.label}`,
    paddingBottom: theme.spacing(1),
    outline: 'none',
    '&::placeholder': {
      color: theme.palette.text.label
    }
  },
  songTitleInput: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1.25rem',
    border: 'none',
    outline: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.125rem'
    }
  },
  songDescInput: {
    border: 'none',
    outline: 'none',
    fontSize: '1rem',
    marginTop: theme.spacing(1),
    fontFamily: 'Roboto',
    resize: 'none',
    '&::placeholder': {
      color: theme.palette.text.label
    }
  },
  visibilityInput: {
    position: 'relative'
  },
  tooltip: {
    textAlign: 'center',
    boxShadow: '5px 5px 16px -6px rgba(0,0,0,0.75)',
    opacity: props => (props.hovering ? 1 : 0),
    pointerEvents: 'none',
    transition: 'opacity 0.2s',
    position: 'absolute',
    right: 50,
    zIndex: 10000,
    minWidth: 150,
    padding: theme.spacing(0.5),
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF'
  },
  orderContainer: {
    flexShrink: 0,
    width: 50,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 35,
      height: 35
    }
  }
}))
