// @flow

import type { Action } from 'src/utils/types'
import type { PaymentErrorAction } from 'src/services/payments'
import type { PaymentRecord } from 'src/utils/types/payments'

export const SUBMIT_PAYMENT_REQUEST: 'SUBMIT_PAYMENT_REQUEST' =
  'SUBMIT_PAYMENT_REQUEST'
export const SUBMIT_PAYMENT_SUCCESS: 'SUBMIT_PAYMENT_SUCCESS' =
  'SUBMIT_PAYMENT_SUCCESS'
export const SUBMIT_PAYMENT_ERROR: 'SUBMIT_PAYMENT_ERROR' =
  'SUBMIT_PAYMENT_ERROR'

export const CANCEL_SUBSCRIPTION_REQUEST: 'CANCEL_SUBSCRIPTION_REQUEST' =
  'CANCEL_SUBSCRIPTION_REQUEST'
export const CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS' =
  'CANCEL_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION_ERROR: 'CANCEL_SUBSCRIPTION_ERROR' =
  'CANCEL_SUBSCRIPTION_ERROR'

export const GET_PAYMENT_RECORD_REQUEST: 'GET_PAYMENT_RECORD_REQUEST' =
  'GET_PAYMENT_RECORD_REQUEST'

export const GET_PAYMENT_RECORD_SUCCESS: 'GET_PAYMENT_RECORD_SUCCESS' =
  'GET_PAYMENT_RECORD_SUCCESS'

export const GET_PAYMENT_RECORD_ERROR: 'GET_PAYMENT_RECORD_ERROR' =
  'GET_PAYMENT_RECORD_ERROR'

export const GET_CHECKOUT_SESSION_REQUEST: 'GET_CHECKOUT_SESSION_REQUEST' =
  'GET_CHECKOUT_SESSION_REQUEST'

export const GET_CHECKOUT_SESSION_SUCCESS: 'GET_CHECKOUT_SESSION_SUCCESS' =
  'GET_CHECKOUT_SESSION_SUCCESS'

export const GET_CHECKOUT_SESSION_ERROR: 'GET_CHECKOUT_SESSION_ERROR' =
  'GET_CHECKOUT_SESSION_ERROR'

export type PaymentState = {
  error: PaymentErrorAction | '',
  isFetchingSession: boolean,
  isSubmitting: boolean,
  isCancelling: boolean,
  cancellationSucceeded: boolean,
  paymentSucceeded: boolean,
  isFetchingRecord: boolean,
  record?: PaymentRecord
}

const paymentState = {
  isFetchingSession: false,
  isSubmitting: false,
  isCancelling: false,
  cancellationSucceeded: false,
  paymentSucceeded: false,
  isFetchingRecord: false,
  error: ''
}

const reducer = (state: PaymentState = paymentState, action: Action<any>) => {
  switch (action.type) {
    case SUBMIT_PAYMENT_REQUEST:
      return { ...state, isSubmitting: true, paymentSucceeded: false }
    case SUBMIT_PAYMENT_SUCCESS:
      return { ...state, paymentSucceeded: true, isSubmitting: false }
    case SUBMIT_PAYMENT_ERROR:
      return {
        ...state,
        paymentSucceeded: false,
        isSubmitting: false,
        error: action.payload
      }
    case CANCEL_SUBSCRIPTION_REQUEST:
      return { ...state, isCancelling: true, cancellationSucceeded: false }
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return { ...state, isCancelling: false, cancellationSucceeded: true }
    case CANCEL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isCancelling: false,
        error: action.payload
      }
    case GET_PAYMENT_RECORD_REQUEST:
      return { ...state, isFetchingRecord: true }
    case GET_PAYMENT_RECORD_SUCCESS:
      return { ...state, isFetchingRecord: false, record: action.payload }
    case GET_PAYMENT_RECORD_ERROR: {
      return { ...state, isFetchingRecord: false }
    }
    case GET_CHECKOUT_SESSION_REQUEST:
      return { ...state, isFetchingSession: true }
    case GET_CHECKOUT_SESSION_SUCCESS:
      return { ...state, isFetchingSession: false }
    case GET_CHECKOUT_SESSION_ERROR:
      return { ...state, isFetchingSession: false }
    default:
      return state
  }
}

export default reducer
