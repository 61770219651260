// @flow

import React, { useEffect } from 'react'
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { FieldArray, reduxForm, SubmissionError, change } from 'redux-form'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextFieldSelect from 'src/components/atoms/TextFieldSelect'
import TextField from 'src/components/atoms/TextField'
import RadioField from 'src/components/molecules/RadioField'
import Button from 'src/components/atoms/buttons/Button'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { YES_NO_FIELD } from './index.consts'
import { updateUserAction } from 'src/state/actions/profile'
import { transformOptions } from 'src/utils/fieldTransformers'
import type { ReduxFormProps } from 'src/utils/types'
import type { ProfileState } from 'src/state/reducers/profile'
import type { ConfigState } from 'src/state/reducers/Config'
import type { State } from 'src/utils/types'
import type { Option } from 'src/components/atoms/TextFieldSelect'
import { validNumber } from 'src/utils/fieldValidators'

const FORM_ID = 'editPublishing'

const useStyles = makeStyles(theme => ({
  paper: {
    zIndex: 1,
    width: '100%',
    maxWidth: 600,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(2),
      margin: `${theme.spacing(1)}px auto`
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  actionButton: {
    margin: `0px ${theme.spacing(1)}px`
  },
  subForm: {
    width: '95%',
    margin: 'auto'
  },
  formSectionTitle: {
    color: theme.palette.text.label,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  fieldGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': {
      marginRight: theme.spacing(2)
    }
  },
  removeItemIcon: {
    marginRight: theme.spacing(1)
  }
}))

type PublishingForm = {
  stats: {
    currentPublishers: Option[],
    previousPublishers: Option[],
    publishingFlags: {
      proSeekingDeal: boolean,
      available: boolean,
      catalogForSale: boolean
    }
  }
}

const renderHitSongsInCatalog = ({
  styles,
  fields,
  meta: { error, submitFailed }
}) => (
  <>
    {fields.map((field, index) => (
      <div key={index}>
        <div className={styles.fieldGroup}>
          <Typography
            variant="h6"
            component="p"
            className={styles.formSectionTitle}
          >
            Song {index + 1}
          </Typography>
          <Button color="secondary" onClick={() => fields.remove(index)}>
            <DeleteOutlineIcon className={styles.removeItemIcon} /> REMOVE
          </Button>
        </div>
        <div className={styles.subForm}>
          <TextField
            id={`hit-song-in-catalog-name-${index}`}
            label="Song Title"
            name={`${field}.songTitle`}
            placeholder="Song Title..."
            fullWidth
          />
        </div>
      </div>
    ))}
    <Button variant="contained" color="primary" onClick={() => fields.push({})}>
      ADD SONG +
    </Button>
  </>
)

const renderTopChartSongs = ({
  styles,
  fields,
  meta: { error, submitFailed }
}) => {
  return (
    <>
      {fields.map((field, index) => (
        <div key={index}>
          <div className={styles.fieldGroup}>
            <Typography
              variant="h6"
              component="p"
              className={styles.formSectionTitle}
            >
              Song {index + 1}
            </Typography>
            <Button color="secondary" onClick={() => fields.remove(index)}>
              <DeleteOutlineIcon className={styles.removeItemIcon} /> REMOVE
            </Button>
          </div>
          <div className={styles.subForm}>
            <TextField
              id={`top-chart-song-title-field-${index}`}
              label="Song Title"
              name={`${field}.songTitle`}
              placeholder="Song Title..."
              fullWidth
            />
            <TextField
              id={`top-chart-artist-name-field-${index}`}
              label="Artist Name"
              name={`${field}.artistName`}
              placeholder="John Doe..."
              fullWidth
            />
            <TextField
              id={`top-chart-name-field-${index}`}
              label="Chart Name"
              name={`${field}.chartName`}
              placeholder="Top 40..."
              fullWidth
            />
            <TextField
              id={`top-chart-rank-field-${index}`}
              label="Chart Rank"
              name={`${field}.chartRank`}
              placeholder="10"
              validators={[validNumber]}
              fullWidth
            />
          </div>
        </div>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={() => fields.push({})}
      >
        ADD SONG +
      </Button>
    </>
  )
}

type EditPublishingState = ProfileState &
  ConfigState & {
    form: ?PublishingForm
  }

const selectState: State => EditPublishingState = state => ({
  ...state.profile,
  ...state.config,
  form: state.form[FORM_ID] ? state.form[FORM_ID].values : null
})

export const EditPublishingForm = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error
}: ReduxFormProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const state: EditPublishingState = useSelector(selectState, shallowEqual)
  const user = state.user

  const hasPublishingAvailable =
    state && state.form && state.form.stats.publishingFlags.available
  const hasPreviousPublisher = state.form?.stats.previousPublishers?.length

  const publishersToOptions = state.config
    ? state.config.publishers.map(publisher => ({
        value: publisher,
        label: publisher
      }))
    : []

  const prevPublisherOptions = publishersToOptions.filter(
    opt => opt.label !== 'Self'
  )

  const fillFormData = () => {
    batch(() => {
      dispatch(
        change(
          FORM_ID,
          'stats.currentPublishers',
          user.stats.currentPublishers?.length
            ? user.stats.currentPublishers.map(pub => ({
                value: pub,
                label: pub
              }))
            : [{ value: 'Self', label: 'Self' }]
        )
      )
      dispatch(
        change(
          FORM_ID,
          'stats.previousPublishers',
          user.stats.previousPublishers?.length
            ? user.stats.previousPublishers.map(pub => ({
                value: pub,
                label: pub
              }))
            : [{ value: 'None', label: 'None' }]
        )
      )
      dispatch(
        change(
          FORM_ID,
          'stats.publishingFlags.catalogForSale',
          user.stats.publishingFlags.catalogForSale
        )
      )
      dispatch(
        change(
          FORM_ID,
          'stats.publishingFlags.proSeekingDeal',
          user.stats.publishingFlags.proSeekingDeal
        )
      )
      dispatch(
        change(
          FORM_ID,
          'stats.publishingFlags.available',
          user.stats.publishingFlags.available
        )
      )
      user.stats.hitSongsInCatalog.forEach((hitSong, index) => {
        dispatch(change(FORM_ID, `stats.hitSongsInCatalog.${index}`, hitSong))
      })
      user.stats.availableTopChartSongs.forEach((topChartSong, index) => {
        dispatch(
          change(FORM_ID, `stats.availableTopChartSongs.${index}`, topChartSong)
        )
      })
    })
  }

  useEffect(() => {
    if (user) {
      fillFormData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch])
  return (
    <form
      onSubmit={handleSubmit(async ({ stats }) => {
        const transformedStats = {
          ...stats
        }
        transformedStats.currentPublishers = transformOptions(
          stats.currentPublishers
        ) || ['Self']
        transformedStats.previousPublishers = transformOptions(
          stats.previousPublishers
        ) || ['Self']
        try {
          await dispatch(
            updateUserAction({ fields: { stats: transformedStats } })
          )
        } catch (errors) {
          throw new SubmissionError(errors)
        }
      })}
    >
      <Paper className={styles.paper}>
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Recent Publishers
        </Typography>
        <div className={styles.subForm}>
          <TextFieldSelect
            variant="creatable"
            id="current-publisher-field"
            label="Current Publisher(s)"
            name="stats.currentPublishers"
            placeholder="Set Publisher(s)..."
            options={publishersToOptions}
            defaultValue={{ label: 'Self', value: 'Self' }}
            fullWidth
            isMulti
          />
          <TextFieldSelect
            variant="creatable"
            id="previous-publishers-field"
            label="Previous Publisher(s)"
            name="stats.previousPublishers"
            placeholder="Add Publisher(s)..."
            options={prevPublisherOptions}
            defaultValue={{ label: 'None', value: 'None' }}
            isMulti
            fullWidth
          />
        </div>
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Catalog For Sale
        </Typography>
        <div className={styles.subForm}>
          <RadioField
            name="stats.publishingFlags.catalogForSale"
            label="Catalog For Sale?"
            options={YES_NO_FIELD}
            darkLabel
          />
          {state.form && state.form.stats.publishingFlags.catalogForSale && (
            <>
              <Typography
                variant="subtitle1"
                component="p"
                className={styles.formSectionTitle}
              >
                Hit Songs in Catalog
              </Typography>
              <FieldArray
                name="stats.hitSongsInCatalog"
                component={renderHitSongsInCatalog}
                props={{ styles }}
              />
            </>
          )}
        </div>
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Publishing Availability
        </Typography>
        <div className={styles.subForm}>
          <RadioField
            name="stats.publishingFlags.available"
            label="Publishing Available?"
            options={YES_NO_FIELD}
            darkLabel
          />
          {hasPublishingAvailable && (
            <>
              {hasPreviousPublisher ? (
                <RadioField
                  name="stats.publishingFlags.proSeekingDeal"
                  label="Seeking New Publishing Deal?"
                  options={YES_NO_FIELD}
                  darkLabel
                />
              ) : (
                ''
              )}

              <Typography
                variant="subtitle1"
                component="p"
                className={styles.formSectionTitle}
              >
                Songs on Top Charts Available for Publishing
              </Typography>
              <FieldArray
                name="stats.availableTopChartSongs"
                component={renderTopChartSongs}
                props={{ styles }}
              />
            </>
          )}
        </div>
        <div className={styles.actionButtons}>
          <Button
            color="secondary"
            className={styles.actionButton}
            onClick={fillFormData}
          >
            RESET
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={pristine || submitting || invalid}
            loading={submitting}
          >
            UPDATE
          </Button>
        </div>
      </Paper>
    </form>
  )
}

export default reduxForm({ form: FORM_ID })(EditPublishingForm)
