// @flow

import React from 'react'
import { Flex } from 'src/components/molecules/Flex'
import { makeStyles } from '@material-ui/styles'
import TextField from 'src/components/atoms/TextField'
import TextFieldSelect from 'src/components/atoms/TextFieldSelect'
import { Card, CardHeader } from 'src/components/molecules/Card'
import {
  STATES_OPTIONS,
  SONGWRITER_TYPE_OPTIONS
} from 'src/components/organisms/forms/EditAccount/index.consts'
import { CTA } from 'src/components/molecules/CTA'
import { EDIT_GENRES_OPTIONS } from 'src/components/organisms/forms/EditSkills/index.consts'
import ImageUploadField from 'src/components/atoms/ImageUploadField'
import { requiredField, validFile, validPhone } from 'src/utils/fieldValidators'
import { COUNTRY_OPTIONS } from 'src/consts'

const useStyles = makeStyles(theme => ({
  uploadContainer: {
    width: 145,
    height: 145,
    margin: 'auto'
  }
}))

type SongwriterProfileProps = {
  formState: Object,
  creatingProfile?: boolean,
  error?: boolean
}

export const SongwriterProfile = ({
  formState,
  error,
  creatingProfile
}: SongwriterProfileProps) => {
  const styles = useStyles()
  return (
    <Flex container flexDirection="column" alignItems="center" padding="0 16px">
      <CTA
        header="Create a Profile"
        subHeader="Let's get the basics out of the way."
      />
      <Flex
        container
        margin="32px 0 0 0"
        width="100%"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Card
          mode="light"
          actionLabel="Create Profile"
          variant="form"
          loading={creatingProfile}
        >
          <CardHeader>Name</CardHeader>
          <TextField
            required
            id="first-name"
            name="firstname"
            label="First Name"
            placeholder="John"
            validators={[requiredField]}
          />
          <TextField
            required
            id="last-name"
            name="lastname"
            label="Last Name"
            placeholder="Doe"
            validators={[requiredField]}
          />
          <CardHeader margin="48px 0 32px 0">Location</CardHeader>
          <TextFieldSelect
            required
            fullWidth
            id="country"
            label="Country"
            name="country"
            options={COUNTRY_OPTIONS}
            validators={[requiredField]}
          />
          {formState.country ? (
            formState.country?.value === 'US' ? (
              <TextFieldSelect
                required
                fullWidth
                id="state"
                label="State"
                name="state"
                options={STATES_OPTIONS}
                validators={[requiredField]}
              />
            ) : (
              <TextField
                required
                id="state"
                label="State or Province"
                name="state"
                placeholder="State or Province"
                validators={[requiredField]}
              />
            )
          ) : (
            <div />
          )}
          <TextField
            required
            id="city"
            name="city"
            label="City"
            placeholder="enter city"
            validators={[requiredField]}
          />
          <CardHeader>Contact</CardHeader>
          <TextField
            required
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            placeholder="(123) 456 7890..."
            validators={[requiredField, validPhone]}
          />

          <CardHeader margin="48px 0 32px 0">Music Background</CardHeader>

          <TextFieldSelect
            required
            fullWidth
            id="songwriter-type"
            name="occupation"
            label="Songwriter Type"
            options={SONGWRITER_TYPE_OPTIONS}
            validators={[requiredField]}
          />
          <TextFieldSelect
            required
            fullWidth
            isMulti
            id="genre"
            name="genres"
            label="Genres"
            options={EDIT_GENRES_OPTIONS}
            validators={[requiredField]}
          />
          <CardHeader margin="48px 0 32px 0">Profile Picture</CardHeader>
          <ImageUploadField
            name="profileImage"
            label="Upload Image (square, max 3mb png, jpeg)"
            classes={{ container: styles.uploadContainer }}
            validators={[requiredField, validFile]}
          />
        </Card>
      </Flex>
    </Flex>
  )
}
