// @flow
export const EDIT_SKILLS_OPTIONS = [
  {
    value: 'Can Sing the Demo',
    label: 'Can Sing the Demo'
  },
  {
    value: 'Can Produce the Demo',
    label: 'Can Produce the Demo'
  },
  {
    value: 'Can Play Instrument on Demo',
    label: 'Can Play Instrument on Demo'
  },
  {
    value: 'Has a Songplugger',
    label: 'Has a Songplugger'
  }
]

export const EDIT_COWRITE_FREQUENCY_OPTIONS = [
  {
    value: '0',
    label: '0'
  },
  {
    value: '1',
    label: '1'
  },
  {
    value: '2-5',
    label: '2-5'
  },
  {
    value: '6-10',
    label: '6-10'
  },
  {
    value: '11-15+',
    label: '11-15+'
  }
]

export const EDIT_COWRITE_EXPERIENCE_OPTIONS = [
  {
    value: 'I do not co-write',
    label: 'I do not co-write'
  },
  {
    value: 'Less than 1 Year',
    label: 'Less than 1 Year'
  },
  {
    value: '1-2 Years',
    label: '1-2 Years'
  },
  {
    value: '3-6 Years',
    label: '3-6 Years'
  },
  {
    value: '7+ Years',
    label: '7+ Years'
  }
]

export const EDIT_GENRES_OPTIONS = [
  { label: 'Americana', value: 'Americana' },
  { label: 'Blue Grass', value: 'Blue Grass' },
  { label: 'Country', value: 'Country' },
  { label: 'Country (Traditional)', value: 'Country (Traditional)' },
  { label: 'EDM', value: 'EDM' },
  { label: 'Jazz', value: 'Jazz' },
  { label: 'Reggae', value: 'Reggae' },
  { label: 'Sync', value: 'Sync' },
  { label: 'TV/Film Composer', value: 'TV/Film Composer' },
  { label: 'Pop', value: 'Pop' },
  { label: 'CCM', value: 'CCM' },
  { label: 'Gospel', value: 'Gospel' },
  { label: 'Rock', value: 'Rock' },
  { label: 'Hip Hop', value: 'Hip Hop' },
  { label: 'Latin', value: 'Latin' },
  { label: 'R&B', value: 'R&B' }
]

export const EDIT_PERFORMING_ORGS_OPTIONS = [
  {
    value: 'ASCAP',
    label: 'ASCAP'
  },
  {
    value: 'BMI',
    label: 'BMI'
  },
  {
    value: 'GMR',
    label: 'GMR'
  },
  {
    value: 'SESAC',
    label: 'SESAC'
  },
  {
    value: 'SOCAN',
    label: 'SOCAN'
  },
  {
    value: 'ACEMLA',
    label: 'ACEMLA'
  },
  {
    value: 'SPACEM',
    label: 'SPACEM'
  },
  {
    value: 'APRA',
    label: 'APRA'
  },
  {
    value: 'CMRRA',
    label: 'CMRRA'
  },
  {
    value: 'PRS',
    label: 'PRS'
  },
  {
    value: 'PPL',
    label: 'PPL'
  },
  {
    value: 'SODRAC',
    label: 'SODRAC'
  },
  {
    value: 'SPACQ',
    label: 'SPACQ'
  },
  {
    value: 'SACM',
    label: 'SACM'
  }
]

export const SOCIAL_MEDIA_OPTIONS = [
  {
    label: 'Facebook',
    value: 'Facebook'
  },
  {
    label: 'Instagram',
    value: 'Instagram'
  },
  {
    label: 'Twitter',
    value: 'Twitter'
  },
  {
    label: 'Spotify',
    value: 'Spotify'
  },
  {
    label: 'SoundCloud',
    value: 'SoundCloud'
  },
  {
    label: 'Apple Music',
    value: 'Apple Music'
  },
  {
    label: 'Pandora',
    value: 'Pandora'
  },
  {
    label: 'Youtube',
    value: 'Youtube'
  }
]
