// @flow

import React from 'react'
import Navigation from 'src/components/organisms/Navigation'
import Discover from 'src/components/organisms/Discover'

export default () => {
  return (
    <Navigation pageTitle="Discover">
      <Discover />
    </Navigation>
  )
}
