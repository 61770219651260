// @flow
import base64ToBlob from 'src/utils/images/base64ToBlob'
import { readAndCompressImage } from 'browser-image-resizer'

const config = {
  quality: 0.8,
  width: 1000,
  height: 1000
}

type Image = {
  name: string,
  type: string,
  size: number,
  lastMod: number,
  lastModDate: string,
  img: string
}

export const resizeImage = async (coverImage: Image) => {
  const file = new File([base64ToBlob(coverImage.img)], coverImage.name, {
    type: coverImage.type,
    lastModified: coverImage.lastMod,
    lastModDate: coverImage.lastModDate,
    size: coverImage.size
  })
  const resizedImage = await readAndCompressImage(file, config)
  const resizedFile = new File([resizedImage], coverImage.name, {
    type: coverImage.type,
    lastModified: coverImage.lastMod,
    lastModDate: coverImage.lastModDate,
    size: coverImage.size
  })
  return resizedFile
}

export default (data: any) => {
  const formData = new FormData()

  Object.keys(data).forEach(key => {
    if (data[key]) {
      formData.append(key, data[key])
    }
  })
  return formData
}
