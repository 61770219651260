// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import LoadingIndicator from 'src/components/atoms/LoadingIndicator'

const useStyles = makeStyles(theme => ({
  container: {
    position: props => props.variant,
    zIndex: 10000,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF'
  },
  '@keyframes spinning': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' }
  }
}))

type FullPageLoadingProps = {
  variant?: 'fixed' | 'absolute'
}

export default ({ variant = 'absolute' }: FullPageLoadingProps) => {
  const styles = useStyles({ variant })
  return (
    <div className={styles.container}>
      <LoadingIndicator />
    </div>
  )
}
