// @flow

import React from 'react'
import DiscoverTemplate from 'src/components/templates/Discover'

export const AuthPage = () => {
  return <DiscoverTemplate />
}

export default AuthPage
