// @flow

import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { AuthHeader } from 'src/components/molecules/AuthHeader'
import { Welcome } from 'src/components/templates/auth/Welcome'
import { Login } from 'src/components/templates/auth/Login'
import { useAuth } from 'src/hooks/useAuth'
import { Signup } from 'src/components/templates/auth/Signup'

export const AuthPage = () => {
  const loc = useLocation()
  const history = useHistory()
  const {
    loggedIn,
    logout,
    signupWithEmail,
    loginWithEmail,
    loginWithGoogle,
    loginWithFacebook
  } = useAuth()

  const providerLogins = {
    onFacebookLogin: loginWithFacebook,
    onGoogleLogin: loginWithGoogle
  }

  const getAuthStep = () => {
    switch (loc.pathname) {
      case '/welcome':
        return (
          <Welcome
            onLoginClick={() => history.replace('/login')}
            onSignupClick={() => history.replace('/signup')}
          />
        )
      case '/signup':
        return (
          <Signup
            {...providerLogins}
            onEmailSignup={signupWithEmail}
            onEmailSignupSuccess={() => history.replace('/account-select')}
          />
        )
      case '/login':
        return (
          <Login
            {...providerLogins}
            onEmailLogin={loginWithEmail}
            onEmailLoginSuccess={() => history.push('/discover')}
          />
        )
      default:
        return <div />
    }
  }

  return (
    <>
      <AuthHeader logout={logout} loggedIn={loggedIn} />
      {getAuthStep()}
    </>
  )
}

export default AuthPage
