// @flow
import type { ThunkAction } from 'src/utils/types'
import { push } from 'react-router-redux'
import { getServiceErrors } from 'src/utils/api/apiErrors'
import { getConfig } from 'src/services/config'
import {
  GET_CONFIG_REQUEST,
  GET_CONFIG_SUCCESS
} from 'src/state/reducers/config'
import type { GetConfigService } from 'src/services/config'

export type GetConfigAction = ({ service?: GetConfigService }) => ThunkAction

export const getConfigAction: GetConfigAction = ({
  service = getConfig
}) => async dispatch => {
  try {
    dispatch({ type: GET_CONFIG_REQUEST })
    const { data } = await service()
    dispatch({ type: GET_CONFIG_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      Promise.reject(errors)
    }
  }
}
