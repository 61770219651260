// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'src/components/atoms/buttons/Button'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  error: {
    display: 'block',
    color: theme.palette.error.main,
    fontWeight: 'bold'
  }
}))

type DeleteAccountDialogProps = {
  isOpen?: boolean,
  onClose: () => void,
  deleteAccount: () => void,
  onDeleteAccountSuccess: () => void,
  error?: string,
  isDeleting?: boolean
}

export default ({
  isOpen,
  onClose,
  error,
  isDeleting,
  onDeleteAccountSuccess,
  deleteAccount
}: DeleteAccountDialogProps) => {
  const styles = useStyles()
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Close Account</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {error ? (
            <span className={styles.error}>{error}</span>
          ) : (
            'Are you sure you wish to close your account? Your profile will no longer appear on the website. If you login within the next 30 days, your account will be immediately reactivated. After a 30 day period your data will be permanently deleted.'
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={onClose}>
          CANCEL
        </Button>
        {!error && (
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            loading={isDeleting}
            disabled={isDeleting}
            onClick={async () => {
              await deleteAccount()
              onClose()
              onDeleteAccountSuccess()
            }}
          >
            CLOSE ACCOUNT
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
