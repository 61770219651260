// @flow
import { push } from 'react-router-redux'
import { getMessages, sendMessage } from 'src/services/messages'
import {
  getThreads,
  createThread,
  deleteThread,
  markThreadRead
} from 'src/services/threads'
import {
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_ERROR,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  GET_THREADS_REQUEST,
  GET_THREADS_SUCCESS,
  GET_THREADS_ERROR,
  CREATE_THREAD_REQUEST,
  CREATE_THREAD_SUCCESS,
  CREATE_THREAD_ERROR,
  DELETE_THREAD_REQUEST,
  DELETE_THREAD_SUCCESS,
  DELETE_THREAD_ERROR,
  MARK_THREAD_READ_REQUEST,
  MARK_THREAD_READ_SUCCESS,
  MARK_THREAD_READ_ERROR
} from 'src/state/reducers/inbox'
import { getServiceErrors } from 'src/utils/api/apiErrors'
import type {
  GetMessagesService,
  SendMessageService
} from 'src/services/messages'
import type {
  GetThreadsService,
  MarkThreadReadService,
  DeleteThreadService,
  CreateThreadService
} from 'src/services/threads'
import type { Thread } from 'src/utils/types/inbox'
import type { User } from 'src/utils/types/users'

import type { ThunkAction } from 'src/utils/types'

export type GetMessagesParams = {
  threadId: string,
  service?: GetMessagesService
}

export type GetMessagesAction = GetMessagesParams => ThunkAction

export const getMessagesAction: GetMessagesAction = ({
  threadId,
  service = getMessages
}) => async dispatch => {
  try {
    dispatch({ type: GET_MESSAGES_REQUEST })
    const { data } = await service({
      threadId,
      resultIndex: 0
    })
    dispatch({ type: GET_MESSAGES_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({
        type: GET_MESSAGES_ERROR,
        message: 'There was an error loading messages'
      })
    }
  }
}

export type SendMessageParams = {
  thread: Thread,
  currentUser: User,
  message: string,
  service?: SendMessageService
}

export type SendMessageAction = SendMessageParams => ThunkAction

export const sendMessageAction: SendMessageAction = ({
  thread,
  currentUser,
  message,
  service = sendMessage
}) => async dispatch => {
  try {
    dispatch({ type: SEND_MESSAGE_REQUEST })
    const { data } = await service({
      threadId: thread._id,
      author: currentUser._id,
      authorImage: currentUser.profile.profileImage,
      message,
      recipients: thread.members.map(member => member.userId)
    })
    dispatch({ type: SEND_MESSAGE_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({
        type: SEND_MESSAGE_ERROR,
        message: 'There was an error sending your message'
      })
    }
  }
}

export type GetThreadsParams = {
  service?: GetThreadsService
}

export type GetThreadsAction = GetThreadsParams => ThunkAction

export const getThreadsAction: GetThreadsAction = ({
  service = getThreads
}) => async dispatch => {
  try {
    dispatch({ type: GET_THREADS_REQUEST })
    const { data } = await service()
    dispatch({ type: GET_THREADS_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({
        type: GET_THREADS_ERROR,
        message: 'There was an error loading threads'
      })
    }
  }
}

export type CreateThreadParams = {
  members: string[],
  messagePreview: string,
  service?: CreateThreadService
}

export type CreateThreadAction = CreateThreadParams => ThunkAction

export const createThreadAction: CreateThreadAction = ({
  members,
  messagePreview,
  service = createThread
}) => async dispatch => {
  try {
    dispatch({ type: CREATE_THREAD_REQUEST })
    const { data } = await service({ members, messagePreview })
    dispatch({
      type: CREATE_THREAD_SUCCESS,
      payload: data
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({
        type: CREATE_THREAD_ERROR,
        message: 'There was an error creating this thread'
      })
    }
  }
}

export type DeleteThreadParams = {
  threadId: string,
  service?: DeleteThreadService
}

export type DeleteThreadAction = DeleteThreadParams => ThunkAction

export const deleteThreadAction: DeleteThreadAction = ({
  threadId,
  service = deleteThread
}) => async dispatch => {
  try {
    dispatch({ type: DELETE_THREAD_REQUEST })
    await service({ threadId })
    dispatch({
      type: DELETE_THREAD_SUCCESS,
      payload: { threadId }
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({
        type: DELETE_THREAD_ERROR,
        message: 'There was an error deleting this thread'
      })
    }
  }
}

export type MarkThreadReadParams = {
  userId: string,
  threadId: string,
  service?: MarkThreadReadService
}

export type MarkThreadReadAction = MarkThreadReadParams => ThunkAction

export const markThreadReadAction: MarkThreadReadAction = ({
  userId,
  threadId,
  service = markThreadRead
}) => async dispatch => {
  try {
    dispatch({
      type: MARK_THREAD_READ_REQUEST,
      payload: { threadId, userId }
    })
    await service({ threadId })
    dispatch({ type: MARK_THREAD_READ_SUCCESS })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({
        type: MARK_THREAD_READ_ERROR,
        message: 'There was an error marking this thread as read'
      })
    }
  }
}
