// @flow

import React from 'react'
import UpgradeTemplate from 'src/components/templates/Upgrade'

const UpgradePage = () => {
  return <UpgradeTemplate />
}

export default UpgradePage
