// @flow
import axios from 'src/settings/axios'
import type { PaymentRecord } from 'src/utils/types/payments'

export type PaymentErrorAction = 'REQUIRE_NEW_PAYMENT_METHOD'

export type SubmitPaymentResponse = {
  data: {
    success: boolean,
    action?: PaymentErrorAction
  }
}

export type SubmitPaymentService = ({
  paymentMethod: any,
  plan: 'Career' | 'Premium',
  planPeriod?: 'Monthly' | 'Yearly',
  coupon?: string
}) => Promise<SubmitPaymentResponse>

export const submitPayment: SubmitPaymentService = data =>
  axios.post('/payments/charge', data)

export type CancelSubscriptionResponse = {}

export type CancelSubscriptionService = () => Promise<CancelSubscriptionResponse>

export const cancelSubscription: CancelSubscriptionService = () =>
  axios.post('/payments/cancel')

export type GetPaymentRecordResponse = {
  data: PaymentRecord
}

export type GetPaymentRecordService = string => Promise<GetPaymentRecordResponse>

export const getPaymentRecord: GetPaymentRecordService = userId =>
  axios.get('/payments/record', {
    params: {
      userId
    }
  })

export type GetCheckoutSessionResponse = {
  data: string
}

export type GetCheckoutSessionService = () => Promise<GetCheckoutSessionResponse>

export const getCheckoutSession: GetCheckoutSessionService = () =>
  axios.post('/payments/checkout-session')
