// @flow

import React from 'react'
import ResetPasswordTemplate from 'src/components/templates/ResetPassword'

const ResetPassword = () => {
  return <ResetPasswordTemplate />
}

export default ResetPassword
