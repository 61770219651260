// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { reduxForm, SubmissionError } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from 'src/components/atoms/TextField'
import Button from 'src/components/atoms/buttons/Button'
import { updateUserAction } from 'src/state/actions/profile'
import { requiredField } from 'src/utils/fieldValidators'
import type { ReduxFormProps } from 'src/utils/types'

export const FORM_ID = 'editName'

const useStyles = makeStyles(theme => ({
  modalTitle: {
    fontWeight: 'bold'
  },
  dialog: {
    margin: 0,
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '90vw'
    }
  },
  dialogContent: {
    width: '100%',
    height: 'auto',
    minHeight: 75
  },
  dialogActions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  fieldGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': {
      marginRight: theme.spacing(2)
    }
  }
}))

type EditNameProps = {
  isOpen?: boolean,
  onClose: () => void
}

export const EditNameForm = ({
  pristine,
  submitting,
  invalid,
  error,
  isOpen,
  onClose,
  handleSubmit,
  ...props
}: EditNameProps & ReduxFormProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  return (
    <Dialog
      aria-labelledby="edit-name-dialog-title"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: styles.dialog
      }}
    >
      <form
        onSubmit={handleSubmit(async ({ firstname, lastname }) => {
          try {
            await dispatch(
              updateUserAction({ fields: { firstname, lastname } })
            )
            onClose()
          } catch (errors) {
            throw new SubmissionError(errors)
          }
        })}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            id="edit-name-dialog-title"
            className={styles.modalTitle}
          >
            Edit Name
          </Typography>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <div className={styles.fieldGroup}>
            <TextField
              required
              id="edit-firstname-field"
              name="firstname"
              label="First Name"
              placeholder="Manon"
              validators={[requiredField]}
              fullWidth
            />
            <TextField
              required
              id="edit-lastname-field"
              name="lastname"
              label="Last Name"
              placeholder="Ward"
              validators={[requiredField]}
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={onClose}>CANCEL</Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={pristine || submitting || invalid}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default reduxForm({
  form: FORM_ID
})(EditNameForm)
