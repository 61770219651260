// @flow

import type { Action } from 'src/utils/types'
import type { Splash, Comment } from 'src/utils/types/splashes'

export const CREATE_SPLASH_REQUEST: 'CREATE_SPLASH_REQUEST' =
  'CREATE_SPLASH_REQUEST'
export const CREATE_SPLASH_SUCCESS: 'CREATE_SPLASH_SUCCESS' =
  'CREATE_SPLASH_SUCCESS'
export const CREATE_SPLASH_ERROR: 'CREATE_SPLASH_ERROR' = 'CREATE_SPLASH_ERROR'

export const DELETE_SPLASH_REQUEST: 'DELETE_SPLASH_REQUEST' =
  'DELETE_SPLASH_REQUEST'
export const DELETE_SPLASH_SUCCESS: 'DELETE_SPLASH_SUCCESS' =
  'DELETE_SPLASH_SUCCESS'

export const GET_SPLASH_REQUEST: 'GET_SPLASH_REQUEST' = 'GET_SPLASH_REQUEST'
export const GET_SPLASH_SUCCESS: 'GET_SPLASH_SUCCESS' = 'GET_SPLASH_SUCCESS'

export const CLAP_REQUEST: 'CLAP_REQUEST' = 'CLAP_REQUEST'
export const CLAP_SUCCESS: 'CLAP_SUCCESS' = 'CLAP_SUCCESS'

export const GET_COMMENT_REQUEST: 'GET_COMMENT_REQUEST' = 'GET_COMMENT_REQUEST'
export const GET_COMMENT_SUCCESS: 'GET_COMMENT_SUCCESS' = 'GET_COMMENT_SUCCESS'
export const GET_COMMENT_ERROR: 'GET_COMMENT_ERROR' = 'GET_COMMENT_ERROR'

export const DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST' =
  'DELETE_COMMENT_REQUEST'
export const DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS' =
  'DELETE_COMMENT_SUCCESS'
export const DELETE_COMMENT_ERROR: 'DELETE_COMMENT_ERROR' =
  'DELETE_COMMENT_ERROR'

export const GET_REPLIES_REQUEST: 'GET_REPLIES_REQUEST' = 'GET_REPLIES_REQUEST'
export const GET_REPLIES_SUCCESS: 'GET_REPLIES_SUCCESS' = 'GET_REPLIES_SUCCESS'
export const GET_REPLIES_ERROR: 'GET_REPLIES_ERROR' = 'GET_REPLIES_ERROR'

export const POST_COMMENT_REQUEST: 'POST_COMMENT_REQUEST' =
  'POST_COMMENT_REQUEST'
export const POST_COMMENT_SUCCESS: 'POST_COMMENT_SUCCESS' =
  'POST_COMMENT_SUCCESS'
export const POST_COMMENT_ERROR: 'POST_COMMENT_ERROR' = 'POST_COMMENT_ERROR'

export const GET_SPLASHES_BY_USER_REQUEST: 'GET_SPLASHES_BY_USER_REQUEST' =
  'GET_SPLASHES_BY_USER_REQUEST'
export const GET_SPLASHES_BY_USER_SUCCESS: 'GET_SPLASHES_BY_USER_SUCCESS' =
  'GET_SPLASHES_BY_USER_SUCCESS'

export type SplashState = {
  isCreatingSplash: boolean,
  isLoadingSplashes: boolean,
  isDeletingSplash: boolean,
  isClapping: boolean,
  splashList: ?(Splash[]),
  splash: ?Splash,
  comments: Comment[],
  isFetchingComments: boolean,
  isFetchingReplies: boolean,
  isPostingComment: boolean,
  isDeletingComment: boolean,
  deleteCommentError?: string,
  postCommentError?: string,
  getCommentsError?: string
}

const splashState = {
  isCreatingSplash: false,
  isLoadingSplashes: false,
  isDeletingSplash: false,
  splashList: null,
  splash: null,
  isClapping: false,
  isFetchingComments: false,
  isFetchingReplies: false,
  isPostingComment: false,
  isDeletingComment: false,
  comments: []
}

const reducer = (state: SplashState = splashState, action: Action<any>) => {
  switch (action.type) {
    case CREATE_SPLASH_REQUEST:
      return { ...state, isCreatingSplash: true }
    case CREATE_SPLASH_SUCCESS:
      return { ...state, isCreatingSplash: false }
    case CREATE_SPLASH_ERROR:
      return { ...state, isCreatingSplash: false }
    case DELETE_SPLASH_REQUEST:
      return { ...state, isDeletingSplash: true }
    case DELETE_SPLASH_SUCCESS:
      return {
        ...state,
        isDeletingSplash: false,
        splashList:
          action.payload &&
          state.splashList &&
          deleteSplash(state.splashList, action.payload)
      }
    case GET_SPLASH_REQUEST:
      return { ...state, isLoadingSplashes: true }
    case GET_SPLASH_SUCCESS:
      return { ...state, isLoadingSplashes: false, splash: action.payload }
    case CLAP_REQUEST:
      return { ...state, isClapping: true }
    case CLAP_SUCCESS:
      return {
        ...state,
        isClapping: false,
        splashList:
          action.payload &&
          state.splashList &&
          updateSplashList(state.splashList, action.payload)
      }
    case GET_SPLASHES_BY_USER_REQUEST:
      return { ...state, isLoadingSplashes: true }
    case GET_SPLASHES_BY_USER_SUCCESS:
      return { ...state, isLoadingSplashes: false, splashList: action.payload }
    case GET_COMMENT_REQUEST:
      return { ...state, isFetchingComments: true }
    case GET_COMMENT_SUCCESS:
      return { ...state, comments: action.payload, isFetchingComments: false }
    case GET_COMMENT_ERROR:
      return {
        ...state,
        isFetchingComments: false,
        getCommentsError: 'Error fetching comments'
      }
    case DELETE_COMMENT_REQUEST:
      return { ...state, isDeletingComment: true }
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: deleteComment(action.payload, state.comments)
      }
    case DELETE_COMMENT_ERROR:
      return {
        ...state,
        isDeletingComment: false,
        deleteCommentError: 'Error deleting comment'
      }
    case POST_COMMENT_REQUEST:
      return { ...state, isPostingComment: true }
    case POST_COMMENT_SUCCESS:
      return {
        ...state,
        isPostingComment: false,
        //$FlowFixMe
        comments: addComment(action.payload, state.comments)
      }
    case POST_COMMENT_ERROR:
      return { ...state, isPostingComments: false }
    case GET_REPLIES_REQUEST:
      return { ...state, isFetchingReplies: true }
    case GET_REPLIES_SUCCESS:
      return {
        ...state,
        isFetchingReplies: false,
        comments: addRepliesToComment(
          state.comments,
          action.payload.commentId,
          action.payload.replies
        )
      }
    default:
      return state
  }
}

const updateSplashList = (splashList: Splash[], updatedSplash: Splash) => {
  const splashListCopy = [...splashList]
  const index = splashListCopy.findIndex(
    splash => splash._id === updatedSplash._id
  )
  if (index !== -1) splashListCopy[index] = updatedSplash
  return splashListCopy
}

const deleteSplash = (splashList: Splash[], id: string) =>
  splashList.filter<any>(splash => splash._id !== id)

const addRepliesToComment = (
  comments: Comment[],
  commentId: string,
  replies: Comment[]
) => {
  const comment = comments.find(com => com._id === commentId)
  if (comment) comment.replies = replies
  return comments
}

const addComment = (comment: Comment, comments: Comment[]) => {
  const parentComment = comments.find(
    com => comment.commentParentId === com._id
  )

  if (parentComment) {
    // eslint-disable-next-line no-unused-expressions
    if (parentComment.replies) {
      parentComment.replies.push(comment)
    } else {
      parentComment.replies = [comment]
    }
  } else {
    comments.push(comment)
  }

  return comments
}

const deleteComment = (commentId: string, comments: Comment[]) => {
  comments.forEach(com => {
    if (com.replies) {
      com.replies = com.replies.filter(reply => reply._id !== commentId)
    }
  })
  return comments.filter(com => com._id !== commentId)
}

export default reducer
