// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { reduxForm, SubmissionError } from 'redux-form'
import { createThreadAction } from 'src/state/actions/inbox'
import { searchUsersAction } from 'src/state/actions/users'
import TextFieldSelect from 'src/components/atoms/TextFieldSelect'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from 'src/components/atoms/TextField'
import Button from 'src/components/atoms/buttons/Button'
import SendIcon from '@material-ui/icons/Send'
import { requiredField } from 'src/utils/fieldValidators'
import type { ReduxFormProps } from 'src/utils/types'
import { useAnalytics } from 'src/hooks/useAnalytics'

export const FORM_ID = 'createThread'

const useStyles = makeStyles(theme => ({
  dialog: {
    margin: 0,
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '90vw'
    }
  },
  modalTitle: {
    fontWeight: 'bold'
  },
  dialogContent: {
    width: '100%',
    height: 'auto',
    minHeight: 400

    // overflow: 'hidden'
  },
  dialogActions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  memberSelect: {
    paddingBottom: theme.spacing(4),
    width: '100%'
  },
  sendIcon: {
    marginRight: theme.spacing(1)
  }
}))

type CreateThreadFormProps = {
  isOpen?: boolean,
  defaultRecipient?: {
    name: string,
    id: string
  },
  onClose: () => void
}

export const CreateThreadForm = ({
  defaultRecipient,
  pristine,
  submitting,
  invalid,
  error,
  isOpen,
  onClose,
  handleSubmit,
  ...props
}: CreateThreadFormProps & ReduxFormProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { analytics } = useAnalytics()

  const loadUserOptions = async (searchString: string) => {
    const users = await dispatch(searchUsersAction({ searchString }))
    return users.map(user => ({
      value: user._id,
      label: `${user.firstname} ${user.lastname}`
    }))
  }

  return (
    <Dialog
      aria-labelledby="create-message-modal-title"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: styles.dialog
      }}
    >
      <form
        onSubmit={handleSubmit(async ({ members, messagePreview }) => {
          if (!members || !members.length) {
            throw new SubmissionError({ member: 'recipient(s) required' })
          }
          try {
            const memberIds = members.map(member => member.value)
            await dispatch(
              createThreadAction({ members: memberIds, messagePreview })
            )
            analytics.logEvent('thread_created')
            onClose()
          } catch (errors) {
            throw new SubmissionError(errors)
          }
        })}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            id="create-message-modal-title"
            className={styles.modalTitle}
          >
            New Message
          </Typography>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <TextFieldSelect
            isMulti
            required
            fullWidth
            variant="async"
            id="add-recipient"
            label="Recipient"
            name="members"
            placeholder="Add Recipient..."
            validators={[requiredField]}
            options={[]}
            defaultOptions
            loadOptions={loadUserOptions}
          />
          <TextField
            required
            multiline
            fullWidth
            id="message-preview"
            name="messagePreview"
            placeholder="Hello..."
            label="Message"
            validators={[requiredField]}
          />
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={onClose}>CANCEL</Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={pristine || submitting || invalid}
          >
            <SendIcon fontSize="small" className={styles.sendIcon} />
            SEND
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default reduxForm({
  form: FORM_ID
})(CreateThreadForm)
