// @flow
export const useAnalytics = () => {
  let analytics = null
  const localLogEvent = (eventName: string, value: Object) => {}
  if (window.firebase?.analytics) {
    analytics = window.firebase.analytics()
  }
  return {
    analytics: analytics || { logEvent: localLogEvent }
  }
}
