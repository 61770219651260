// @flow
import axiosCore from 'axios'
import env, { firebaseApp } from 'src/settings/env'
import { history } from 'react-router-util'

const axios = axiosCore.create({
  withCredentials: true,
  baseURL: env.API_URL,
  timeout: 30000
})

axios.interceptors.request.use(function(config) {
  const token = localStorage.getItem('access_token')
  config.headers.Authorization = token
  return config
})

axios.interceptors.response.use(
  function(res) {
    return res
  },
  error => {
    console.log(error)

    if (error.response?.status === 401) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('user')
      firebaseApp.auth().signOut()
      history.replace('/login')
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error)
  }
)

export const matchPaths = (paths: string[]) =>
  !!paths.find(path => window.location.pathname.includes(path))

export default axios
