// @flow
import type { ThunkAction } from 'src/utils/types'
import { getServiceErrors } from 'src/utils/api/apiErrors'
import {
  verifyAccount,
  processReferral,
  createConnection,
  deleteUser,
  upgradeUser,
  downgradeUser,
} from 'src/services/admin'

import type {
  VerifyAccountService,
  ProcessReferralService,
  CreateConnectionService,
  DeleteUserService
} from 'src/services/admin'
import {
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  PROCESS_USER_REFERRAL_REQUEST,
  PROCESS_USER_REFERRAL_SUCCESS,
  PROCESS_USER_REFERRAL_ERROR,
  CREATE_CONNECTION_ERROR,
  CREATE_CONNECTION_REQUEST,
  CREATE_CONNECTION_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  UPGRADE_USER_ERROR,
  UPGRADE_USER_REQUEST,
  UPGRADE_USER_SUCCESS,
  DOWNGRADE_USER_ERROR,
  DOWNGRADE_USER_REQUEST,
  DOWNGRADE_USER_SUCCESS
} from 'src/state/reducers/admin'

export type VerifyAccountAction = ({
  service?: VerifyAccountService,
  email: string,
  verified: boolean
}) => ThunkAction

export const verifyAccountAction: VerifyAccountAction = ({
  service = verifyAccount,
  email,
  verified
}) => async dispatch => {
  try {
    dispatch({ type: VERIFY_ACCOUNT_REQUEST })
    await service({ email, verified })
    dispatch({ type: VERIFY_ACCOUNT_SUCCESS })
  } catch (err) {
    getServiceErrors(err)
  }
}

export type ProcessReferralAction = ({
  service?: ProcessReferralService,
  email: string,
  approved: boolean,
  feedback: string
}) => ThunkAction

export const processReferralAction: ProcessReferralAction = ({
  service = processReferral,
  email,
  approved,
  feedback
}) => async dispatch => {
  try {
    dispatch({ type: PROCESS_USER_REFERRAL_REQUEST })
    await service({ email, approved, feedback })
    dispatch({ type: PROCESS_USER_REFERRAL_SUCCESS })
  } catch (err) {
    getServiceErrors(err)
    dispatch({ type: PROCESS_USER_REFERRAL_ERROR, payload: err.message })
  }
}

export type CreateConnectionAction = ({
  service?: CreateConnectionService,
  userA: string,
  userB: string
}) => ThunkAction

export const createConnectionAction: CreateConnectionAction = ({
  service = createConnection,
  userA,
  userB
}) => async dispatch => {
  try {
    dispatch({ type: CREATE_CONNECTION_REQUEST })
    await service({ userA, userB })
    dispatch({ type: CREATE_CONNECTION_SUCCESS })
  } catch (err) {
    getServiceErrors(err)
    dispatch({ type: CREATE_CONNECTION_ERROR, payload: err.message })
  }
}

export type DeleteUserAction = ({
  service?: DeleteUserService,
  email: string
}) => ThunkAction

export const deleteUserAction: DeleteUserAction = ({
  service = deleteUser,
  email
}) => async dispatch => {
  try {
    dispatch({ type: DELETE_USER_REQUEST })
    await service({ email })
    dispatch({ type: DELETE_USER_SUCCESS })
  } catch (err) {
    getServiceErrors(err)
    dispatch({ type: DELETE_USER_ERROR, payload: err.response.data })
  }
}

export type UpgradeUserAction = ({
  service?: UpgradeUserService,
  email: string
}) => ThunkAction

export const upgradeUserAction: UpgradeUserAction = ({
  service = upgradeUser,
  email
}) => async dispatch => {
  try {
    dispatch({ type: UPGRADE_USER_REQUEST })
    await service({ email })
    dispatch({ type: UPGRADE_USER_SUCCESS })
  } catch (err) {
    getServiceErrors(err)
    dispatch({ type: UPGRADE_USER_ERROR, payload: err.response.data })
  }
}


export type DowngradeUserAction = ({
  service?: DowngradeUserService,
  email: string
}) => ThunkAction

export const downgradeUserAction: DowngradeUserAction = ({
  service = downgradeUser,
  email
}) => async dispatch => {
  try {
    dispatch({ type: DOWNGRADE_USER_REQUEST })
    await service({ email })
    dispatch({ type: DOWNGRADE_USER_SUCCESS })
  } catch (err) {
    getServiceErrors(err)
    dispatch({ type: DOWNGRADE_USER_ERROR, payload: err.response.data })
  }
}