// @flow

import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    flex: 1
  },
  icon: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    width: 65,
    height: 65,
    [theme.breakpoints.down('sm')]: {
      width: 55,
      height: 55
    }
  },
  desc: {
    textAlign: 'center',
    color: theme.palette.text.label,
    fontWeight: 'normal',
    marginTop: theme.spacing(2),
    display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center'
  },
  check: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  }
}))

type FeaturedPlaylistProps = {
  Icon: any,
  header: string,
  desc?: string,
  perks?: string[]
}

export default ({ Icon, header, desc, perks = [] }: FeaturedPlaylistProps) => {
  const styles = useStyles({})
  return (
    <React.Fragment>
      <div className={styles.container}>
        <Icon className={styles.icon} />
        <Typography variant="h6">{header}</Typography>
        {perks.map(perk => (
          <Typography key={perk} className={styles.desc} variant="subtitle1">
            {perk}
          </Typography>
        ))}
        {
          <Typography className={styles.desc} variant="subtitle1">
            {desc}
          </Typography>
        }
      </div>
    </React.Fragment>
  )
}
