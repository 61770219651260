// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import Tabs from 'src/components/atoms/Tabs'
import Tab from 'src/components/atoms/Tab'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  container: {
    width: '90%',
    margin: 'auto',
    marginTop: theme.spacing(5)
  },
  contentBackground: {
    width: '100%',
    minHeight: '50%',
    backgroundColor: theme.palette.background.main,
    paddingBottom: theme.spacing(5)
  },
  contentBody: {
    position: 'relative',
    width: '100%',
    margin: 'auto',
    overflow: 'hidden',
    minHeight: 350
  },
  tabs: {
    marginBottom: 0,
    marginTop: theme.spacing(7)
  }
}))

// type TabLabel = 'Content' | 'Music' | 'Stats'

type RenderProps = {
  activeTab: string
}

type ProfileContentProps = {
  labels: string[],
  component: RenderProps => React.Node,
  defaultTab?: string
}

const labelToTabIndex = (labels: string[], label: string) => {
  switch (label) {
    case labels[0]:
      return 0
    case labels[1]:
      return 1
    case labels[2]:
      return 2
    default:
      return 0
  }
}

export default ({
  component,
  labels,
  defaultTab = 'Music'
}: ProfileContentProps) => {
  const styles = useStyles()
  const theme = useTheme()
  const isXS = useMediaQuery(theme.breakpoints.down('xs'))
  const [activeTab, setActiveTab] = React.useState(defaultTab)

  const renderTabs = () =>
    labels.map((label, index) => (
      <Tab
        key={`${label}-${index}`}
        label={label}
        onClick={() => setActiveTab(labels[index])}
      />
    ))

  return (
    <React.Fragment>
      <div className={styles.container}>
        <Tabs
          variant={isXS ? 'fullWidth' : 'standard'}
          className={styles.tabs}
          defaultTab={labelToTabIndex(labels, defaultTab)}
        >
          {renderTabs()}
        </Tabs>
      </div>
      <div className={styles.contentBackground}>
        <div className={styles.contentBody}>{component({ activeTab })}</div>
      </div>
    </React.Fragment>
  )
}
