// @flow

export const YES_NO_FIELD = [
  { name: 'Yes', value: true },
  { name: 'No', value: false }
]
export default [
  {
    value: 'Can Sing the Demo',
    label: 'Can Sing the Demo'
  },
  {
    value: 'Can Produce the Demo',
    label: 'Can Produce the Demo'
  },
  {
    value: 'Can Play Instrument on Demo',
    label: 'Can Play Instrument on Demo'
  },
  {
    value: 'Has a Songplugger',
    label: 'Has a Songplugger'
  }
]
