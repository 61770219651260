// @flow

import { push } from 'react-router-redux'
import type { ThunkAction } from 'src/utils/types'
import {
  FILTER_NETWORK_REQUEST,
  FILTER_NETWORK_SUCCESS,
  LOAD_MORE_NETWORK_RESULTS_REQUEST,
  LOAD_MORE_NETWORK_RESULTS_SUCCESS
} from 'src/state/reducers/network'
import { filterNetwork } from 'src/services/network'
import { getServiceErrors } from 'src/utils/api/apiErrors'
import type { NetworkRelationship, Filters } from 'src/utils/types/network'
import type { FilterNetworkService } from 'src/services/network'
import type {
  ConfirmConnectionService,
  DenyConnectionService
} from 'src/services/network'
import { confirmConnection, denyConnection } from 'src/services/network'

const reduceFilters = filters => {
  const keys = Object.keys(filters)
  keys.forEach(key => {
    if (filters[key] === 'All') {
      delete filters[key]
    }
  })
}

export type FilterNetworkParams = {
  loadMore?: boolean,
  relationship: NetworkRelationship,
  resultIndex?: number,
  filters?: Filters,
  service?: FilterNetworkService
}

export type FilterNetworkAction = FilterNetworkParams => ThunkAction

export const filterNetworkAction: FilterNetworkAction = ({
  loadMore,
  relationship,
  resultIndex = 0,
  filters,
  service = filterNetwork
}) => async dispatch => {
  try {
    filters && reduceFilters(filters)

    if (loadMore) {
      dispatch({ type: LOAD_MORE_NETWORK_RESULTS_REQUEST })
      const { data } = await service({
        relationship,
        resultIndex,
        ...filters
      })

      dispatch({ type: LOAD_MORE_NETWORK_RESULTS_SUCCESS, payload: data })
    } else {
      dispatch({ type: FILTER_NETWORK_REQUEST })
      const { data } = await service({
        relationship,
        resultIndex,
        ...filters
      })

      dispatch({ type: FILTER_NETWORK_SUCCESS, payload: data })
    }
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
    console.error(err)
  }
}

export type ConfirmConnectionParams = {
  sender: string,
  service?: ConfirmConnectionService
}

export type ConfirmConnectionAction = ConfirmConnectionParams => ThunkAction

export const confirmConnectionAction: ConfirmConnectionAction = ({
  sender,
  service = confirmConnection
}) => async dispatch => {
  try {
    await service({ sender })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
  }
}

export type DenyConnectionParams = {
  requesterId: string,
  service?: DenyConnectionService
}

export type DenyConnectionAction = DenyConnectionParams => ThunkAction

export const denyConnectionAction: DenyConnectionAction = ({
  requesterId,
  service = denyConnection
}) => async dispatch => {
  try {
    await service({ requesterId })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
  }
}
