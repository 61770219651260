// @flow

type Option = {
  name: string,
  value: string
}

export const SONGWRITER_TYPES = [
  { name: 'Hit Songwriter', value: 'Hit Songwriter' },
  { name: 'Songwriter', value: 'Songwriter' },
  { name: 'Artist/Writer', value: 'Artist/Writer' },
  { name: 'Writer/Producer', value: 'Writer/Producer' }
]

export const LIVE_PERFORMANCE_OPTIONS = [
  {
    value: 'Open Mics & Rounds',
    name: 'Open Mics & Rounds'
  },
  {
    value: 'Solo shows',
    name: 'Solo shows'
  },
  {
    value: 'Full band shows',
    name: 'Full band shows'
  }
]

export const SHOWS_PER_YEAR = [
  {
    value: 'Under 50',
    name: 'Under 50'
  },
  {
    value: '51-100',
    name: '51-100'
  },
  {
    value: '101-150',
    name: '101-150'
  },
  {
    value: '151-200',
    name: '151-200'
  },
  {
    value: '201+',
    name: '201+'
  }
]

export const GENRES: Option[] = [
  { name: 'Americana', value: 'Americana' },
  { name: 'Blue Grass', value: 'Blue Grass' },
  { name: 'Country (Contemporary)', value: 'Country (Contemporary)' },
  { name: 'Country (Traditional)', value: 'Country (Traditional)' },
  { name: 'EDM', value: 'EDM' },
  { name: 'Jazz', value: 'Jazz' },
  { name: 'Reggae', value: 'Reggae' },
  { name: 'Sync', value: 'Sync' },
  { name: 'TV/Film', value: 'TV/Film' },
  { name: 'Pop', value: 'Pop' },
  { name: 'CCM', value: 'CCM' },
  { name: 'Gospel', value: 'Gospel' },
  { name: 'Rock', value: 'Rock' },
  { name: 'Hip Hop', value: 'Hip Hop' },
  { name: 'Latin', value: 'Latin' },
  { name: 'R&B Urban', value: 'R&B Urban' },
  { name: 'Singer/Songwriter', value: 'Singer/Songwriter' }
].sort((a, b) => a.name.localeCompare(b.name))

export const RECENT_SIGNUPS = [
  { name: 'All', value: 'All' },
  { name: '< 30 Days Ago', value: 30 },
  { name: '< 60 Days Ago', value: 60 },
  { name: '< 90 Days Ago', value: 90 }
]

export const PUBLISHING_AVAILABLE = [
  { name: 'Available', value: true },
  { name: 'Unavailable', value: false }
]

export const TOP_CHART_SONG_AVAILABLE = [
  { name: 'Not Required', value: false },
  { name: 'Required', value: true }
]

export const PUBLISHING_FLAGS = [
  { name: 'Catalog For Sale', value: 'catalogForSale' },
  { name: 'Seeking Pub Deal', value: 'proSeekingDeal' }
]

export const SKILLS: Option[] = [
  { name: 'Lyrics', value: 'Lyrics' },
  { name: 'Melody', value: 'Melody' },
  { name: 'Demo Vocalist', value: 'Demo Vocalist' },
  { name: 'Demo Producer', value: 'Demo Producer' },
  { name: 'Demo BGVs', value: 'Demo BGVs' },
  {
    name: 'Demo Instrumentalist',
    value: 'Demo Instrumentalist'
  },
  { name: 'Has a Songplugger', value: 'Has a Songplugger' },
  { name: 'Has a Sync Agent', value: 'Has a Sync Agent' }
].sort((a, b) => a.name.localeCompare(b.name))

export const EDUCATION = [
  { name: 'Songwriting Program', value: 'Songwriting Program' },
  { name: 'Audio Program', value: 'Audio Program' },
  { name: 'Music Undergraduate', value: 'Undergraduate' },
  { name: 'Music Masters', value: 'Masters' }
]

export const REFERAL_TYPES = [
  { name: 'All', value: 'All' },
  { name: 'Non Referred', value: 'Non Referred' },
  { name: 'Referred', value: 'Referred' }
]
