// @flow

import React from 'react'
import UpgradeTemplateOld from 'src/components/templates/UpgradeOld'

const UpgradePage = () => {
  return <UpgradeTemplateOld />
}

export default UpgradePage
