// @flow
import React from 'react'
import { Field } from 'redux-form'
import { makeStyles } from '@material-ui/styles'
import MuiTextField from '@material-ui/core/TextField'
import numeral from 'numeral'
import type { FieldProps } from 'redux-form'
import type { FieldValidator } from 'src/utils/fieldValidators'

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    marginBottom: props => (props.noMargin ? 0 : theme.spacing(2.5))
  },
  input: {
    textAlign: props => (props.center ? 'center' : 'left'),
    paddingLeft: theme.spacing(1),
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: '0.9rem'
  },
  root: {
    width: '100%',
    color: theme.palette.primary.main
  },
  labelFocused: {
    '&&': {
      color: `${theme.palette.primary.main}`
    }
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: theme.spacing(2),
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    color: theme.palette.error.main
  }
}))

type TextFieldProps = {
  id: string,
  label?: string,
  center?: boolean,
  touched?: boolean,
  error?: string,
  active?: boolean,
  value?: string,
  isNumber?: boolean,
  noMargin?: boolean,
  required?: boolean,
  numerical?: boolean
}

export const TextField = ({
  id,
  label,
  required,
  touched,
  active,
  error,
  isNumber,
  center,
  noMargin,
  ...props
}: TextFieldProps) => {
  const styles = useStyles({ center, noMargin })
  const errorId = `${id}-error`

  return (
    <div className={styles.container}>
      <MuiTextField
        id={id}
        //variant="outlined"
        required={required}
        className={styles.textField}
        classes={{
          root: styles.root
        }}
        label={label}
        inputProps={{
          className: styles.input,
          autoCapitalize: 'none'
        }}
        error={touched && !!error}
        aria-errormessage={errorId}
        {...props}
      />
      {touched && error && (
        <div className={styles.errorMessage}>
          <span id={errorId}>
            <strong>{error}</strong>
          </span>
        </div>
      )}
    </div>
  )
}

const renderTextField = ({
  id,
  input,
  meta,
  label,
  ...props
}: TextFieldProps & FieldProps) => (
  <TextField
    id={id}
    label={label}
    touched={meta.touched}
    error={meta.error}
    active={meta.active}
    {...input}
    {...props}
  />
)

type ConnectedTextFieldProps = {
  name: string,
  validators?: FieldValidator[]
} & TextFieldProps

export default ({
  name,
  id,
  label,
  numerical,
  validators = [],
  ...props
}: ConnectedTextFieldProps) => {
  const numericFormatting = numerical
    ? {
        format: val => (numerical ? numeral(val).format('0,0') : val),
        parse: val => (numerical ? numeral(val).value() : val)
      }
    : {}
  return (
    <Field
      name={name}
      id={id}
      label={label}
      component={renderTextField}
      validate={[...validators]} // not ideal since validators is already an array.. but flow complains otherwise
      {...numericFormatting}
      {...props}
    />
  )
}
