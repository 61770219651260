// @flow
import axios from 'src/settings/axios'
import type { User } from 'src/utils/types/users'
import type { Connection, NetworkEntry, Filters } from 'src/utils/types/network'

type FilterNetworkResponse = {
  data: Connection[] | User[]
}

export type FilterNetworkService = Filters => Promise<FilterNetworkResponse>

export const filterNetwork: FilterNetworkService = filters =>
  axios.post('/network/filter', {
    ...filters
  })

type AddConnectionResponse = {
  data: Connection[] | User[]
}
type AddConnectionRequest = {
  recipient: string
}

export type AddConnectionService = AddConnectionRequest => Promise<AddConnectionResponse>

export const addConnection: AddConnectionService = data =>
  axios.post('/network/connection', {
    ...data
  })

type ConfirmConnectionRequest = {
  sender: string
}

export type ConfirmConnectionService = ConfirmConnectionRequest => Promise<{}>

export const confirmConnection: ConfirmConnectionService = data =>
  axios.post('/network/connection/confirm', {
    ...data
  })

type RemoveConnectionResponse = {
  data: Connection[] | User[]
}
type RemoveConnectionRequest = {
  recipient: string,
  notifId?: string
}

export type RemoveConnectionService = RemoveConnectionRequest => Promise<RemoveConnectionResponse>

export const removeConnection: RemoveConnectionService = data =>
  axios.delete('/network/connection', {
    params: { ...data }
  })

type DenyConnectionRequest = {
  requesterId: string
}

export type DenyConnectionService = DenyConnectionRequest => Promise<{}>

export const denyConnection: DenyConnectionService = data =>
  axios.post('/network/connection/deny', {
    ...data
  })

type AddNetworkResponse = {
  data: Connection[] | User[]
}
type AddNetworkRequest = {
  recipient: string
}

export type AddNetworkService = AddNetworkRequest => Promise<AddNetworkResponse>

export const addNetwork: AddNetworkService = data =>
  axios.post('/network/follow', {
    ...data
  })

type RemoveNetworkRequest = {
  recipient: string
}

export type RemoveNetworkService = RemoveNetworkRequest => Promise<boolean>

export const removeNetwork: RemoveNetworkService = data =>
  axios.post('/network/unfollow', {
    ...data
  })

export type GetNetworkResponse = {
  data: NetworkEntry[]
}

export type GetNetworkService = ({
  userId: string
}) => Promise<GetNetworkResponse>

export const getNetwork: GetNetworkService = data =>
  axios.get('/network', {
    params: data
  })
