// @flow

import React from 'react'
import DashboardTemplate from 'src/components/templates/Dashboard'

const DashboardPage = () => {
  return <DashboardTemplate />
}

export default DashboardPage
