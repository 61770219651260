// @flow

type ErrorMessage = {
  value: string,
  msg: string,
  param: string
}

type ServiceError = {
  response?: {
    status: number,
    data: {
      errorMessage?: string,
      error: ErrorMessage[]
    }
  }
}

export const getServiceErrors = (err: ServiceError) => {
  // if (
  //   err.response &&
  //   (err.response.status === 504 ||
  //     err.response.status === 401 ||
  //     err.response.status === 403)
  // ) {

  //   history.push('/login')
  // }

  // // a 404 error means user doesn't exist in Sooner yet, so direct them to signup flow
  // // this can happen when logging in with 3rd party providers or when a user drops off
  // // the signup flow before creating a sooner account
  // if (err.response?.status === 404) {
  //   console.log('user has not finished signup')
  //   history.push('/account-select')
  // }

  if (err.response?.data?.errorMessage) {
    return { _error: err.response?.data?.errorMessage }
  }

  // if (err.response?.data?.error) {
  //   const errorMsgObj = {}
  //   //$FlowFixMe
  //   err.response.data.error.forEach(
  //     error => (errorMsgObj[error.param] = error.msg)
  //   )

  //   return {
  //     _error: 'Please fix the errors above and try again.',
  //     ...errorMsgObj
  //   }
  // }
  return ''
}
