// @flow

import type { Action, Reducer } from 'src/utils/types'
import type { User } from 'src/utils/types/users'

export const FILTER_USERS_REQUEST: 'FILTER_USERS_REQUEST' =
  'FILTER_USERS_REQUEST'
export const FILTER_USERS_SUCCESS: 'FILTER_USERS_SUCCESS' =
  'FILTER_USERS_SUCCESS'

export const SEARCH_USERS_REQUEST: 'SEARCH_USERS_REQUEST' =
  'SEARCH_USERS_REQUEST'
export const SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS' =
  'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_ERROR: 'SEARCH_USERS_ERROR' = 'SEARCH_USERS_ERROR'

export const DISABLE_LOAD_MORE_USERS_BUTTON: 'DISABLE_LOAD_MORE_USERS_BUTTON' =
  'DISABLE_LOAD_MORE_USERS_BUTTON'
export const LOAD_MORE_USER_RESULTS_REQUEST: 'LOAD_MORE_USER_RESULTS_REQUEST' =
  'LOAD_MORE_USER_RESULTS_REQUEST'

export const LOAD_MORE_USER_RESULTS_SUCCESS: 'LOAD_MORE_USER_RESULTS_SUCCESS' =
  'LOAD_MORE_USER_RESULTS_SUCCESS'

export const DELETE_USER_REQUEST: 'DELETE_USER_REQUEST' = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS' = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR: 'DELETE_USER_ERROR' = 'DELETE_USER_ERROR'

export const UPGRADE_USER_REQUEST: 'UPGRADE_USER_REQUEST' = 'UPGRADE_USER_REQUEST'
export const UPGRADE_USER_SUCCESS: 'UPGRADE_USER_SUCCESS' = 'UPGRADE_USER_SUCCESS'
export const UPGRADE_USER_ERROR: 'UPGRADE_USER_ERROR' = 'UPGRADE_USER_ERROR'

export const DOWNGRADE_USER_REQUEST: 'DOWNGRADE_USER_REQUEST' = 'DOWNGRADE_USER_REQUEST'
export const DOWNGRADE_USER_SUCCESS: 'DOWNGRADE_USER_SUCCESS' = 'DOWNGRADE_USER_SUCCESS'
export const DOWNGRADE_USER_ERROR: 'DOWNGRADE_USER_ERROR' = 'DOWNGRADE_USER_ERROR'

export type UsersState = {
  userList: User[],
  disableLoadMore: boolean,
  isLoadingMore: boolean,
  isLoadingFilteredUsers: boolean,
  isDeletingUser: boolean,
  deleteUserError?: string,
  isUpgradingUser: boolean,
  upgradeUserError?: string,
  isDowngradingUser: boolean,
  downgradeUserError?: string,
  resultIndex: number
}

const usersState = {
  userList: [],
  disableLoadMore: false,
  isLoadingMore: false,
  isLoadingFilteredUsers: false,
  isDeletingUser: false,
  isUpgradingUser: false,
  isDowngradingUser: false,
  resultIndex: 0
}

const reducer: Reducer<UsersState> = (
  state: UsersState = usersState,
  action: Action<any>
) => {
  switch (action.type) {
    case FILTER_USERS_REQUEST:
      return {
        ...state,
        isLoadingFilteredUsers: true,
        disableLoadMore: false,
        userList: []
      }
    case FILTER_USERS_SUCCESS:
      return {
        ...state,
        isLoadingFilteredUsers: false,
        disableLoadMore: action.payload ? action.payload.length < 10 : false,
        userList: action.payload,
        resultIndex: action.payload && action.payload.length
      }
    case LOAD_MORE_USER_RESULTS_REQUEST:
      return {
        ...state,
        isLoadingMore: true
      }
    case LOAD_MORE_USER_RESULTS_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        disableLoadMore: action.payload ? action.payload.length < 10 : false,
        userList: action.payload
          ? state.userList.concat(action.payload)
          : state.userList,
        resultIndex:
          action.payload && action.payload.length + state.userList.length
      }
    case SEARCH_USERS_REQUEST:
      return {
        ...state,
        searchUserResults: []
      }
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searchUserResults: action.payload
      }
    case SEARCH_USERS_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case DISABLE_LOAD_MORE_USERS_BUTTON: {
      return {
        ...state,
        disableLoadMore: true
      }
    }
    case DELETE_USER_REQUEST:
      return {
        ...state,
        isDeletingUser: true
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeletingUser: false
      }
    case DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserError: action.payload
      }
    case UPGRADE_USER_REQUEST:
      return {
        ...state,
        isUpgradingUser: true
      }
    case UPGRADE_USER_SUCCESS:
      return {
        ...state,
        isUpgradingUser: false
      }
    case UPGRADE_USER_ERROR:
      return {
        ...state,
        upgradeUserError: action.payload
      }
    case DOWNGRADE_USER_REQUEST:
      return {
        ...state,
        isDowngradingUser: true
      }
    case DOWNGRADE_USER_SUCCESS:
      return {
        ...state,
        isDowngradingUser: false
      }
    case DOWNGRADE_USER_ERROR:
      return {
        ...state,
        downgradeUserError: action.payload
      }
    default:
      return state
  }
}

export default reducer
