/* eslint-disable flowtype/space-after-type-colon */
// @flow

import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Flex } from 'src/components/molecules/Flex'
import { GenericIcon } from 'src/components/atoms/icons/GenericIcon'
import { useStyles } from './index.styles'
import type { SocialNetworkNames } from 'src/utils/types/users'
type MusicAccountLinkProps = {
  platform: SocialNetworkNames,
  url: string,
  followers: number
}

export const MusicAccountLink = ({
  platform,
  url,
  followers
}: MusicAccountLinkProps) => {
  const styles = useStyles()
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      className={styles.musicAccountLink}
    >
      <GenericIcon platform={platform} ariaLabel="account link" size="md" />
      <Flex container flexDirection="column" margin="0 0 0 16px">
        <Typography variant="h6">{platform}</Typography>
        <Typography variant="subtitle1" className={styles.subHeader}>
          {followers} {platform === 'Youtube' ? 'subscribers' : 'followers'}
        </Typography>
      </Flex>
    </a>
  )
}
