// @flow

import type { Action } from 'src/utils/types'
import type { Splash } from 'src/utils/types/splashes'

export const GET_SPLASH_FEED_REQUEST: 'GET_SPLASH_FEED_REQUEST' =
  'GET_SPLASH_FEED_REQUEST'
export const GET_SPLASH_FEED_SUCCESS: 'GET_SPLASH_FEED_SUCCESS' =
  'GET_SPLASH_FEED_SUCCESS'
export const GET_SPLASH_FEED_ERROR: 'GET_SPLASH_FEED_ERROR' =
  'GET_SPLASH_FEED_ERROR'

export type DashboardState = {
  isFetchingFeed: boolean,
  feed: Splash[],
  feedIndex: number,
  endOfFeed: boolean
}

const dashboardState = {
  isFetchingFeed: false,
  feed: [],
  feedIndex: 0,
  endOfFeed: false
}

const reducer = (
  state: DashboardState = dashboardState,
  action: Action<any>
) => {
  switch (action.type) {
    case GET_SPLASH_FEED_REQUEST:
      return { ...state, isFetchingFeed: true }
    case GET_SPLASH_FEED_SUCCESS:
      return {
        ...state,
        isFetchingFeed: false,
        //$FlowFixMe
        feed: state.feed.concat(action.payload),
        feedIndex: action.payload
          ? state.feedIndex + action.payload.length
          : state.feedIndex,
        endOfFeed: action.payload && action.payload.length < 15 ? true : false
      }
    case GET_SPLASH_FEED_ERROR:
      return { ...state, isFetchingFeed: false }
    default:
      return state
  }
}

export default reducer
