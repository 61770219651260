// @flow

import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import ThreadList from 'src/components/organisms/ThreadList'
import ChatRoom from 'src/components/organisms/ChatRoom'
import CreateThreadForm from 'src/components/organisms/forms/CreateThread'
import { getThreadsAction } from 'src/state/actions/inbox'
import type { Thread } from 'src/utils/types/inbox'
import type { InboxState } from 'src/state/reducers/inbox'

const inboxSelector = state => state.inbox

const getActiveThread = (threads: Thread[], threadId: string) =>
  threads.find(thread => thread._id === threadId) || null

const openCreateThreadFromQuery = () => {
  const url = window.location.href
  const queryParams = url.split('?')
  if (queryParams.length > 1) {
    return true
  }
  return false
}

export default () => {
  const inboxState: InboxState = useSelector(inboxSelector, shallowEqual)
  const dispatch = useDispatch()
  const [activeThreadId, setActiveThreadId] = useState('')
  const { threadId } = useParams()
  const [createThreadOpen, setCreateThreadOpen] = useState(
    openCreateThreadFromQuery()
  )

  useEffect(() => {
    if (inboxState.threads.length && threadId && !activeThreadId) {
      setActiveThreadId(threadId)
    }
    if (!inboxState.threads.length) dispatch(getThreadsAction({}))
  }, [activeThreadId, dispatch, inboxState.threads.length, threadId])

  return (
    <React.Fragment>
      <ThreadList
        activeThreadId={activeThreadId}
        setActiveThreadId={setActiveThreadId}
        onCreateNewThread={() => setCreateThreadOpen(true)}
        threads={inboxState.threads}
        isLoading={inboxState.isLoadingThreads}
      />
      <ChatRoom
        thread={getActiveThread(inboxState.threads, activeThreadId)}
        isOpen={!!activeThreadId}
        isLoading={inboxState.isLoadingMessages}
        onExit={() => setActiveThreadId('')}
      />
      <CreateThreadForm
        onClose={() => setCreateThreadOpen(false)}
        isOpen={createThreadOpen}
      />
    </React.Fragment>
  )
}
