// @flow
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import FormLabel from '@material-ui/core/FormLabel'
import { Field } from 'redux-form'
import type { FieldProps } from 'redux-form'
import RadioButton from 'src/components/atoms/buttons/RadioButton'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import classnames from 'classnames'

import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  radioFieldContainer: {
    marginBottom: theme.spacing(2)
  },
  radioButtonLabel: {
    display: 'block',
    fontSize: '0.75rem',
    marginBottom: theme.spacing(2)
  },
  options: {
    width: '100%',
    margin: props => (props.center ? 'auto' : '0')
  },
  showAll: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  showAllArrow: {
    transition: 'transform 0.2s',
    transform: props => (props.showAll ? 'rotate(180deg)' : 'rotate(0deg)')
  }
}))

export type RadioFieldOption = {
  name: string,
  value: any
}

type RadioFieldProps = {
  options: RadioFieldOption[],
  label: string,
  multiSelect?: boolean,
  disabled?: boolean,
  center?: boolean,
  expand?: boolean,
  onChange: (?Array<any> | ?any) => void,
  darkLabel?: boolean,
  activeOptions: Array<any> | string,
  className?: string
}

export const RadioField = ({
  className,
  label,
  options,
  activeOptions,
  center,
  multiSelect,
  onChange,
  darkLabel,
  expand,
  disabled,
  ...props
}: RadioFieldProps) => {
  const [showAll, setShowAll] = useState(false)
  const styles = useStyles({ center, showAll, darkLabel, disabled })

  const onClick = option => {
    const { value } = option
    if (multiSelect) {
      const activeOpts = activeOptions instanceof Array ? activeOptions : [] //value can be array or string
      if (activeOpts.includes(value)) {
        const result = activeOpts.filter(opt => opt !== value)
        onChange(result.length ? result : undefined) //set to undefined if empty so it's not included in filter
      } else {
        onChange(activeOpts.concat(value))
      }
    } else {
      onChange(value)
    }
  }

  const isActive = option =>
    multiSelect
      ? activeOptions.includes(option.value)
      : activeOptions === option.value

  const optionButtons = options
    .map((option: RadioFieldOption) => (
      <RadioButton
        key={option.name}
        onClick={() => onClick(option)}
        active={isActive(option)}
        disabled={disabled}
      >
        {option.name}
      </RadioButton>
    ))
    .slice(0, showAll || expand ? options.length : 4)
  return (
    <div className={classnames(styles.radioFieldContainer, className)}>
      <FormLabel className={styles.radioButtonLabel} component="label">
        {label}
      </FormLabel>
      <div className={styles.options}>{optionButtons}</div>
      <Typography
        variant="caption"
        className={styles.showAll}
        onClick={() => setShowAll(!showAll)}
      >
        {options.length > 4 && !expand && (
          <React.Fragment>
            Show All <KeyboardArrowDown className={styles.showAllArrow} />
          </React.Fragment>
        )}
      </Typography>
    </div>
  )
}

const renderRadioField = ({
  label,
  options,
  input,
  multiSelect,
  onChange,
  ...props
}: RadioFieldProps & FieldProps) => {
  return (
    <RadioField
      label={label}
      options={options}
      multiSelect={multiSelect}
      activeOptions={input.value}
      onChange={onChange}
      {...input}
      {...props}
    />
  )
}

type ConnectedTextFieldProps = {
  name: string,
  label?: string,
  multiSelect?: boolean,
  center?: boolean,
  options: RadioFieldOption[]
}

export default ({
  name,
  label,
  multiSelect,
  center,
  options,
  ...props
}: ConnectedTextFieldProps) => (
  <Field
    name={name}
    label={label}
    center={center}
    options={options}
    multiSelect={multiSelect}
    component={renderRadioField}
    {...props}
  />
)
