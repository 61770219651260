// @flow

import React, { useEffect, useRef, useContext } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { reduxForm, SubmissionError, reset } from 'redux-form'
import { makeStyles } from '@material-ui/styles'
import Slide from '@material-ui/core/Slide'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MessageComponent from 'src/components/molecules/Message'
import Button from 'src/components/atoms/buttons/Button'
import IconButton from 'src/components/atoms/buttons/IconButton'
import ChatInput from 'src/components/atoms/ChatInput'
import LoadingIndicator from 'src/components/atoms/LoadingIndicator'
import { getMessagesAction, sendMessageAction } from 'src/state/actions/inbox'
import printThreadMembers from 'src/utils/printThreadMembers'
import getImage from 'src/utils/images/getImage'
import { ConfigContext } from 'src/components/hoc/RouteWithConfig'
import { useAnalytics } from 'src/hooks/useAnalytics'

import type { Thread, Message } from 'src/utils/types/inbox'
import type { User } from 'src/utils/types/users'
import type { ReduxFormProps } from 'src/utils/types'
import type { Config } from 'src/utils/types/config'
import { useAuth } from '../../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    width: '60%',
    height: 'calc(100% - 2px)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 99999999,
    right: 0,
    top: 0,
    outline: `2px solid ${theme.palette.border.main}`,
    // borderTop: `2px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down('sm')]: {
      top: -65,
      height: 'calc(100% + 65px)',
      width: '100%'
    }
  },
  roomHeader: {
    width: '100%',
    height: 65,
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.1)'
  },
  threadName: {
    textAlign: 'center',
    overflow: 'hidden',
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.label
  },
  loadingOverlay: {
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 25000,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectAThread: {
    color: theme.palette.text.label
  },
  backButton: {
    width: 35,
    height: 35,
    color: theme.palette.text.label
  },

  endOfConvoHeader: {
    width: '85%',
    margin: 'auto',
    textAlign: 'center',
    color: theme.palette.text.label,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  messages: {
    width: '100%',
    flex: 1,
    overflow: 'scroll',
    WebkitOverflowScrolling: 'touch'
  },
  actionBar: {
    display: props => (props.messages.length ? 'flex' : 'none'),
    width: '100%',
    height: 75,
    minHeight: 75,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.1)'
  },
  sendButton: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    minWidth: 75,
    minHeight: 75
  }
}))

const generateMessages = (
  currentUser: User,
  messages: Message[],
  config: ?Config
) =>
  messages.map((message, index) => (
    <MessageComponent
      key={message._id}
      author={message.author}
      variant={message.author === currentUser._id ? 'sent' : 'received'}
      profileImage={
        config ? getImage('profileImage', message.author, config) : ''
      }
      message={message.message}
    />
  ))

const messagesSelector = state => state.inbox.messages

type ChatRoomProps = {
  isOpen?: boolean,
  isLoading?: boolean,
  thread?: Thread,
  onExit: () => void
}

export const ChatRoom = ({
  pristine,
  submitting,
  handleSubmit,
  isLoading,
  isOpen,
  thread,
  onExit
}: ChatRoomProps & ReduxFormProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const messages = useSelector(messagesSelector, shallowEqual)
  const config = useContext(ConfigContext)
  const styles = useStyles({ messages })
  const dispatch = useDispatch()
  const messageListRef = useRef(null)
  const { analytics } = useAnalytics()

  const { user: currentUser } = useAuth()

  useEffect(() => {
    thread && dispatch(getMessagesAction({ threadId: thread._id }))
  }, [dispatch, thread])

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight
    }
  }, [messageListRef, messages])

  return (
    <Slide
      direction="left"
      timeout={isMobile ? 400 : 0}
      in={isOpen || !isMobile}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.container}>
        {isMobile && (
          <div className={styles.roomHeader}>
            <IconButton ariaLabel="back to thread list" onClick={onExit}>
              <ChevronLeftIcon className={styles.backButton} />
            </IconButton>
            <Typography variant="h6" className={styles.threadName}>
              {thread &&
                thread.members.length &&
                printThreadMembers(currentUser, thread.members)}
            </Typography>
          </div>
        )}
        {!isLoading && !messages.length && (
          <div className={styles.loadingOverlay}>
            <Typography variant="h6" className={styles.selectAThread}>
              Select a Message Thread
            </Typography>
          </div>
        )}
        {isLoading && (
          <div className={styles.loadingOverlay}>
            <LoadingIndicator size="medium" />
          </div>
        )}
        <div className={styles.messages} ref={messageListRef}>
          {currentUser && (
              <Typography
                variant="subtitle2"
                className={styles.endOfConvoHeader}
              >
                End of Conversation
              </Typography>
            ) &&
            generateMessages(currentUser, messages, config)}
        </div>

        <form
          className={styles.actionBar}
          onSubmit={handleSubmit(async input => {
            try {
              if (currentUser && thread) {
                await dispatch(
                  sendMessageAction({
                    thread,
                    currentUser,
                    message: input.chatInput
                  })
                )
                analytics.logEvent('message_sent')
                dispatch(reset('chatRoom'))
              }
            } catch (errors) {
              throw new SubmissionError(errors)
            }
          })}
        >
          <ChatInput id="chat-input" name="chatInput" />
          <Button
            className={styles.sendButton}
            type="submit"
            variant="contained"
            color="secondary"
            disabled={pristine || submitting}
            loading={submitting}
          >
            {!submitting && <SendIcon />}
          </Button>
        </form>
      </div>
    </Slide>
  )
}

export default reduxForm({
  form: 'chatRoom'
})(ChatRoom)
