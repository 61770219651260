// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { fade } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles(theme => ({
  icon: {
    width: 35,
    height: 35,
    color: fade(theme.palette.error.main, 0.7)
  }
}))

export default () => {
  const styles = useStyles()
  return <CancelIcon className={styles.icon} />
}
