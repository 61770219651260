// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'

const useRadioStyles = makeStyles(theme => ({
  root: {
    minWidth: 100,
    minHeight: 40,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: props => (props.active ? theme.palette.primary.main : '#9E9E9E'),
    borderColor: props =>
      props.active ? theme.palette.primary.main : '#9E9E9E',
    '&:active': {},
    '&:focus': {},
    '&:hover': {
      background: props => (props.active ? 'none' : 'rgba(0, 0, 0, 0.08)')
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1)
    }
  },
  label: {
    textTransform: 'capitalize'
  }
}))

type RadioButtonProps = {
  children: string,
  onClick: (label: string) => void,
  active?: boolean
}

export default ({ children, active, onClick, ...props }: RadioButtonProps) => {
  const styles = useRadioStyles({ active })
  return (
    <Button
      disableRipple={active}
      variant="outlined"
      classes={styles}
      onClick={() => {
        onClick(children)
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
