// @flow
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  anchor: {
    textDecoration: 'none',
    outline: 'none'
  },
  publisherOnlyLabel: {
    color: theme.palette.text.label,
    marginLeft: theme.spacing(1),
    fontSize: '1rem'
  },
  songTitle: {
    fontWeight: 500,
    paddingBottom: theme.spacing(0.5)
    // textDecoration: 'underline'
  },
  songDesc: {
    color: theme.palette.text.label
  },
  songLink: {
    borderRadius: 5,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    }
  },
  orderContainer: {
    flexShrink: 0,
    width: 50,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
