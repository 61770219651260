// @flow

import type { Thread, Message } from 'src/utils/types/inbox'
import type { Action } from 'src/utils/types'

export const GET_MESSAGES_REQUEST: 'GET_MESSAGES_REQUEST' =
  'GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS' =
  'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_ERROR: 'GET_MESSAGES_ERROR' = 'GET_MESSAGES_ERROR'

export const GET_THREADS_REQUEST: 'GET_THREADS_REQUEST' = 'GET_THREADS_REQUEST'
export const GET_THREADS_SUCCESS: 'GET_THREADS_SUCCESS' = 'GET_THREADS_SUCCESS'
export const GET_THREADS_ERROR: 'GET_THREADS_ERROR' = 'GET_THREADS_ERROR'

export const SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST' =
  'SEND_MESSAGE_REQUEST'
export const SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS' =
  'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_ERROR: 'SEND_MESSAGE_ERROR' = 'SEND_MESSAGE_ERROR'

export const MARK_THREAD_READ_REQUEST: 'MARK_THREAD_READ_REQUEST' =
  'MARK_THREAD_READ_REQUEST'
export const MARK_THREAD_READ_SUCCESS: 'MARK_THREAD_READ_SUCCESS' =
  'MARK_THREAD_READ_SUCCESS'
export const MARK_THREAD_READ_ERROR: 'MARK_THREAD_READ_ERROR' =
  'MARK_THREAD_READ_ERROR'

export const DELETE_THREAD_REQUEST: 'DELETE_THREAD_REQUEST' =
  'DELETE_THREAD_REQUEST'
export const DELETE_THREAD_SUCCESS: 'DELETE_THREAD_SUCCESS' =
  'DELETE_THREAD_SUCCESS'
export const DELETE_THREAD_ERROR: 'DELETE_THREAD_ERROR' = 'DELETE_THREAD_ERROR'

export const CREATE_THREAD_REQUEST: 'CREATE_THREAD_REQUEST' =
  'CREATE_THREAD_REQUEST'
export const CREATE_THREAD_SUCCESS: 'CREATE_THREAD_SUCCESS' =
  'CREATE_THREAD_SUCCESS'
export const CREATE_THREAD_ERROR: 'CREATE_THREAD_ERROR' = 'CREATE_THREAD_ERROR'

export type InboxState = {
  isLoadingMessages: boolean,
  isLoadingThreads: boolean,
  isSendingMessage: boolean,
  isDeletingThread: boolean,
  messages: Message[],
  threads: Thread[]
}

const inboxState = {
  isLoadingMessages: false,
  isLoadingThreads: false,
  isSendingMessage: false,
  isDeletingThread: false,
  messages: [],
  threads: []
}

const updateMessagePreview = (
  id: string,
  threads: Thread[],
  messagePreview: string
) => {
  const [...threadsCopy] = threads
  threads.forEach(thread => {
    if (thread._id === id) {
      thread.messagePreview = messagePreview
    }
  })
  return threadsCopy
}

const markThreadRead = (
  threads: Thread[],
  userId: string,
  threadId: string
) => {
  threads.forEach(thread =>
    thread.members.forEach(member => {
      if (member.userId === userId) {
        member.readAll = true
      }
    })
  )
  return threads
}

const addNewThread = (threads: Thread[], thread: Thread): Thread[] => {
  const index = threads.findIndex(t => t._id === thread._id)
  //check if we are really updating an existing thread
  if (index >= 0) {
    const [...threadsCopy] = threads
    threadsCopy[index] = thread
    return threadsCopy
  } else {
    return threads.concat(thread)
  }
}

const deleteThread = (threads: Thread[], threadId: string): Thread[] =>
  threads.filter(thread => thread._id !== threadId)

const reducer = (state: InboxState = inboxState, action: Action<any>) => {
  switch (action.type) {
    case GET_MESSAGES_REQUEST:
      return { ...state, isLoadingMessages: true }
    case GET_MESSAGES_SUCCESS:
      return { ...state, isLoadingMessages: false, messages: action.payload }
    case GET_MESSAGES_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoadingMessages: false
      }
    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        isSendingMessage: true
      }
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload],
        threads:
          action.payload &&
          updateMessagePreview(
            action.payload.threadId,
            state.threads,
            action.payload.message
          ),
        isSendingMessage: false
      }
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        error: action.payload,
        isSendingMessage: false
      }
    case GET_THREADS_REQUEST:
      return { ...state, isLoadingThreads: true }
    case GET_THREADS_SUCCESS:
      return { ...state, isLoadingThreads: false, threads: action.payload }
    case GET_THREADS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoadingThreads: false
      }
    case CREATE_THREAD_REQUEST:
      return {
        ...state
      }
    case CREATE_THREAD_SUCCESS:
      return {
        ...state,
        threads: action.payload
          ? addNewThread(state.threads, action.payload)
          : state.threads
      }
    case CREATE_THREAD_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case DELETE_THREAD_REQUEST:
      return {
        ...state,
        isDeletingThread: true
      }
    case DELETE_THREAD_SUCCESS:
      return {
        ...state,
        isDeletingThread: false,
        threads: action.payload
          ? deleteThread(state.threads, action.payload.threadId)
          : state.threads
      }
    case DELETE_THREAD_ERROR:
      return {
        ...state,
        isDeletingThread: false,
        error: action.payload
      }
    case MARK_THREAD_READ_REQUEST:
      return {
        ...state,
        threads: action.payload
          ? markThreadRead(
              state.threads,
              action.payload.userId,
              action.payload.threadId
            )
          : state.threads
      }
    case MARK_THREAD_READ_SUCCESS:
      return {
        ...state
      }
    case MARK_THREAD_READ_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}

export default reducer
