// @flow
import React, { useEffect } from 'react'
import {
  reduxForm,
  SubmissionError,
  reset,
  FieldArray,
  change
} from 'redux-form'
import { useDispatch, useSelector, shallowEqual, batch } from 'react-redux'
import Button from 'src/components/atoms/buttons/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import type { ReduxFormProps, State } from 'src/utils/types'
import type { MusicState } from 'src/state/music'
import { setSongsAction } from 'src/state/music'
import { Flex } from 'src/components/molecules/Flex'
import { EditableMusicLink } from 'src/components/molecules/EditableMusicLink'
import { useStyles } from './index.styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const FORM_ID = 'editSongs'

const selectMusic: State => MusicState = state => state.music

type EditSongProps = {
  isOpen: boolean,
  onClose: () => void
}

export const EditSong = ({
  isOpen,
  onClose,
  pristine,
  submitting,
  invalid,
  error,
  handleSubmit,
  ...props
}: EditSongProps & ReduxFormProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { songs } = useSelector(selectMusic, shallowEqual)

  useEffect(() => {
    batch(() => {
      if (songs) {
        songs
          .sort((a, b) => a.order - b.order)
          .forEach((song, index) => {
            dispatch(
              change(FORM_ID, `songs.${index}`, {
                _id: song._id,
                authorId: song.authorId,
                title: song.title,
                url: song.url,
                desc: song.desc,
                isPrivate: song.isPrivate,
                order: song.order
              })
            )
          })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen])

  const submit = handleSubmit(async data => {
    const songSet = data.songs.map((song, index) => ({
      ...song,
      order: index + 1
    }))
    try {
      await dispatch(setSongsAction({ songs: songSet, originalSongs: songs }))
    } catch (errors) {
      throw new SubmissionError(errors)
    }
  })

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    <Dialog
      scroll="paper"
      open={isOpen}
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby="Song List"
      aria-describedby="Add a new song or edit an existing one"
    >
      <DialogTitle id="alert-dialog-title">
        Edit Music List
        <Typography variant="subtitle2" style={{ color: 'grey' }}>
          Add links to songs, playlists and videos.
        </Typography>
      </DialogTitle>

      <DialogContent dividers className={styles.content}>
        <form className={styles.form}>
          <FieldArray
            name="songs"
            //$FlowFixMe
            component={renderSongFields}
            props={{
              totalSongs: songs.length
            }}
          />
        </form>
      </DialogContent>
      <DialogActions style={{ paddingRight: 16 }}>
        <Button
          onClick={() => {
            onClose()
            dispatch(reset(FORM_ID))
          }}
        >
          CLOSE
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          loading={submitting}
          onClick={submit}
          disabled={submitting || pristine || error || invalid}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const renderSongFields = ({
  totalSongs,
  fields,
  meta: { error, pristine, invalid, submitFailed }
}) => {
  return (
    <>
      <Flex container width="100%" justifyContent="center">
        <Button
          variant="outlined"
          color="primary"
          id="add-song-link"
          onClick={() => fields.unshift({})}
        >
          ADD MUSIC +
        </Button>
      </Flex>
      {fields.map((field, index) => (
        <EditableMusicLink
          elevate
          key={index}
          totalSongs={fields.length}
          onDelete={() => fields.remove(index)}
          onSwap={direction => fields.swap(index, index + direction)}
          fieldName={`${field}`}
          order={index + 1}
        />
      ))}
    </>
  )
}

export const EditSongForm = reduxForm({
  form: FORM_ID,
  // validate,
  enableReinitialize: true
})(EditSong)
