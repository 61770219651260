/* eslint-disable react-hooks/exhaustive-deps */
// @flow

import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector, batch } from 'react-redux'
import { reduxForm, SubmissionError, change } from 'redux-form'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import TextField from 'src/components/atoms/TextField'
import RadioField from 'src/components/molecules/RadioField'
import TextFieldSelect from 'src/components/atoms/TextFieldSelect'
import Button from 'src/components/atoms/buttons/Button'
import CancelSubscriptionDialog from 'src/components/molecules/dialogs/CancelSubscriptionDialog'
import {
  STATES_OPTIONS,
  SONGWRITER_TYPE_OPTIONS,
  BUSINESS_TYPE_OPTIONS
} from './index.consts'
import { updateUserAction } from 'src/state/actions/profile'
import { searchUsersAction } from 'src/state/actions/users'
import {
  cancelSubscriptionAction,
  getCheckoutSessionAction
} from 'src/state/actions/payments'
import {
  transformOptions,
  transformBandMembers
} from 'src/utils/fieldTransformers'
import type { ReduxFormProps } from 'src/utils/types'
import type { ProfileState } from 'src/state/reducers/profile'
import type { PaymentState } from 'src/state/reducers/payments'

import type { State } from 'src/utils/types'
import type { ConfigState } from 'src/state/reducers/Config'
import DeleteAccountDialog from 'src/components/molecules/dialogs/DeleteAccountDialog'
import { deleteAccountAction } from 'src/state/actions/users'
import { push } from 'react-router-redux'
import { requiredField } from 'src/utils/fieldValidators'
import { YES_NO_FIELD } from '../EditPublishing/index.consts'
import { injectStripe } from 'react-stripe-elements'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { COUNTRY_OPTIONS } from '../../../../consts'
import { replace } from 'react-router-redux/lib/actions'
import { firebaseApp } from 'src/settings/env'

const FORM_ID = 'editAccount'

const useStyles = makeStyles(theme => ({
  paper: {
    zIndex: 1,
    width: '100%',
    maxWidth: 600,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)}px auto`
    }
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  actionButton: {
    margin: `0px ${theme.spacing(1)}px`
  },
  fieldGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': {
      marginRight: theme.spacing(2)
    }
  },
  subForm: {
    width: '95%',
    margin: 'auto'
  },
  formSectionTitle: {
    color: theme.palette.text.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  blockButton: {
    color: theme.palette.primary.main,
    display: 'block'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  closeAccount: {
    color: theme.palette.text.label,
    display: 'block'
  }
}))

type EditAccountState = ProfileState &
  ConfigState & {
    payments: PaymentState,
    form: ?{
      email: string,
      firstname: string,
      lastname: string,
      currentPassword: string,
      newPassword: string,
      location?: {
        city?: string,
        state?: string | Object,
        country?: Object
      }
    },
    isUpdatingUser: boolean,
    isDeletingUser: boolean,
    deleteUserError: string
  }

const selectState: State => EditAccountState = state => ({
  ...state.profile,
  ...state.config,
  isDeletingUser: state.users.isDeletingUser,
  deleteUserError: state.users.deleteUserError,
  payments: state.payments,
  form: state.form[FORM_ID] ? state.form[FORM_ID].values : null
})

type EditAccountFormProps = {
  accountType: 'Business' | 'Songwriter'
}

export const EditAccountForm = ({
  accountType,
  handleSubmit,
  pristine,
  submitting,
  stripe,
  invalid,
  error
}: EditAccountFormProps & ReduxFormProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { analytics } = useAnalytics()
  const state: EditAccountState = useSelector(selectState, shallowEqual)
  const user = state.user

  const [isCancelSubDialogOpen, setIsCancelSubDialogOpen] = useState(false)
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = useState(
    false
  )
  const [isRedirectingCheckout, setIsRedirectingCheckout] = useState(false)

  const onCancelSub = () => dispatch(cancelSubscriptionAction({ user }))
  const onDeleteAccount = () => {
    dispatch(deleteAccountAction({}))
    analytics.logEvent('account_closed')
  }
  const onEditPaymentInfo = async () => {
    setIsRedirectingCheckout(true)
    try {
      const { session } = await dispatch(getCheckoutSessionAction({ user }))
      await stripe.redirectToCheckout({
        sessionId: session.id
      })
      setIsRedirectingCheckout(false)
    } catch (err) {
      console.error(err)
      setIsRedirectingCheckout(false)
    }
  }

  const loadUserOptions = async (searchString: string) => {
    const users = await dispatch(searchUsersAction({ searchString }))
    return users.map(user => ({
      value: user._id,
      label: `${user.firstname} ${user.lastname}`
    }))
  }

  const fillFormData = () => {
    batch(() => {
      dispatch(change(FORM_ID, 'location.city', user?.location.city))
      if (user?.location.state) {
        dispatch(
          change(
            FORM_ID,
            'location.state',
            user?.location.country === 'US'
              ? {
                  label: user?.location.state,
                  value: user?.location.state
                }
              : user?.location.state
          )
        )
      }
      dispatch(
        change(
          FORM_ID,
          'location.country',
          COUNTRY_OPTIONS.find(
            country => country.value === user?.location.country
          )
        )
      )

      dispatch(change(FORM_ID, 'phoneNumber', user?.phoneNumber))
      dispatch(change(FORM_ID, 'profile.website', user?.profile.website))
      dispatch(
        change(
          FORM_ID,
          'settings.receiveEmailNotifications',
          user?.settings.receiveEmailNotifications
        )
      )
      if (user?.accountType === 'Business')
        dispatch(
          change(
            FORM_ID,
            'businessVerification.businessName',
            user?.businessVerification?.businessName
          )
        )
      dispatch(
        change(
          FORM_ID,
          'stats.occupation',
          user?.stats.occupation.map(type => ({
            label: type,
            value: type
          }))
        )
      )

      if (user?.accountType === 'Songwriter') {
        dispatch(change(FORM_ID, 'stats.band.name', user?.stats.band?.name))
        dispatch(
          change(
            FORM_ID,
            'stats.band.members',
            user?.stats.band?.members?.map(member => ({
              label: member.name,
              value: member.id
            }))
          )
        )
      }

      if (user?.accountType === 'Business') {
        dispatch(
          change(
            FORM_ID,
            'businessVerification.businessName',
            user?.businessVerification?.businessName
          )
        )
        dispatch(
          change(
            FORM_ID,
            'businessVerification.songwriterName',
            user?.businessVerification?.songwriterName
          )
        )
        dispatch(
          change(
            FORM_ID,
            'businessVerification.songTitle',
            user?.businessVerification?.songTitle
          )
        )
        dispatch(
          change(
            FORM_ID,
            'businessVerification.songYear',
            user?.businessVerification?.songYear
          )
        )
        dispatch(
          change(
            FORM_ID,
            'businessVerification.songUrl',
            user?.businessVerification?.songUrl
          )
        )
        dispatch(
          change(
            FORM_ID,
            'businessVerification.filmTitle',
            user?.businessVerification?.filmTitle
          )
        )
      }
    })
  }

  useEffect(() => {
    const locationState = state.form?.location

    if (
      locationState?.country?.value !== 'US' &&
      user?.location.country === 'US'
    ) {
      //$FlowFixMe
      dispatch(change(FORM_ID, 'location.state', locationState?.state?.value))
    }
  }, [state.form?.location?.country?.value, user?.location.country])

  useEffect(() => {
    if (user) {
      fillFormData()
    }
  }, [user, dispatch])
  return (
    <form
      onSubmit={handleSubmit(
        async ({
          email,
          phoneNumber,
          stats,
          profile,
          location,
          businessVerification,
          settings
        }) => {
          const transformedStats = {
            ...stats
          }
          const transformedLocation = { ...location }
          transformedStats.occupation = transformOptions(stats.occupation)
          if (transformedStats.band) {
            transformedStats.band.members = transformBandMembers(
              stats.band?.members
            )
            transformedStats.band.name = transformedStats.band.name || ''
          }

          transformedLocation.state =
            typeof location.state === 'string'
              ? location.state
              : transformOptions(location.state)
          transformedLocation.country = transformOptions(location.country)
          try {
            await dispatch(
              updateUserAction({
                user,
                fields: {
                  stats: transformedStats,
                  location: transformedLocation,
                  email,
                  profile,
                  phoneNumber,
                  settings,
                  businessVerification
                }
              })
            )
          } catch (errors) {
            throw new SubmissionError(errors)
          }
        }
      )}
    >
      <Paper className={styles.paper}>
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Personal Info
        </Typography>
        <TextFieldSelect
          required
          fullWidth
          id="edit-country-field"
          label="Country"
          name="location.country"
          options={COUNTRY_OPTIONS}
          validators={[requiredField]}
        />
        <div className={styles.fieldGroup}>
          {state.form?.location?.country?.value === 'US' ? (
            <TextFieldSelect
              required
              fullWidth
              id="edit-state-field"
              label="State or Province"
              name="location.state"
              options={STATES_OPTIONS}
              validators={[requiredField]}
            />
          ) : (
            <TextField
              required
              id="edit-state-field"
              label="State or Province"
              name="location.state"
              placeholder="state"
              validators={[requiredField]}
            />
          )}

          <TextField
            required
            id="edit-city-field"
            label="City"
            name="location.city"
            placeholder="city"
            validators={[requiredField]}
          />
        </div>
        <div className={styles.fieldGroup}>
          <TextField
            id="edit-phone-field"
            label="Phone Number"
            name="phoneNumber"
            placeholder="(123) 456 7890..."
          />
          <TextField
            id="edit-website-field"
            label="Website"
            name="profile.website"
            placeholder="www.mysite.com..."
          />
        </div>
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          {accountType === 'Songwriter'
            ? 'Songwriter Type'
            : 'Business Information'}
        </Typography>
        {accountType === 'Business' && (
          <>
            <TextField
              required
              id="edit-business-name-field"
              label="Company Name"
              name="businessVerification.businessName"
              placeholder="Name of My Business..."
              validators={[requiredField]}
            />
            <TextField
              required
              id="edit-songwriter-name-field"
              label="Songwriter Name"
              name="businessVerification.songwriterName"
              placeholder="John Doe..."
              validators={[requiredField]}
            />
            <TextField
              required
              id="edit-song-title-field"
              label="Song Title (by your writer or client)"
              name="businessVerification.songTitle"
              placeholder="John Doe..."
              validators={[requiredField]}
            />
            <TextField
              required
              id="edit-song-year-field"
              label="Song Year"
              name="businessVerification.songYear"
              placeholder="2020..."
              validators={[requiredField]}
            />
            <TextField
              id="edit-song-url-field"
              label="Song Link (not the URI or embed url)"
              name="businessVerification.songUrl"
              placeholder="https://"
            />
            <TextField
              id="edit-film-title-field"
              label="Film Title"
              name="businessVerification.filmTitle"
              placeholder="Titanic..."
            />
          </>
        )}

        <TextFieldSelect
          required
          id="edit-occupation-type-field"
          label="Professional Title"
          name="stats.occupation"
          placeholder={
            accountType === 'Songwriter' ? 'Songwriter...' : 'Publisher...'
          }
          options={
            accountType === 'Songwriter'
              ? SONGWRITER_TYPE_OPTIONS
              : BUSINESS_TYPE_OPTIONS
          }
          validators={[requiredField]}
          fullWidth
        />

        {accountType === 'Songwriter' && (
          <>
            <Typography
              variant="h6"
              component="p"
              className={styles.formSectionTitle}
            >
              Band Info
            </Typography>
            <TextField
              id="edit-band-name-field"
              label="Band Name"
              name="stats.band.name"
              placeholder="My Band Name"
            />
            <TextFieldSelect
              isMulti
              fullWidth
              variant="async"
              id="add-band-member"
              label="Band Members"
              name={`stats.band.members`}
              placeholder="Add Recipient..."
              options={[]}
              defaultOptions
              loadOptions={loadUserOptions}
            />
          </>
        )}

        {/* <FieldArray
          name="stats.band.members"
          component={renderBandMembers}
          props={{ styles, loadUserOptions }}
        /> */}
        {/* <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Credentials
        </Typography> */}

        {/* <div className={styles.fieldGroup}>
          <TextField
            type="password"
            id="edit-current-password-field"
            label="Current Password"
            name="currentPassword"
            placeholder="****"
          />
          <TextField
            type="password"
            id="edit-new-password-field"
            label="New Password"
            name="newPassword"
            placeholder="****"
            disabled={
              !state.form || (state.form && !state.form.currentPassword)
            }
          />
        </div> */}

        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Email Settings
        </Typography>
        <RadioField
          name="settings.receiveEmailNotifications"
          label="Receive Email Notifications?"
          options={YES_NO_FIELD}
          darkLabel
        />
        <Typography
          variant="h6"
          component="p"
          className={styles.formSectionTitle}
        >
          Manage Subscription
        </Typography>
        {user?.accountLevel !== 'Free' && (
          <Button
            className={styles.blockButton}
            loading={state.payments?.isFetchingSession || isRedirectingCheckout}
            disabled={
              state.payments?.isFetchingSession || isRedirectingCheckout
            }
            onClick={e => {
              e.preventDefault()
              onEditPaymentInfo()
            }}
          >
            <AccountBalanceIcon className={styles.buttonIcon} /> Set Billing
            Information
          </Button>
        )}
        {user &&
          user.accountLevel === 'Free' &&
          user.accountType === 'Songwriter' && (
            <Button
              className={styles.blockButton}
              onClick={e => {
                e.preventDefault()
                setIsCancelSubDialogOpen(true)
                dispatch(push('/upgrade'))
              }}
            >
              <ArrowUpwardIcon className={styles.buttonIcon} />
              Upgrade to Career Plan
            </Button>
          )}

        {false && (
          <Button
            className={styles.blockButton}
            disabled={user && user.accountLevel === 'Free'}
            onClick={e => {
              e.preventDefault()
              setIsCancelSubDialogOpen(true)
            }}
          >
            <TransitEnterexitIcon className={styles.buttonIcon} />
            {user && user.accountLevel !== 'Free'
              ? `Cancel ${user.accountLevel} Subscription`
              : 'Currently on the Free Plan'}
          </Button>
        )}
        <div>
          <Button
            className={styles.closeAccount}
            onClick={e => {
              e.preventDefault()
              setIsDeleteAccountDialogOpen(true)
            }}
          >
            <HighlightOffIcon className={styles.buttonIcon} /> Close Account
          </Button>
        </div>
        <DeleteAccountDialog
          isOpen={isDeleteAccountDialogOpen}
          isDeleting={state.isDeletingUser}
          error={state.deleteUserError}
          onClose={() => setIsDeleteAccountDialogOpen(false)}
          deleteAccount={onDeleteAccount}
          onDeleteAccountSuccess={async () => {
            await firebaseApp.auth().signOut()
            dispatch(replace('/signup'))
          }}
        />
        <CancelSubscriptionDialog
          isOpen={isCancelSubDialogOpen}
          isCancelling={state.payments.isCancelling}
          error={state.payments.error}
          onClose={() => setIsCancelSubDialogOpen(false)}
          onCancelSubscription={onCancelSub}
        />
        <div className={styles.actionButtons}>
          <Button
            color="secondary"
            className={styles.actionButton}
            onClick={fillFormData}
          >
            RESET
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={pristine || submitting || invalid}
            loading={submitting}
          >
            UPDATE
          </Button>
        </div>
      </Paper>
    </form>
  )
}

export default reduxForm({
  form: FORM_ID,
  touchOnBlur: true,
  initialValues: {
    stats: {
      band: {
        name: ''
      }
    },
    businessVerification: {
      songUrl: ''
    },
    education: {
      year: '',
      major: '',
      school: ''
    }
  }
})(injectStripe(EditAccountForm))
