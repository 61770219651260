// @flow

import React, { useEffect } from 'react'
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  change,
  FieldArray,
  reduxForm,
  SubmissionError,
  reset
} from 'redux-form'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from 'src/components/atoms/TextField'
import TextFieldSelect from 'src/components/atoms/TextFieldSelect'
import Button from 'src/components/atoms/buttons/Button'
import { SOCIAL_MEDIA_OPTIONS } from './index.consts'
import { updateUserAction } from 'src/state/actions/profile'
import type { ReduxFormProps } from 'src/utils/types'
import type { ProfileState } from 'src/state/reducers/profile'
import type { State } from 'src/utils/types'
import type { ConfigState } from 'src/state/reducers/Config'

const FORM_ID = 'editSocialMedia'

const useStyles = makeStyles(theme => ({
  paper: {
    zIndex: 1,
    width: '100%',
    maxWidth: 600,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)}px auto`
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  actionButton: {
    margin: `0px ${theme.spacing(1)}px`
  },
  subForm: {
    width: '95%',
    margin: 'auto'
  },
  fieldGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': {
      marginRight: theme.spacing(2)
    }
  },
  formSectionTitle: {
    color: theme.palette.text.label,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  removeItemIcon: {
    marginRight: theme.spacing(1)
  }
}))

const transformSocialMediaFields = socialNetworks => {
  const keys = Object.keys(socialNetworks)
  return keys.map(key => ({
    name: socialNetworks[key].name,
    url: sanitizeUrl(socialNetworks[key].url),
    followerCount: Number(
      `${socialNetworks[key].followerCount || 0}`.replace(',', '')
    )
  }))
}

const sanitizeUrl = (url: string) =>
  url.includes('://') ? url : `https://${url}`

const renderSocialMediaFields = ({
  styles,
  networks,
  form = {},
  fields,
  meta: { error, submitFailed }
}) => {
  return (
    <>
      <div className={styles.fieldGroup}>
        <TextFieldSelect
          id="current-platform-field"
          label="Platform"
          name="socialNetworkName"
          placeholder="Facebook..."
          options={SOCIAL_MEDIA_OPTIONS}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={!!!form.socialNetworkName}
          onClick={() =>
            form.socialNetworkName &&
            fields.push({ name: form.socialNetworkName.value })
          }
        >
          ADD PLATFORM +
        </Button>
      </div>
      {fields.map((field, index) => (
        <div key={index}>
          <div className={styles.fieldGroup}>
            <Typography
              variant="h6"
              component="p"
              className={styles.formSectionTitle}
            >
              {form.socialMediaLinks[index] &&
                form.socialMediaLinks[index].name}
            </Typography>
            <Button color="secondary" onClick={() => fields.remove(index)}>
              remove
            </Button>
          </div>
          <div className={styles.subForm}>
            <div className={styles.fieldGroup}>
              <TextField
                required
                id={`edit-${field.name}-url-field`}
                label={`Link`}
                name={`${field}.url`}
                placeholder="Share URL..."
                fullWidth
              />
              <TextField
                required
                numerical
                id={`edit-${field.name}-follower-count-field`}
                label={`Follower Count`}
                name={`${field}.followerCount`}
                placeholder="234..."
                fullWidth
              />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

type EditSocialMediaState = ProfileState & ConfigState & { form?: any }

const selectState: State => EditSocialMediaState = state => ({
  ...state.profile,
  ...state.config,
  form: state.form[FORM_ID] ? state.form[FORM_ID].values : null
})

export const EditSocialMediaForm = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error
}: ReduxFormProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const state: EditSocialMediaState = useSelector(selectState, shallowEqual)
  const user = state.user

  useEffect(() => {
    batch(() => {
      if (user) {
        user.profile.socialMediaLinks.forEach((link, index) => {
          dispatch(
            change(FORM_ID, `socialMediaLinks.${index}`, {
              url: link.url,
              followerCount: link.followerCount || 0,
              name: link.name
            })
          )
        })
      }
    })
  }, [user, dispatch])
  return (
    <form
      onSubmit={handleSubmit(async ({ socialMediaLinks }) => {
        const socialMedia = transformSocialMediaFields(socialMediaLinks)
        try {
          await dispatch(
            updateUserAction({
              fields: { profile: { socialMediaLinks: socialMedia } }
            })
          )
        } catch (errors) {
          throw new SubmissionError(errors)
        }
      })}
    >
      <Paper className={styles.paper}>
        <FieldArray
          name="socialMediaLinks"
          props={{
            styles,
            networks: state.config ? state.config.socialNetworks : [],
            form: state.form
          }}
          component={renderSocialMediaFields}
        />
        <div className={styles.actionButtons}>
          <Button
            color="secondary"
            className={styles.actionButton}
            onClick={() => dispatch(reset(FORM_ID))}
          >
            RESET
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={pristine || submitting || invalid}
            loading={submitting}
          >
            UPDATE
          </Button>
        </div>
      </Paper>
    </form>
  )
}

export default reduxForm({
  form: FORM_ID,
  // validate,
  initialValues: { socialMediaLinks: [] }
})(EditSocialMediaForm)
