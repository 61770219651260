// @flow
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginTop: theme.spacing(2.5)
  },
  paper: {
    minWidth: 600,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      margin: 0,
      width: '100%',
      maxWidth: '100% !important'
    }
  },
  content: {
    padding: theme.spacing(2)
  },
  formErrorMessage: {
    display: 'block',
    marginTop: theme.spacing(1),
    height: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
    color: theme.palette.error.main
  },
  form: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 0
    }
  },
  fieldGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': {
      marginRight: theme.spacing(2)
    }
  },
  uploadContainer: {
    width: 125,
    height: 125,
    margin: 'auto'
  },
  thumbnail: {
    borderRadius: 5
  },
  uploadLabel: {
    fontWeight: 500,
    color: theme.palette.text.label,
    paddingBottom: theme.spacing(1)
  }
}))
