// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from 'src/components/atoms/buttons/IconButton'
import Button from 'src/components/atoms/buttons/Button'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0
  },
  modalHeader: {
    width: '100%',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.1)'
  },
  modalActions: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => (props.justify ? props.justify : 'end'),
    boxShadow: '0px -3px 5px -2px rgba(0,0,0,0.1)'
  },
  modalActionsChildren: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  closeIcon: {
    marginLeft: 15
  },
  cancelButton: {
    height: 50
  },
  paper: {
    width: 700,
    height: 600,
    position: 'relative',
    outline: 0,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      height: '95%'
    }
  },
  titleModalHeaderContainer: {
    display: 'flex'
  },
  modalHeaderTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important'
    }
  }
}))

type ModalHeaderProps = {
  className?: string,
  title?: string,
  children?: any,
  onClose: () => void
}

export const ModalHeader = ({
  className,
  children,
  title = null,
  onClose,
  ...props
}: ModalHeaderProps) => {
  const styles = useStyles()

  return (
    <div className={styles.modalHeader} {...props}>
      <div className={styles.titleModalHeaderContainer}>
        <IconButton
          variant="header"
          ariaLabel="close modal window"
          className={styles.closeIcon}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        {title && <h2 className={styles.modalHeaderTitle}>{title}</h2>}
      </div>
      {children}
    </div>
  )
}

type ModalActionsProps = {
  className?: string,
  children?: any,
  justify?: string,
  onCancel?: () => void
}

export const ModalActions = ({
  className,
  children,
  onCancel,
  justify = 'flex-end',
  ...props
}: ModalActionsProps) => {
  const styles = useStyles({ justify })

  return (
    <div className={styles.modalActions} {...props}>
      {onCancel && (
        <Button
          aria-label="cancel modal action"
          className={styles.cancelButton}
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}
      <div className={styles.modalActionsChildren}>{children}</div>
    </div>
  )
}

type ModalProps = {
  open?: boolean,
  setOpen: boolean => void,
  className?: string,
  paperClassName?: string,
  children: any,
  onClick?: () => void
}

export default ({
  open,
  setOpen,
  className,
  paperClassName,
  children,
  ...props
}: ModalProps) => {
  const styles = useStyles()
  return (
    <Modal
      className={classNames(styles.modal, className)}
      open={open}
      onClose={() => setOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300
      }}
      closeAfterTransition
      {...props}
    >
      <Fade in={open}>
        <Paper className={`${styles.paper} ${paperClassName || ''}`}>
          {children}
        </Paper>
      </Fade>
    </Modal>
  )
}
