// @flow
import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import FullPageLoading from 'src/components/atoms/FullPageLoading'
import { getConfigAction } from 'src/state/actions/config'
import type { ConfigState } from 'src/state/reducers/config'
import type { Config } from 'src/utils/types/Config'
import type { State } from 'src/utils/types'
import { getUserAction } from 'src/state/actions/profile'
import { useAuth } from 'src/hooks/useAuth'

type PrivateRouteProps = {
  path: string,
  pageTitle: string,
  component: any
}

export const ConfigContext = React.createContext<?Config>(null)

const selectConfig: State => ConfigState = state => state.config

const VerifyHOC = ({ Component, pageTitle, ...props }) => {
  const { config } = useSelector(selectConfig, shallowEqual)
  const dispatch = useDispatch()
  const { user } = useAuth()

  useEffect(() => {
    if (!config) {
      dispatch(getConfigAction({}))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      dispatch(getUserAction({ id: user._id, refetchProfile: true }))
    }
  }, [dispatch, user])

  return user && config ? (
    <>
      <ConfigContext.Provider value={config}>
        <Component {...props} />
      </ConfigContext.Provider>
    </>
  ) : (
    <FullPageLoading />
  )
}

export default ({ path, component, ...props }: PrivateRouteProps) => {
  const { user } = useAuth()
  const redirectToUpgrade = user && user.accountType === "Songwriter" && user.accountLevel === "Free" && path !== "/upgrade"
  return (
    <Route
      path={path}
      {...props}
      render={routerProps =>
        redirectToUpgrade ? (
          <Redirect to={{ pathname: '/upgrade', state: { from: props.location } }} />
        ) : (
          <VerifyHOC Component={component} />
        )
      }
    />
  )
}
