// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'src/components/atoms/buttons/Button'

type DeleteThreadDialogProps = {
  threadId: string,
  isOpen?: boolean,
  onClose: () => void,
  onDelete: string => void,
  isDeleting?: boolean
}

export default ({
  threadId,
  isOpen,
  onClose,
  onDelete,
  isDeleting
}: DeleteThreadDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Messages</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If you delete this thread, you will be removed from this conversation
          permanently. Are you sure you wish to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={onClose}>
          CANCEL
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          loading={isDeleting}
          disabled={isDeleting}
          onClick={() => onDelete(threadId) && onClose()}
        >
          DELETE THREAD
        </Button>
      </DialogActions>
    </Dialog>
  )
}
