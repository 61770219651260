// @flow

import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Navigation from 'src/components/organisms/Navigation'
import Typography from '@material-ui/core/Typography'
import FeatureGroup from 'src/components/molecules/FeatureGroup'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import AssignmentIcon from '@material-ui/icons/Assignment'
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags'
import Button from 'src/components/atoms/buttons/Button'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import PaymentForm from 'src/components/organisms/forms/PaymentForm'
import { Elements, StripeProvider } from 'react-stripe-elements'
import env from 'src/settings/env'
import type { State } from 'src/utils/types'
import type { ProfileState } from 'src/state/reducers/profile'
import type { PaymentState } from 'src/state/reducers/payments'
import { useAuth } from 'src/hooks/useAuth'
import { useStyles } from './index.styles'

const selectState: State => PaymentState & ProfileState = state => ({
  ...state.payments,
  ...state.profile
})

const PRO_ACCESS_PERKS = [
  'Industry pros can reach out to songwriters in the career plan. Sooner immediately notifies you when a pro follows you or wants to connect!'
]

const PUBLISHING_FIELDS_PERKS = [
  'Let publishers know if your publishing is available, you want to sell your catalog, or have a song on the chart. Publishers will want to connect.'
]

const REFERRAL_FIELDS_PERKS = [
  'Increase your discoverability. Referred writers are listed with top writers in the search filters and receive a “referred” badge on their profile.'
]

export default () => {
  const styles = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const state: PaymentState & ProfileState = useSelector(
    selectState,
    shallowEqual
  )

  const { user } = useAuth()

  const onClick = () => {
    setIsPaymentModalOpen(true)
  }

  return (
    <Navigation pageTitle="Upgrade">
      <div className={styles.wrapper}>
        <Typography variant="h2" component="h1" className={styles.header}>
          <span className={styles.emphasizedText}>Join the Career Network</span>
        </Typography>
        <Typography variant="h6" component="h2" className={styles.price}>
          $10 / month billed annually
          <Typography
            variant="h6"
            component="span"
            className={styles.monthlyPrice}
          >
            (or $13 / month)
          </Typography>
        </Typography>
        {/* <div className={styles.tabsWrapper}>
          <Tabs variant="fullWidth" className={styles.tabs}>
            <Tab label="CAREER" onClick={() => setActiveTab(0)} />
            <Tab label="PREMIUM" onClick={() => setActiveTab(1)} />
          </Tabs>
        </div> */}
        <div className={styles.featureList}>
          {activeTab === 0 && (
            <>
              <FeatureGroup
                Icon={PeopleAltIcon}
                header="Industry Access"
                perks={PRO_ACCESS_PERKS}
              />
              <FeatureGroup
                Icon={EmojiFlagsIcon}
                header="Publishing Fields"
                perks={PUBLISHING_FIELDS_PERKS}
              />
              <FeatureGroup
                Icon={AssignmentIcon}
                header="Request a Referral"
                perks={REFERRAL_FIELDS_PERKS}
              />
            </>
          )}
          {/* {activeTab === 1 && (
            <>
              <FeatureGroup
                Icon={MailIcon}
                header="InMail Messages"
                desc="You get 4 InMail messages you can use to reach out to any songwriter not currently in your network."
              />
              <FeatureGroup
                Icon={RocketIcon}
                header="Boost Posts"
                desc="Each month you can send out a powerful boost post that will show up on the dashboard of all industry professionals."
              />
              <FeatureGroup
                Icon={TrendingUpIcon}
                header="Search Trends"
                desc="See detailed search trends of industry professionals and understand the demands of your industry. (COMING SOON)"
              />
            </>
          )} */}
        </div>
        <div className={styles.upgradeButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !user ||
              user.accountLevel === 'Career' ||
              (activeTab === 1 && !user?.isReferred)
            }
            onClick={onClick}
          >
            <Typography
              color="inherit"
              className={styles.upgradeButtonText}
              variant="h6"
            >
              {activeTab === 0
                ? user && user.accountLevel === 'Career'
                  ? 'CURRENTLY ON CAREER PLAN'
                  : 'UPGRADE TO CAREER'
                : user && user.isReferred
                ? 'UPGRADE TO PREMIUM'
                : 'REFERRAL REQUIRED'}
            </Typography>
            <TrendingFlatIcon />
          </Button>
        </div>
      </div>
      <StripeProvider apiKey={env.STRIPE_API_KEY}>
        <Elements>
          <PaymentForm
            email={user && user.email}
            plan="Career"
            isOpen={isPaymentModalOpen}
            onClose={() => setIsPaymentModalOpen(false)}
            error={state.error}
            success={state.paymentSucceeded}
            isSubmitting={state.isSubmitting}
          />
        </Elements>
      </StripeProvider>
    </Navigation>
  )
}
