// @flow
import * as React from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import { useTheme } from '@material-ui/styles'

type LoadingIndicatorProps = {
  size?: 'small' | 'medium' | 'large'
}

export default ({ size = 'large' }: LoadingIndicatorProps) => {
  const theme = useTheme()
  return (
    <PulseLoader
      sizeUnit={'px'}
      color={theme.palette.primary.main}
      size={15}
      loading={true}
    />
  )
}
