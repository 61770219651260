// @flow

import React, { useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { push } from 'react-router-redux'
import { getUserAction } from 'src/state/actions/profile'
import Navigation from 'src/components/organisms/Navigation'
import ProfileHeader from 'src/components/organisms/ProfileHeader'
import ProfileContent from 'src/components/molecules/ProfileContent'
import MusicView from 'src/components/organisms/MusicView'
import EditProfileForm from 'src/components/organisms/EditProfile'
import ContentView from 'src/components/organisms/ContentView'
import type { State } from 'src/utils/types'
import type { ProfileState } from 'src/state/reducers/profile'
import { useAuth } from 'src/hooks/useAuth'

const renderTab = ({ activeTab }) => {
  switch (activeTab) {
    case 'Settings':
      return <EditProfileForm />
    case 'Music':
      return <MusicView />
    case 'Splashes':
      return <ContentView />
    default:
      return ''
  }
}

const selectProfile: State => ProfileState = state => state.profile

export default () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const profile: ProfileState = useSelector(selectProfile, shallowEqual)
  const { user } = profile
  const { user: currentUser } = useAuth()
  const { id } = useParams()
  const dispatch = useDispatch()
  if (user && currentUser && id !== currentUser._id) {
    dispatch(push(`/profile/${currentUser._id}`))
  }

  useEffect(() => {
    if (id) {
      dispatch(getUserAction({ id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id])

  const labels = ['Splashes', 'Music', 'Settings']

  return (
    <Navigation pageTitle="Edit Profile">
      <ProfileHeader isFollowing isConnection={false} editMode />
      <ProfileContent
        labels={labels}
        component={renderTab}
        defaultTab={
          urlParams.get('editMusic') === 'true' ? 'Music' : 'Settings'
        }
      />
    </Navigation>
  )
}
