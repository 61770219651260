// @flow
import axios from 'src/settings/axios'
import type { Config } from 'src/utils/types/config'
type GetConfigResponse = {
  data: Config
}

export type GetConfigService = () => Promise<GetConfigResponse>

export const getConfig: GetConfigService = body => axios.get('/config')
