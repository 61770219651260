// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Link from 'src/components/atoms/Link'

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
    justifyContent: props =>
      props.variant === 'received' ? 'flex-start' : 'flex-end'
  },
  alignmentContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%'
    }
  },
  profileLink: {
    order: props => (props.variant === 'received' ? 1 : 2)
  },
  profileImage: {
    minWidth: 60,
    height: 60,
    borderRadius: 60,
    margin: theme.spacing(2),
    backgroundColor: theme.palette.text.label,
    backgroundImage: props => `url(${props.profileImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  messageContainer: {
    order: props => (props.variant === 'received' ? 2 : 1),
    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.3)',
    padding: theme.spacing(2),
    borderRadius: 2,
    backgroundColor: props =>
      props.variant === 'received'
        ? theme.palette.message.main
        : theme.palette.message.light
  },
  text: {
    fontWeight: '300',
    color: props =>
      props.variant === 'received' ? '#FFFFFF' : theme.palette.text.main
  }
}))

type MessageEntryProps = {
  variant?: 'sent' | 'received',
  author: string,
  profileImage?: string,
  message: string
}

export default ({
  variant = 'sent',
  profileImage = '',
  author,
  message
}: MessageEntryProps) => {
  const styles = useStyles({ variant, profileImage })

  return (
    <div className={styles.flexContainer}>
      <div className={styles.alignmentContainer}>
        <Link to={`/profile/${author}`} className={styles.profileLink}>
          <div className={styles.profileImage} />
        </Link>
        <div className={styles.messageContainer}>
          <Typography variant="subtitle2" className={styles.text}>
            {message}
          </Typography>
        </div>
      </div>
    </div>
  )
}
