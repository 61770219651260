// @flow
import React from 'react'
import type { Node } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'

const useRadioStyles = makeStyles(theme => ({
  root: {
    color: props =>
      props.variant === 'header'
        ? theme.palette.text.main
        : props.color
        ? theme.palette[props.color].main
        : theme.palette.text.label,
    padding: theme.spacing(1)
  }
}))

type IconButtonProps = {
  children: Node,
  color?: string,
  ariaLabel: string,
  isLink?: boolean,
  variant?: 'default' | 'header',
  to?: string,
  onClick?: Event => void
}

export default ({
  children,
  color,
  onClick = () => {},
  ariaLabel,
  variant = 'default',
  isLink,
  to,
  ...props
}: IconButtonProps) => {
  const styles = useRadioStyles({ color, variant })

  const iconButton = () => (
    <IconButton
      classes={styles}
      onClick={onClick}
      aria-label={ariaLabel}
      {...props}
    >
      {children}
    </IconButton>
  )

  return isLink && to ? <Link to={to}>{iconButton()}</Link> : iconButton()
}
