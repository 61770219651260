// @flow
import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    }
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(2)
  },
  paper: {
    display: 'flex',
    justifyContent: props => (props.justify ? props.justify : 'space-around'),
    flexWrap: 'wrap',
    width: 500,
    padding: props => (props.transparent ? 0 : theme.spacing(2)),
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '100%'
    }
  }
}))

type PaperListProps = {
  title: string,
  justify?: string,
  children: React.Node
}

export const TransparentList = ({
  title,
  justify,
  children,
  ...props
}: PaperListProps) => {
  const styles = useStyles({ justify, transparent: true })

  return (
    <div className={styles.container} {...props}>
      <Typography variant="h5" className={styles.title}>
        {title}
      </Typography>
      <div className={styles.paper}>{children}</div>
    </div>
  )
}

export default ({ title, children, justify, ...props }: PaperListProps) => {
  const styles = useStyles({ justify })

  return (
    <div className={styles.container} {...props}>
      <Typography variant="h5" className={styles.title}>
        {title}
      </Typography>
      <Paper className={styles.paper}>{children}</Paper>
    </div>
  )
}
