// @flow
import type { ThunkAction } from 'src/utils/types'
import { push } from 'react-router-redux'
import { getServiceErrors } from 'src/utils/api/apiErrors'
import { getNotifications, markRead } from 'src/services/notifications'
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  MARK_READ_REQUEST,
  MARK_READ_SUCCESS,
  MARK_READ_ERROR
} from 'src/state/reducers/notifications'
import type {
  GetNotificationService,
  MarkReadService
} from 'src/services/notifications'

export type GetNotificationsAction = ({
  index: number,
  service?: GetNotificationService
}) => ThunkAction

export const getNotificationsAction: GetNotificationsAction = ({
  index,
  accountLevel,
  service = getNotifications
}) => async dispatch => {
  try {
    dispatch({ type: GET_NOTIFICATIONS_REQUEST })
    const { data } = await service({ index, accountLevel })
    dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: data
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    dispatch({ type: GET_NOTIFICATIONS_ERROR })
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      Promise.reject(errors)
    }
  }
}

export type MarkNotificationReadAction = ({
  notificationId: string,
  service?: MarkReadService
}) => ThunkAction

export const markNotificationReadAction: MarkNotificationReadAction = ({
  notificationId,
  service = markRead
}) => async dispatch => {
  try {
    dispatch({ type: MARK_READ_REQUEST })
    await service({ notificationId })
    dispatch({ type: MARK_READ_SUCCESS, payload: notificationId })
  } catch (err) {
    const errors = getServiceErrors(err)
    dispatch({ type: MARK_READ_ERROR })
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      Promise.reject(errors)
    }
  }
}
