/* eslint-disable jsx-a11y/no-onchange */
// @flow

import React from 'react'
import type { FieldProps } from 'redux-form'
import { Field } from 'redux-form'
import { makeStyles, useTheme } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import classnames from 'classnames'
import type { FieldValidator } from 'src/utils/fieldValidators'

//$FlowFixMe
import Select from 'react-select'
//$FlowFixMe
import CreatableSelect from 'react-select/creatable'
//$FlowFixMe
import AsyncSelect from 'react-select/async'
//$FlowFixMe
import AsyncCreatableSelect from 'react-select/async-creatable'
//$FlowFixMe
import makeAnimated from 'react-select/animated'

const useStyles = makeStyles(theme => ({
  selectContainer: {
    width: props => (props.fullWidth ? '100%' : 250),
    // paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    flex: 1
  },
  selectLabel: {
    fontSize: '1rem',
    color: props =>
      props.error
        ? theme.palette.error.main
        : props.isDisabled
        ? 'rgba(0,0,0,0.54)'
        : props.active
        ? theme.palette.primary.main
        : 'rgba(0,0,0,0.54)',
    transform: props =>
      props.active || props.value
        ? 'translateY(0) scale(0.75)'
        : 'translateY(27px) scale(1)'
  },
  errorContainer: {
    height: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  error: {
    fontWeight: 'bold',
    color: theme.palette.error.main
  }
}))

const createSelectStyles = theme => ({
  container: provided => ({
    ...provided,
    outline: 'none'
  }),
  menu: provided => ({
    ...provided,
    color: theme.palette.text.main,
    zIndex: 9999999
  }),
  control: (styles, state) => ({
    ...styles,
    background: 'none',
    height: 41,
    marginBottom: 4,
    border: 0,
    borderRadius: 0,
    borderBottom: theme.error
      ? `1px solid ${theme.palette.error.main}`
      : state.isDisabled
      ? `1px dashed ${theme.palette.text.label}`
      : `1px solid ${theme.palette.menu.text}`,
    outline: 0,
    '& > div:first-of-type': {
      paddingLeft: 0
    }
  }),
  placeholder: (styles, state) => ({
    ...styles,
    display: 'none'
  }),
  menuPortal: styles => ({ ...styles, zIndex: 99999 })
})

export type Option = {
  value: string,
  label: string
}

type TextFieldSelectProps = {
  id: string,
  options?: Option[],
  value: Option | Option[],
  loadOptions?: () => Promise<Option[]>,
  label?: string,
  isMulti?: boolean,
  className?: string,
  touched?: boolean,
  error?: boolean,
  active?: boolean,
  fullWidth?: boolean,
  isDisabled?: boolean,
  required?: boolean,
  defaultValue?: Option,
  keepDefault?: boolean,
  variant?: 'default' | 'async' | 'async-creatable' | 'creatable'
}

const animatedComponents = makeAnimated()

export const TextFieldSelect = ({
  id,
  options,
  value,
  isMulti,
  error,
  touched,
  active,
  required,
  loadOptions,
  className,
  label,
  fullWidth,
  variant,
  isDisabled,
  ...props
}: TextFieldSelectProps) => {
  const styles = useStyles({
    active,
    value,
    fullWidth,
    isDisabled,
    error: error && touched
  })
  const theme = useTheme()
  const reactSelectStyles = createSelectStyles({
    error: error && touched,
    ...theme
  })

  console.log(error)
  const errorId = `${id}-error`

  const returnSelect = () => {
    switch (variant) {
      case 'async':
        return (
          <AsyncSelect
            id={id}
            cacheOptions
            loadOptions={loadOptions}
            components={animatedComponents}
            value={value}
            isMulti={isMulti}
            styles={reactSelectStyles}
            aria-errormessage={errorId}
            isDisabled={isDisabled}
            {...props}
          />
        )
      case 'async-creatable':
        return (
          <AsyncCreatableSelect
            id={id}
            cacheOptions
            loadOptions={loadOptions}
            components={animatedComponents}
            value={value}
            isMulti={isMulti}
            styles={reactSelectStyles}
            aria-errormessage={errorId}
            isDisabled={isDisabled}
            {...props}
          />
        )
      case 'creatable':
        return (
          <CreatableSelect
            id={id}
            components={animatedComponents}
            value={value}
            isMulti={isMulti}
            styles={reactSelectStyles}
            aria-errormessage={errorId}
            isDisabled={isDisabled}
            options={options}
            {...props}
          />
        )
      default:
        return (
          <>
            <Select
              id={id}
              components={animatedComponents}
              options={options}
              value={value}
              isMulti={isMulti}
              styles={reactSelectStyles}
              aria-errormessage={errorId}
              isDisabled={isDisabled}
              {...props}
            />
          </>
        )
    }
  }

  return (
    <div className={classnames(styles.selectContainer, className)}>
      <InputLabel className={styles.selectLabel}>
        {required ? `${label} *` : label}
      </InputLabel>
      {returnSelect()}
      {error && touched && (
        <Typography className={styles.error} variant="caption">
          {error}
        </Typography>
      )}
      {/* {touched && error && !active && (
          <strong>
            <span id={errorId} className={styles.error}>
              {error}
            </span>
          </strong>
        )} */}
    </div>
  )
}

export const renderSelectField = ({
  value,
  options,
  meta,
  input,
  isMulti,
  defaultValue,
  keepDefault,
  ...props
}: TextFieldSelectProps & FieldProps) => {
  return (
    <TextFieldSelect
      options={options}
      value={input.value}
      isMulti={isMulti}
      touched={meta.touched}
      error={meta.error}
      active={meta.active}
      {...input}
      {...props}
      onChange={value => {
        if (!value) {
          input.onChange(defaultValue && [defaultValue])
        } else {
          if (Array.isArray(value)) {
            input.onChange(
              keepDefault
                ? value
                : value.filter(val => val.label !== defaultValue?.label)
            )
          } else {
            input.onChange(value)
          }
        }
      }}
      onBlur={value => {
        input.onBlur()
      }}
    />
  )
}

type ConnectedTextFieldSelectFieldProps = {
  name: string,
  options?: Option[],
  isMulti?: boolean,
  validators?: FieldValidator[],
  defaultValue?: Object,
  keepDefault?: boolean,
  variant?: 'default' | 'async' | 'async-creatable' | 'creatable'
}

export default ({
  name,
  options,
  isMulti,
  keepDefault,
  validators = [],
  defaultValue,
  ...props
}: ConnectedTextFieldSelectFieldProps) => (
  <Field
    name={name}
    component={renderSelectField}
    options={options}
    isMulti={isMulti}
    validate={[...validators]} // not ideal since validators is already an array.. but flow complains otherwise
    defaultValue={defaultValue}
    keepDefault={keepDefault}
    {...props}
  />
)
