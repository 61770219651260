// @flow
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { reduxForm, reset, SubmissionError } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import ImageUploadField from 'src/components/atoms/ImageUploadField'
import Button from 'src/components/atoms/buttons/Button'
import { uploadProfileImageAction } from 'src/state/actions/profile'
import { validFile } from 'src/utils/fieldValidators'
import type { ReduxFormProps } from 'src/utils/types'

export const FORM_ID = 'editProfileImage'

const useStyles = makeStyles(theme => ({
  modalTitle: {
    fontWeight: 'bold'
  },
  dialog: {
    margin: 0,
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '90vw'
    }
  },
  dialogContent: {
    width: '100%',
    height: 'auto',
    minHeight: 75
  },
  dialogActions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  uploadContainer: {
    width: 145,
    height: 145,
    margin: 'auto'
  }
}))

type EditProfileImageProps = {
  img: string,
  isOpen?: boolean,
  onClose: () => void
}

export const EditBioForm = ({
  pristine,
  submitting,
  invalid,
  error,
  isOpen,
  onClose,
  handleSubmit,
  ...props
}: EditProfileImageProps & ReduxFormProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  return (
    <Dialog
      aria-labelledby="edit-profile-image-dialog-title"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: styles.dialog
      }}
    >
      <form
        onSubmit={handleSubmit(async ({ profileImage }) => {
          try {
            await dispatch(uploadProfileImageAction({ profileImage }))
            onClose()
          } catch (errors) {
            throw new SubmissionError(errors)
          }
        })}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            id="edit-profile-image-dialog-title"
            className={styles.modalTitle}
          >
            Upload Profile Image
          </Typography>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <ImageUploadField
            name="profileImage"
            label="Upload a Square Image"
            classes={{ container: styles.uploadContainer }}
            validators={[validFile]}
          />
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            onClick={() => {
              onClose()
              dispatch(reset(FORM_ID))
            }}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={pristine || submitting || invalid}
            loading={submitting}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default reduxForm({
  form: FORM_ID
})(EditBioForm)
