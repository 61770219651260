// @flow

import { push } from 'react-router-redux'
import { batch } from 'react-redux'
import type { ThunkAction } from 'src/utils/types'
import type { User } from 'src/utils/types/users'
import type {
  GetUserService,
  UpdateUserService,
  UploadProfileImageService
} from 'src/services/users'
import type {
  AddNetworkService,
  GetNetworkService,
  RemoveNetworkService,
  AddConnectionService,
  RemoveConnectionService,
  ConfirmConnectionService
} from 'src/services/network'
import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_VIDEOS_BY_USER_REQUEST,
  GET_VIDEOS_BY_USER_SUCCESS,
  ADD_NETWORK_REQUEST,
  ADD_NETWORK_SUCCESS,
  ADD_NETWORK_ERROR,
  REMOVE_NETWORK_SUCCESS,
  REMOVE_NETWORK_REQUEST,
  REMOVE_NETWORK_ERROR,
  GET_NETWORK_REQUEST,
  GET_NETWORK_SUCCESS,
  GET_NETWORK_ERROR,
  UPLOAD_PROFILE_IMAGE_REQUEST,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_ERROR,
  ADD_CONNECTION_REQUEST,
  ADD_CONNECTION_SUCCESS,
  ADD_CONNECTION_ERROR,
  CONFIRM_CONNECTION_REQUEST,
  CONFIRM_CONNECTION_SUCCESS,
  REMOVE_CONNECTION_REQUEST,
  REMOVE_CONNECTION_SUCCESS,
  REMOVE_CONNECTION_ERROR
} from 'src/state/reducers/profile'
import {
  GET_SPLASHES_BY_USER_REQUEST,
  GET_SPLASHES_BY_USER_SUCCESS
} from 'src/state/reducers/splashes'
import { getUser, updateUser, uploadProfileImage } from 'src/services/users'
import { getVideosByUser } from 'src/services/content'
import { getSplashesByUser } from 'src/services/splashes'
import {
  addNetwork,
  getNetwork,
  removeNetwork,
  addConnection,
  removeConnection,
  confirmConnection
} from 'src/services/network'

import { getServiceErrors } from 'src/utils/api/apiErrors'
import { resizeImage } from 'src/utils/api/formatFormData'

export type UploadProfileImageParams = {
  profileImage: {
    name: string,
    type: string,
    size: number,
    lastMod: number,
    lastModDate: string,
    img: string
  },
  service?: UploadProfileImageService
}

export type UploadProfileImageAction = UploadProfileImageParams => ThunkAction

export const uploadProfileImageAction: UploadProfileImageAction = ({
  profileImage,
  service = uploadProfileImage
}) => async dispatch => {
  try {
    dispatch({ type: UPLOAD_PROFILE_IMAGE_REQUEST })
    const resizedFile = await resizeImage(profileImage)
    const { data } = await service({ profileImage: resizedFile })
    dispatch({
      type: UPLOAD_PROFILE_IMAGE_SUCCESS,
      payload: data
    })

    return data
  } catch (err) {
    console.error(err)
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({
        type: UPLOAD_PROFILE_IMAGE_ERROR,
        message: 'There was an error uploading your profile image'
      })
    }
  }
}
export type UpdateUserParams = {
  user: User,
  fields: any,
  service?: UpdateUserService
}

export type UpdateUserAction = UpdateUserParams => ThunkAction

export const updateUserAction: UpdateUserAction = ({
  user,
  fields,
  service = updateUser
}) => async dispatch => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST })
    const { data } = await service(fields)
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data
    })
    return data
  } catch (err) {
    console.log(err)
    dispatch({
      type: UPDATE_USER_ERROR,
      message: 'There was an error updating your profile'
    })
  }
}

export type GetUserParams = {
  id: string,
  refetchProfile?: boolean, //refetch profile flag used to keep logged in user updated
  service?: GetUserService
}

export type GetUserAction = GetUserParams => ThunkAction

export const getUserAction: GetUserAction = ({
  id,
  refetchProfile,
  service = getUser
}) => async dispatch => {
  try {
    if (refetchProfile) {
      await service({
        id
      })
    } else {
      dispatch({ type: GET_USER_REQUEST })
      const { data } = await service({
        id
      })

      dispatch({ type: GET_USER_SUCCESS, payload: data })
    }
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
    return Promise.reject(getServiceErrors(err))
  }
}

export type GetUserProfileContentParams = {
  id: string
}

export type GetUserProfileContentAction = GetUserProfileContentParams => ThunkAction

export const getUserProfileContentAction: GetUserProfileContentAction = ({
  id
}) => async dispatch => {
  try {
    batch(() => {
      dispatch({ type: GET_VIDEOS_BY_USER_REQUEST })
      dispatch({ type: GET_SPLASHES_BY_USER_REQUEST })
    })

    const { data: videos } = await getVideosByUser({ id })
    const { data: splashes } = await getSplashesByUser({ id })

    batch(() => {
      dispatch({ type: GET_VIDEOS_BY_USER_SUCCESS, payload: videos })
      dispatch({ type: GET_SPLASHES_BY_USER_SUCCESS, payload: splashes })
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
    return Promise.reject(getServiceErrors(err))
  }
}

export type AddConnectionParams = {
  recipient: string,
  service?: AddConnectionService
}

export type AddConnectionAction = AddConnectionParams => ThunkAction

export const addConnectionAction: AddConnectionAction = ({
  recipient,
  service = addConnection
}) => async dispatch => {
  dispatch({
    type: ADD_CONNECTION_REQUEST
  })

  try {
    await service({ recipient })
    dispatch({
      type: ADD_CONNECTION_SUCCESS
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({ type: ADD_CONNECTION_ERROR })
    }
  }
}

export type ConfirmConnectionParams = {
  sender: string,
  service?: ConfirmConnectionService
}

export type ConfirmConnectionAction = ConfirmConnectionParams => ThunkAction

export const confirmConnectionAction: ConfirmConnectionAction = ({
  sender,
  service = confirmConnection
}) => async dispatch => {
  try {
    dispatch({
      type: CONFIRM_CONNECTION_REQUEST
    })
    await service({ sender })
    dispatch({
      type: CONFIRM_CONNECTION_SUCCESS
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
  }
}

export type RemoveConnectionParams = {
  recipient: string,
  service?: RemoveConnectionService
}

export type RemoveConnectionAction = RemoveConnectionParams => ThunkAction

export const removeConnectionAction: RemoveConnectionAction = ({
  recipient,
  service = removeConnection
}) => async dispatch => {
  dispatch({
    type: REMOVE_CONNECTION_REQUEST
  })

  try {
    await service({ recipient })
    dispatch({
      type: REMOVE_CONNECTION_SUCCESS
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({ type: REMOVE_CONNECTION_ERROR })
    }
  }
}

export type AddNetworkParams = {
  recipient: string,
  followedByPublisher?: boolean,
  service?: AddNetworkService
}

export type AddNetworkAction = AddNetworkParams => ThunkAction

export const addNetworkAction: AddNetworkAction = ({
  recipient,
  followedByPublisher,
  service = addNetwork
}) => async dispatch => {
  dispatch({
    type: ADD_NETWORK_REQUEST
  })

  try {
    await service({ recipient })
    dispatch({
      type: ADD_NETWORK_SUCCESS,
      payload: { followedByPublisher }
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({ type: ADD_NETWORK_ERROR })
    }
  }
}

export type RemoveNetworkParams = {
  recipient: string,
  followedByPublisher?: boolean,
  service?: RemoveNetworkService
}

export type RemoveNetworkAction = RemoveNetworkParams => ThunkAction

export const removeNetworkAction: RemoveNetworkAction = ({
  recipient,
  followedByPublisher,
  service = removeNetwork
}) => async dispatch => {
  dispatch({
    type: REMOVE_NETWORK_REQUEST,
    payload: { followedByPublisher }
  })

  try {
    await service({ recipient })
    dispatch({
      type: REMOVE_NETWORK_SUCCESS,
      payload: { followedByPublisher }
    })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    } else {
      dispatch({ type: REMOVE_NETWORK_ERROR })
    }
  }
}

export type GetNetworkParams = {
  userId: string,
  service?: GetNetworkService
}

export type GetNetworkAction = GetNetworkParams => ThunkAction

export const getNetworkAction: GetNetworkAction = ({
  userId,
  service = getNetwork
}) => async dispatch => {
  try {
    dispatch({ type: GET_NETWORK_REQUEST })
    const { data } = await service({ userId })
    dispatch({ type: GET_NETWORK_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
    dispatch({ type: GET_NETWORK_ERROR })
    console.error(err)
  }
}
