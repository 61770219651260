// @flow
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Field } from 'redux-form'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import TabView from 'src/components/atoms/Tabs'
import Tab from 'src/components/atoms/Tab'
import Button from 'src/components/atoms/buttons/Button'
import {
  SPLASH_DESC,
  BOOST_DESC
} from 'src/components/organisms/forms/PostType/index.consts'
import type { PostUsage } from 'src/utils/types/users'

const useStyles = makeStyles(theme => ({
  dialog: {
    margin: 0,
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '90vw'
    }
  },
  dialogTitle: {
    fontWeight: 'bold'
  },
  typeDescription: {
    marginTop: theme.spacing(4),
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.label
  },
  amountRemaining: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.label
  }
}))

const getPostType = (tab: number) => {
  switch (tab) {
    case 0:
      return 'SPLASH'
    case 1:
      return 'BOOST'
    default:
      return 'SPLASH'
  }
}

const renderPostTypeField = ({ input, _onChange, styles }) => {
  const { onChange } = input
  return (
    <TabView
      variant="fullWidth"
      onChange={(e, val) => {
        onChange(getPostType(val))
        _onChange(val)
      }}
      margin={0}
    >
      <Tab label="SPLASH" />
    </TabView>
  )
}

type PostTypeProps = {
  formId: string,
  isOpen: boolean,
  postUsage?: PostUsage,
  disabled?: boolean,
  submitting?: boolean,
  onClose: () => void
}

export default ({
  formId,
  isOpen,
  postUsage,
  onClose,
  disabled,
  submitting
}: PostTypeProps) => {
  const styles = useStyles()
  const [formData, setFormData] = useState({
    currentTab: 0,
    postType: 'SPLASH'
  })

  const postLimitReached =
    (formData.postType === 'SPLASH' &&
      postUsage &&
      postUsage.splashesUsed === 4) ||
    (formData.postType === 'BOOST' && postUsage && postUsage.boostsUsed >= 1)

  return (
    <Dialog
      aria-labelledby="create-post-modal-title"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: styles.dialog
      }}
    >
      <DialogTitle>
        <Typography variant="h5" className={styles.dialogTitle}>
          Splashes
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Field
          name="postType"
          component={renderPostTypeField}
          _onChange={val =>
            setFormData({
              ...formData,
              currentTab: val,
              postType: getPostType(val)
            })
          }
          styles={styles}
        />
        <Typography variant="body2" className={styles.typeDescription}>
          {formData.postType === 'SPLASH' ? SPLASH_DESC : BOOST_DESC}
        </Typography>
        <Typography variant="body1" className={styles.amountRemaining}>
          {formData.postType === 'SPLASH'
            ? `${postUsage ? postUsage.splashesUsed : '-'} / 4`
            : `${postUsage ? postUsage.boostsUsed : '-'} / 1`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}> CANCEL </Button>
        <Button
          type="submit"
          form="create-post-form"
          variant="contained"
          color="secondary"
          loading={submitting}
          disabled={disabled || submitting || postLimitReached}
        >
          SUBMIT POST
        </Button>
      </DialogActions>
    </Dialog>
  )
}
