// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'src/components/atoms/buttons/Button'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  error: {
    display: 'block',
    color: theme.palette.error.main,
    fontWeight: 'bold'
  }
}))

type CancelSubscriptionDialogProps = {
  isOpen?: boolean,
  onClose: () => void,
  onCancelSubscription: () => void,
  error?: string,
  isCancelling?: boolean
}

export default ({
  isOpen,
  onClose,
  error,
  isCancelling,
  onCancelSubscription
}: CancelSubscriptionDialogProps) => {
  const styles = useStyles()
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Cancel Subscription</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {error ? (
            <span className={styles.error}>{error}</span>
          ) : (
            'Are you sure you want cancel your subscription?'
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={onClose}>
          CLOSE
        </Button>
        {!error && (
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            loading={isCancelling}
            disabled={isCancelling}
            onClick={async () => {
              await onCancelSubscription()
              onClose()
            }}
          >
            CANCEL SUBSCRIPTION
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
