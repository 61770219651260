/* eslint-disable react-hooks/exhaustive-deps */
// @flow

import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import type { State } from 'src/utils/types'
import type { DashboardState } from 'src/state/reducers/dashboard'
import type { NotificationState } from 'src/state/reducers/notifications'
import Button from 'src/components/atoms/buttons/Button'
import Paper from '@material-ui/core/Paper'
import { Flex } from 'src/components/molecules/Flex'
import Navigation from 'src/components/organisms/Navigation'
import Typography from '@material-ui/core/Typography'
import SplashCard from 'src/components/molecules/SplashCard'
import LoadingIndicator from 'src/components/atoms/LoadingIndicator'
import { getSplashFeedAction } from 'src/state/actions/dashboard'
import { clapAction } from 'src/state/actions/splashes'
import Notification from 'src/components/molecules/Notification'
import { useAnalytics } from 'src/hooks/useAnalytics'

import { ReactComponent as FollowerIcon } from 'src/images/followers.svg'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    minHeight: '100%',
    padding: `${theme.spacing(2)}px 0px`,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  feedContainer: {
    width: '60%',
    minHeight: '100%',
    padding: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(2)}px 0px`,
      width: '95%',
      order: 2
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3)
    }
  },
  recentNotifContainer: {
    width: '35%',
    minHeight: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      // width: '95%',
      // height: '40%',
      // order: 1
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3)
    }
  },
  header: {
    color: theme.palette.text.label,
    fontWeight: 'normal'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  feed: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    margin: 'auto',
    marginTop: theme.spacing(4),
    '& > div': {
      marginRight: theme.spacing(3),
      // marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(4)
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      '& > div': {
        marginRight: theme.spacing(1)
      }
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      '& > div': {
        marginRight: 0,
        marginLeft: 0
      }
    }
  },
  loadMoreContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  loadMoreText: {
    color: theme.palette.text.label
  },
  emptyFeedText: {
    color: theme.palette.text.label,
    textAlign: 'center',
    marginTop: theme.spacing(4)
  },
  followerIcon: {
    width: 100,
    height: 100,
    margin: 'auto',
    marginBottom: theme.spacing(1),
    opacity: 0.7
  },
  noNewNotifs: {
    color: theme.palette.text.label,
    marginTop: theme.spacing(4),
    textAlign: 'center'
  }
}))

const selectState: State => DashboardState & NotificationState = state => ({
  ...state.dashboard,
  ...state.notifications
})

export default () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { analytics } = useAnalytics()
  const {
    feed,
    feedIndex,
    isFetchingFeed,
    endOfFeed,
    notifications
  } = useSelector(selectState, shallowEqual)

  useEffect(() => {
    dispatch(getSplashFeedAction({ feedIndex: 0 }))
  }, [])

  const onClap = (count: number, id: string) => {
    dispatch(clapAction({ count, splashId: id }))
  }

  const onLoadMore = () => {
    analytics.logEvent('dashboard_load_more_splashes')
    dispatch(getSplashFeedAction({ feedIndex }))
  }

  return (
    <Navigation pageTitle="Dashboard">
      <div className={styles.wrapper}>
        <Paper className={styles.feedContainer}>
          <Typography variant="h6" className={styles.header}>
            SPLASH FEED
          </Typography>
          {isFetchingFeed && (
            <div className={styles.loadingContainer}>
              <LoadingIndicator />
            </div>
          )}
          <div className={styles.feed}>
            {feed.map((splash, index) => (
              <SplashCard
                key={index}
                noPadding
                size="lg"
                splash={splash}
                onClap={onClap}
              />
            ))}
          </div>
          <div className={styles.loadMoreContainer}>
            {feed.length > 0 && (
              <Button
                onClick={onLoadMore}
                disabled={endOfFeed}
                loading={isFetchingFeed}
              >
                <Typography variant="h6" className={styles.loadMoreText}>
                  {!endOfFeed && 'LOAD MORE'}
                </Typography>
              </Button>
            )}
            <Flex
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {!isFetchingFeed && feed.length === 0 && (
                <>
                  <FollowerIcon className={styles.followerIcon} />
                  <Typography variant="h5" className={styles.emptyFeedText}>
                    Splashes from people you follow will show here. Go search
                    for songwriters!
                  </Typography>
                </>
              )}
            </Flex>
          </div>
        </Paper>
        <Paper className={styles.recentNotifContainer}>
          <Typography variant="h6" className={styles.header}>
            NOTIFICATIONS
          </Typography>
          {!notifications.length && (
            <Typography variant="h5" className={styles.noNewNotifs}>
              No New Notifications
            </Typography>
          )}
          {notifications
            ?.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
            .map(notif => (
              <Notification key={notif._id} notification={notif} />
            ))}
        </Paper>
      </div>
    </Navigation>
  )
}
