// @flow

import React, { useState } from 'react'
import type { Notification } from 'src/utils/types/notifications'
import { makeStyles } from '@material-ui/styles'
import Modal from 'src/components/molecules/Modal'
import Typography from '@material-ui/core/Typography'
import NotificationComp from 'src/components/molecules/Notification'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from 'src/components/atoms/buttons/IconButton'
import { Flex } from 'src/components/molecules/Flex'
import Button from 'src/components/atoms/buttons/Button'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: 500,
    minHeight: 600,
    padding: theme.spacing(3),
    paddingBottom: 0,
    paddingTop: 0,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      height: '95%'
    }
  },
  notificationsHeader: {
    color: theme.palette.text.main,
    fontWeight: 'bold'
  },
  headerWrapper: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff'
  },
  noNewNotifs: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    color: theme.palette.text.label
  }
}))

export type NotificationModalProps = {
  isOpen?: boolean,
  setOpen: boolean => void,
  isLoading?: boolean,
  noNewNotifications?: boolean,
  notifications?: Notification[],
  loadMore?: () => Promise<void>
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  isOpen,
  setOpen,
  noNewNotifications,
  notifications,
  isLoading,
  loadMore
}: NotificationModalProps) => {
  const styles = useStyles()
  const [loadingMore, setLoadingMore] = useState(false)

  return (
    <Modal open={isOpen} setOpen={setOpen} paperClassName={styles.paper}>
      <Flex
        className={styles.headerWrapper}
        container
        justifyContent="space-between"
        alignItems="center"
        padding="16px 0 8px 0"
      >
        <Typography variant="h5" className={styles.notificationsHeader}>
          Notifications
        </Typography>
        <IconButton
          variant="header"
          ariaLabel="close modal window"
          className={styles.closeIcon}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </Flex>

      <div className={styles.notifications}>
        {!notifications?.length && (
          <Typography variant="h5" className={styles.noNewNotifs}>
            No New Notifications
          </Typography>
        )}
        {notifications
          ?.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
          .map(notif => (
            <NotificationComp key={notif._id} notification={notif} />
          ))}
      </div>
      <Flex container justifyContent="center" margin="16px 0 16px 0">
        {noNewNotifications ? (
          ''
        ) : (
          <Button
            onClick={async () => {
              setLoadingMore(true)
              loadMore && (await loadMore())
              setLoadingMore(false)
            }}
            variant="outlined"
            disabled={isLoading}
            loading={isLoading || loadingMore}
          >
            LOAD MORE
          </Button>
        )}
      </Flex>
    </Modal>
  )
}
