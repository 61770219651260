// @flow
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  paper: {
    height: '90vh',
    position: 'relative'
  },
  form: {
    display: 'inline'
  },
  clearAll: {
    marginRight: 15
  },
  submitButton: {
    width: 175
  },
  filters: {
    position: 'relative',
    padding: theme.spacing(4, 4),
    height: `calc(100% - 50px - 75px)`, //need to subtract top and bottom padding
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch'
  },
  filterSection: {
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.border.main}`,
    '&:nth-last-child(1)': {
      borderBottom: 'none',
      marginBottom: 0,
      paddingBottom: 0
    }
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing(2)
  },
  streamFilterDesc: {
    color: theme.palette.text.label,
    fontSize: '0.75rem'
  },
  rangeDivider: {
    margin: ` 0px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      margin: `0px ${theme.spacing(2)}px`
    }
  }
}))
